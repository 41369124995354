import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { SenderSubscription, SenderSubscriptionEvent } from '@campaign-portal/namespace/entities/sender-id/specs';
import { SenderSubscriptionEventStatus, SenderSubscriptionEventType } from '@campaign-portal/namespace/common/enums';
import { AlarisDialogService } from '@campaign-portal/components-library';

import { SendersService } from '../../services/senders.service';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';

export interface SendersDialogData {
	type: SendersDialogType;
	senderId: SenderSubscription<exist>;
}

export type SendersDialogType = 'Unsubscribe' | 'Cancel';

@Component({
	selector: 'app-sender-dialog',
	templateUrl: './sender-dialog.component.html',
	styleUrls: ['./sender-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderDialogComponent extends CanDeactivateComponent implements OnDestroy {
	senderId!: SenderSubscription<exist>;
	readonly comment = new FormControl('');
	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: SendersDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly sendersService: SendersService,
		private readonly cd: ChangeDetectorRef,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.senderId = data.senderId;
	}

	get disabled(): boolean {
		return this.loading$.getValue();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Unsubscribe':
				const unsubscribeEvent: SenderSubscriptionEvent = {
					history: [],
					sender: this.senderId,
					id: null,
					type: SenderSubscriptionEventType.SUBSCRIPTION_UPDATE,
					status: SenderSubscriptionEventStatus.UNSUBSCRIBED,
					comment: this.comment.value
				};
				caller = this.sendersService.unsubscribe(unsubscribeEvent);
				break;
			case 'Cancel':
				const cancelEvent: SenderSubscriptionEvent = {
					id: null,
					type: SenderSubscriptionEventType.SUBSCRIPTION_REGISTRATION,
					status: SenderSubscriptionEventStatus.CANCELLED,
					sender: this.senderId,
					history: []
				};
				caller = this.sendersService.cancel(cancelEvent);
				break;
			default:
				break;
		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
