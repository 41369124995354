@switch (type) {
	@case ('Campaigns') {
		<alaris-icon class="icon" name="icon-paper-plane-tilt"></alaris-icon>
		<fieldset class="content">
			<span class="title">{{ 'notifications.titles.campaign' | translate }}</span>
			<div class="note">{{ 'dashboard.createCampaigns' | translate }}</div>
		</fieldset>
		<button (click)="confirm()" alaris-button bType="primary" data-testid="createCampaignLink" size="lg">
			{{ 'dashboard.createCampaign' | translate }}
		</button>
		<alaris-link (click)="toCampaignFromFile()" data-testid="campaignFromFileLink" type="secondary">
			{{ 'dashboard.campaignFromFile' | translate }}
		</alaris-link>
	}
	@case ('Contacts') {
		<alaris-icon class="icon" name="icon-user-plus"></alaris-icon>
		<fieldset class="content">
			<span class="title">{{ 'notifications.titles.contact' | translate }}</span>
			<div class="note">{{ 'dashboard.updateClient' | translate }}</div>
		</fieldset>
		<button (click)="confirm()"
				alaris-button bType="primary" data-testid="importContactsLink" size="lg">
			{{ 'dashboard.importContacts' | translate }}
		</button>
		<alaris-link (click)="toStopList()" data-testid="stopListLink" type="secondary">
			{{ 'dashboard.manageStopList' | translate }}
		</alaris-link>
	}
	@case ('SenderId') {
		<alaris-icon class="icon" name="icon-sender-ID"></alaris-icon>
		<fieldset class="content">
			<span class="title">{{ 'notifications.entities.sender' | translate }}</span>
			<div class="note">{{ 'dashboard.addSenderIdToBrand' | translate }}</div>
		</fieldset>
		<button (click)="confirm()" alaris-button bType="primary" data-testid="addSenderIdBtn" size="lg">
			{{ 'dashboard.addSenderId' | translate }}
		</button>
		<br>
	}
	@default {
	}
}
