<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [confirmButtonText]="confirmButtonText"
			   [disabled]="disabled"
			   [loading$]="loading$"
			   class="w-100">

	<ng-container slot="header">
		<ng-container [ngSwitch]="data.type">
			<ng-container *ngIf="contacts">
				<!--				contacts-->
				<ng-container *ngSwitchCase="'Delete'">{{ "contacts.delete" | translate }}</ng-container>
				<ng-container *ngSwitchCase="'MoveToGroup'">
					{{ "contacts.actions.moveToGroup" | translate }}
				</ng-container>
				<ng-container *ngSwitchCase="'RemoveFromGroup'">
					{{ "contacts.actions.removeFromGroup" | translate }}
				</ng-container>
				<ng-container *ngSwitchCase="'AddToGroup'">
					{{ "contacts.actions.addToGroup" | translate }}
				</ng-container>
				<ng-container *ngSwitchCase="'MoveToStopList'">
					{{ "contacts.actions.moveToStopList" | translate }}
				</ng-container>
				<ng-container *ngSwitchCase="'RemoveFromStopList'">
					{{ "contacts.actions.removeFromStopList" | translate }}
				</ng-container>
				<ng-container *ngSwitchCase="'CampaignUsage'">
					{{ "contacts.relatedCampaigns" | translate }}
				</ng-container>
			</ng-container>

			<ng-container *ngIf="groups">
				<!--					contactGroups-->
				<ng-container *ngSwitchCase="'Delete'">
					{{ "contacts.actions.deleteGroup" | translate }}
				</ng-container>
				<ng-container *ngSwitchCase="'addToGroup'">
					{{ "contacts.actions.addToGroup" | translate }}
				</ng-container>
				<ng-container *ngSwitchCase="'moveToGroup'">
					{{ "contacts.actions.moveToGroup" | translate }}
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>

	<ng-container slot="content">
		@if (details.length > 0
		&& activeImports().length === 0
		&& profile.allowed([CP_PERMISSIONS.CAMPAIGNS_R])) {
			<div class="details">
				<p><strong>
					{{ 'contacts.campaignUsageSource' | translate: { entity: sourceEntity } }},
					({{ details.length }}):
				</strong></p>
				<ng-container *ngTemplateOutlet="campaignsList; context: {list: details}"></ng-container>
			</div>
		}
		<div [ngSwitch]="data.type" class="actions">
			<ng-container *ngIf="contacts">
				<!--				contacts-->
				<ng-container *ngSwitchCase="'Delete'">
					<p>{{ 'contacts.actions.deleteNote' | translate }}</p>
					<ul *ngIf="details.length === 0" class="list">
						<li *ngFor="let contact of contacts">{{ contact.phone }}</li>
					</ul>
				</ng-container>

				<ng-container *ngSwitchCase="'MoveToGroup'">
					<p>{{ 'contacts.actions.moveToGroupNote' | translate }}:</p>
					<ng-container *ngTemplateOutlet="groupsControl"></ng-container>
				</ng-container>

				<ng-container *ngSwitchCase="'RemoveFromGroup'">
					<p>{{ 'contacts.actions.removeFromGroupNote' | translate }}:</p>
					<ng-container *ngTemplateOutlet="groupsControl"></ng-container>
				</ng-container>

				<ng-container *ngSwitchCase="'AddToGroup'">
					<p>{{ 'contacts.actions.addToGroupNote' | translate }}:</p>
					<ng-container *ngTemplateOutlet="groupsControl"></ng-container>
				</ng-container>

				<ng-container *ngSwitchCase="'MoveToStopList'">
					<p>{{ 'contacts.actions.moveToStopListNote' | translate }}</p>
					<ul *ngIf="details.length === 0" class="list">
						<li *ngFor="let contact of contacts">{{ contact.phone }}</li>
					</ul>
				</ng-container>

				<ng-container *ngSwitchCase="'RemoveFromStopList'">
					<p>{{ 'contacts.actions.removeFromStopList' | translate }}:</p>
					<ng-container *ngTemplateOutlet="groupsControl"></ng-container>
				</ng-container>
			</ng-container>
			<!--					contactGroups-->
			<ng-container *ngIf="groups">
				<ng-container *ngSwitchCase="'Delete'">
					@if (activeImports().length) {
						<p>{{ 'contacts.activeImportsNote' | translate }}</p>
						<ul class="list">
							<li *ngFor="let activeImport of activeImports()">
								{{ activeImport.file.name }}
							</li>
						</ul>
					} @else {
						<p>{{ 'contacts.deleteGroupNote' | translate }}</p>
						<ul class="list">
							<li *ngFor="let cg of groups">
								{{ cg.name | translate }} (Contacts: {{ cg.contactsIncluded }})
							</li>
						</ul>
					}
				</ng-container>
				<ng-container *ngSwitchCase="'addToGroup'">
					<p>{{ "contacts.actions.addToGroupNote" | translate }}</p>
					<ul *ngIf="details.length === 0" class="list">
						<li *ngFor="let cg of groups">
							{{ cg.name | translate }} (Contacts: {{ cg.contactsIncluded }})
						</li>
					</ul>

					<ng-container *ngTemplateOutlet="groupsControl"></ng-container>
				</ng-container>

				<ng-container *ngSwitchCase="'moveToGroup'">
					<p>{{ "contacts.actions.moveToGroupNote" | translate }}</p>
					<ul *ngIf="details.length === 0" class="list">
						<li *ngFor="let group of groups">
							{{ group.name | translate }} (Contacts: {{ group.contactsIncluded }})
						</li>
					</ul>
					<ng-container *ngTemplateOutlet="groupsControl"></ng-container>
				</ng-container>
			</ng-container>
		</div>
		@if (additionalDetails.length > 0 && profile.allowed([CP_PERMISSIONS.CAMPAIGNS_R])) {
			<div class="details">
				<p><strong>
					{{ 'contacts.campaignUsageDestination' | translate: {entity: destinationEntity} }},
					({{ additionalDetails.length }}):
				</strong></p>
				<ng-container *ngTemplateOutlet="campaignsList; context: {list: additionalDetails}"></ng-container>
			</div>
		}

		<alaris-alert *ngIf="showPriceChangeNote" type="warning">
			{{ 'contacts.totalPriceChangeNote' | translate }}
		</alaris-alert>
	</ng-container>
</alaris-dialog>

<ng-template #groupsControl>
	<alaris-select2 [formControl]="groupControl"
					[label]="'contacts.contactGroup' | translate"
					[placeholder]="'contacts.selectContactGroup' | translate"
					[selectedLabel]="'name'" data-testid="groupsSelect">
		<alaris-select-filter2 *ngIf="contactGroups.length > 5"
							   [formControl]="groupFilter" data-testid="groupsFilter">
		</alaris-select-filter2>
		<alaris-options2 *ngFor="let cg of contactGroups"
						 [attr.data-testid]="'groupsOption/' + cg.name" [value]="cg">
			{{ cg.name | translate }}
		</alaris-options2>
	</alaris-select2>
</ng-template>

<ng-template #campaignsList let-list="list">
	<ul class="list">
		<li *ngFor="let campaign of list" class="campaign">
			<span class="name">{{ campaign.name ?? campaign.id }}</span>
			<span class="dots"></span>
			<span class="scheduled">{{ campaign.scheduled | date: 'd MMM y, HH:mm' }}</span>
		</li>
	</ul>
</ng-template>
