import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, Subject, switchMap, takeUntil } from 'rxjs';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	CustomValidators,
	EditPanelInputData,
	filterWildcardData,
	PROFILE_SERVICE_INJECTOR,
	TableEntityField
} from '@campaign-portal/components-library';
import { AlarisMultiSelectDisplayWithFn } from '@campaign-portal/components-library/lib/multi-select2/src/multi-select2/multi-select2.component';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Contact } from '@campaign-portal/namespace/entities/contacts/specs';
import { FieldType, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { CampaignInfo } from '@campaign-portal/namespace/entities/campaigns/specs';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { ContactGroupsService } from '../../../services/contact-groups.service';
import { ContactsService } from '../../../services/contacts.service';
import { ContactCampaignUsageComponent } from '../../../dialogs/contact-campaign-usage/contact-campaign-usage.component';

interface ReservedFields {
	phone: FormControl<number>;
	contactGroups: FormControl<number[] | null>;
}

@Component({
	selector: 'app-edit-contact',
	templateUrl: './edit-contact.component.html',
	styleUrls: ['./edit-contact.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditContactComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly InputComplexType = InputComplexType;
	readonly InputType = InputType;
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	readonly contact: Contact;
	readonly customFields: TableEntityField[];
	readonly toStopList: boolean;
	readonly reservedFieldsForm: FormGroup<ReservedFields>;
	readonly customFieldsForm: UntypedFormGroup;
	readonly filterGroupControl = new FormControl('');
	filterGroupList: ContactGroup<exist>[] = [];

	groupsInfo: CampaignInfo[] = [];
	contactInfo: CampaignInfo[] = [];

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	readonly errors = [
		{ key: 'pattern', value: 'errors.onlyNumbers' }
	];

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profileService: AlarisProfileService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		public readonly contactGroupsService: ContactGroupsService,
		public readonly contactsService: ContactsService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly dialog: AlarisDialogService,
		private readonly guard: CanDeactivateGuardService,
		private readonly translate: AlarisLanguageService,
		private readonly cd: ChangeDetectorRef
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.toStopList = (this.inputData.toStopList as boolean) ?? false;

		this.contact = (this.inputData.contact as Contact) ?? {
			id: null,
			phone: null,
			contactGroups: [],
			channels: [],
			stopList: this.toStopList
		};

		this.customFields = (this.inputData.customFields as TableEntityField[]) ?? [];

		this.reservedFieldsForm = new FormGroup({
			phone: new FormControl(
				this.contact.phone,
				{ nonNullable: true, validators: [Validators.required, Validators.pattern(/^\d+$/)] }),
			contactGroups: new FormControl<Id<exist>[] | null>(
				[...this.contact.contactGroups],
				{ validators: this.contact.stopList ? [] : [CustomValidators.requiredArrayOrNull] }
			)
		});
		this.customFieldsForm = new UntypedFormGroup(
			this.customFields.reduce(
				(result, field) => {
					let initialValue: unknown;
					switch (field.type) {
						case InputComplexType.MULTISELECT: {
							if ( this.contact[field.variable] === undefined ) {
								initialValue = [];
								break;
							}

							initialValue = Array.isArray(this.contact[field.variable])
								? (this.contact[field.variable] as ValueObject[]).map(opt => {
									return field.data?.find(val => val?.value === opt?.value) ?? '';
								})
								: null;
						}
							break;
						case InputComplexType.SELECT:
							initialValue = this.contact[field.variable]
								? field.data?.find(
									val => val?.value === (this.contact[field.variable] as ValueObject)?.value
								) ?? ''
								: null;
							break;
						default:
							initialValue = this.contact[field.variable] ?? null;
					}

					if ( !field.readonly ) {
						const control = new UntypedFormControl(initialValue, {
							validators: field.required ? this.getValidators(field.type) : null
						});
						Object.assign(
							result, { [field.variable]: control }
						);
					}
					return result;
				}, {}
			)
		);
	}

	ngOnInit(): void {
		this.contactGroupsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterGroupList = filterWildcardData(this.filterGroupControl.value, list, 'name');
			});
		this.filterGroupControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterGroupList = filterWildcardData(value, this.contactGroupsService.list, 'name');
			});

		this.reservedFieldsForm.controls.contactGroups.valueChanges
			.pipe(
				switchMap(groupIds => {
					if (
						groupIds && groupIds.length === 0
						|| !this.profileService.allowed([CP_PERMISSIONS.CAMPAIGNS_R, CP_PERMISSIONS.CONTACTS_E])
					) {
						this.groupsInfo = [];
						return EMPTY;
					}
					return this.contactGroupsService.checkCampaignUsage(
						groupIds?.reduce((arr: ContactGroup<exist>[], id) => {
							if ( this.contactGroupsService.map.has(id) ) {
								arr.push(this.contactGroupsService.map.get(id) as ContactGroup<exist>);
							}
							return arr;
						}, []) ?? null
					);
				}),
				takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				if ( resp.Success ) {
					this.groupsInfo = resp.Data;
					this.cd.markForCheck();
				}
			});

		if (
			this.contact.id
			&& !this.contact.stopList
			&& this.profileService.allowed([CP_PERMISSIONS.CAMPAIGNS_R, CP_PERMISSIONS.CONTACTS_E])
		) {
			this.contactsService.checkCampaignUsage(this.contact as Contact<exist>)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((resp) => {
					if ( resp.Success ) {
						this.contactInfo = resp.Data;
					}
				});
		}

		if ( !this.profileService.allowed([CP_PERMISSIONS.CONTACTS_E]) ) {
			this.reservedFieldsForm.disable();
			this.customFieldsForm.disable();
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	save(): void {
		this.updateMultiselectControls();
		const contactGroups = this.reservedFieldsForm.controls.contactGroups.value ?
			this.reservedFieldsForm.controls.contactGroups.value :
			this.contactGroupsService.list.map(item => item.id);
		const contact: Contact = {
			...this.contact,
			phone: this.reservedFieldsForm.controls.phone.value,
			contactGroups,
			...this.customFieldsForm.value
		};

		this.allowedDeactivation.next(false);
		this.contactsService.update(contact)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(resp) => {
					// To display the correct number of contacts in groups
					this.contactGroupsService.refresh$.next();
					
					this.allowedDeactivation.next(true);
					if ( resp.Success ) {
						this.editPanel.close(resp.Success);
					}
				}
			);
	}

	updateMultiselectControls(): void {
		this.customFields.forEach(cf => {
			if ( cf.type === InputComplexType.MULTISELECT && this.customFieldsForm.get(cf.variable)?.value === null ) {
				this.customFieldsForm.get(cf.variable)?.setValue(cf.data);
			}
		});
	}

	close(): void {
		this.editPanel.close();
	}

	updateField(dataKey: string, selectedValue: string): void {
		this.customFieldsForm.get(dataKey)?.setValue(selectedValue);
	}

	displayGroup: AlarisMultiSelectDisplayWithFn<exist> = (ids: exist[] | null): string => {
		if ( ids === null ) {
			return this.translate.translate('gl.all');
		}
		return ids.map((id) => {
			return this.contactGroupsService.map.get(id)?.name ?? '';
		}).join(', ');
	};

	openHintDialog(): void {
		this.dialog.open(ContactCampaignUsageComponent, {
			data: {
				details: this.contactInfo,
				additionalDetails: this.groupsInfo,
				groupsChanged: this.reservedFieldsForm.controls.contactGroups.dirty,
				type: 'CampaignUsage'
			},
			autoFocus: false
		});
	}

	private getValidators(type: FieldType): ValidatorFn {
		switch (type) {
			case InputComplexType.MULTISELECT:
				return CustomValidators.requiredArrayOrNull;
			default:
				return Validators.required;
		}
	}

	// updateGroup($event: ContactGroup<exist>[] | null): void {
	// 	this.reservedFieldsForm.patchValue({
	// 		contactGroups: $event !== null ? $event.map(
	// 			group => group.id
	// 		) : $event
	// 	});
	// }
}
