<alaris-card [class.line]="view === PurchaseView.LIST" class="card" size="auto">
	<div class="title">
		<alaris-icon [name]="cu.icon(package.trafficType)" class="channel-icon"></alaris-icon>
		<span [alarisTooltip]="package.name" [overflow]="true" class="name">{{ package.name }}</span>
	</div>
	<div class="cost">{{ package.packSettings.packPrice | currency: bs.currencyCode }}</div>
	<div class="item">
		<div class="description text-truncate">
			<div>
				<alaris-icon name="icon-paper-plane-right"></alaris-icon>
				<span>{{ 'purchase.channel' | translate }}</span>
			</div>
			<span>
				{{ cu.purpose(package.trafficType, package.settings?.viberSettings?.messagePurpose) | translate }}
			</span>
		</div>
		<div class="description countries-wrapper">
			<div>
				<alaris-icon name="icon-map-pin"></alaris-icon>
				<span>{{ 'purchase.country' | translate }}</span>
			</div>
			<span class="countries-tag">
				<span class="text-truncate">{{ package.countries!.at(0) }}</span>
				<alaris-tag *ngIf="package.countries!.length > 1"
							[alarisTooltip]="'purchase.allCountryHint' | translate"
							(click)="seeAllCountries(package)"
							[rounded]="true" style="transform: scale(.75); cursor: pointer">
							+{{ package.countries!.length - 1 }}
					</alaris-tag>
			</span>
		</div>
		<div class="description text-truncate">
			<div>
				<alaris-icon name="icon-money-2"></alaris-icon>
				<span>{{ 'purchase.billingMode' | translate }}</span>
			</div>
			<span>
				{{ 'enums.' + package.billingMode | translate }}
			</span>
		</div>
		<div class="description text-truncate">
			<div>
				<alaris-icon name="icon-money-cash"></alaris-icon>
				<span>{{ 'purchase.smsCost' | translate }}</span>
			</div>
			<span>
				{{
					package.packSettings.packPrice / package.messagesTotal
						| currency: bs.currencyCode : 'symbol' : '1.2-5'
				}}
			</span>
		</div>
		<div class="description text-truncate">
			<div>
				<alaris-icon name="icon-package"></alaris-icon>
				<span>{{ 'purchase.smsVolume' | translate }}</span>
			</div>
			<span>{{ package.messagesTotal }}</span>
		</div>
	</div>
	<div class="button">
		<button (click)="buyNow(package)"
				[attr.data-testid]="'buyPackageBtn/' + package.id"
				alarisButton bType="primary" class="w-100" size="md">
			{{ 'purchase.buyNow' | translate }}
		</button>
	</div>
	<!--	<small *ngIf="package.packSettings.activeTo" class="available-data">-->
	<!--		{{'purchase.availableUntil' | translate}} {{package.packSettings.activeTo | date: 'short'}}-->
	<!--	</small>-->
</alaris-card>
