<alaris-card [alarisLoading]="apiService.loading$">
	@if (((apiService.list$ | async) ?? []).length) {
		<div class="table-management">
			<div class="left">
				<span class="secondary">{{ 'api.keysNote' | translate }}</span>
			</div>
			<div class="right">
				<button (click)="addApiKey()"
						alaris-button bType="primary" data-testid="createBtn" size="lg">
					<alaris-icon class="in-primary" name="icon-add"></alaris-icon>
					{{ 'api.add' | translate }}
				</button>
			</div>
		</div>
	}
	<div [alarisFade]="'vertical'" class="content cards-container">
		@if (!(apiService.loading$ | async) && !((apiService.list$ | async) ?? []).length) {
			<app-empty-table (newKey)="addApiKey()"
							 type="api-keys">
			</app-empty-table>
		} @else {
			<app-api-key *ngFor="let key of apiService.list$ | async"
						 [actions]="actions"
						 [key]="key">
			</app-api-key>
		}
	</div>
	<div class="footer"></div>
</alaris-card>
