<tui-root>
	<div class="dashboard-grid">
		<alaris-card class="overview" size="auto">
			<app-overview></app-overview>
		</alaris-card>
		<div class="row">
			<alaris-card class="by-status" size="auto">
				<app-message-by-status-chart></app-message-by-status-chart>
			</alaris-card>
			<alaris-card *alarisPBAC="[CP_PERMISSIONS.CAMPAIGNS_R]" class="last-campaign" size="auto">
				<app-last-campaign-chart></app-last-campaign-chart>
			</alaris-card>
		</div>
		<alaris-card class="by-channel" size="auto">
			<app-message-by-channel-chart></app-message-by-channel-chart>
		</alaris-card>
	</div>
</tui-root>

<app-chart-templates></app-chart-templates>
