import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { CountryPhoneCode } from '@campaign-portal/namespace/entities/countries/specs';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { FilesReports } from '@campaign-portal/namespace/entities/files/specs';

import {
	AlarisDialogService,
	AlarisFilesService,
	AlarisProfileService,
	ChartUtilsService,
	DialogData,
	DialogNotificationType,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { CountriesService } from '@helpers/services/countries.service';
import { sumElementsInArray } from '@helpers/utils/math';
import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { CampaignWizardService, CONTACTS_SPLIT_PATTERN } from '../campaign-wizard.service';
import { ContactGroupsService } from '../../../contacts/services/contact-groups.service';
import { phone } from '../../campaign-from-file/fields';

enum CampaignRecipientsType {
	GROUP,
	MANUALLY,
	FILE
}

const ChangeRecipientsDialogData: DialogData<boolean> = {
	title: 'campaigns.recipientsType.changeType',
	note: 'campaigns.recipientsType.changeTypeNote',
	closeAction: () => {
		return false;
	},
	confirmAction: () => {
		return true;
	},
	confirmButton: 'actions.confirm',
	closeButton: 'actions.close',
	result: true,
	type: DialogNotificationType.WARNING
};

@Component({
	selector: 'app-campaign-recipients',
	templateUrl: './campaign-recipients.component.html',
	styleUrls: ['./campaign-recipients.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignRecipientsComponent implements OnInit, OnDestroy {

	readonly CampaignRecipientsType = CampaignRecipientsType;
	readonly tabs = [
		{
			label: 'campaigns.recipientsType.manually',
			value: CampaignRecipientsType.MANUALLY
		},
		{
			label: 'campaigns.recipientsType.file',
			value: CampaignRecipientsType.FILE
		}
	];
	readonly recipientsType = new FormControl<CampaignRecipientsType>(
		CampaignRecipientsType.MANUALLY,
		{ nonNullable: true }
	);
	_recipientsType = CampaignRecipientsType.MANUALLY;

	readonly groupsFilter = new FormControl<string>('', { nonNullable: true });
	filteredGroups: ContactGroup<exist>[] = [];
	readonly countriesFilter = new FormControl<string>('', { nonNullable: true });
	filteredCountries: CountryPhoneCode[] = [];
	// Charts
	contactGroupValue: number[] = [];
	contactGroupLabels: string[] = [];
	manualPhonesValue: number[] = [];
	fileUploadAnalysis: number[] = [];

	readonly manualPhonesErrors = [
		{ key: 'pattern', value: 'errors.pattern' },
		{ key: 'limitExceeded', value: 'errors.limitExceeded' }
	];

	readonly fileLoading$ = new BehaviorSubject(false);
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService,
		public readonly contactGroupsService: ContactGroupsService,
		public readonly countriesService: CountriesService,
		public readonly cw: CampaignWizardService,
		private readonly filesService: AlarisFilesService,
		private readonly dialog: AlarisDialogService,
		private readonly cd: ChangeDetectorRef,
		private readonly translate: TranslateService,
		private readonly chartUtils: ChartUtilsService
	) {
	}

	ngOnInit(): void {
		if ( this.profile.allowed([CP_PERMISSIONS.CONTACTS_R]) ) {
			this.contactGroupsService.refresh$.next();
			this.tabs.unshift({
				label: 'campaigns.recipientsType.contactGroups',
				value: CampaignRecipientsType.GROUP
			});
		}
		this._recipientsType = this.cw.contactsControls.value.hasOwnProperty('groups') &&
		this.profile.allowed([CP_PERMISSIONS.CONTACTS_R])
			? CampaignRecipientsType.GROUP
			: this.cw.contactsControls.value.hasOwnProperty('plain')
				? CampaignRecipientsType.MANUALLY
				: this.cw.contactsControls.value.hasOwnProperty('file')
					? CampaignRecipientsType.FILE
					: CampaignRecipientsType.MANUALLY;
		this.recipientsType.patchValue(this._recipientsType);
		this.enableControls(this._recipientsType);

		this.countriesService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filteredCountries = [...list];
			});

		this.countriesFilter.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((filter) => {
				this.filteredCountries = this.countriesService.list.filter((country) => {
					return country.name.toLowerCase().includes(filter.toLowerCase())
						|| ('' + country.code).includes(filter);
				});
			});

		this.cw.contactsPlain.controls.text.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.prepareCharts(CampaignRecipientsType.MANUALLY);
			});

		this.contactGroupsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.filteredGroups = [...this.contactGroupsService.list];
			});

		this.groupsFilter.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((filter) => {
				this.filteredGroups = this.contactGroupsService.list.filter((item) => {
					return item.name.toLowerCase().includes(filter.toLowerCase());
				});
			});

		this.cw.contactGroups.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.prepareCharts(CampaignRecipientsType.GROUP);
			});


		this.recipientsType.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.changeRecipientsType(value);
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.fileLoading$.complete();
	}

	addFiles($event: (FileInfo | undefined)[]): void {
		this.cw.contactsFile.patchValue($event[0]);
		if ( $event[0] ) {
			this.prepareCharts(CampaignRecipientsType.FILE);
		} else {
			const resetData: FilesReports = { correctRow: 0, wrongFormatRow: 0, duplicatesRow: 0 };
			this.updateUploadFromFileChart(resetData);
		}
	}

	displayFnGroups(value: Id<exist>[] | null): string {
		return value === null
			? this.translate.instant('gl.all')
			: this.contactGroupsService.list
				.reduce((result: string[], group) => {
					if ( value.includes(group.id) ) {
						result.push(this.translate.instant(group.name));
					}
					return result;
				}, [])
				.join(', ');
	}

	displayCountryCode(value: number): string {
		const selected = this.countriesService.map.get(value);
		return selected ? `( + ${selected.code} ) ${selected.name}` : '';
	}

	analyseFile(): void {
		if ( this.cw.contactsFile.value?.id ) {
			this.fileLoading$.next(true);
			this.filesService.analysis({
				Data: {
					fileId: this.cw.contactsFile.value.id,
					columnName: phone.variable,
					format: 'number',
					columns: [{
						columnName: phone.variable,
						order: 'col004'
					}]
				}
			})
				.pipe(
					takeUntil(this.cw.contactsFile.valueChanges),
					takeUntil(this.ngUnsubscribe)
				)
				.subscribe({
					next: (resp) => {
						this.updateUploadFromFileChart(resp.Data);
					},
					complete: () => {
						this.fileLoading$.next(false);
					}
				});
		}
	}

	generateTemplate(): void {
		const dataCoding = '\ufeff';
		const headers = this.translate.instant('columns.phone') + '\n';
		const row = 1111 + '\n';
		const csvFile = dataCoding + headers + row;

		const blobFile = new Blob([csvFile], { type: 'text/csv;charset=utf-8' });
		const file = window.URL.createObjectURL(blobFile);
		const anchor = this.document.createElement('a');
		anchor.download = 'contacts_template.csv';
		anchor.href = file;
		anchor.click();
		window.URL.revokeObjectURL(file);
	}

	private updateUploadFromFileChart({ correctRow, wrongFormatRow, duplicatesRow }: FilesReports): void {
		this.fileUploadAnalysis = [correctRow, wrongFormatRow, duplicatesRow];
		this.cw.totalRecipients = correctRow;
		this.cd.markForCheck();
	}

	private changeRecipientsType($event: CampaignRecipientsType): void {
		const { plain, file, groups } = this.cw.contactsControls.value;

		if (
			plain?.text
				|| plain?.code
				|| file
				|| (
					groups === null
					|| (groups && groups.length > 0)
				)
		) {
			this.dialog.openStock(
				ChangeRecipientsDialogData,
				DialogNotificationType.WARNING
			)
				.closed
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((result) => {
					if ( result ) {
						this.cw.contactsControls.reset({
							plain: { code: null, text: '' },
							groups: undefined,
							file: undefined
						});
						this.enableControls($event);
						this._recipientsType = $event;
						this.cd.markForCheck();
					} else {
						this.recipientsType.patchValue(this._recipientsType, { emitEvent: false });
						this.cd.markForCheck();
					}
				});
			return;
		} else {
			this._recipientsType = $event;
			this.enableControls($event);
			this.cd.markForCheck();
		}
	}

	private enableControls($event: CampaignRecipientsType): void {
		switch ($event) {
			case CampaignRecipientsType.GROUP:
				this.cw.contactGroups.enable();
				// this.cw.contactsPlain.controls.code.disable();
				// this.cw.contactsPlain.controls.text.disable();
				this.cw.contactsPlain.disable();
				this.cw.contactsFile.disable();
				break;
			case CampaignRecipientsType.MANUALLY:
				this.cw.contactGroups.disable();
				this.cw.contactsPlain.enable();
				this.cw.contactsFile.disable();
				break;
			case CampaignRecipientsType.FILE:
				this.cw.contactGroups.disable();
				// this.cw.contactsPlain.controls.code.disable();
				// this.cw.contactsPlain.controls.text.disable();
				this.cw.contactsPlain.disable();
				this.cw.contactsFile.enable();
				break;
		}
		this.prepareCharts($event);
	}

	private prepareCharts(type: CampaignRecipientsType): void {
		let value;
		switch (type) {
			case CampaignRecipientsType.GROUP:
				value = this.cw.contactGroups.value;
				if ( Array.isArray(value) && value.length === 1 ) {
					this.contactGroupValue = [this.contactGroupsService.map.get(value[0])!.contactsIncluded ?? 0];
					this.contactGroupLabels = [this.contactGroupsService.map.get(value[0])!.name];
					this.cw.totalRecipients = this.contactGroupsService.map.get(value[0])!.contactsIncluded ?? 0;
				} else if ( value === null || Array.isArray(value) && value.length > 1 ) {
					this.contactGroupsService.analysis(value)
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe(
							(resp) => {
								[this.contactGroupValue, this.contactGroupLabels] = this.chartUtils.sortData(
									...Object.entries(resp.Data.uniq)
										.reduce((result: [number[], string[]], item) => {
											result[0].push(item[1]);
											result[1].push(this.contactGroupsService.map.get(+item[0])?.name || '');
											return result;
										}, [[], []])
								);
								this.cw.totalRecipients = sumElementsInArray(this.contactGroupValue);
								this.contactGroupValue.push(resp.Data.duplicates);
								this.contactGroupLabels.push(this.translate.instant('campaigns.duplicates'));
							}
						);
				} else {
					[this.contactGroupValue, this.contactGroupLabels] = [[], []];
					this.cw.totalRecipients = 0;
				}
				return;
			case CampaignRecipientsType.MANUALLY:
				// todo add validation
				value = this.cw.contactsPlain.controls.text.value ?? '';
				const allManualPhones = value.split(CONTACTS_SPLIT_PATTERN).filter(String) ?? [];
				const uniqPhonesSize = new Set(allManualPhones).size;
				const duplicatesSize = allManualPhones.length - uniqPhonesSize;

				this.manualPhonesValue = [allManualPhones.length, duplicatesSize];
				this.cw.totalRecipients = uniqPhonesSize;
				return;
			case CampaignRecipientsType.FILE:
				value = this.cw.contactsFile.value?.id;
				if ( value ) {
					this.fileLoading$.next(true);
					this.analyseFile();
				}
				return;
		}
	}
}
