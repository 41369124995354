import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { EntityField } from '@campaign-portal/namespace/common/entityField';

// eslint-disable-next-line no-shadow
enum Encodings {
	standard = 'standard',
	extended = 'extended'
}

// Using emojis in an SMS message reduces the character length limit to 70 characters (from 160)
function isEmoji(char: string): boolean {
	return (/\p{Emoji}/ug).test(char)
}

const charset7bit = [
	'@', '£', '$', '¥', 'è', 'é', 'ù', 'ì', 'ò', 'Ç', '\n', 'Ø', 'ø', '\r', 'Å', 'å', 'Δ', '_', 'Φ', 'Γ',
	'Λ', 'Ω', 'Π', 'Ψ', 'Σ', 'Θ', 'Ξ', 'Æ', 'æ', 'ß', 'É', ' ', '!', '"', '#', '¤', '%', '&', '\'', '(', ')',
	'*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>',
	'?', '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S',
	'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§', '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h',
	'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'ä', 'ö', 'ñ',
	'ü', 'à'
];
const charset7bitText = ['\f', '^', '{', '}', '\\', '[', '~', ']', '|', '€'];

// @ts-ignore
const charsInVariable = 10;
const VARIABLE_CHARS_COUNTING = '**********';

@Component({
	selector: 'app-message-counter',
	templateUrl: './message-counter.component.html',
	styleUrls: ['./message-counter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageCounterComponent implements OnChanges {
	@Input() text = '';
	@Input() channel = TrafficType.SMS;
	@Input() variables: EntityField[] = [];
	@Input() variablesAmount = 0;
	readonly channelsType = TrafficType;
	messageCounts = {
		smsChars: 0,
		encoding: Encodings.standard,
		smsParts: 1,
		perSms: 160
	};

	hasVariable = false;

	constructor() {
	}

	ngOnChanges(): void {
		this.messageCounts = {
			...this.smsCounter(this.text)
		};
	}

	smsCounter(text: string): {
		smsChars: number;
		encoding: Encodings;
		smsParts: number;
		perSms: number;
	} {

		const replaceVariables = this.variables.reduce((result, v) => {
			result = result.replace(new RegExp(`%${v.variable}%`, 'g'), VARIABLE_CHARS_COUNTING);
			return result;
		}, text);

		let smsChars = 0;
		let encoding = Encodings.standard;
		let smsParts = 1;
		let perSms = 160;
		for ( const char of replaceVariables ) {
			if ( charset7bit.indexOf(char) >= 0 ) {
				smsChars = smsChars + 1;
			} else if ( charset7bitText.indexOf(char) >= 0 ) {
				smsChars = smsChars + 2;
			} else if ( isEmoji(char) ) {
				encoding = Encodings.extended;
				smsChars = smsChars + 2;
			} else {
				encoding = Encodings.extended;
				smsChars = replaceVariables.length;
				break;
			}
		}
		smsChars += this.variablesAmount * charsInVariable;
		if ( encoding === Encodings.standard ) {
			if ( smsChars > 160 ) {
				smsParts = Math.ceil(smsChars / 153);
				perSms = 153;
			} else {
				perSms = 160;
			}
		} else {
			if ( smsChars > 70 ) {
				smsParts = Math.ceil(smsChars / 67);
				perSms = 67;
			} else {
				perSms = 70;
			}
		}
		return {
			smsChars,
			encoding,
			smsParts,
			perSms
		};
	}

}
