import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {

	constructor() {
	}

	transform<T extends Record<string, unknown>, U>(
		arr: T[] | null,
		field: string,
		value: U,
		condition?: (item: T, value: U) => boolean
	): T[] {
		if ( arr === null ) {
			// todo check if [] is ok
			return [];
		}
		if ( condition ) {
			return arr.filter((item) => {
				return condition(item, value);
			});
		}
		return arr.filter((item) => {
			return item[field] === value;
		});
	}

	// transform(ids: Id<exist> | IdObject<exist>, type: RepoTypeEnum): unknown | null {
	// 	if ( ids === undefined ) {
	// 		return '';
	// 	}
	// 	return this.repo.repoTypePipe(type)(ids);
	// }

}
