<alaris-card [cdkContextMenuTriggerFor]="ctxMenu" class="card" size="auto">
	<div class="info">
		<div class="name text-truncate">{{ key.name }}</div>
		@if (!expired) {
			<alaris-tag mode="success">{{ 'api.active' | translate }}</alaris-tag>
			<div class="expires">
				@if (key.expires) {
					@if (deltaInDays > MONITORED_PERIOD) {
						{{ ('api.expiresOn' | translate) + (key.expires  | date: 'MMM d, y') }}
					} @else {
						{{ ('api.expiresIn' | translate) + (key.expires | timeAgo) }}
					}
				} @else {
					{{ 'api.noExpiration' | translate }}
				}
			</div>
		} @else {
			<div class="alert">{{ 'api.expired' | translate }}</div>
			<div class="expires">
				@if (deltaInDays < -MONITORED_PERIOD) {
					{{
						('api.expired' | translate | titlecase) +
						(key.expires | date: 'MMM d, y')
					}}
				} @else {
					{{
						('api.expired' | translate | titlecase) +
						(key.expires | as: 'string' | timeAgo: ('gl.ago' | translate))
					}}
				}
			</div>
		}
	</div>
	<div class="last-used">
		{{
			key.lastUsed ?
				('api.lastUsed' | translate) + (key.lastUsed  | timeAgo: ('gl.ago' | translate)) :
				'api.neverUsed' | translate
		}}
	</div>
	<button (cdkMenuClosed)="menuOpened = false"
			(cdkMenuOpened)="menuOpened = true"
			(contextmenu)="$event.stopPropagation()"
			[attr.data-testid]="'actionDropdownToggle/' + key.id"
			[cdkMenuPosition]="position"
			[cdkMenuTriggerFor]="ctxMenu"
			[class.opened]="menuOpened" class="actions">
		<alaris-icon name="icon-dot-vertical"></alaris-icon>
	</button>
</alaris-card>

<ng-template #ctxMenu>
	<alaris-ctx-action [actions]="actions"
					   [attr.data-testid]="'dropdownActions/' + key.id"
					   [row]="key"
					   cdkMenu>
	</alaris-ctx-action>
</ng-template>
