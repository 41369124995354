import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ApiToken } from '@campaign-portal/namespace/entities/api/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	EditPanelWidth,
	RowActionSimple
} from '@campaign-portal/components-library';

import { CreateApiKeyComponent } from './create-key/create-key.component';
import { ApiKeysService } from '../api.service';
import { ApiCallbackDialogComponent } from './dialog/callback-dialog.component';

@Component({
	selector: 'app-api-keys',
	templateUrl: './api-keys.component.html',
	styleUrls: ['./api-keys.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiKeysComponent {
	readonly actions: RowActionSimple<ApiToken<exist>>[] = [
		{
			icon: 'icon-arrow-clock-wise',
			label: 'actions.refresh',
			action: this.refresh.bind(this)
		},
		{
			icon: 'icon-link',
			label: 'api.showCallback',
			action: this.showCallback.bind(this),
			highlight: false,
			shown: (key): boolean => {
				return !!key?.callbackURL;
			}
		},
		{
			icon: 'icon-delete',
			label: 'actions.removeKey',
			action: this.delete.bind(this),
			highlight: true
		}
	];

	private readonly destroyedRef = inject(DestroyRef);

	constructor(
		public readonly apiService: ApiKeysService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly dialog: AlarisDialogService
	) {
	}

	addApiKey(): void {
		this.editPanel.open(CreateApiKeyComponent, EditPanelWidth.SM, {})
			.pipe(takeUntilDestroyed(this.destroyedRef))
			.subscribe();
	}

	refresh(key?: ApiToken<exist>): void {
		if ( key?.id ) {
			this.apiService.update([key?.id])
				.pipe(takeUntilDestroyed(this.destroyedRef))
				.subscribe((result) => {
					if ( result ) {
						this.apiService.openSpecificDialog(key.name, result.Data[0]);
					}
				});
		}
	}

	private delete(key?: ApiToken<exist>): void {//() => void {
		if ( key?.id ) {
			this.apiService.delete([key.id])
				.pipe(takeUntilDestroyed(this.destroyedRef))
				.subscribe();
		}
	}

	private showCallback(key?: ApiToken<exist>): void {
		if ( key?.callbackURL ) {
			this.dialog.open(ApiCallbackDialogComponent, { data: key });
		}
	}
}
