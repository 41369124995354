import { NgModule, Type } from '@angular/core';
import {
	TuiAxesModule,
	TuiBarChartModule,
	TuiLegendItemModule,
	TuiLineChartModule,
	TuiRingChartModule
} from '@taiga-ui/addon-charts';


import {
	AlarisAccountSettingsComponent,
	AlarisActivatedComponent,
	AlarisAlertComponent,
	AlarisAsPipe,
	AlarisBadgeComponent,
	AlarisBalanceComponent,
	AlarisButtonComponent,
	AlarisCaptchaComponent,
	AlarisCardComponent,
	AlarisCellContextDirective,
	AlarisCheckboxComponent,
	AlarisComplexTableComponent,
	AlarisComplexTooltipDirective,
	AlarisCopyComponent,
	AlarisCtxActionComponent,
	AlarisDateFilterComponent,
	AlarisDialogComponent,
	AlarisDropdownActionsComponent,
	AlarisDropdownComponent,
	AlarisDropdownDirective,
	AlarisEditPanelComponent,
	AlarisFadeComponent,
	AlarisFadeDirective,
	AlarisFileLoaderComponent,
	AlarisFilterComponent,
	AlarisFilterControlComponent,
	AlarisFilterManagerComponent,
	AlarisFilterPipe,
	AlarisIconDirective,
	AlarisInputComponent,
	AlarisInputDateComponent,
	AlarisInputDateRangeComponent,
	AlarisInputNumberComponent,
	AlarisLanguageComponent,
	AlarisLetDirective,
	AlarisLinkComponent,
	AlarisLoadingDirective,
	AlarisLoadingLineComponent,
	AlarisLocalDateRangeTransformerDirective,
	AlarisLocalDateTransformerDirective,
	AlarisMenuSidebarComponent,
	AlarisMultiOptions2Component,
	AlarisMultiSelect2Component,
	AlarisMultiSelectFilter2Component,
	AlarisNavbarComponent,
	AlarisNoValueCheckPipe,
	AlarisOptions2Component,
	AlarisOverflowFaderDirective,
	AlarisPaginationComponent,
	AlarisParsedFileTableComponent,
	AlarisPBACDirective,
	AlarisPBACPipe,
	AlarisProfileComponent,
	AlarisProgressCircleComponent,
	AlarisProgressLineComponent,
	AlarisRadioComponent,
	AlarisRangeComponent,
	AlarisResetPasswordComponent,
	AlarisRootContentComponent,
	AlarisScheduledComponent,
	AlarisSelect2Component,
	AlarisSelectFilter2Component,
	AlarisSettingsManagerComponent,
	AlarisSignInComponent,
	AlarisSignUpComponent,
	AlarisSpinnerComponent,
	AlarisStepComponent,
	AlarisStepContentComponent,
	AlarisStepLabelComponent,
	AlarisStepperComponent,
	AlarisSystemDateRangeTransformerDirective,
	AlarisSystemDateTransformerDirective,
	AlarisTabComponent,
	AlarisTableComponent,
	AlarisTableSelectionBarComponent,
	AlarisTabsButtonComponent,
	AlarisTabsComponent,
	AlarisTagComponent,
	AlarisTextAreaComponent,
	AlarisToasterModule,
	AlarisToggleComponent,
	AlarisTooltipDirective,
	BindEventPlugin,
	DashboardTimeFilterComponent,
	DAY_TYPE_HANDLER,
	TimeAgoPipe
} from '@campaign-portal/components-library';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { TuiHintModule, TuiRootModule } from '@taiga-ui/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';

const standalones: Type<unknown>[] = [
	AlarisAccountSettingsComponent,
	AlarisAlertComponent,
	AlarisAsPipe,
	AlarisBadgeComponent,
	AlarisBalanceComponent,
	AlarisButtonComponent,
	AlarisCaptchaComponent,
	AlarisCardComponent,
	AlarisCellContextDirective,
	AlarisCheckboxComponent,
	AlarisCopyComponent,
	AlarisCtxActionComponent,
	AlarisDateFilterComponent,
	AlarisDialogComponent,
	AlarisDropdownComponent,
	AlarisDropdownDirective,
	AlarisEditPanelComponent,
	AlarisFadeComponent,
	AlarisFadeDirective,
	AlarisFileLoaderComponent,
	AlarisInputDateComponent,
	AlarisInputDateRangeComponent,
	AlarisInputComponent,
	AlarisInputNumberComponent,
	AlarisLetDirective,
	AlarisLinkComponent,
	AlarisLoadingDirective,
	AlarisMenuSidebarComponent,
	AlarisMultiSelect2Component,
	AlarisMultiSelectFilter2Component,
	AlarisMultiOptions2Component,
	AlarisNavbarComponent,
	AlarisNoValueCheckPipe,
	AlarisPaginationComponent,
	AlarisParsedFileTableComponent,
	AlarisPBACDirective,
	AlarisPBACPipe,
	AlarisSpinnerComponent,
	AlarisProgressLineComponent,
	AlarisLoadingLineComponent,
	AlarisProgressCircleComponent,
	AlarisProfileComponent,
	AlarisRadioComponent,
	AlarisRangeComponent,
	AlarisRootContentComponent,
	AlarisScheduledComponent,
	AlarisSelect2Component,
	AlarisSelectFilter2Component,
	AlarisOptions2Component,
	AlarisSignInComponent,
	AlarisSignUpComponent,
	AlarisStepperComponent,
	AlarisStepComponent,
	AlarisStepLabelComponent,
	AlarisStepContentComponent,
	AlarisComplexTableComponent,
	AlarisFilterPipe,
	AlarisFilterComponent,
	AlarisTableComponent,
	AlarisTableSelectionBarComponent,
	AlarisFilterManagerComponent,
	AlarisFilterControlComponent,
	AlarisSettingsManagerComponent,
	AlarisDropdownActionsComponent,
	AlarisTabsButtonComponent,
	AlarisTabsComponent,
	AlarisTabComponent,
	AlarisTagComponent,
	AlarisTextAreaComponent,
	TimeAgoPipe,
	AlarisToggleComponent,
	AlarisTooltipDirective,
	AlarisComplexTooltipDirective,
	AlarisResetPasswordComponent,
	AlarisActivatedComponent,
	AlarisLanguageComponent,
	AlarisIconDirective,
	AlarisLocalDateRangeTransformerDirective,
	AlarisLocalDateTransformerDirective,
	AlarisSystemDateRangeTransformerDirective,
	AlarisSystemDateTransformerDirective,
	AlarisOverflowFaderDirective,
	DashboardTimeFilterComponent
];

@NgModule({
	declarations: [],
	imports: [
		AlarisToasterModule.forRoot(),
		...standalones
	],
	exports: [
		AlarisToasterModule,
		...standalones,

		// CDK
		CdkAccordionModule,
		CdkMenuModule,

		// Taiga-UI
		TuiBarChartModule,
		TuiAxesModule,
		TuiRingChartModule,
		TuiLegendItemModule,
		TuiHintModule,
		TuiRootModule,
		TuiLineChartModule
	],
	providers: [
		{
			provide: EVENT_MANAGER_PLUGINS,
			useClass: BindEventPlugin,
			multi: true
		},
		{
			provide: DAY_TYPE_HANDLER,
			useValue: (): string => 'weekday'
		}
	]
})
export class StyleComponentsLibsModule {
}
