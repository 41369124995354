import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	ChannelUtilsService,
	EditPanelWidth,
	filterWildcardFn,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple
} from '@campaign-portal/components-library';

import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { MessageTemplate } from '@campaign-portal/namespace/entities/templates/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { Campaign, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { MessageTemplatesService } from '../services/message-templates.service';
import { UpdateTemplateComponent } from './update-template/update-template.component';
import { TemplatesDialogComponent, TemplatesDialogType } from '../shared/dialog/templates-dialog.component';
import { CampaignWizardService } from '../campaign-wizard/campaign-wizard.service';

interface FilterControls {
	trafficType: FormControl<TrafficType | null>;
	sender: FormControl<string>;
	messageTemplate: FormControl<string>;
}

@Component({
	selector: 'app-message-templates',
	templateUrl: './message-templates.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./message-templates.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageTemplatesComponent implements OnInit, OnDestroy {
	readonly tabs = [
		{
			label: 'gl.all',
			value: null
		},
		{
			label: this.cu.name(TrafficType.SMS),
			value: TrafficType.SMS
		}, {
			label: this.cu.name(TrafficType.VIBER),
			value: TrafficType.VIBER
		}];
	actions: RowActionSimple<MessageTemplate>[] = [
		{
			icon: '',
			label: 'campaigns.createCampaign',
			action: this.createCampaign.bind(this)
		},
		{
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.deleteTemplate.bind(this)
		}
	];

	readonly CP_PERMISSIONS = CP_PERMISSIONS;

	filteredTemplates: MessageTemplate[] = [];
	readonly filter = new FormGroup<FilterControls>({
		trafficType: new FormControl<TrafficType | null>(null),
		sender: new FormControl<string>('', { nonNullable: true }),
		messageTemplate: new FormControl<string>('', { nonNullable: true })
	});

	readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly templatesService: MessageTemplatesService,
		private readonly cu: ChannelUtilsService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly dialog: AlarisDialogService,
		private readonly cw: CampaignWizardService,
		private readonly cd: ChangeDetectorRef,
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profileService: AlarisProfileService
	) {
	}

	get hasFilter(): boolean {
		return Object.values(this.filter.value).filter(Boolean).length > 0;
	}

	ngOnInit(): void {
		this.templatesService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filteredTemplates = [...list];
				this.cd.detectChanges();
			});

		this.filter.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filteredTemplates = this.templatesService.list
					.filter((template) => {
						let trafficType = true;
						let sender = true;
						let text = true;
						if ( value.trafficType !== null ) {
							trafficType = template.channelType === value.trafficType;
						}
						if ( value.sender ) {
							sender = filterWildcardFn(
								template.sender?.name.toLowerCase() || '',
								value.sender.toLowerCase()
							);
						}
						if ( value.messageTemplate ) {
							text = filterWildcardFn(
								template.messageTemplate?.toLowerCase() || '',
								value.messageTemplate.toLowerCase()
							);
						}
						return trafficType && sender && text;
					});
				this.cd.markForCheck();
			});

		if ( !this.profileService.allowed([CP_PERMISSIONS.MESSAGE_TEMPLATES_E]) ) {
			this.actions = [
				{
					icon: '',
					label: 'actions.details',
					action: this.updateTemplate.bind(this),
					highlight: false
				}
			];
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	load(): void {
		this.templatesService.read()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.cd.markForCheck();
			});
	}

	updateTemplate(template?: MessageTemplate): void {
		this.editPanel.open(UpdateTemplateComponent, EditPanelWidth.XL, {
			template
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe();
	}

	deleteTemplate(template?: MessageTemplate<exist>): void {
		if ( template ) {
			this.openDialog('Delete', template);
		}
	}

	createCampaign(template?: MessageTemplate): void {
		if ( template ) {
			const campaign: Campaign = {
				id: null,
				info: {
					id: null,
					name: template.name,
					scheduled: '',
					status: CampaignStatus.SCHEDULED
				},
				settings: {},
				setup: {
					channels: [{
						// @ts-ignore-next-line
						sender: template.sender?.id,
						message: {
							text: template.messageTemplate,
							templateID: template.id || undefined
						},
						trafficType: template.channelType || TrafficType.SMS,
						omni: template.channelType === TrafficType.VIBER
							? {
								viberSettings: {
									buttonActionUrl: template.actionUrl,
									imageUrl: template.imageUrl,
									buttonCaption: template.buttonText
								}
							}
							: undefined
					}]
				}
			};
			this.cw.repeat(campaign);
		}
	}

	private openDialog(
		type: TemplatesDialogType,
		item?: MessageTemplate<exist>
	): void {
		this.dialog.open(TemplatesDialogComponent, {
			data: {
				template: item,
				type
			},
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe();
	}
}
