import { FormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseCanDeactivateComponent } from '@helpers/shared/can-deactivate/base-deactivate';

@Component({
	selector: 'app-form-can-deactivate',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class FormCanDeactivateComponent extends BaseCanDeactivateComponent {
	abstract get form(): FormGroup;

	canDeactivate(): boolean {
		return !this.form.dirty;
	}
}
