import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';

import { RowActionSimple } from '@campaign-portal/components-library';

import { ApiToken } from '@campaign-portal/namespace/entities/api/specs';
import { exist } from '@campaign-portal/namespace/common/id';

@Component({
	selector: 'app-api-key',
	templateUrl: './key.component.html',
	styleUrl: './key.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiKeyComponent {

	@Input({ required: true }) key!: ApiToken<exist>;
	@Input() actions: RowActionSimple<ApiToken<exist>>[] = [];
	deltaInDays = 0;
	menuOpened = false;

	readonly MONITORED_PERIOD = 10;
	readonly position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: -4,
			offsetX: 0
		}
	];

	get expired(): boolean {
		if ( this.key.expires ) {
			this.deltaInDays = (new Date(this.key.expires).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
			return new Date(this.key.expires) < new Date();
		}
		return false;
	}
}
