import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Package, Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { Id } from '@campaign-portal/namespace/common/id';

import { AlarisProgressLineType, ChannelUtilsService } from '@campaign-portal/components-library';

import { PurchaseService } from '../../../finance/purchase/purchase.service';

@Component({
	selector: 'app-purchase-card',
	templateUrl: './purchase-card.component.html',
	styleUrls: ['./purchase-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PurchaseCardComponent implements OnChanges {
	@Input() packId: Id = null;
	@Input() plan: Plan | null = null;
	packs: Package[] = [];
	rest = 0;
	total = 0;

	constructor(
		public readonly cu: ChannelUtilsService,
		private readonly purchaseService: PurchaseService
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.packId ) {
			this.packs = this.purchaseService.subscribedPackages.get(this.packId) ?? [];
			const [used, total, locked] = PurchaseService.calcProgress(this.packs);
			this.total = total;
			this.rest = total - used - locked;
		}
	}

	progressType(rest: number, total: number): AlarisProgressLineType {
		return PurchaseService.packUsedState(rest, total);
	}
}
