<div [alarisLoading]="loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-edit-list"></alaris-icon>
		<span>{{
				group.id ?
					('contacts.contactGroup' | translate) + ': ' +
					(group.name | translate) :
					('contacts.addNewGroup'| translate)
			}}
		</span>
	</div>
	<div alarisFade="vertical" class="edit-panel-content">
		@if ((importHistory$ | async)?.length && ([CP_PERMISSIONS.CONTACTS_E] | alarisPBAC)) {
			<alaris-tabs #senderDetailTabs [underline]="true">
				<alaris-tab [label]="'actions.edit' | translate" tabId="edit">
					<ng-container [ngTemplateOutlet]="editOrAddForm"></ng-container>
				</alaris-tab>
				<alaris-tab [label]="'contacts.importHistory' | translate" tabId="importHistory">
					<ng-container [ngTemplateOutlet]="importHistory"></ng-container>
				</alaris-tab>
			</alaris-tabs>
		} @else {
			<ng-container [ngTemplateOutlet]="editOrAddForm"></ng-container>
		}
	</div>

	<div *alarisPBAC="[CP_PERMISSIONS.CONTACTS_E]" class="edit-panel-footer">
		<button (click)="save()"
				[disabled]="form.invalid || !form.dirty"
				alaris-button bType="primary" data-testid="saveBtn"
				size="lg"
				type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (group.id ? 'actions.save' : 'actions.create') | translate }}
		</button>

		<button (click)="close()" alaris-button bType="outline" data-testid="cancelBtn" size="lg" type="button">
			{{ 'actions.cancel' | translate }}
		</button>
	</div>
</div>

<ng-template #editOrAddForm>
	<div [formGroup]="form" class="flex-box">
		@if (group.id) {
			<div class="date-box">
				<div class="date">
					{{ 'contacts.creationDate' | translate }}:
					{{ group.creationDate | date: 'd MMM y, HH:mm' }}
				</div>
				@if (group.lastUpdated) {
					<div class="date">
						{{ 'gl.lastUpdated' | translate | uppercase }}:
						{{ group.lastUpdated | date: 'd MMM y, HH:mm' }}
					</div>
				}
			</div>
		}

		<alaris-input [label]="'contacts.name' | translate"
					  [placeholder]="'contacts.name' | translate"
					  [required]="true"
					  class="w-100" data-testid="nameInput"
					  formControlName="name">
		</alaris-input>
		<alaris-text-area class="w-100" data-testid="descriptionTextarea"
						  formControlName="description">
			{{ 'contacts.description' | translate }}
		</alaris-text-area>
	</div>
</ng-template>

<ng-template #importHistory>
	@for (history of importHistory$ | async; track history; let first = $first) {
		<alaris-card [class.first]="first"
					 class="card"
					 size="auto">
			<div (click)="export(history)" class="file-info" data-testid="exportBtn">
				<alaris-icon class="icon" name="icon-arrow-save"></alaris-icon>
				<div class="header">{{ 'contacts.import.file' | translate }}</div>
				<div class="name">{{ history.file.name }}</div>
			</div>
			<div class="statistics">
				<div class="item">
					<span class="header">{{ 'contacts.totalRows' | translate }}</span>
					<span class="amount">{{ history.total }}</span>
				</div>
				<div class="item">
					<span class="header">{{ 'contacts.addedRows' | translate }}</span>
					<span class="amount">{{ history.added }}</span>
				</div>
				<div class="item">
					<span class="header">{{ 'contacts.updatedRows' | translate }}</span>
					<span class="amount">{{ history.updated }}</span>
				</div>
				<div class="item">
					<span class="header">{{ 'contacts.errorCount' | translate }}</span>
					<span class="amount">{{ history.errors }}</span>
				</div>
			</div>
			<div class="additions-info">
				@if (history?.createdBy) {
					<div class="item user"
						 [alarisTooltip]="(history?.createdBy | noValue) === '-' ? '' : history?.createdBy ?? ''"
						 [overflow]="true">
						<alaris-icon name="icon-user-circle"></alaris-icon>
						<div class="ellipsis">
							{{ history?.createdBy | noValue }}
						</div>
					</div>
				}
				@if (history?.creationDate) {
					<div class="item">
						<alaris-icon name="icon-data"></alaris-icon>
						{{ history?.creationDate  | date: 'd MMM y, HH:mm' }}
					</div>
				}
				@if (history.errors) {
					<button (click)="getErrorReport(history.id)" class="item report" data-testid="errorReportBtn">
						<alaris-icon name="icon-file-arrow-down"></alaris-icon>
						{{ 'contacts.errorReport' | translate }}
					</button>
				}
			</div>
		</alaris-card>
	}
</ng-template>
