<h3 class="title">{{ 'contacts.addContact' | translate }}</h3>

<div [alarisLoading]="contactGroupsService.loading$" class="container">
	<alaris-tabs-button [formControl]="recipientsType"
						[tabs]="tabs"
						class="selector"
						data-testid="recipientsTypeTabs" size="md">
	</alaris-tabs-button>
	<div class="empty"></div>
	<div [ngSwitch]="_recipientsType" class="contacts">
		<ng-container *ngSwitchCase="CampaignRecipientsType.GROUP">
			<alaris-multi-select2
				[displayWith]="displayFnGroups.bind(this)"
				[formControl]="cw.contactGroups"
				[label]="'campaigns.recipientsType.selectGroup' | translate"
				[optionsCount]="(contactGroupsService.list$ | async)?.length || 0"
				[placeholder]="'campaigns.recipientsType.selectGroup' | translate"
				[required]="(cw.contactGroups.value !== null) && recipientsType.value === CampaignRecipientsType.GROUP"
				[stateAddonEnabled]="{ reset: true, state: true }" data-testid="groupsMultiSelect">
				<alaris-multi-select-filter2 [formControl]="groupsFilter"
											 data-testid="groupsFilter">
				</alaris-multi-select-filter2>
				<alaris-multi-options2 *ngFor="let group of filteredGroups"
									   [attr.data-testid]="'groupsOption/' + group.name" [value]="group.id">
					{{ group.name | translate }}
				</alaris-multi-options2>
			</alaris-multi-select2>
		</ng-container>
		<ng-container *ngSwitchCase="CampaignRecipientsType.MANUALLY">
			<div [alarisLoading]="countriesService.loading$" class="plain-recipients">
				<div class="write-contacts-note">
					<span>{{ 'campaigns.writeContactsNote' | translate }}</span>
					<li>{{ 'campaigns.pastPhoneNote' | translate }}</li>
					<li>{{ 'campaigns.phoneFormatNote' | translate }}</li>
				</div>
				<div>
					<alaris-select2
						[displayWith]="displayCountryCode.bind(this)"
						[formControl]="cw.contactsPlain.controls.code"
						[label]="'campaigns.recipientsType.countryCode' | translate"
						[stateAddonEnabled]="{ reset: true, state: true }" data-testid="codeSelect">
						<alaris-select-filter2 [formControl]="countriesFilter"
											   data-testid="codeFilter">
						</alaris-select-filter2>
						<alaris-options2 *ngFor="let opt of filteredCountries"
										 [attr.data-testid]="'codeOptions/' + opt.code" [value]="opt.code">
							( +{{ opt.code }} ) {{ opt.name }}
						</alaris-options2>
					</alaris-select2>
					<!--					<alaris-link class="countries-source" href="https://www.itu.int/pub/T-SP-E.164D-2016"-->
					<!--								 type="secondary">-->
					<!--						{{'campaign.recipients.countryCodeSource' | translate}}-->
					<!--					</alaris-link>-->
				</div>
				<alaris-text-area
					[errors]="manualPhonesErrors"
					[formControl]="cw.contactsPlain.controls.text"
					[required]="recipientsType.value === CampaignRecipientsType.MANUALLY"
					[rows]="9" data-testid="manualPhonesTextarea">
					{{
						cw.contactsPlain.controls.code.value ?
							('campaigns.recipientsType.phonesWithCountry' | translate) :
							('campaigns.recipientsType.phonesExample' | translate)
					}}
				</alaris-text-area>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="CampaignRecipientsType.FILE">
			<div class="write-contacts-note">
				<div>
					{{ 'contacts.import.fileHintPart1' | translate }}
					<alaris-link (click)="generateTemplate()" data-testid="sampleTemplateLink" type="accent">
						{{ 'contacts.import.fileHintPart2' | translate }}
					</alaris-link>
				</div>
				<b>{{ 'campaigns.uploadFileNote' | translate }}</b>
				<li>{{ 'campaigns.fileFormatNote' | translate }}</li>
				<li>{{ 'campaigns.columnFormatNote' | translate }}</li>
				<li>{{ 'campaigns.phoneFormatNote' | translate }}</li>
				<li>{{ 'campaigns.newLineNote' | translate }}</li>
			</div>
			<alaris-file-loader (fileListChanges)="addFiles($event)"
								[exportInternally]="true"
								[fileTypes]="'.csv,.xls,.xlsx,.numbers'"
								[files]="this.cw.contactsFile.value ? [this.cw.contactsFile.value] : []"
								[multiple]="false" data-testid="recipientsFile">
			</alaris-file-loader>
		</ng-container>
	</div>
	<div class="statistics">
		<app-campaign-statistics-chart *ngIf="recipientsType.value === CampaignRecipientsType.GROUP"
									   [labels]="contactGroupLabels"
									   [totalRecipients]="cw.totalRecipients"
									   [value]="contactGroupValue">
		</app-campaign-statistics-chart>

		<app-campaign-statistics-chart *ngIf="recipientsType.value === CampaignRecipientsType.MANUALLY"
									   [labels]="[
									   'campaigns.recipientsType.phoneAmount' | translate,
									   'campaigns.duplicates' | translate]"
									   [totalRecipients]="cw.totalRecipients"
									   [value]="manualPhonesValue">
		</app-campaign-statistics-chart>

		<app-campaign-statistics-chart *ngIf="recipientsType.value === CampaignRecipientsType.FILE"
									   [alarisLoading]="fileLoading$"
									   [labels]="[
												   'campaigns.recipientsType.fileCorrectRows' | translate,
												   'campaigns.recipientsType.fileErrorRows' | translate,
												   'campaigns.recipientsType.fileDuplicateRows' | translate
												   ]"
									   [totalRecipients]="cw.totalRecipients"
									   [value]="fileUploadAnalysis">
		</app-campaign-statistics-chart>
	</div>
</div>

