import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	ChannelUtilsService,
	EditPanelWidth
} from '@campaign-portal/components-library';
import { TabButtonEntity } from '@campaign-portal/components-library/lib/tabs-button/src/base';

import {
	DecisionMode,
	EnabledDisabledStatus,
	MessagePurpose,
	TrafficType
} from '@campaign-portal/namespace/common/enums';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { MessageTemplate } from '@campaign-portal/namespace/entities/templates/specs';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { ChannelsService } from '@helpers/services/channels.service';
import { SenderRequestComponent } from '../../senders/sender-request/sender-request.component';
import { SendersService } from '../../services/senders.service';
import { MessageTemplatesService } from '../../services/message-templates.service';
import { PurchaseService } from '../../../finance/purchase/purchase.service';
import { CampaignWizardService } from '../campaign-wizard.service';
import {
	CampaignChannelSettingsControls,
	CampaignMessageControls,
	CampaignOmniSettingsControls,
	CampaignViberSettingsControls
} from '../../shared/types/form-types';
import { TemplatesDialogComponent } from '../../shared/dialog/templates-dialog.component';

type ChannelsSetupControls = {
	[channelType: string]: FormControl<boolean | null>;
}

@Component({
	selector: 'app-campaign-setup',
	templateUrl: './campaign-setup.component.html',
	styleUrls: ['./campaign-setup.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignSetupComponent implements OnInit, OnDestroy {
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	readonly selectedChannelInTemplate = new FormControl(
		this.cw.channelsArray[0]?.controls.trafficType.value ?? TrafficType.SMS,
		{ nonNullable: true }
	);
	channelsSetupForm!: FormGroup<ChannelsSetupControls>;
	channelsSetupFormSubscription = new Subscription();

	hasSubscriptions = false;
	hasSenders = false;

	readonly senders$ = new BehaviorSubject<Map<TrafficType, SenderSubscription<exist>[]>>(new Map());
	readonly templates$ = new BehaviorSubject<Map<TrafficType, MessageTemplate<exist>[]>>(new Map());

	readonly loading$ = new BehaviorSubject(true);

	private readonly savedTemplate$ = new Subject<{ id: exist; trafficType: TrafficType }>();

	private readonly channelsInTemplateTabs: Map<TrafficType, TabButtonEntity<TrafficType>> = new Map();
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly cw: CampaignWizardService,
		public readonly cu: ChannelUtilsService,
		public readonly sendersService: SendersService,
		public readonly templatesService: MessageTemplatesService,
		public readonly purchaseService: PurchaseService,
		public readonly channelsService: ChannelsService,
		private readonly profile: AlarisProfileService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly dialog: AlarisDialogService,
		private readonly cd: ChangeDetectorRef,
		private readonly router: Router
	) {
	}

	get channels(): TabButtonEntity<TrafficType>[] {
		return Array.from(this.channelsInTemplateTabs.values());
	}

	get buttonActionUrl(): string | undefined {
		return this.cw.findChannel(this.selectedChannelInTemplate.value)
			?.controls.omni
			?.controls.viberSettings
			?.controls.buttonActionUrl.value
			?? undefined;
	}

	get buttonCaption(): string | undefined {
		return this.cw.findChannel(this.selectedChannelInTemplate.value)
			?.controls.omni
			?.controls.viberSettings
			?.controls.buttonCaption.value
			?? undefined;
	}

	get imageUrl(): string | undefined {
		return this.cw.findChannel(this.selectedChannelInTemplate.value)
			?.controls.omni
			?.controls.viberSettings
			?.controls.imageUrl.value
			?? undefined;
	}

	get message(): string {
		const text = this.cw.findChannel(this.selectedChannelInTemplate.value)
			?.controls.message
			?.controls.text.value;
		const template = this.cw.findChannel(this.selectedChannelInTemplate.value)
			?.controls.message
			?.controls.templateID.value;

		if ( template ) {
			return this.templatesService.map.get(template)?.messageTemplate ?? '';
		}
		return text ?? '';
	}

	get sender(): string {
		const sender = this.cw.findChannel(this.selectedChannelInTemplate.value)
			?.controls.sender.value;
		if ( sender ) {
			return this.sendersService.map.get(sender)?.name ?? '';
		}
		return '';
	}


	ngOnInit(): void {
		if ( this.profile.allowed([CP_PERMISSIONS.SENDERS_R]) ) {
			this.sendersService.refresh$.next();
		}

		this.cw.channelsArray.forEach((chSetup) => {
			const ch = chSetup.controls.trafficType.value;
			this.channelsInTemplateTabs.set(
				ch,
				{
					label: this.cu.name(ch),
					icon: this.cu.icon(ch),
					value: ch
				}
			);
		});
		// this.channelsService.refresh$.next();
		// this.templatesService.refresh$.next();
		// this.contactGroups.refresh$.next();
		// this.contactFields.refresh$.next();

		combineLatest([
			this.sendersService.loading$, this.purchaseService.loadingSubscribed$, this.channelsService.loading$
		])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(([ss, subs, ch]) => {
				this.loading$.next(ss || subs || ch);
			});

		combineLatest([
			this.sendersService.active$,
			this.templatesService.list$,
			this.channelsService.list$,
			this.purchaseService.subscribed()
		])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(([senders, templates, channels, subscribed]) => {
				// init
				this.hasSenders = senders.length > 0;
				this.hasSubscriptions = subscribed.Data.packs.size > 0 || subscribed.Data.plans.length > 0;
				this.channelsSetupFormSubscription.unsubscribe();
				this.channelsSetupForm = new FormGroup({});

				const sendersMap = new Map();
				const templateMap = new Map();
				// setup
				channels.forEach((channel) => {
					sendersMap.set(
						channel.channelType,
						senders.filter((sender) => {
							return sender.trafficType === channel.channelType
								&& sender.enabled === EnabledDisabledStatus.ENABLED;
						})
					);
					templateMap.set(
						channel.channelType,
						templates.filter(template => template.channelType === channel.channelType)
					);
					this.channelsSetupForm.addControl(
						channel.channelType,
						new FormControl<boolean>(!!this.cw.findChannel(channel.channelType))
					);
					this.channelsSetupFormSubscription = this.channelsSetupForm
						.controls[channel.channelType]
						.valueChanges
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe((value) => {
							if ( value ) {
								this.setChannelSettings(channel);
							} else {
								this.deleteChannelSettings(channel);
							}
						});
				});
				this.senders$.next(sendersMap);
				this.templates$.next(templateMap);
			});

		combineLatest([this.templates$, this.savedTemplate$])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(([_templates, saved]) => {
				if ( saved ) {
					setTimeout(() => {
						// due to in CampaignTemplateComponent displayTemplate called before ngOnChange called
						// cause there is a subscription to template control value change
						this.cw.findChannel(saved.trafficType)?.controls.message?.controls.templateID.patchValue(saved.id);
					});
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
		this.channelsSetupFormSubscription.unsubscribe();
	}

	moveToPurchase(): void {
		this.router.navigate(['/purchase']);
	}

	openSenderIdRequestPanel(): void {
		this.editPanel.open(SenderRequestComponent, EditPanelWidth.SM, {});
	}

	saveTemplate(channel: Channel<exist>): void {
		const control = this.cw.findChannel(channel.channelType);

		const template: MessageTemplate = {
			id: null,
			name: '',
			messageTemplate: control?.controls.message?.controls.text.value,
			channelType: channel.channelType
		};
		if ( control?.controls.sender.value ) {
			Object.assign(template, {
				sender: {
					id: control.controls.sender.value
				}
			});
		}
		if ( channel.channelType !== TrafficType.SMS ) {
			Object.assign(template, {
				actionUrl: control?.controls.omni?.controls.viberSettings?.controls.buttonActionUrl.value,
				buttonText: control?.controls.omni?.controls.viberSettings?.controls.buttonCaption.value,
				imageUrl: control?.controls.omni?.controls.viberSettings?.controls.imageUrl.value
			});
		}

		this.dialog.open<boolean | MessageTemplate<exist>>(TemplatesDialogComponent, {
			data: {
				template,
				type: 'CreateFromCampaignWizard'
			},
			autoFocus: false
		})
			.closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((res) => {
				if ( res && typeof res === 'object' && res.id ) {
					this.savedTemplate$.next({ id: res.id, trafficType: channel.channelType });
				}
			});
	}

	private deleteChannelSettings(channel: Channel<exist>): void {
		this.channelsInTemplateTabs.delete(channel.channelType);
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const element = this.cw.findChannel(channel.channelType)!;
		const index = this.cw.channelsArray.indexOf(element);
		this.cw.setupControls.channels.removeAt(index);

		this.selectedChannelInTemplate.patchValue(this.selectedChannelInTemplate.value !== channel.channelType ?
			this.selectedChannelInTemplate.value :
			this.cw.channelsArray.find(
				(ch) => {
					return ch.value !== this.selectedChannelInTemplate;
				}
			)?.value.trafficType ?? this.selectedChannelInTemplate.value);

		this.cd.markForCheck();
	}

	//
	private setChannelSettings(channel: Channel<exist>): void {
		this.channelsInTemplateTabs.set(
			channel.channelType,
			{
				label: this.cu.name(channel.channelType),
				icon: this.cu.icon(channel.channelType),
				value: channel.channelType
			}
		);
		const channelSettings = new FormGroup<CampaignChannelSettingsControls>({
			sender: new FormControl<Id<exist> | null>(
				null,
				{
					nonNullable: true,
					validators: Validators.required
				}
			),
			message: new FormGroup<CampaignMessageControls>({
				text: new FormControl<string | undefined>(
					undefined,
					{
						nonNullable: true,
						validators: Validators.required
					}
				),
				templateID: new FormControl<Id<exist> | null>(
					null,
					{ nonNullable: true }
				)
			}),
			trafficType: new FormControl<TrafficType>(
				channel.channelType,
				{ nonNullable: true }
			)
		});
		if ( channel.channelType !== TrafficType.SMS ) {
			const omniControls = new FormGroup<CampaignOmniSettingsControls>({
				fallbackType: new FormControl<DecisionMode>(DecisionMode.BY_SUBMITTED, { nonNullable: true }),
				viberSettings: new FormGroup<CampaignViberSettingsControls>({
					buttonActionUrl: new FormControl<string>('', { nonNullable: true }),
					imageUrl: new FormControl<string>('', { nonNullable: true }),
					buttonCaption: new FormControl<string>('', { nonNullable: true }),
					messagePurpose: new FormControl<MessagePurpose>(MessagePurpose.TRANSACTION, {
						nonNullable: true,
						validators: Validators.required
					})
				}),
				ttl: new FormControl<number>(
					30,
					{
						nonNullable: true,
						validators: [Validators.required, Validators.min(15), Validators.max(1440)]
					}
				)
			});
			channelSettings.addControl(
				'omni',
				omniControls
			);
		}
		this.cw.setupControls.channels.push(channelSettings);
		this.selectedChannelInTemplate.patchValue(channel.channelType);
		this.cd.markForCheck();
	}

}
