import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ApiToken } from '@campaign-portal/namespace/entities/api/specs';
import { exist } from '@campaign-portal/namespace/common/id';

@Component({
	selector: 'app-callback-dialog',
	templateUrl: './callback-dialog.component.html',
	styles: [`
		.callback {
			color: var(--as-TextSecondary);
			--as-icon-size: 1.25rem;
			border: solid 1px var(--as-BorderSecondary);
			padding: 1rem;
			display: grid;
			grid-template-columns: auto 1.5rem;
			justify-content: space-between;
			align-items: center;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiCallbackDialogComponent {

	constructor(
		@Inject(DIALOG_DATA) public readonly data: ApiToken<exist>,
		private readonly dialogRef: DialogRef<void>
	) {
	}

	close(): void {
		this.dialogRef.close();
	}

}
