import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { OperationType } from '@campaign-portal/namespace/common/enums';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Injectable()
export class InvoicesFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly headers: EntityField[] = [
		{
			id: 0,
			name: 'columns.id',
			variable: 'id',
			required: true,
			type: InputType.NUMBER,
			readonly: true,
			filterType: FilterType.LIKE
		},
		{
			id: 1,
			name: 'columns.amount',
			variable: 'amount',
			required: true,
			type: InputComplexType.RANGE,
			data: [],
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 2,
			name: 'columns.msgFromPack',
			variable: 'msgFromPack',
			required: true,
			type: InputType.NUMBER,
			filterType: FilterType.MORE,
			readonly: true
		},
		{
			id: 3,
			name: 'columns.creationDate',
			variable: 'creationDate',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 4,
			name: 'columns.operationType',
			variable: 'operationType',
			required: true,
			readonly: true,
			type: InputComplexType.SELECT,
			filterType: FilterType.EXACT,
			data: [
				{ value: OperationType.CAMPAIGN, name: OperationType.CAMPAIGN },
				{ value: OperationType.PACK_PURCHASE, name: OperationType.PACK_PURCHASE },
				{ value: OperationType.PAYMENT, name: OperationType.PAYMENT }
			]
		},
		{
			id: 5,
			name: 'columns.initiator',
			variable: 'initiator',
			required: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			readonly: true
		},
		{
			id: 6,
			name: 'columns.description',
			variable: 'description',
			required: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			readonly: true
		}
	];

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}
