import { Component, Inject } from '@angular/core';

import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { EdrStatus } from '@campaign-portal/namespace/common/enums';

import { AlarisConfigService, TOOLTIP_COMPONENT_DATA } from '@campaign-portal/components-library';

import { getStatusColor } from '@helpers/utils/status';

@Component({
	selector: 'app-campaign-progress-campaign-template-tooltip',
	templateUrl: './campaign-progress-tooltip.component.html',
	styleUrls: ['./campaign-progress-tooltip.component.scss']
})
export class CampaignProgressTooltipComponent {
	getStatusColor = getStatusColor;
	statuses: { name: string, mode: EdrStatus, value: unknown }[] = [
		{
			name: 'enums.SENT',
			mode: EdrStatus.SENT,
			value: this.campaign.statistics?.sent
		},
		{
			name: 'enums.DELIVERED',
			mode: EdrStatus.DELIVERED,
			value: this.campaign.statistics?.delivered
		},
		{
			name: 'enums.SEEN',
			mode: EdrStatus.SEEN,
			value: this.campaign.statistics?.seen
		},
		{
			name: 'enums.UNDELIVERED',
			mode: EdrStatus.UNDELIVERED,
			value: this.campaign.statistics?.undelivered
		},
		{
			name: 'enums.FAILED',
			mode: EdrStatus.FAILED,
			value: this.campaign.statistics?.failed
		}
	];

	constructor(
		@Inject(TOOLTIP_COMPONENT_DATA) public readonly campaign: Campaign,
		public readonly alarisConfig: AlarisConfigService
	) {
	}

}
