import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	ChannelUtilsService,
	EditPanelInputData,
	filterWildcardData,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { EnabledDisabledStatus, TrafficType } from '@campaign-portal/namespace/common/enums';
import { MessageTemplate } from '@campaign-portal/namespace/entities/templates/specs';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { SenderPipe } from '@helpers/repo/repo.pipe';
import { CPCustomValidators } from '@helpers/validators/custom-validators';
import { SendersService } from '../../services/senders.service';
import { TemplatesDialogComponent, TemplatesDialogType } from '../../shared/dialog/templates-dialog.component';
import { MessageTemplatesService } from '../../services/message-templates.service';

interface MessageTemplateControls {
	name: FormControl<string>;
	sender: FormControl<SenderSubscription | undefined>;
	messageTemplate: FormControl<string>;
	channelType: FormControl<TrafficType>;
	actionUrl: FormControl<string | undefined>;
	buttonText: FormControl<string | undefined>;
	imageUrl: FormControl<string | undefined>;
}

type Cache = {
	[key in TrafficType]: {
		name: string;
		sender?: SenderSubscription;
		messageTemplate: string;
		actionUrl?: string;
		buttonText?: string;
		imageUrl?: string;
	}
};

@Component({
	selector: 'app-update-template',
	templateUrl: './update-template.component.html',
	styleUrls: ['./update-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateTemplateComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly status = EnabledDisabledStatus;
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	readonly TrafficType = TrafficType;
	readonly tabs = [
		{
			label: this.cu.name(TrafficType.SMS),
			value: TrafficType.SMS,
			icon: this.cu.icon(TrafficType.SMS)
		}, {
			label: this.cu.name(TrafficType.VIBER),
			value: TrafficType.VIBER,
			icon: this.cu.icon(TrafficType.VIBER)
		}
	];
	template: MessageTemplate;
	templateForm: FormGroup<MessageTemplateControls>;
	initialMessage = '';
	readonly filterSendersControl = new FormControl('');
	filterSendersList: SenderSubscription<exist>[] = [];
	readonly templateFormErrors = [
		{ key: 'duplicate', value: 'errors.duplicate' }
	];
	_cache: Cache;

	// private _skipFirstSenderInteractionFlag = true;
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly senderIdsService: SendersService,
		public readonly templatesService: MessageTemplatesService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		private readonly cu: ChannelUtilsService,
		private readonly senderPipe: SenderPipe,
		private readonly dialog: AlarisDialogService,
		private readonly guard: CanDeactivateGuardService,
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profileService: AlarisProfileService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this._cache = this.initCache();
		this.template = (this.inputData.template ?? { id: null, name: '' }) as MessageTemplate;
		this.initialMessage = this.template.messageTemplate ?? '';

		this.templateForm = new FormGroup<MessageTemplateControls>({
			name: new FormControl<string>(this.template.name ?? '', {
				nonNullable: true,
				validators: [
					Validators.required,
					CPCustomValidators.hasTemplateDuplicate(this.template.id, templatesService.list)
				]
			}),
			sender: new FormControl<SenderSubscription | undefined>(
				this.template.sender !== undefined ?
					this.senderPipe.transform(this.template.sender?.id) ?? undefined : undefined,
				{ nonNullable: true }
			),
			messageTemplate: new FormControl<string>(
				this.template.messageTemplate ?? '',
				{ nonNullable: true, validators: Validators.required }
			),
			channelType: new FormControl<TrafficType>(
				this.template.channelType ?? TrafficType.SMS,
				{ nonNullable: true, validators: Validators.required }
			),
			actionUrl: new FormControl<string | undefined>(this.template.actionUrl, { nonNullable: true }),
			buttonText: new FormControl<string | undefined>(this.template.buttonText, { nonNullable: true }),
			imageUrl: new FormControl<string | undefined>(this.template.imageUrl, { nonNullable: true })
		});

		if ( !this.profileService.allowed([CP_PERMISSIONS.MESSAGE_TEMPLATES_E]) ) {
			this.templateForm.disable();
		}
	}

	get cache(): Cache[TrafficType] {
		const channelType = this.templateForm.controls.channelType.value;
		return this._cache[channelType];
	}

	ngOnInit(): void {
		this.senderIdsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterSendersList = filterWildcardData(this.filterSendersControl.value, list, 'name');
			});


		this.filterSendersControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterSendersList = filterWildcardData(value, this.senderIdsService.list, 'name');
			});

		this.templateForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.templateForm.markAsTouched();
			this.templateForm.markAsDirty();
		});

		this.templateForm.controls.channelType.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				const channelType = this.templateForm.controls.channelType.value;
				this.cacheTemplate(channelType === TrafficType.SMS ? TrafficType.VIBER : TrafficType.SMS);
				this.templateForm.patchValue(this.cache);
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	// changeSender(sender: SenderSubscription): void {
	// 	if ( this._skipFirstSenderInteractionFlag ) {
	// 		this._skipFirstSenderInteractionFlag = false;
	// 	} else {
	// 		this.templateForm.controls.sender.setValue(sender);
	// 	}
	// }

	// condition(sender: SenderSubscription, status: EnabledDisabledStatus): boolean {
	// 	return this.template.id ? true : sender.enabled === status;
	// }

	save(): void {
		const template: MessageTemplate = {
			...this.template,
			...this.templateForm.value,
			sender: {
				name: this.templateForm.value.sender?.name ?? '',
				id: this.templateForm.value.sender?.id as Id<exist>
			}
		};

		if ( !this.templateForm.value.sender ) {
			delete template.sender;
		}

		this.allowedDeactivation.next(false);
		this.templatesService.update(template)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(result => {
				this.allowedDeactivation.next(true);
				if ( result.Success ) {
					this.editPanel.close(result.Success);
				}
			});
	}

	delete(): void {
		this.openDialog('Delete', this.template as MessageTemplate<exist>);
	}

	close(): void {
		this.editPanel.close();
	}

	activeSenders(
		value: TrafficType, source: SenderSubscription<exist>[] = this.senderIdsService.list
	): SenderSubscription[] {
		//(senderIdsService.senderIdsList
		// 									| filter: 'enabled' : status.ENABLED : condition.bind(this)
		// 									| filter: 'trafficType' : (templateForm.controls.channelType.value))
		return source.filter((sender) => {
			return sender.enabled === EnabledDisabledStatus.ENABLED && sender.trafficType === value;
		});
	}

	private openDialog(
		type: TemplatesDialogType,
		item?: MessageTemplate<exist>
	): void {
		this.dialog.open(TemplatesDialogComponent, {
			data: {
				template: item,
				type
			},
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					this.editPanel.close(true);
				}
			});
	}

	private initCache(): Cache {
		const empty = {
			sender: undefined,
			messageTemplate: '',
			name: '',
			imageUrl: undefined,
			actionUrl: undefined,
			buttonText: undefined
		};
		return {
			[TrafficType.VIBER]: { ...empty },
			[TrafficType.SMS]: { ...empty },
			[TrafficType.TELEGRAM]: { ...empty },
			[TrafficType.WHATSAPP]: { ...empty }
		};
	}

	private cacheTemplate(type: TrafficType): void {
		const { channelType, ...template } = this.templateForm.getRawValue();
		this._cache[type] = template;
	}
}
