import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import {
	ContactsImportHistory,
	ReportRequest,
	ReportResponse
} from '@campaign-portal/namespace/entities/contacts-import-history/specs';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';

@Injectable({
	providedIn: 'root'
})
export class ContactImportService implements AbstractCRUDService {

	readonly entity = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.contactImportHistory');
	}

	read(params: RPCRequestParams): Observable<ReadResponse<ContactsImportHistory[]>> {
		return this.api.loader<ReadResponse<ContactsImportHistory[]>>(
			'ContactsImportHistory.Read',
			params,
			this.loading$,
			this.errorNotifier
		);
	}

	errorsReport(Ids: Id<exist>[]): Observable<FileInfo> {
		const params: ReportRequest = {
			Data: {
				Ids
			}
		};
		return this.api.loader<ReportResponse>(
			'ContactsImportHistory.Report', params, this.loading$, this.errorNotifier
		)
			.pipe(map((r => r.Data[0] || [])));
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	update(): Observable<void> {
		return of(undefined);
	}

}
