<div class="edit-panel-header">
	<alaris-icon name="icon-package-detail"></alaris-icon>
	<span> {{ 'purchase.packageDetails' | translate }} </span>
</div>
<div alarisFade="vertical" class="container edit-panel-content">
	<alaris-card class="card" size="auto">
		<div class="header">
			<h5 class="name">
				<alaris-icon [name]="cu.icon(packs[0].trafficType)" class="channel-icon"></alaris-icon>
				{{ packs[0].name }}
			</h5>
			<div class="details">
				<div class="row">
					<span class="row__title">{{ 'purchase.channel' | translate }}</span>
					<b>
						{{
							cu.purpose(
								packs[0].trafficType,
								packs[0].settings?.viberSettings?.messagePurpose) | translate
						}}
					</b>
				</div>
				<div class="row">
					<span class="row__title">{{ 'purchase.billingMode' | translate }}</span>
					<b>
						{{ 'enums.' + packs[0].billingMode | translate }}
					</b>
				</div>
				<div class="row">
					<span class="row__title">{{ 'purchase.smsCost' | translate }}</span>
					<b>
						{{
							(packs[0].packSettings.packPrice / packs[0].messagesTotal)
								| currency: bs.currencyCode : 'symbol' : '1.2-5'
						}}
					</b>
				</div>

				<div class="messages-info">
					<div class="row">
						<span class="row__title">{{ 'purchase.smsVolume' | translate }}</span>
						<span>
							<strong>{{ rest }}</strong>
							{{ 'gl.of' | translate }}
							<span class="label">{{ total }}</span>
						</span>
					</div>
					<alaris-progress-line [percentageComplete]="rest * 100 / total"
										  [percentages]="false"
										  [type]="progressType(rest, total)">
					</alaris-progress-line>
				</div>

				@if (packs.length > 1) {
					<div [alarisDropdown]="dropdown"
						 [positions]="positions" (isOpened)="dropdownOpened = $event"
						 [class.opened]="dropdownOpened" class="messages-selector">
						{{ 'purchase.showPacks' | translate : { amount: packs.length } }}
						<alaris-icon class="icon" name="icon-arrow-select"></alaris-icon>
					</div>
				}
			</div>
		</div>
		<app-package-country-network-list [package]="packs[0]" class="countries-networks">
		</app-package-country-network-list>
	</alaris-card>
</div>

<alaris-dropdown #dropdown>
	<div class="dropdown-content">
		@for (pack of packs; track pack; let index = $index; let last = $last) {
			<div *alarisLet="(pack.messagesTotal - (pack.messagesUsed ?? 0) - (pack.messagesLocked ?? 0)) as rest"
				 [class.last]="last" class="pack-messages">
				<div class="info">
					<span>{{ 'purchase.pack' | translate }} {{ index + 1 }}</span>
					<span>
						<strong>{{ rest }}</strong>
						{{ 'gl.of' | translate }}
						<span class="label">{{ total }}</span>
					</span>
				</div>
				<alaris-progress-line [percentageComplete]="rest * 100 / pack.messagesTotal"
									  [percentages]="false"
									  [type]="progressType(rest, pack.messagesTotal)">
				</alaris-progress-line>
			</div>
		}
	</div>
</alaris-dropdown>
