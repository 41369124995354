import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { AbstractConfigService, CONFIG_SERVICE_INJECTOR } from '@campaign-portal/components-library';

@Component({
	selector: 'app-sign-up-layout',
	templateUrl: './sign-up-layout.component.html',
	styleUrls: ['./sign-up-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpLayoutComponent {

	constructor(
		@Inject(CONFIG_SERVICE_INJECTOR) private readonly appConfigService: AbstractConfigService
	) {
	}

	get style(): string {
		const light = this.appConfigService.themeType$.getValue() !== 'dark';
		return `background-image: url(${light ? 'assets/img/bg-light.png' : 'assets/img/bg-dark.png'})`;
	}
}
