import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';

import {
	SenderEventActionRequest,
	SenderEventActionResponse,
	SenderSubscription,
	SenderSubscriptionEvent
} from '@campaign-portal/namespace/entities/sender-id/specs';
import {
	CreateResponse,
	ReadResponse,
	UpdateRequest,
	UpdateResponse
} from '@campaign-portal/namespace/common/implementations';
import { exist } from '@campaign-portal/namespace/common/id';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { EnabledDisabledStatus, SenderSubscriptionEventStatus } from '@campaign-portal/namespace/common/enums';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	PROFILE_SERVICE_INJECTOR,
	sortData
} from '@campaign-portal/components-library';
import { CP_PERMISSIONS } from '@helpers/types/permissions';


@Injectable({
	providedIn: 'root'
})
export class SendersService extends ExtendableRefBookService<SenderSubscription<exist>> implements AbstractCRUDService {

	readonly active$ = new BehaviorSubject<SenderSubscription<exist>[]>([]);

	readonly validPeriodStatuses: (SenderSubscriptionEventStatus | undefined)[] = [
		SenderSubscriptionEventStatus.PERIOD_CHANGED,
		SenderSubscriptionEventStatus.CONFIRMED,
		SenderSubscriptionEventStatus.UNSUBSCRIBED
	];

	readonly read = this.load;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profileService: AlarisProfileService
	) {
		super();
		this.list$.subscribe((list) => {
			this.active$.next(list.filter((sender) => {
				return sender.enabled === EnabledDisabledStatus.ENABLED
					&& [
						SenderSubscriptionEventStatus.CONFIRMED, SenderSubscriptionEventStatus.PERIOD_CHANGED
					].includes(sender.lastEvent!)
					&& (sender.activeFrom ? new Date(sender.activeFrom) < new Date() : true)
					&& (sender.activeTill ? new Date() < new Date(sender.activeTill) : true);
			}));
		});
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.sender');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.sender');
	}

	isWaitingForApproval(sender: SenderSubscription): boolean {
		return !sender.lastEvent ||
			[SenderSubscriptionEventStatus.NEW, SenderSubscriptionEventStatus.IN_PROGRESS].includes(sender.lastEvent);
	}

	override load(): Observable<ReadResponse<SenderSubscription<exist>[]>> {
		if ( this.profileService.allowed([CP_PERMISSIONS.SENDERS_R]) ) {
			return this.api.loader<ReadResponse<SenderSubscription<exist>[]>>(
				'Senders.Read', undefined, this.loading$, this.errorNotifier
			)
				.pipe(map((resp) => {
					sortData(resp.Data, 'name');
					return super.process(resp);
				}));
		}
		return of({ Success: true, Total: 0, Data: [] });
	}

	create(): Observable<void> {
		return of(undefined);
	}

	update(): Observable<void> {
		return of(undefined);
	}

	requestSenderId(
		senderIds: SenderSubscription | SenderSubscription[]
	): Observable<CreateResponse<SenderSubscription<exist>>> {
		const Entities = Array.isArray(senderIds) ? senderIds : [senderIds];
		const params: RPCRequestParams = { Data: { Entities } };
		const notify = (response: CreateResponse<SenderSubscription<exist>>): void => {
			const message = this.langService.translate('notifications.actions.request', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<SenderSubscription<exist>>>(
			'Senders.Create', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(sender: SenderSubscription<exist>): Observable<UpdateResponse<SenderSubscription<exist>>> {
		const params: UpdateRequest<SenderSubscription<exist>> = { Data: { Entities: [sender] } };
		const notify = (response: UpdateResponse<SenderSubscription<exist>>): void => {
			const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<UpdateResponse<SenderSubscription<exist>>>(
			'Senders.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	unsubscribe(event: SenderSubscriptionEvent): Observable<SenderEventActionResponse> {
		const params: SenderEventActionRequest = {
			Data: {
				Ids: [event.sender.id],
				Entities: [event]
			}
		};
		const notify = (response: SenderEventActionResponse): void => {
			const message = this.langService.translate('notifications.actions.unsubscribe', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<SenderEventActionResponse>(
			'SenderEvents.Unsubscribe', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	cancel(event: SenderSubscriptionEvent): Observable<SenderEventActionResponse> {
		const params: SenderEventActionRequest = {
			Data: {
				Ids: [event.sender.id],
				Entities: [event]
			}
		};
		const notify = (response: SenderEventActionResponse): void => {
			const message = this.langService.translate('notifications.actions.cancel', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<SenderEventActionResponse>(
			'SenderEvents.Cancel', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}
}
