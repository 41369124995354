import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Injectable()
export class PlanDetailsFieldService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly headers: EntityField[] = [
		{
			id: 0,
			name: 'columns.country',
			variable: 'country',
			required: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			readonly: true
		},
		{
			id: 1,
			name: 'columns.network',
			variable: 'network',
			required: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			readonly: true
		},
		{
			id: 2,
			name: 'columns.price',
			variable: 'price',
			required: true,
			type: InputType.NUMBER,
			filterType: FilterType.LIKE,
			readonly: true
		}
	];

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}
