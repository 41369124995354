<ng-template #period
			 let-periodType="periodType"
			 let-range="range"
			 let-reduceDayPeriod="reduceDayPeriod"
			 let-title="title">
	<div class="period">
		<h4 class="label">{{ title | translate }}</h4>
		<div class="range">
			<alaris-dashboard-time-filter (periodTypeChange)="periodType.setValue($event)"
										  [formControl]="range"
										  [min]="reduceDayPeriod && periodType.value === CubeTypeENUM.DAY
													? lastWeek: FIRST_DAY"
										  [toLocalDate]="true"
										  data-testid="periodDateRange">
			</alaris-dashboard-time-filter>
		</div>
	</div>
</ng-template>

<ng-template #barChart
			 let-collapsed="collapsed"
			 let-hint="hint"
			 let-labelsX="labelsX"
			 let-labelsY="labelsY"
			 let-legend="legend"
			 let-loading$="loading$"
			 let-maxChartHeight="maxChartHeight"
			 let-stubChart="stubChart"
			 let-value="value">
	<div [alarisLoading]="loading$" [backdrop]="false" class="bar-chart">
		<tui-axes *ngIf="value.length; else stubChart"
				  [axisXLabels]="labelsX"
				  [axisYLabels]="labelsY" class="chart">
			<tui-bar-chart [collapsed]="collapsed ?? true"
						   [max]="maxChartHeight"
						   [size]="null"
						   [tuiHintContent]="hint"
						   [value]="value">
			</tui-bar-chart>
		</tui-axes>
		<ng-container *ngTemplateOutlet="barChartLegend;
			context: {
				legend
			}">
		</ng-container>
	</div>
</ng-template>

<ng-template #barChartLegend let-legend="legend">
	<div class="legend">
		<tui-legend-item
			*ngFor="let label of legend; let index = index"
			[color]="cu.chartHintColorMapper(label)"
			[text]="label | translate"
			size="s">
		</tui-legend-item>
	</div>
</ng-template>

<ng-template #totalsProgressCharts let-totalMessages="totalMessages" let-totals="totals">
	<div class="circle-charts">
		<div *ngFor="let total of totals;" class="circle-chart">
			<alaris-progress-circle [color]="total.color"
									[percentageComplete]="100 * (total.total / totalMessages)">
			</alaris-progress-circle>
			<label class="label">
				<div>{{ total.label | translate }}</div>
				<div class="total">{{ total.total }}</div>
			</label>
		</div>
	</div>
</ng-template>

<ng-template #statistic
			 let-costDelta="costDelta"
			 let-sentDelta="sentDelta"
			 let-totalCost="totalCost"
			 let-totalMessages="totalMessages">
	<div class="statistic">
		<div class="label">
			<label>{{ 'dashboard.totalSent' | translate }}</label>
			<div class="total">{{ totalMessages }}</div>
			<ng-container *ngIf="sentDelta !== undefined">
				<ng-container *ngTemplateOutlet="delta; context: {dataDelta: sentDelta}"></ng-container>
			</ng-container>
		</div>
		<div class="label">
			<label>{{ 'campaigns.totalCost' | translate }}</label>
			<div class="total">{{ totalCost | currency: bs.currencyCode }}</div>
			<ng-container *ngIf="costDelta !== undefined">
				<ng-container *ngTemplateOutlet="delta; context: {dataDelta: costDelta}"></ng-container>
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #totalStatistics
			 let-costDelta="costDelta"
			 let-sentDelta="sentDelta"
			 let-totalCost="totalCost"
			 let-totalMessages="totalMessages"
			 let-totals="totals">
	<div class="total-statistic">
		<ng-container *ngTemplateOutlet="totalsProgressCharts;
				context: {
					totalMessages,
					totals
				}">
		</ng-container>
		<ng-container *ngTemplateOutlet="statistic;
				context: {
					totalCost,
					totalMessages
				}">
		</ng-container>
	</div>
</ng-template>

<ng-template #delta let-dataDelta="dataDelta">
	<div class="delta">
		<alaris-icon *ngIf="dataDelta>0" class="icon" name="icon-arrow-up"></alaris-icon>
		<alaris-icon *ngIf="dataDelta<0" class="icon" name="icon-arrow-down"></alaris-icon>
		<span [style.color]="dataDelta<0 ? '#e31f14': '#058e02'">{{ dataDelta }}%</span>
		{{ 'dashboard.vsLastPeriod' | translate }}
	</div>
</ng-template>

<ng-template #barChartSkeleton>
	<tui-axes [horizontalLines]="5"
			  axisY="none"
			  class="chart stub-chart">
		<tui-bar-chart [max]="30000"
					   [value]="[[8000, 2000, 12000, 10000, 20000, 28000, 29000, 26000, 18000, 5000, 25000, 1000]]"
					   size="l">
		</tui-bar-chart>
	</tui-axes>
</ng-template>

<ng-template #chartVisibility let-configs=configs let-themeType=themeType>
	<div class="box">
		@for (config of configs; track config) {
			<alaris-checkbox [formControl]="config.control"
							 [class]="config.type"
							 [class.secondary]="!config.control.value"
							 [class.dark]="themeType==='dark'"
							 data-testid="chartVisibility">
				{{ ('enums.' + config.type) | translate }}
				<span class="bold">{{ config.percent + '%' }}</span>
			</alaris-checkbox>
		}
	</div>
</ng-template>

<ng-template #lineCharts
			 let-configs=configs
			 let-hint="hint"
			 let-labelsX="labelsX"
			 let-labelsY="labelsY"
			 let-loading$="loading$"
			 let-maxChartHeight="maxChartHeight"
			 let-stubChart="stubChart"
			 let-themeType=themeType
			 let-value="value">
	@if (value.length) {
		<tui-axes
			[alarisLoading]="loading$"
			[axisXLabels]="labelsX"
			[axisYLabels]="labelsY"
			[horizontalLines]="4"
			[tuiLineChartHint]="hint"
			axisY="none"
			class="line-chart">
			@for (config of configs; track config; let index = $index) {
				<tui-line-chart
					class="line"
					[class.dark]="themeType==='dark'"
					[class.transparent]="!config.control.value"
					[height]="maxChartHeight"
					[value]="value[index]"
					[width]="value[index].length-1"
					[x]="0"
					[y]="0">
				</tui-line-chart>
			}
		</tui-axes>
	} @else {
		<ng-container *ngTemplateOutlet="stubChart"></ng-container>
	}
</ng-template>

<ng-template #lineChartSkeleton>
	<tui-axes [horizontalLines]="4"
			  axisY="none"
			  class="stub-line-chart">
		<tui-line-chart
			[height]="1"
			[value]="[]"
			[width]="1"
			[x]="0"
			[y]="0"
			class="line">
		</tui-line-chart>
	</tui-axes>
</ng-template>
