import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { EntityField } from '@campaign-portal/namespace/common/entityField';

import {
	AbstractCRUDService,
	AbstractRefService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService,
	ErrorNotifierConfig,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { CP_PERMISSIONS } from '@helpers/types/permissions';

@Injectable({
	providedIn: 'root'
})
export class EdrExportFieldsService implements AbstractRefService<EntityField>, AbstractCRUDService {

	readonly entity = '';

	readonly list$ = new BehaviorSubject<EntityField[]>([]);
	readonly map$ = new BehaviorSubject<Map<string, EntityField>>(new Map());

	readonly refresh$ = new Subject<void>();

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly load = this.read;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profile: AlarisProfileService,
		private readonly api: AlarisApiService,
		private readonly translate: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.translate.translate('notifications.titles.edrExportField');
	}

	get map(): Map<string, EntityField> {
		return this.map$.getValue();
	}

	get list(): EntityField[] {
		return this.list$.getValue();
	}

	fields(variables: string[]): string {
		const fList = variables.map((v) => this.map.get(v) ?
			this.translate.translate(this.map.get(v)?.name || '') : '', []);
		return fList
			.filter(f => f)
			.join(', ');
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return this.api.loader<ReadResponse<EntityField[]>>(
			'EdrExportFields.Read', {}, this.loading$, this.errorNotifier
		)
			.pipe(
				map((resp) => {
					this.pickAvailableFields(resp);
					this.list$.next(resp.Data || []);
					this.map$.next(this.list.reduce((result, item) => {
						result.set(item.variable, item);
						return result;
					}, new Map()));
					return resp;
				})
			);
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	update(): Observable<void> {
		return of(undefined);
	}

	clear(): void {
		this.map$.next(new Map());
		this.list$.next([]);
	}

	private pickAvailableFields(r: ReadResponse<EntityField[]>): ReadResponse<EntityField[]> {
		const permissions = [CP_PERMISSIONS.CONTACTS_R, CP_PERMISSIONS.CAMPAIGNS_R, CP_PERMISSIONS.SENDERS_R];
		permissions.forEach(p => {
			if ( this.profile.allowed([p]) ) {
				return;
			}
			switch (p) {
				case CP_PERMISSIONS.CONTACTS_R:
					r.Data = r.Data.filter((f => f.variable !== 'destinationNumber'));
					break;
				case CP_PERMISSIONS.CAMPAIGNS_R:
					r.Data = r.Data.filter((f => f.variable !== 'campaignName'));
					break;
				case CP_PERMISSIONS.SENDERS_R:
					r.Data = r.Data.filter((f => f.variable !== 'senderName'));
					break;
				default:
					break;
			}
		});
		return r;
	}
}

