import { Inject, Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';

import {
	ContactGroup,
	ContactGroupsCheckCampaignUsageRequest,
	ContactGroupsCheckCampaignUsageResponse,
	ContactGroupsResponse,
	CopyContactsRequest,
	CopyContactsResponse,
	MoveContactsRequest,
	MoveContactsResponse
} from '@campaign-portal/namespace/entities/contact-groups/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ParamsDataIdsRequest,
	ReadResponse,
	UpdateResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	PROFILE_SERVICE_INJECTOR,
	sortData
} from '@campaign-portal/components-library';
import { CP_PERMISSIONS } from '@helpers/types/permissions';


@Injectable({
	providedIn: 'root'
})
export class ContactGroupsService extends ExtendableRefBookService<ContactGroup<exist>> implements AbstractCRUDService {
	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profileService: AlarisProfileService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.group');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.group');
	}

	override load(): Observable<ReadResponse<ContactGroup<exist>[]>> {
		if ( this.profileService.allowed([CP_PERMISSIONS.CONTACTS_R]) ) {
			return this.api.loader<ReadResponse<ContactGroup<exist>[]>>(
				'ContactGroups.Read', undefined, this.loading$, this.errorNotifier
			)
				.pipe(map(
					resp => {
						sortData(resp.Data, 'name');
						return super.process(resp);
					}
				));
		}
		return of({ Success: true, Total: 0, Data: [] });
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<ContactGroup<exist>[]>> {
		return this.api.loader<ReadResponse<ContactGroup<exist>[]>>(
			'ContactGroups.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(group: ContactGroup): Observable<CreateResponse<[ContactGroup<exist>]> | UpdateResponse<[ContactGroup<exist>]>> {
		const notify = (response: CreateResponse<[ContactGroup<exist>]>): void => {
			const message = this.langService.translate(
				group.id ? 'notifications.actions.update' : 'notifications.actions.create', {
					entity: this.entity,
					name: group.name ?? ''
				});
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};

		return this.api.loader<CreateResponse<[ContactGroup<exist>]>>(
			'ContactGroups.' + (group.id === null ? 'Create' : 'Update'),
			{ Data: { Entities: [group] } },
			this.loading$,
			this.errorNotifier,
			notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(group: ContactGroup<exist> | ContactGroup<exist>[] | null): Observable<DeleteResponse<ContactGroup>> {
		const params: DeleteRequest<ContactGroup<exist>> = {
			Data: {
				Ids: Array.isArray(group) && group !== null ?
					group.map(cg => cg.id) :
					group !== null ? [group.id] : group
			}
		};
		const notify = (response: DeleteResponse<ContactGroup>): void => {
			const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<ContactGroup>>(
			'ContactGroups.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	checkCampaignUsage(
		group: ContactGroup<exist> | ContactGroup<exist>[] | null,
		params: RPCRequestParams = {}
	): Observable<ContactGroupsCheckCampaignUsageResponse> {
		const _params: ContactGroupsCheckCampaignUsageRequest = {
			Data: {
				Ids: Array.isArray(group)
					? group.map(item => item.id)
					: group !== null
						? [group.id]
						: null
			},
			Filters: group === null ? params.Filters : []
		};
		return this.api.loader<ContactGroupsCheckCampaignUsageResponse>(
			'ContactGroups.CheckCampaignUsage',
			_params,
			this.loading$,
			this.errorNotifier
		);
	}

	moveContacts(
		fromGroup: ContactGroup<exist> | ContactGroup<exist>[] | null,
		toGroup: ContactGroup<exist>
	): Observable<MoveContactsResponse> {
		const params: MoveContactsRequest = {
			Data: {
				FromGroups: Array.isArray(fromGroup) ?
					fromGroup.map(item => item.id) :
					fromGroup !== null ? [fromGroup.id] : fromGroup,
				ToGroup: toGroup.id
			}
		};
		const notify = (response: MoveContactsResponse): void => {
			const message = this.langService.translate('notifications.actions.moveContacts', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<MoveContactsResponse>(
			'ContactGroups.MoveContacts',
			params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	copyContacts(
		fromGroup: ContactGroup<exist> | ContactGroup<exist>[] | null,
		toGroup: ContactGroup<exist>
	): Observable<CopyContactsResponse> {
		const params: CopyContactsRequest = {
			Data: {
				FromGroups: Array.isArray(fromGroup) ?
					fromGroup.map(item => item.id) :
					fromGroup !== null ? [fromGroup.id] : fromGroup,
				ToGroup: toGroup.id
			}
		};
		const notify = (response: CopyContactsResponse): void => {
			const message = this.langService.translate('notifications.actions.copyContacts', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CopyContactsResponse>(
			'ContactGroups.CopyContacts',
			params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	analysis(group: Id<exist>[] | null): Observable<ContactGroupsResponse> {
		const params: ParamsDataIdsRequest = {
			Data: {
				Ids: Array.isArray(group) ? group : this.list.map(g => g.id)
			}
		};
		return this.api.loader<ContactGroupsResponse>(
			'ContactGroups.Analysis', params, this.loading$, this.errorNotifier
		);
	}
}
