import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { CP_PERMISSIONS } from '@helpers/types/permissions';

import { SharedTemplatesService } from '@campaign-portal/components-library';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, OnDestroy {
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly templates: SharedTemplatesService,
		private readonly cd: ChangeDetectorRef
	) {
	}

	ngOnInit(): void {
		this.templates.loading$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.cd.detectChanges();
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

}
