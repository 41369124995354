<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total"> {{ totalTitle | translate }}: {{ total }} </span>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
			<button (click)="openRequestPanel()"
					*alarisPBAC="[CP_PERMISSIONS.SENDERS_E]"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'senderIds.requestSenderId' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table (senderRequest)="openRequestPanel()"
							 type="senders">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="dblClickRowAction.bind(this)"
						  [dropDownRowActions]="dropDownRowActions"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{name, enabled, activeTill, lastUpdated, lastEvent}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #name [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div (click)="openViewPanel.call(this, data)" class="senderId-column" data-testid="openViewPanelBtn">
		<alaris-icon [name]="cu.icon(data.trafficType)"></alaris-icon>
		{{ data.name }}
	</div>
</ng-template>

<ng-template #enabled [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	@if (sendersService.isWaitingForApproval(data)) {
		<alaris-tag class="no-wrap">
			{{ 'enums.WAITING_FOR_APPROVAL' | translate }}
		</alaris-tag>
	} @else {
		<alaris-tag [mode]="data.enabled === Status.ENABLED ? 'success': 'error'"
					class="no-wrap">
			{{ 'enums.' + data.enabled | translate }}
		</alaris-tag>
	}
</ng-template>

<ng-template #activeTill [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<ng-container *ngIf="sendersService.validPeriodStatuses.includes(data.lastEvent)">
		{{ data.activeTill ? (data.activeTill | timeAgo : '' : true) : ('senderIds.neverExpires' | translate) }}
	</ng-container>
</ng-template>

<ng-template #lastUpdated [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.lastUpdated ?? '' | timeAgo: ('gl.ago' | translate) }}
</ng-template>

<ng-template #lastEvent [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<alaris-tag [dot]="true" [mode]="eventMode(data.lastEvent)" class="no-wrap">
		{{ data.lastEvent ? ('enums.' + data.lastEvent | translate) : '' }}
	</alaris-tag>
</ng-template>
