import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

import { AlarisEditPanelService, EditPanelInputData } from '@campaign-portal/components-library';

import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';

import { EdrExportService } from '../edr-export.service';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';

@Component({
	selector: 'app-edr-export-settings-panel',
	templateUrl: './edr-export-settings-panel.component.html',
	styleUrls: ['./edr-export-settings-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush

})
export class EdrExportSettingsPanelComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	total = 0;
	readonly tHeads: EntityField[] = [];
	readonly readParams = {};
	edrExportForm!: FormGroup;
	readonly selection = new SelectionModel<EntityField>(true, []);

	// For correct checkbox work
	readonly fakeGroup = new FormGroup({});
	readonly fakeControl = new FormControl();

	allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		public readonly edrExportService: EdrExportService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.total = this.inputData.total as number;
		this.tHeads = this.inputData.tHeads as EntityField[];
		this.readParams = this.inputData.readParams as RPCRequestParams;
	}

	ngOnInit(): void {
		this.edrExportForm = new FormGroup({
			email: new FormControl('')
		});

		// For correct checkbox work
		this.tHeads.forEach(field => {
			this.fakeGroup.addControl(field.variable, new FormControl());
		});
	}

	close(): void {
		this.editPanel.close();
	}

	export(): void {
		const params = {
			...this.readParams,
			Data: {
				emails: this.edrExportForm.get('email')?.value,
				fields: this.selection.selected.map(field => field.variable)
			}
		};

		this.allowedDeactivation.next(false);
		this.edrExportService.create(params)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.close();
				}
			});
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.tHeads.length;
		return numSelected === numRows;
	}

	toggleAllRows(): void {
		if ( this.isAllSelected() ) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.tHeads);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
