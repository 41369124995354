import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

import { AlarisTableStateService, AlarisTabsComponent } from '@campaign-portal/components-library';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { CP_PERMISSIONS } from '@helpers/types/permissions';


@Component({
	selector: 'app-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('contactsTabs') readonly contactsTabs!: AlarisTabsComponent;
	readonly CP_PERMISSIONS = CP_PERMISSIONS;

	newContact: Record<string, unknown> | undefined;
	newGroup: Record<string, unknown> | undefined;
	groupId?: Id<exist>;

	readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly cd: ChangeDetectorRef,
		private readonly router: Router,
		private readonly stateService: AlarisTableStateService
	) {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.contactsTabs.tabChanged
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((id) => {
				if ( id !== 'contacts' ) {
					this.groupId = undefined;
				}
			});

		this.cd.detectChanges();
	}


	createContactsEvent(): void {
		this.newContact = {};
	}

	createGroupEvent(): void {
		this.newGroup = {};
	}

	initiateImport(): void {
		this.router.navigate(['import-contacts']);
	}

	goToContacts(): void {
		this.contactsTabs.setActiveTab('contacts');
	}

	changeGroup(id: Id<exist>): void {
		this.groupId = id;
		this.contactsTabs.setActiveTab('contacts');
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.stateService.clear();
	}
}
