<div [alarisLoading]="edrExportService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-import-contact"></alaris-icon>
		{{ 'statistics.edrExport' | translate }}
	</div>
	<div alarisFade="vertical" [formGroup]="edrExportForm" class="edit-panel-content form-group">
		<alaris-alert class="note">
			{{ total }} {{ 'statistics.edrsWillBeExported' | translate }}
		</alaris-alert>

		<div class="section-title">{{ 'statistics.whatColumnExport' | translate }}</div>

		<div [formGroup]="fakeGroup" class="columns-settings">
			<div class="header">
				<alaris-checkbox (change)="$event ? toggleAllRows() : null"
								 [checked]="selection.hasValue() && isAllSelected()"
								 [formControl]="fakeControl"
								 [indeterminate]="selection.hasValue() && !isAllSelected()"
								 data-testid="selectAllCheckbox">
					{{ 'actions.selectAll' | translate }}
				</alaris-checkbox>
				<span class="counter">
					{{ 'gl.selected' | translate }}: {{ selection.selected.length + '/' + tHeads.length }}
				</span>
			</div>
			<div class="content">
				<ol *ngFor="let field of tHeads">
					<alaris-checkbox (change)="$event ? selection.toggle(field) : null"
									 (click)="$event.stopPropagation()"
									 [checked]="selection.isSelected(field)"
									 [attr.data-testid]="'checkbox/' + field.variable"
									 [formControlName]="field.variable" size="md">
						{{ field.name | translate }}
					</alaris-checkbox>
				</ol>
			</div>
		</div>

		<div class="section-title">
			<b class="">{{ 'statistics.sendByEmail' | translate }}</b>
			<alaris-icon [alarisTooltip]="'statistics.sendByEmailNote' | translate"
						 name="icon-info-_active">
			</alaris-icon>
		</div>

		<div class="section-email">
			<alaris-text-area [placeholder]="'statistics.emailNote' | translate"
							  data-testid="emailTextarea" formControlName="email">
				{{ 'statistics.email' | translate }}
			</alaris-text-area>
			<p class="email-hint">{{ 'contacts.import.emailHint' | translate }}</p>
		</div>

	</div>

	<div class="edit-panel-footer">
		<button (click)="export()"
				alaris-button bType="primary"
				data-testid="exportBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ 'actions.export'  | translate }}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ 'actions.cancel' | translate }}
		</button>
	</div>
</div>


