import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import {
	AlarisEditPanelService,
	AlarisProfileService,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { CampaignFromFileComponent } from '../../../campaigns/campaign-from-file/campaign-from-file.component';
import { CP_PERMISSIONS } from '@helpers/types/permissions';

export type LinkCardType = 'Campaigns' | 'Contacts' | 'SenderId';

@Component({
	selector: 'app-link-card',
	templateUrl: './link-card.component.html',
	styleUrls: ['./link-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkCardComponent {
	@Input() type: LinkCardType = 'Campaigns';

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profile: AlarisProfileService,
		private readonly router: Router,
		private readonly editPanel: AlarisEditPanelService
	) {
	}

	confirm(): void {
		switch (this.type) {
			case 'Campaigns':
				this.router.navigate(['campaigns', 'campaign-wizard']);
				break;
			case 'Contacts':
				this.router.navigate(['import-contacts']);
				break;
			case 'SenderId':
				this.router.navigate(['campaigns', 'senders'], {
					queryParams: {
						mode: 'addSenderId'
					}
				});
				break;
			default:
				break;

		}

	}

	toCampaignFromFile(): void {
		if ( this.profile.allowed([CP_PERMISSIONS.CAMPAIGNS_R]) ) {
			this.router.navigate(['campaigns'], { queryParams: { mode: 'fromFile' } });
			return;
		}
		this.editPanel.open(CampaignFromFileComponent, EditPanelWidth.MD, {});
	}

	toStopList(): void {
		this.router.navigate(['contacts'], { queryParams: { contactTabs: 'stopList' } });
	}
}
