import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	OnInit,
	Output
} from '@angular/core';
import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisProfileService,
	AlarisSettingsManagerComponent,
	AlarisTableSettingsService,
	AlarisTableStateService,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple,
	SharedTemplatesService,
	TableFiltersIndicator,
	TableSelectionIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { Contact } from '@campaign-portal/namespace/entities/contacts/specs';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { exist } from '@campaign-portal/namespace/common/id';
import { EntityField } from '@campaign-portal/namespace/common/entityField';

import { CP_PERMISSIONS } from '@helpers/types/permissions';

import { ContactsService } from '../../services/contacts.service';
import { ContactFieldsService } from '../../services/contact-fields.service';
import { ContactGroupsService } from '../../services/contact-groups.service';
import { ContactsActions } from '../actions';

@Component({
	selector: 'app-contacts-stoplist-table',
	templateUrl: '../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.html',
	styleUrls: [
		'../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./contacts-stoplist-table.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactsStoplistTableComponent extends ContactsActions implements OnInit {

	@Output() readonly initiateImport = new EventEmitter<void>();

	override readonly id = 'stoplist';
	override readonly rowActions: RowActionSimple<Contact | Contact[]>[] = [
		{
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.edit.bind(this)
		},
		{
			icon: 'icon-folder',
			label: 'contacts.actions.removeFromStopList',
			action: this.removeFromStopList.bind(this)
		},
		{
			icon: 'icon-export-1',
			label: 'actions.export',
			action: this.export.bind(this)
		},
		{
			icon: 'icon-delete',
			label: 'contacts.actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		}
	];
	override readonly dropDownRowActions: RowActionSimple<Contact | Contact[]>[] = [];
	override readonly mainActions: RowActionSimple<Contact | Contact[]>[] = [
		{
			icon: 'icon-export-1',
			label: 'actions.export',
			action: (): void => {
				this.export(this.selection);
			}
		},
		{
			icon: 'icon-folder',
			label: 'contacts.actions.removeFromStopList',
			action: (): void => {
				this.removeFromStopList(this.selection);
			}
		}
	];
	override readonly additionalActions: RowActionSimple<Contact | Contact[]>[] = [
		{
			icon: 'icon-delete',
			label: 'contacts.actions.delete',
			action: (): void => {
				return this.delete(this.selection);
			}
		}
	];
	override readonly ctxActions: RowActionSimple<Contact | Contact[]>[] = [...this.rowActions].map(a =>
		({ ...a, icon: '' })
	);
	override readonly filters: TableFiltersIndicator = new Map()
		.set('stopList', {
			enabled: true,
			value: true,
			filterType: FilterType.EXACT
		});

	constructor(
		public override readonly contactsService: ContactsService,
		public override readonly contactFieldsService: ContactFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly dialog: AlarisDialogService,
		public override readonly filesService: AlarisFilesService,
		public override readonly stateService: AlarisTableStateService,
		public readonly sharedTemplates: SharedTemplatesService,
		private readonly contactGroupsService: ContactGroupsService,
		@Inject(PROFILE_SERVICE_INJECTOR) public override readonly profileService: AlarisProfileService
	) {
		super(
			contactsService,
			contactFieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.STOP_LIST,
			stateService,
			dialog,
			filesService,
			profileService
		);
		this.templates = this.sharedTemplates.templates;
	}

	override checkFilters(indicators: TableFiltersIndicator): boolean {
		indicators.delete('stopList');
		return super.checkFilters(indicators);
	}

	override dblClickRowAction(contact?: Contact<exist>): void {
		this.edit(contact);
	}

	override delete(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		super.openSpecificDialog('Delete', item, true);
	}

	override removeFromStopList(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		super.openSpecificDialog('RemoveFromStopList', item, true);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		if ( !this.profileService.allowed([CP_PERMISSIONS.CONTACTS_E]) ) {
			this.removeActions();
		}
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			contactGroups: this.contactGroupsService
		});
	}

	override applyFilter(event: TableFiltersIndicator): void {
		super.applyFilter(event);
		if ( !(this.readParams.Filters || []).find(item => item.Field === 'stopList') ) {
			this.readParams.Filters = [
				...this.readParams.Filters ?? [],
				{
					Field: 'stopList',
					Type: FilterType.EXACT,
					Value: true
				}
			];
		}
	}

	override editTableSettings(): void {
		const tHeads = this.profileService.allowed([CP_PERMISSIONS.CONTACTS_E]) ?
			this.tHeaders : this.tHeaders.map(h => ({ ...h, readonly: true }));

		this.editPanel.open(AlarisSettingsManagerComponent, EditPanelWidth.MD, {
			tHeads,
			tSettings: this.tSettings,
			creationFieldAvailable: this.profileService.allowed([CP_PERMISSIONS.CONTACTS_E]),

			tHeadsChange: this.tHeadsChange,
			tSettingsChange: this.tSettingsChange,
			headEvent: this.tHeadEvent
		});
	}

	override import(): void {
		this.initiateImport.emit();
	}
}
