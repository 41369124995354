<alaris-card>
	<div [alarisFade]="'vertical'" class="content">
		<section class="section">
			<h4>{{ 'api.prerequisites' | translate }}</h4>
			<p>
				{{ 'api.useService' | translate }}
				<alaris-link (click)="addApiKey()" [underline]="false" data-testid="createKeyLink" type="secondary">
					{{ 'api.create' | translate }}
				</alaris-link>
			</p>
		</section>

		<section class="section">
			<h4>{{ 'api.params.limitations.title' | translate }}</h4>
			<ul class="limitations">
				<li>{{ 'api.params.limitations.duplicates' | translate }}</li>
				<li>{{ 'api.params.limitations.messagesLimit' | translate }}</li>
				<li>{{ 'api.params.limitations.empty' | translate }}</li>
			</ul>
		</section>

		<section class="section">
			<h4>{{ 'api.parameters' | translate }}</h4>
			<div class="table-container">
				<table>
					<thead>
						@for (header of headers; track header) {
							<th>{{ header.name | translate }}</th>
						}
					</thead>
					<tbody>
						@for (tRow of tRows; track tRow) {
							<tr>
								@for (header of headers; track header) {
									<ng-container *ngTemplateOutlet="cells;
												context: {data: tRow, key: header.variable}">
									</ng-container>
								}
							</tr>
						}
					</tbody>
				</table>
			</div>

		</section>

		<section class="section">
			<h4>{{ 'api.sending.title' | translate }}</h4>
			<alaris-alert class="postNote">
				{{ 'api.sending.postNote' | translate }}
			</alaris-alert>
			<alaris-card class="code" size="auto">
				<div class="title">{{ 'api.sending.request' | translate }}</div>
				<div class="json">
					<span class="red">curl</span> -X POST -H 'Authorization:
					<span class="green"> Bearer &lt;token&gt;' -H 'Content-Type: application/json;
						charset=utf8' '{{ url }}'</span><br>
					<div class="body">
						-d [&#123;
						<div class="nested">
							<span class="red">"from"</span>: <span class="green">"&lt;ani&gt;"</span>,<br>
							<span class="red">"to"</span>: <span class="green">"&lt;dnis&gt;"</span>,<br>
							<span class="red">"message"</span>: <span class="green">"&lt;message&gt;"</span>,<br>
							<span class="red">"label"</span>: <span class="green">"&lt;label&gt;"</span>,<br>
							<span class="red">"channels"</span>: <span class="green">"&lt;channels&gt;"</span>
						</div>
					</div>&#125;]
				</div>
			</alaris-card>

			<p>{{ 'api.sending.successfulResponseNote' | translate }}</p>
			<alaris-card class="code" size="auto">
				<div class="title">{{ 'api.sending.successfulResponse' | translate }}</div>
				<div class="json">
					<ng-container *ngTemplateOutlet="responseHeader; context: {success: true}"></ng-container>
					<div class="body">
						&#123; <span class="red">results</span>:
						[<span class="green">"&lt;number_1&gt;:"&lt;transaction-id-1&gt;"</span>] &#125;
					</div>
				</div>
			</alaris-card>

			<p>{{ 'api.sending.errorResponseNote' | translate }}</p>
			<div class="error-response">
				<p>{{ 'api.sending.wrongSenderId' | translate }}</p>
				<alaris-card class="code" size="auto">
					<div class="title">{{ 'api.sending.errorResponse' | translate }}</div>
					<div class="json">
						<ng-container *ngTemplateOutlet="responseHeader; context: {success: true}"></ng-container>

						<div class="body">
							&#123; <span class="red">results</span>:
							[<span class="green">
							"&lt;number_1&gt;:"For message: can't validate sender &lt;senderId&gt;"
						</span>]
							&#125;
						</div>
					</div>
				</alaris-card>
			</div>

			<div class="error-response">
				<p>{{ 'api.sending.missingMessage' | translate }}</p>
				<alaris-card class="code" size="auto">
					<div class="title">{{ 'api.sending.errorResponse' | translate }}</div>
					<div class="json">
						<ng-container *ngTemplateOutlet="responseHeader; context: {success: false}"></ng-container>
					</div>
				</alaris-card>
			</div>

		</section>

	</div>
</alaris-card>

<ng-template #cells [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<td>
		@switch (key) {
			@case ('key') {
				{{ ('api.params.keys.' + data.key) | translate }}
			}
			@case ('type') {
				@if (data.key === KEYS.channels) {
					{{ channels }}
				} @else {
					{{ data.type | translate }}
				}
			}
			@case ('description') {
				@if (data.key === KEYS.channels) {
					{{ ('api.params.description.' + data.key) | translate : { channels } }}
				} @else {
					{{ ('api.params.description.' + data.key) | translate }}
				}
			}
			@case ('required') {
				{{ (data.required ? 'gl.yes' : 'gl.no') | translate }}
			}
		}
	</td>
</ng-template>

<ng-template #responseHeader let-success="success">
	HTTP/1.1 <span [class]="success ? 'green' : 'red'">{{ success ? '200 OK' : '500 Internal Server Error' }}</span><br>
	Date: <span class="green">"&lt;date&gt;"</span><br>
	Content-Length: <span class="green">"{{ success ? '&lt;number&gt;' : '0' }}"</span><br>
	Content-Type: <span class="green">application/json</span><br>
</ng-template>
