import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, forkJoin, map, Subject, takeUntil } from 'rxjs';

import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { Id } from '@campaign-portal/namespace/common/id';
import { Package, Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { AlarisEditPanelService, EditPanelWidth } from '@campaign-portal/components-library';

import { PurchaseService, SubscribedPackagesStore } from './purchase.service';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { PackageDetailsComponent } from './package-details/package-details.component';
import { CP_PERMISSIONS } from '@helpers/types/permissions';


@Component({
	selector: 'app-purchase',
	templateUrl: './purchase.component.html',
	styleUrls: ['./purchase.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PurchaseComponent implements OnInit, OnDestroy {
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	packs: Package[] = [];
	plans: Plan[] = [];
	subscribed: { plans: Plan[]; packs: SubscribedPackagesStore } = {
		plans: [],
		packs: new Map()
	};

	readonly loading$ = combineLatest(
		[this.purchaseService.loadingRead$, this.purchaseService.loadingSubscribed$]
	).pipe(
		map((resp: boolean[]) => {
			return resp[0] || resp[1];
		}),
		distinctUntilChanged()
	);

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly cd: ChangeDetectorRef,
		private readonly purchaseService: PurchaseService,
		private readonly editPanel: AlarisEditPanelService
	) {
	}

	ngOnInit(): void {
		this.load();

		this.purchaseService.update$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.load();
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	packageRequest(): void {
		this.purchaseService.openSpecificDialog('PackageRequest')
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe();
	}

	planRequest(): void {
		this.purchaseService.openSpecificDialog('PlanRequest')
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe();
	}

	openPlanDetailsPanel(plan: Plan): void {
		this.editPanel.open(PlanDetailsComponent, EditPanelWidth.MD, {
			plan
		});
	}

	openPackageDetailsPanel(packId: Id): void {
		const packs = this.purchaseService.subscribedPackages.get(packId);
		this.editPanel.open(PackageDetailsComponent, EditPanelWidth.MD, {
			packs
		});
	}

	private load(): void {
		forkJoin([this.purchaseService.read(), this.purchaseService.subscribed()])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(([purchase, subscribed]) => {
				this.packs = purchase.Data.packs;
				this.plans = purchase.Data.plans;
				this.subscribed = subscribed.Data;
				this.subscribed.plans.forEach(p => {
					this.plans = this.filterPlans(p, this.plans);
				});
				this.cd.markForCheck();
			});
	}

	private filterPlans(item: Plan, array: Plan[]): Plan[] {
		switch (item.trafficType) {
			case TrafficType.TELEGRAM:
			case TrafficType.WHATSAPP:
			case TrafficType.SMS:
				return array.filter(s => s.trafficType !== TrafficType.SMS);
			case TrafficType.VIBER:
				return array.filter(s => s.settings?.viberSettings.messagePurpose !==
					item.settings?.viberSettings.messagePurpose
				);
		}
	}
}
