<alaris-card [class.line]="view === PurchaseView.LIST" class="card" size="auto">
	<div class="title">
		<alaris-icon [name]="cu.icon(plan.trafficType)" class="channel-icon"></alaris-icon>
		<span [alarisTooltip]="plan.name" [overflow]="true" class="name">{{plan.name}}</span>
	</div>
	<div class="item">
		<div class="description text-truncate">
			<div>
				<alaris-icon name="icon-paper-plane-right"></alaris-icon>
				<span>{{'purchase.channel' | translate}}</span>
			</div>
			<span>
				{{ cu.purpose(plan.trafficType, plan.settings?.viberSettings?.messagePurpose) | translate }}
			</span>
		</div>
		<div class="description text-truncate">
			<div>
				<alaris-icon name="icon-money-2"></alaris-icon>
				<span>{{'purchase.billingMode' | translate}}</span>
			</div>
			<span>
				{{'enums.' + plan.billingMode | translate}}
			</span>
		</div>
		<div class="description countries-wrapper">
			<div>
				<alaris-icon name="icon-map-pin"></alaris-icon>
				<span>{{'purchase.country' | translate}}</span>
			</div>
			<span class="countries-tag">
				<span class="text-truncate">{{ plan.countries!.slice(0, 4).join(', ') }}</span>
				<alaris-tag (click)="seeAllCountries(plan)"
							*ngIf="plan.countries!.length > 4"
							[alarisTooltip]="'purchase.allCountryHint' | translate"
							[rounded]="true" style="transform: scale(.75); cursor: pointer">
											+{{plan.countries!.length - 4}}
				</alaris-tag>
			</span>
		</div>
	</div>
	<div class="button">
		<button (click)="selectPlan(plan)" *ngIf="!plan.isSubscribed"
				[attr.data-testid]="'selectPlanBtn/' + plan.id"
				alaris-button bType="primary" size="md" type="button">
			{{'purchase.selectPlan' | translate}}
		</button>
		<button *ngIf="plan.isSubscribed" [attr.data-testid]="'planInUseBtn/' + plan.id" [disabled]="true"
				alaris-button bType="secondary" size="md" type="button">
			{{'purchase.planInUse' | translate}}
		</button>
		<div (click)="openPlanDetailsPanel(plan)"
			 [attr.data-testid]="'allFeatureBtn/' + plan.id"
			 class="features">{{ 'purchase.allFeature' | translate }}
		</div>
	</div>
</alaris-card>
