<div [alarisLoading]="loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-import-contact"></alaris-icon>
		<span>{{ 'campaigns.campaignFromFile' | translate }}</span>
	</div>
	<div alarisFade="vertical" class="edit-panel-content">
		<ng-template #campaignNotAvailable>
			<app-empty-campaign-card (financeTransition)="moveToPurchase()"
									 (senderRequest)="openSenderIdRequestPanel()"
									 [hasSenders]="hasSenders"
									 [hasSubscriptions]="hasSubscriptions"
									 [loading$]="loading$">
			</app-empty-campaign-card>
		</ng-template>

		<ng-container *ngIf="hasSenders && hasSubscriptions; else campaignNotAvailable">

			<alaris-input [formControl]="cw.infoControls.name"
						  [label]="'campaigns.campaignName' | translate"
						  [placeholder]="'campaigns.campaignName' | translate" data-testid="nameInput">
			</alaris-input>

			<div class="traffic-type">
				<alaris-select2
					[formControl]="channelControls.trafficType"
					[label]="'gl.deliveryChannel' | translate"
					[placeholder]="'gl.deliveryChannel' | translate"
					class="w-50"
					data-testid="trafficTypeSelect" selectedLabel="name">
					<alaris-options2 *ngFor="let opt of channelsService.list$ | async"
									 [attr.data-testid]="'trafficTypeOption/' + opt.channelType"
									 [value]="opt.channelType">
						{{ opt.name }}
					</alaris-options2>
				</alaris-select2>
				<alaris-select2 [displayWith]="displaySender.bind(this)"
								[formControl]="channelControls.sender"
								[label]="'senderIds.senderId' | translate"
								[placeholder]="'senderIds.senderId' | translate"
								class="w-50"
								data-testid="senderIdsSelect">
					<alaris-select-filter2 *ngIf="senders.length > 5"
										   [formControl]="filterSenderIdsControl" data-testid="senderIdsFilter">
					</alaris-select-filter2>
					<alaris-options2
						*ngFor="let opt of filterSenderIdsList | filter: 'trafficType' : channelControls.trafficType.value"
						[attr.data-testid]="'senderIdsOption/' + opt.name" [value]="opt.id">
						{{ opt.name }}
					</alaris-options2>
				</alaris-select2>
			</div>

			<div class="file">
				<label>{{ 'campaigns.uploadFile' | translate }}</label>
				<alaris-file-loader (fileListChanges)="addFiles($event)"
									[exportInternally]="true"
									[fileTypes]="'.csv,.xls,.xlsx,.numbers'"
									[multiple]="false"
									[required]="true" data-testid="campaignFile">
					<div class="loader-hint">
						{{ 'contacts.import.fileHintPart1' | translate }}
						<alaris-link [href]="templateRef" data-testid="sampleTemplateLink" type="accent">
							{{ 'contacts.import.fileHintPart2' | translate }}
						</alaris-link>
						{{ 'contacts.import.fileHintPart3' | translate }}
					</div>
				</alaris-file-loader>
				<ng-container *ngIf="fileRows.length > 0">
					<alaris-parsed-file-table (columns)="cw.setupControls.file.controls.columns.setValue($event)"
											  [fields]="fields"
											  [rows]="fileRows">
					</alaris-parsed-file-table>
				</ng-container>
				<ng-container *ngIf="fileLoading$ | async">
					<div [alarisLoading]="fileLoading$" style="width: 100%; height: 10rem"></div>
				</ng-container>
			</div>


			<ng-container *ngIf="channelControls.trafficType.value === TrafficType.VIBER">
				<div class="options">
					<label>{{ 'campaigns.viberType' | translate }}</label>
					<div class="omni">
						<alaris-radio [alarisComplexTooltip]="tooltipPriorityComponent"
									  [attr.data-testid]="'viberType/' + MessagePurpose.TRANSACTION"
									  [componentData]="MessagePurpose.TRANSACTION"
									  [formControl]="channelControls.omni!.controls.viberSettings!.controls.messagePurpose"
									  [value]="MessagePurpose.TRANSACTION"
									  name="priority">
							{{ 'campaigns.transactional' | translate }}
						</alaris-radio>
						<alaris-radio [alarisComplexTooltip]="tooltipPriorityComponent"
									  [attr.data-testid]="'viberType/' + MessagePurpose.PROMOTION"
									  [componentData]="MessagePurpose.PROMOTION"
									  [formControl]="channelControls.omni!.controls.viberSettings!.controls.messagePurpose"
									  [value]="MessagePurpose.PROMOTION" name="priority">
							{{ 'campaigns.promotional' | translate }}
						</alaris-radio>
					</div>
				</div>
			</ng-container>

			<div class="options">
				<label>{{ 'campaigns.scheduleCampaign' | translate }}</label>
				<alaris-radio [formControl]="scheduledChoiceControl" data-testid="scheduledChoiceRadio_now"
							  name="scheduled" value="now">
					{{ 'campaigns.sendNow' | translate }}
				</alaris-radio>
				<alaris-radio [formControl]="scheduledChoiceControl" data-testid="scheduledChoiceRadio_future"
							  name="scheduled" value="future">
					{{ 'campaigns.schedule' | translate }}
				</alaris-radio>
			</div>


			<ng-container *ngIf="scheduledChoiceControl.value === 'future'">
				<alaris-scheduled [control]="cw.infoControls.scheduled"></alaris-scheduled>
			</ng-container>

		</ng-container>

		<div *ngIf="approximateCampaignData"
			 [alarisLoading]="analysis$"
			 class="analysis">
			<h3 class="title">{{ 'campaigns.campaignDetails' | translate }}</h3>
			<div class="content">
				<div class="item">
					<label> {{ 'campaigns.approximateCost' |translate }}</label>
					<div class="strong">
						{{ (approximateCampaignData.cost.min || 0) | currency: bs.currencyCode }}
					</div>
					<span style="margin-left: 3rem;">-</span>
					<div class="strong">
						{{ (approximateCampaignData.cost.max || 0) | currency: bs.currencyCode }}
					</div>
				</div>

				<div class="item">
					<label>{{ 'campaigns.totalRecipients' |translate }}</label>
					<div class="strong">{{ approximateCampaignData.recipients }}</div>
				</div>

				<div class="chart">
					<tui-ring-chart [value]="[
								approximateFileAnalysis?.correctRow || 0,
								approximateFileAnalysis?.wrongFormatRow || 0,
								approximateFileAnalysis?.duplicatesRow || 0
								]" size="s"></tui-ring-chart>

					<div class="legend">
						<tui-legend-item
							*ngFor="let label of ['correctRow', 'wrongFormatRow', 'duplicatesRow']; let index = index"
							[color]="'var(--tui-chart-'+index+')'"
							[text]="('campaigns.' + label) |translate"
							size="s">
							<strong>{{ approximateFileAnalysis ? approximateFileAnalysis[label] : 0 }}</strong>
						</tui-legend-item>
					</div>
				</div>
			</div>
		</div>

	</div>
	<div class="edit-panel-footer">
		<button (click)="createCampaign()"
				[disabled]="createCampaignDisabled"
				alaris-button
				bType="primary"
				data-testid="createBtn"
				size="lg" type="button">
			{{ 'campaigns.createCampaign' | translate }}
		</button>
		<button (click)="campaignDetails()"
				[disabled]="createCampaignDisabled"
				[progress]="analysis$"
				alaris-button
				bType="outline"
				data-testid="campaignDetailsBtn"
				size="lg" type="button">
			{{ 'campaigns.campaignDetails' | translate }}
		</button>
	</div>

</div>
