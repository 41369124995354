import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Injectable()
export class CampaignFieldsService implements AbstractCRUDService, OnDestroy {

	entity = '';
	title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly headers: EntityField[] = [
		{
			id: 0,
			name: 'columns.campaign',
			variable: 'campaign',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.LIKE
		},
		{
			id: 1,
			name: 'columns.lastUpdated',
			variable: 'lastUpdated',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 2,
			name: 'columns.template',
			variable: 'template',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.LIKE
		},
		{
			id: 3,
			name: 'columns.status',
			variable: 'status',
			required: true,
			type: InputComplexType.MULTISELECT,
			readonly: true,
			data: [],
			filterType: FilterType.IN

		},
		{
			id: 4,
			name: 'columns.progress',
			variable: 'progress',
			required: true,
			type: InputComplexType.RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 5,
			name: 'columns.recipients',
			variable: 'recipients',
			required: true,
			type: InputComplexType.RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 6,
			name: 'columns.cost',
			variable: 'cost',
			required: true,
			type: InputComplexType.RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 7,
			name: 'columns.startDate',
			variable: 'startDate',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 8,
			name: 'columns.finishDate',
			variable: 'finishedDate',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 9,
			name: 'columns.scheduled',
			variable: 'scheduled',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		}
	];

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}

}
