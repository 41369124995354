import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AlarisEditPanelService, EditPanelWidth } from '@campaign-portal/components-library';
import { TrafficType } from '@campaign-portal/namespace/common/enums';

import { SendersService } from '../../campaigns/services/senders.service';
import { CreateApiKeyComponent } from '../api-keys/create-key/create-key.component';

enum KEYS {
	methodType = 'methodType',
	token = 'token',
	to = 'to',
	from = 'from',
	message = 'message',
	label = 'label',
	channels = 'channels',
	fallbackTtl = 'fallbackTtl',
	trafficType = 'trafficType',
	buttonActionUrl = 'buttonActionUrl',
	imageUrl = 'imageUrl',
	buttonCaption = 'buttonCaption'
}

interface ApiHeader {
	name: string;
	variable: string;
}

interface ApiRow {
	key: KEYS;
	type: string;
	required: boolean;
}

@Component({
	selector: 'app-api-methods',
	templateUrl: './api-methods.component.html',
	styleUrl: './api-methods.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiMethodsComponent implements OnInit {

	readonly headers: ApiHeader[] = [
		{
			name: 'columns.parameter',
			variable: 'key'
		},
		{
			name: 'columns.methodType',
			variable: 'type'
		},
		{
			name: 'columns.description',
			variable: 'description'
		},
		{
			name: 'columns.mandatory',
			variable: 'required'
		}
	];
	readonly defaultRows: ApiRow[] = [
		{ key: KEYS.methodType, type: 'api.params.type.methodType', required: false },
		{ key: KEYS.token, type: 'api.params.type.text', required: true },
		{ key: KEYS.channels, type: 'api.params.type.channels', required: true },
		{ key: KEYS.from, type: 'api.params.type.from', required: true },
		{ key: KEYS.to, type: 'api.params.type.to', required: true },
		{ key: KEYS.message, type: 'api.params.type.text', required: true },
		{ key: KEYS.label, type: 'api.params.type.text', required: false },
		{ key: KEYS.fallbackTtl, type: 'api.params.type.fallbackTtl', required: false }
	];
	readonly viberRows: ApiRow[] = [
		{ key: KEYS.trafficType, type: 'api.params.type.trafficType', required: false },
		{ key: KEYS.buttonActionUrl, type: 'api.params.type.url', required: false },
		{ key: KEYS.imageUrl, type: 'api.params.type.url', required: false },
		{ key: KEYS.buttonCaption, type: 'api.params.type.text', required: false }
	];
	readonly KEYS = KEYS;
	readonly url = `${window.location.origin}/api/send_sms`;
	tRows: ApiRow[] = this.defaultRows;

	private hasViberSender = false;
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		private readonly senderService: SendersService,
		private editPanel: AlarisEditPanelService,
		private readonly cd: ChangeDetectorRef
	) {
	}

	get channels(): string {
		return this.hasViberSender ? [TrafficType.VIBER, TrafficType.SMS].join(', ') : TrafficType.SMS;
	}

	ngOnInit(): void {
		this.senderService.list$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(senders => {
				this.hasViberSender = !!senders.find((sender) => {
					return sender.trafficType === TrafficType.VIBER;
				});
				this.tRows = this.hasViberSender
					? [...this.defaultRows, ...this.viberRows]
					: [...this.defaultRows];
				this.cd.detectChanges();
			});
	}

	addApiKey(): void {
		this.editPanel.open(CreateApiKeyComponent, EditPanelWidth.SM, {})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}
}
