<alaris-card size="auto">
	<div [alarisLoading]="templatesService.loading$" style="width: 100%;border-radius: 0.5rem 0.5rem 0 0;">
		<div class="head">
			<alaris-icon (click)="close()"
						 class="standard close" data-testid="closeAdditionalBtn"
						 name="deprecated/modal-close">
			</alaris-icon>
			<h3 [ngSwitch]="data.type">
				<ng-container *ngSwitchCase="'Delete'">{{ "templates.delete" | translate }}</ng-container>
				<ng-container *ngSwitchCase="'Update'">{{ "templates.update" | translate }}</ng-container>
				<ng-container *ngSwitchCase="'CreateFromCampaignWizard'">{{ "templates.save" | translate }}
				</ng-container>
			</h3>
		</div>

		<div class="content">
			<div [ngSwitch]="data.type" class="note">

				<ng-container *ngSwitchCase="'Delete'">
					<p *ngIf="details.length === 0">
						{{ 'templates.deleteNote' | translate }} <strong>({{template.name}})</strong>?
					</p>
					<div *ngIf="details.length !== 0">
						<p>{{ 'templates.templateUse' | translate }}</p>
						<p>{{ 'templates.notBeSent' | translate }}</p>
					</div>
				</ng-container>

				<ng-container *ngSwitchCase="'Update'">
					<p>{{ 'templates.updateNote' | translate }}</p>
				</ng-container>

				<ng-container *ngSwitchCase="'CreateFromCampaignWizard'">
					<p>{{ 'templates.createNote' | translate }}</p>

					<alaris-input [errors]="errors"
								  [formControl]="templateName"
								  [label]="'templates.templateName' | translate"
								  [placeholder]="'templates.templateName' | translate"
								  data-testid="templateNameInput">
					</alaris-input>
				</ng-container>

			</div>

			<div *ngIf="details.length > 0" class="details">
				<p><strong>{{ 'templates.campaignUsage' | translate }}</strong></p>
				<ul>
					<li *ngFor="let campaign of details" class="campaign">
						<span class="name">{{campaign.name ?? campaign.id}}</span>
						<span class="dots"></span>
						<span class="scheduled">{{campaign.scheduled | date: 'd MMM y, HH:mm'}}</span>
					</li>
				</ul>
			</div>

		</div>

		<div class="footer">
			<button (click)="close()"
					[disabled]="!!(templatesService.loading$ | async)"
					alaris-button
					bType="outline" data-testid="closeBtn">
				{{ 'actions.cancel' | translate }}
			</button>
			<button (click)="confirm()"
					[disabled]="disabled"
					alaris-button
					bType="primary" data-testid="confirmBtn">
				{{ 'actions.confirm' | translate }}
			</button>
		</div>
	</div>
</alaris-card>
