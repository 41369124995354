import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-empty-purchase-card',
	templateUrl: './empty-purchase-card.component.html',
	styleUrls: ['./empty-purchase-card.component.scss']
})
export class EmptyPurchaseCardComponent {
	@Input() mode: 'packs' | 'plans' = 'packs';
	@Output() readonly requestSubscription = new EventEmitter<'packs' | 'plans'>();

	requestAdd(): void {
		this.requestSubscription.emit(this.mode);
	}
}
