import { Injectable, OnDestroy } from '@angular/core';
import { AbstractCRUDService } from '@campaign-portal/components-library';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Injectable()
export class ContactImportTableFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly headers: EntityField[] = [
		{
			id: 0,
			name: 'columns.file',
			variable: 'file',
			required: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			readonly: true,
			property: 'name'
		},
		{
			id: 1,
			name: 'columns.status',
			variable: 'status',
			required: true,
			type: InputComplexType.SELECT,
			filterType: FilterType.EXACT,
			data: [],
			readonly: true
		},
		{
			id: 2,
			name: 'columns.contactGroups',
			variable: 'contactGroups',
			required: true,
			type: InputComplexType.MULTISELECT,
			filterType: FilterType.IN,
			property: 'id',
			readonly: true
		},
		{
			id: 3,
			name: 'columns.importDate',
			variable: 'creationDate',
			required: true,
			type: InputComplexType.DATE_RANGE,
			filterType: FilterType.BETWEEN,
			readonly: true
		},
		{
			id: 4,
			name: 'columns.total',
			variable: 'total',
			required: true,
			type: InputComplexType.RANGE,
			filterType: FilterType.BETWEEN,
			readonly: true
		},
		{
			id: 5,
			name: 'columns.addedRows',
			variable: 'added',
			required: true,
			type: InputComplexType.RANGE,
			filterType: FilterType.BETWEEN,
			readonly: true
		},
		{
			id: 6,
			name: 'columns.updated',
			variable: 'updated',
			required: true,
			type: InputComplexType.RANGE,
			filterType: FilterType.BETWEEN,
			readonly: true
		},
		{
			id: 7,
			name: 'columns.errors',
			variable: 'errors',
			required: true,
			type: InputComplexType.RANGE,
			filterType: FilterType.BETWEEN,
			readonly: true
		},
		{
			id: 8,
			name: 'columns.user',
			variable: 'createdBy',
			required: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			readonly: true
		}
	];

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}
