import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
	selector: 'app-empty-campaign-card',
	templateUrl: './empty-campaign-card.component.html',
	styleUrls: ['./empty-campaign-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyCampaignCardComponent {
	@Input() loading$: Observable<boolean> = new BehaviorSubject<boolean>(true);
	@Input() hasSenders = false;
	@Input() hasSubscriptions = false;
	@Output() readonly senderRequest = new EventEmitter();
	@Output() readonly financeTransition = new EventEmitter();

	constructor() {
	}

}
