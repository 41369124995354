<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total"> {{ totalTitle | translate }}: {{ total }} </span>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon name="icon-filter-vertical"></alaris-icon>
				{{ 'gl.filter.filters' | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
			<alaris-link (click)="editTableSettings()" data-testid="editSettingsLink">
				{{ 'table.settings.tableSettings' | translate }}
			</alaris-link>
		</div>
		<div class="right">
			<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
			<ng-container *alarisPBAC="[CP_PERMISSIONS.CAMPAIGNS_E]">
				<button (click)="import.call(this)" alaris-button bType="outline" data-testid="fromFileBtn" size="lg">
					<alaris-icon name="icon-import"></alaris-icon>
					{{ 'campaigns.campaignFromFile' | translate }}
				</button>
				<button (click)="create.call(this)" alaris-button bType="primary" data-testid="createBtn" size="lg">
					<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
					{{ 'campaigns.newCampaign' | translate }}
				</button>
			</ng-container>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<ng-content select="[slot=empty]"></ng-content>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="dblClickRowAction.bind(this)"
						  [dropDownRowActions]="dropDownRowActions"
						  [filters]="filters"
						  [fixFirstColumn]="fixFirstColumn"
						  [resizable]="resizable"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [tSettings]="tSettings"
						  [templates]="templates"
						  alarisOverflowFader style="position: relative">
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>
