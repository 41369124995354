import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Subject, takeUntil } from 'rxjs';

import {
	AlarisConfigService,
	AlarisLanguageService,
	ControlValueAccessorConnector,
	DataTestId
} from '@campaign-portal/components-library';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { ContactField } from '@campaign-portal/namespace/entities/contact-fields/specs';
import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { ContactFieldsService } from '../../../contacts/services/contact-fields.service';
import { EMOJI_CATEGORIES, EMOJI_I18N_KEYS } from '../const/emoji';

// const DUMMY_TEXT = 'Hey! Write your message here.';

@Component({
	selector: 'app-text-area-editor',
	templateUrl: './text-area-editor.component.html',
	styleUrls: ['./text-area-editor.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextAreaEditorComponent,
			multi: true
		},
		DataTestId
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaEditorComponent extends ControlValueAccessorConnector implements OnInit, OnDestroy {
	@ViewChild('editor') readonly editor!: ElementRef;
	@Input() channel = TrafficType.SMS;

	emojiI18n: Record<string, unknown>;
	readonly EMOJI_CATEGORIES = EMOJI_CATEGORIES;

	readonly TrafficType = TrafficType;
	caretPosition!: Range;
	contactFields: ContactField[] = [];
	readonly variable = new FormControl<ContactField | null>(null);

	readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly contactFieldsService: ContactFieldsService,
		public readonly config: AlarisConfigService,
		private readonly ls: AlarisLanguageService,
		private readonly cd: ChangeDetectorRef
	) {
		super();
		this.emojiI18n = this.getEmojiI18n();
	}

	ngOnInit(): void {
		this.checkControlStatus();

		this.contactFieldsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(list => {
				this.contactFields = list;
			});

		this.variable.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				if ( value ) {
					this.variable.setValue(null, { emitEvent: false });
					this.pasteVariable(value);
				}
			});

		this.control.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.cd.detectChanges();
				this.checkControlStatus();
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	bold(): void {
		this.addMDSymbols('*');
	}

	italic(): void {
		this.addMDSymbols('_');
	}

	strike(): void {
		this.addMDSymbols('~');
	}

	addEmoji(event: EmojiEvent): void {
		const textarea = this.editor.nativeElement as HTMLTextAreaElement;
		const newText = textarea.value.substring(0, textarea.selectionStart)
			+ `${event.emoji.native}`
			+ textarea.value.substring(textarea.selectionEnd);
		this.control.setValue(newText);
	}

	pasteVariable(contactField: EntityField): void {
		const textarea = this.editor.nativeElement as HTMLTextAreaElement;
		const newText = textarea.value.substring(0, textarea.selectionStart)
			+ `%${contactField.variable}%`
			+ textarea.value.substring(textarea.selectionEnd);
		this.control.setValue(newText);
	}

	displayVariable(_variable: ContactField | null): string {
		return '';
	}

	private addMDSymbols(symbol: string): void {
		const textarea = this.editor.nativeElement as HTMLTextAreaElement;
		const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
		const newText = textarea.value.substring(0, textarea.selectionStart)
			+ ` ${symbol}` + selectedText + `${symbol} `
			+ textarea.value.substring(textarea.selectionEnd);
		this.control.setValue(newText);
	}

	private getEmojiI18n(): Record<string, unknown> {
		const translate = (obj: any, key: string): void => {
			for ( const [property, value] of Object.entries(obj) ) {
				if ( typeof value === 'object' && value ) {
					translate(value, key + '.' + property);
				} else {
					obj[property] = this.ls.translate(key + '.' + property);
				}
			}
		};

		translate(EMOJI_I18N_KEYS, 'campaigns.emoji');
		return EMOJI_I18N_KEYS;
	}

	private checkControlStatus(): void {
		if ( this.control.enabled && this.variable.disabled ) {
			this.variable.enable();
		} else if ( this.control.disabled && this.variable.enabled ) {
			this.variable.disable();
		}
	}
}
