import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	signal
} from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

import { Contact } from '@campaign-portal/namespace/entities/contacts/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { CampaignInfo } from '@campaign-portal/namespace/entities/campaigns/specs';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ContactsImportHistory } from '@campaign-portal/namespace/entities/contacts-import-history/specs';

import {
	AlarisDialogService,
	AlarisLanguageService,
	AlarisProfileService,
	filterWildcardData,
	PROFILE_SERVICE_INJECTOR,
	TableSelectionIndicator
} from '@campaign-portal/components-library';

import { equalitySets } from '@helpers/utils/math';
import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { ContactsService } from '../../services/contacts.service';
import { ContactGroupsService } from '../../services/contact-groups.service';

export type ContactsDialogType =
	'Delete' | 'MoveToGroup' | 'RemoveFromGroup' | 'AddToGroup' |
	'MoveToStopList' | 'RemoveFromStopList' | 'CampaignUsage';

export interface ContactsDialogData {
	type: ContactsDialogType;
	contacts: Contact<exist> | Contact<exist>[] | TableSelectionIndicator<Contact<exist>>;
	params?: RPCRequestParams;
	stopList: boolean;
}

@Component({
	selector: 'app-contact-actions',
	templateUrl: '../contact-and-groups-actions.component.html',
	styleUrls: ['../contact-and-groups-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactActionsComponent extends CanDeactivateComponent implements OnInit, OnDestroy {

	readonly contacts: Contact<exist>[];
	contactGroups: ContactGroup[] = [];
	details: CampaignInfo[] = [];
	additionalDetails: CampaignInfo[] = [];
	readonly groups?: ContactGroup[];
	readonly groupControl = new FormControl<ContactGroup<exist> | null>(null, { validators: Validators.required });
	readonly groupFilter = new FormControl();

	// unified with contacts-actions
	readonly activeImports = signal<ContactsImportHistory[]>([]);
	readonly confirmButtonText = 'actions.confirm';

	readonly loading$ = new BehaviorSubject<boolean>(true);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly CP_PERMISSIONS = CP_PERMISSIONS;

	private _contactGroups: ContactGroup[] = [];

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profile: AlarisProfileService,
		@Inject(DIALOG_DATA) public readonly data: ContactsDialogData,
		public readonly contactGroupsService: ContactGroupsService,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly contactsService: ContactsService,
		private readonly cd: ChangeDetectorRef,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService,
		private readonly ls: AlarisLanguageService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.contacts = Array.isArray(data.contacts) ? data.contacts : data.contacts !== null ? [data.contacts] : [];
		if ( data.type === 'RemoveFromGroup' ) {
			this.contactGroups = Array.from(this.contacts.reduce(
				(result: Set<ContactGroup>, contact) => {
					contact.contactGroups.forEach(
						(id) => {
							const cg = this.contactGroupsService.map.get(id);
							if ( cg ) {
								result.add(cg);
							}
						}
					);
					return result;
				}, new Set<ContactGroup>()
			));
		}
	}

	get disabled(): boolean {
		let result = this.loading$.getValue();
		if (
			this.data.type === 'MoveToGroup' || this.data.type === 'RemoveFromGroup' || this.data.type === 'AddToGroup'
		) {
			result = result || !this.groupControl.value;
		}
		return result;
	}

	get showPriceChangeNote(): boolean {
		let show = true;
		const selectedGroup: ContactGroup<exist> | null = this.groupControl.value;
		switch (this.data.type) {
			case 'AddToGroup':
				if ( !selectedGroup || !this.additionalDetails.length ) {
					return false;
				}
				show = this.contacts.length
					? !!(this.contacts.find(contact => !contact.contactGroups.includes(selectedGroup.id)))
					: true;
				break;
			case 'MoveToGroup':
				const noAffectedCampaigns = !this.details.length && !this.additionalDetails.length;
				if ( !selectedGroup || noAffectedCampaigns ) {
					return false;
				}
				const sourceDetails = new Set(this.details.map(details => details.id));
				const destinationDetails = new Set(this.additionalDetails.map(details => details.id));
				if ( equalitySets(sourceDetails, destinationDetails) ) {
					show = this.contacts.length
						? !!(this.contacts.find(contact => !contact.contactGroups.includes(selectedGroup.id)))
						: true;
				}
				break;
			case 'RemoveFromGroup':
				if ( !selectedGroup || this.additionalDetails.length === 0 ) {
					return false;
				}
				show = this.contacts.length
					? !!(this.contacts.find(contact => contact.contactGroups.includes(selectedGroup.id)))
					: true;
				break;
			case 'RemoveFromStopList':
				return !!this.additionalDetails.length;
			case 'MoveToStopList':
			case 'Delete':
				return !!this.details.length;
		}
		return show;
	}

	get sourceEntity(): string {
		return this.ls.translate('notifications.titles.contact');
	}

	get destinationEntity(): string {
		return this.ls.translate('notifications.titles.group');
	}

	ngOnInit(): void {
		this.groupControl.valueChanges
			.pipe(
				switchMap(group => {
					if ( group && this.profile.allowed([CP_PERMISSIONS.CONTACTS_E]) ) {
						this.loading$.next(true);
						return this.contactGroupsService.checkCampaignUsage(group);
					}
					return EMPTY;
				}),
				takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.loading$.next(false);
				if ( resp.Success ) {
					this.additionalDetails = resp.Data ?? [];
					this.cd.markForCheck();
				}
			});

		this.contactGroupsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				if (
					this.contacts.length
					&& (this.data.type === 'AddToGroup' || this.data.type === 'MoveToGroup')
				) {
					const groupId = this.contacts[0].contactGroups[0];
					this._contactGroups = this.contactGroups = this.contacts
						.find(c => c.contactGroups[0] !== groupId || c.contactGroups.length > 1)
						? list
						: list.filter(i => i.id !== groupId);
				} else {
					this._contactGroups = this.contactGroups = list;
				}
				this.groupFilter.setValue('', { emitEvent: false });
				this.cd.detectChanges();
			});

		this.groupFilter.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
			this.contactGroups = filterWildcardData(value, this._contactGroups, 'name');
		});

		if ( !this.data.stopList && this.profile.allowed([CP_PERMISSIONS.CONTACTS_E]) ) {
			this.contactsService.checkCampaignUsage(this.contacts)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(resp) => {
						this.loading$.next(false);
						this.details = resp.Data ?? [];
						this.cd.markForCheck();
					}
				);
		} else {
			this.loading$.next(false);
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });
		const contacts: Contact<exist>[] | null = this.contacts.length
			? this.contacts
			: null;

		switch (this.data.type) {
			case 'Delete':
				caller = this.contactsService.delete(contacts);
				break;
			case 'MoveToStopList':
				caller = this.contactsService.moveToStopList(contacts);
				break;
			case 'AddToGroup':
				caller = this.contactsService.addToGroup(contacts, this.groupControl.value as ContactGroup<exist>);
				break;
			case 'MoveToGroup':
				caller = this.contactsService.moveToGroup(contacts, this.groupControl.value as ContactGroup<exist>);
				break;
			case 'RemoveFromGroup':
				caller = this.contactsService.removeFromGroup(contacts, this.groupControl.value as ContactGroup<exist>);
				break;
			case 'RemoveFromStopList':
				caller = this.contactsService.removeFromStopList(contacts, this.groupControl.value as ContactGroup<exist>);
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				// To display the correct number of contacts in groups
				this.contactGroupsService.refresh$.next();

				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}
}
