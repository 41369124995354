<alaris-card>
	<div *ngIf="filteredTemplates.length > 0 || filteredTemplates.length === 0 && hasFilter"
		 [formGroup]="filter"
		 class="table-management">
		<div class="left">
			<div class="total" data-testid="total"> {{ 'gl.total' | translate }}: {{ templatesService.list.length }}
			</div>
			<alaris-tabs-button [formControl]="filter.controls.trafficType"
								[tabs]="tabs"
								data-testid="trafficTypeTabs" size="xl">
			</alaris-tabs-button>
			<alaris-input [label]="'templates.searchBySender' | translate"
						  data-testid="searchBySenderInput" formControlName="sender"
						  type="text">
			</alaris-input>
			<alaris-input [label]="'templates.searchByText' | translate"
						  data-testid="searchByTextInput" formControlName="messageTemplate"
						  type="text">
			</alaris-input>
		</div>
		<div class="right">
			<button (click)="updateTemplate()"
					*alarisPBAC="[CP_PERMISSIONS.MESSAGE_TEMPLATES_E]"
					alaris-button bType="primary" data-testid="createBtn" size="xl">
				<alaris-icon class="right-space in-primary" name="icon-plus"></alaris-icon>
				{{ 'templates.createTemplate' | translate }}
			</button>
		</div>
	</div>
	<div [alarisFade]="'vertical'" [alarisLoading]="templatesService.loading$" class="content">
		<app-empty-table (clearFilter)="filter.reset()"
						 (newTemplate)="updateTemplate()"
						 *ngIf="filteredTemplates.length === 0; else cards"
						 [type]="hasFilter ? 'templates-with-filter': 'templates'">
		</app-empty-table>
		<ng-template #cards>
			<div class="card-container">
				<app-template-card (click)="updateTemplate(template)"
								   *ngFor="let template of filteredTemplates"
								   [actions]="actions"
								   [attr.data-testid]="'templateCard/' + template.id"
								   [template]="template">
				</app-template-card>
			</div>

		</ng-template>
	</div>
	<div class="footer"></div>
</alaris-card>
