import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ApiKeysService } from './api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-api',
	templateUrl: './api.component.html',
	styleUrl: './api.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiComponent implements OnInit {

	private readonly destroyedRef = inject(DestroyRef);

	constructor(
		private readonly apiService: ApiKeysService
	) {
	}

	ngOnInit(): void {
		this.apiService.load()
			.pipe(takeUntilDestroyed(this.destroyedRef))
			.subscribe();
	}

}
