<ng-container *ngIf="packs !== null && packs.length > 0">
	<alaris-card class="card" size="auto">
		<div class="header">
			<alaris-icon [name]="cu.icon(packs[0].trafficType)" class="channel-icon"></alaris-icon>
			<div class="label column">
				<span>{{'gl.channel' | translate}}</span>
				<strong>
					{{ cu.name(packs[0].trafficType) | translate}} {{'purchase.pack' | translate}}
				</strong>
			</div>
			<alaris-icon name="icon-caret-right"></alaris-icon>
		</div>
		<div class="progress">
			<span [alarisTooltip]="packs[0].name" [overflow]="true" class="text-truncate">
				{{packs[0].name}}
			</span>
			<span class="label row"> <strong>{{rest}}</strong> {{'gl.of' | translate}} {{total}}</span>
		</div>
		<alaris-progress-line [content]="true" [percentageComplete]="rest * 100 / total"
							  [type]="progressType">
		</alaris-progress-line>
	</alaris-card>

</ng-container>

<ng-container *ngIf="plan">
	<alaris-card class="card" size="auto">
		<div class="header">
			<alaris-icon [name]="cu.icon(plan.trafficType)" class="channel-icon">
			</alaris-icon>
			<div class="label column">
				<span>
					{{ cu.purpose(
					plan.trafficType,
					plan.settings?.viberSettings?.messagePurpose) | translate
					}}
				</span>
				<strong [alarisTooltip]="plan.name" [overflow]="true" class="text-truncate">
					{{plan.name}}
				</strong>
			</div>
			<alaris-icon name="icon-caret-right"></alaris-icon>
		</div>
		<div class="progress">
			<span>
				{{'purchase.country' | translate}}:
				<span class="countries text-truncate">
					{{plan.countries!.slice(0, 3).join(', ')}}
				</span>
			</span>
			<div (click)="seeAllCountries($event, plan)"
				 *ngIf="plan.countries!.length > 3"
				 [alarisTooltip]="'purchase.allCountryHint' | translate"
				 data-testid="seeAllCountriesBtn">
				+ {{ plan.countries!.length - 3 }}
			</div>
		</div>
	</alaris-card>
</ng-container>


