import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';

import {
	AbstractConfigService,
	CONFIG_SERVICE_INJECTOR,
	ResetPasswordSubmit,
	SignInState
} from '@campaign-portal/components-library';

import { ResetService } from './reset.service';

@Component({
	selector: 'app-reset',
	templateUrl: './reset.component.html',
	styleUrls: ['./reset.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetComponent implements OnDestroy {

	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(CONFIG_SERVICE_INJECTOR) private readonly appConfigService: AbstractConfigService,
		private readonly resetPassword: ResetService,
		private readonly router: Router
	) {
	}

	get style(): string {
		const light = this.appConfigService.themeType$.getValue() !== 'dark';
		return `background-image: url(${light ? 'assets/img/bg-light.png' : 'assets/img/bg-dark.png'})`;
	}

	activate($event: ResetPasswordSubmit): void {
		this.loading$.next(true);
		this.resetPassword.activate($event)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(
				(resp) => {
					this.loading$.next(false);
					if ( resp?.Success ) {
						this.router.navigate(['sign-in'], {
							queryParams: {
								state: SignInState.changedPassword
							}
						}).then();
					}
				}
			);
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}
}
