<div [class.sms]="trafficType === TrafficType.SMS"
	 [class.viber]="trafficType !== TrafficType.SMS"
	 class="phone">
	<div class="sender">
		{{ sender }}
	</div>
	<div class="write-message-hint">
		{{ 'templates.writeMessage' | translate }}
	</div>
	<div *ngIf="!file; else fromFileStub" class="message-box">
		<div class="today">{{ 'gl.today' | translate }}</div>
		<div class="message">
			<div #messageTemplate class="text">
				<!--				{{message}}-->
			</div>
			<ng-container *ngIf="(trafficType !== TrafficType.SMS) && (imageUrl || buttonCaption)">
				<img *ngIf="imageUrl"
					 [alt]="'templates.image' | translate"
					 [src]="imageUrl">
				<a *ngIf="buttonCaption"
				   [attr.href]="buttonActionUrl" target="_blank">
					{{ buttonCaption }}
				</a>
			</ng-container>
		</div>
		<div class="time">{{ today | date: 'HH:mm' }}</div>
	</div>
</div>

<ng-template #fromFileStub>
	<div class="stub">
		<div class="note-section">
			<span class="title">{{ 'campaigns.sentFromFile' | translate }}</span> <br>
			<div class="note">{{ 'campaigns.sentFromFileNote' | translate }}</div>
		</div>
		<div (click)="download()" class="download-section" data-testid="downloadFileBtn">
			<div class="icon-round">
				<alaris-icon name="icon-import"></alaris-icon>
			</div>
			{{ 'actions.downloadFile' | translate }}
		</div>
	</div>
</ng-template>
