import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisProfileService,
	AlarisSettingsManagerComponent,
	AlarisTableSettingsService,
	AlarisTableStateService,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple,
	SharedTemplatesService,
	TableFiltersIndicator
} from '@campaign-portal/components-library';

import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { Contact } from '@campaign-portal/namespace/entities/contacts/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { EntityField } from '@campaign-portal/namespace/common/entityField';

import { ContactsService } from '../../services/contacts.service';
import { ContactFieldsService } from '../../services/contact-fields.service';
import { ContactGroupsService } from '../../services/contact-groups.service';
import { ContactsActions } from '../actions';
import { CP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-contacts-table',
	templateUrl: '../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.html',
	styleUrls: [
		'../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./contacts-table.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactsTableComponent extends ContactsActions implements OnChanges, OnInit {
	@Input() createContact: Record<string, unknown> | undefined;
	@Input() groupId?: Id<exist>;
	@Output() readonly initiateImport = new EventEmitter<void>();

	override readonly id = 'contacts';
	override readonly rowActions: RowActionSimple<Contact | Contact[]>[] = [
		{
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.edit.bind(this)
		},
		{
			icon: 'icon-delete',
			label: 'actions.delete',
			// @ts-ignore
			action: this.delete.bind(this)
		}
	];
	override readonly dropDownRowActions: RowActionSimple<Contact | Contact[]>[] = [
		{
			icon: '',
			label: 'actions.export',
			// @ts-ignore
			action: this.export.bind(this)
		},
		{
			icon: '',
			label: 'contacts.actions.removeFromGroup',
			action: this.removeFromGroup.bind(this),
			// @ts-ignore
			shown: (contact: Contact<exist>): boolean => contact.contactGroups.length > 1
		},
		{
			icon: '',
			label: 'contacts.actions.addToGroup',
			action: this.addToGroup.bind(this)
		},
		{
			icon: '',
			label: 'contacts.actions.moveToGroup',
			action: this.moveToGroup.bind(this)
		},
		{
			icon: '',
			label: 'contacts.actions.moveToStopList',
			action: this.moveToStopList.bind(this)
		}
	];
	override readonly mainActions: RowActionSimple<Contact | Contact[]>[] = [
		{
			icon: 'icon-export-1',
			label: 'actions.export',
			action: (): void => {
				return this.export(this.selection as Contact<exist>[]);
			}
		}
	];
	override readonly additionalActions: RowActionSimple<Contact | Contact[]>[] = [
		{
			icon: 'icon-arrow-move-list',
			label: 'contacts.actions.moveToGroup',
			action: (): void => {
				return this.moveToGroup(this.selection);
			}
		},
		{
			icon: 'icon-folder-minus',
			label: 'contacts.actions.removeFromGroup',
			action: (): void => {
				return this.removeFromGroup(this.selection);
			},
			disabled: (): boolean => {
				return this.selection === null || !!this.selection.find(c => c.contactGroups.length === 1);
			}
		},
		{
			icon: 'icon-folder',
			label: 'contacts.actions.addToGroup',
			action: (): void => {
				return this.addToGroup(this.selection);
			}
		},
		{
			icon: 'deprecated/stop',
			label: 'contacts.actions.moveToStopList',
			action: (): void => {
				return this.moveToStopList(this.selection);
			}
		},
		{
			icon: 'icon-delete',
			label: 'contacts.actions.delete',
			action: (): void => {
				return this.delete(this.selection);
			}
		}
	];
	override readonly ctxActions: RowActionSimple<Contact | Contact[]>[] = [
		// @ts-ignore
		...this.dropDownRowActions,
		{
			icon: '',
			label: 'actions.delete',
			// @ts-ignore
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		}
	];

	override filters: TableFiltersIndicator = new Map()
		.set('stopList', {
			enabled: true,
			value: false,
			filterType: FilterType.EXACT
		});

	constructor(
		public override readonly contactsService: ContactsService,
		public override readonly contactFieldsService: ContactFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly dialog: AlarisDialogService,
		public override readonly filesService: AlarisFilesService,
		public override readonly stateService: AlarisTableStateService,
		public readonly sharedTemplates: SharedTemplatesService,
		public readonly contactGroupsService: ContactGroupsService,
		@Inject(PROFILE_SERVICE_INJECTOR) public override readonly profileService: AlarisProfileService
	) {
		super(
			contactsService,
			contactFieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.CONTACTS,
			stateService,
			dialog,
			filesService,
			profileService
		);
		this.templates = this.sharedTemplates.templates;
	}

	override dblClickRowAction(contact?: Contact<exist>): void {
		this.edit(contact);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.createContact.currentValue ) {
			this.create();
		}
		if ( changes.groupId && this.groupId ) {
			const filter: TableFiltersIndicator = new Map()
				.set('contactGroups', {
					filterType: FilterType.IN,
					value: [this.contactGroupsService.map.get(this.groupId)],
					property: 'id',
					enabled: true
				});
			if ( changes.groupId.firstChange ) {
				this.filters = new Map([...this.filters, ...filter]);
				this.isFiltersApplied = true;
				this.stateService.save(this.id, this);
			} else {
				this.applyFilter(filter);
			}
		}
	}

	override ngOnInit(): void {
		super.ngOnInit();
		if ( !this.profileService.allowed([CP_PERMISSIONS.CONTACTS_E]) ) {
			this.removeActions();
		}
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			contactGroups: this.contactGroupsService
		});
	}

	override applyFilter(event: TableFiltersIndicator): void {
		super.applyFilter(event);
		if ( !(this.readParams.Filters || []).find(item => item.Field === 'stopList') ) {
			this.readParams.Filters = [
				...this.readParams.Filters ?? [],
				{
					Field: 'stopList',
					Type: FilterType.EXACT,
					Value: false
				}
			];
		}
	}

	override import(): void {
		this.initiateImport.emit();
	}

	override editTableSettings(): void {
		const tHeads = this.profileService.allowed([CP_PERMISSIONS.CONTACTS_E]) ?
			this.tHeaders : this.tHeaders.map(h => ({ ...h, readonly: true }));

		this.editPanel.open(AlarisSettingsManagerComponent, EditPanelWidth.MD, {
			tHeads,
			tSettings: this.tSettings,
			creationFieldAvailable: this.profileService.allowed([CP_PERMISSIONS.CONTACTS_E]),

			tHeadsChange: this.tHeadsChange,
			tSettingsChange: this.tSettingsChange,
			headEvent: this.tHeadEvent
		});
	}
}
