import { Injectable } from '@angular/core';
import { AbstractTableComplexSettingsService } from '@campaign-portal/components-library';
import {
	TableSettingsReadResponse,
	TableSettingsUpdateResponse
} from '@campaign-portal/namespace/entities/table-settings/specs';
import { Observable, of } from 'rxjs';

@Injectable()
export class InvoicesTableSettingsService implements AbstractTableComplexSettingsService {

	constructor() {
	}

	read(): Observable<TableSettingsReadResponse> {
		return of({ Success: true, Total: 0, Data: [] });
	}

	update(): Observable<TableSettingsUpdateResponse> {
		return of({ Success: true });
	}
}
