import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import {
	AlarisBalanceService,
	AlarisProgressLineType,
	ChannelUtilsService,
	EditPanelInputData,
	SelectDropdownPositions
} from '@campaign-portal/components-library';
import { Package } from '@campaign-portal/namespace/entities/subscriptions/specs';

import { PurchaseService } from '../purchase.service';

@Component({
	selector: 'app-package-details',
	templateUrl: './package-details.component.html',
	styleUrls: ['./package-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageDetailsComponent {

	readonly positions = SelectDropdownPositions;
	readonly packs: Package[];
	dropdownOpened = false;
	total = 0;
	rest = 0;

	constructor(
		public readonly bs: AlarisBalanceService,
		public readonly cu: ChannelUtilsService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData
	) {
		this.packs = this.inputData.packs as Package[];
		const [used, total, locked] = PurchaseService.calcProgress(this.packs);
		this.total = total;
		this.rest = total - used - locked;
	}

	progressType(rest: number, total: number): AlarisProgressLineType {
		return PurchaseService.packUsedState(rest, total);
	}
}
