import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { Rate } from '@campaign-portal/namespace/entities/rates/specs';
import { Id } from '@campaign-portal/namespace/common/id';
import { FilterType, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { AlarisApiService, AlarisLanguageService, ErrorNotifierConfig } from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class RatesService {
	readonly loading$ = new BehaviorSubject(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.rate');
	}

	read(id: Id): Observable<ReadResponse<Rate[]>> {
		const params: RPCRequestParams = {
			Filters: [
				{
					Field: 'subscriptionId',
					Type: FilterType.EXACT,
					Value: id
				}
			]
		};
		return this.api.loader(
			'Rates.Read',
			params, this.loading$, this.errorNotifier
		);
	}
}
