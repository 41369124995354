import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import {
	AlarisEditPanelService,
	ChannelUtilsService,
	EditPanelInputData,
	SharedTemplatesService
} from '@campaign-portal/components-library';

import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { EnabledDisabledStatus, SenderSubscriptionEventStatus } from '@campaign-portal/namespace/common/enums';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { SendersDialogType } from '../sender-dialog/sender-dialog.component';
import { SendersService } from '../../services/senders.service';

@Component({
	selector: 'app-sender-view',
	templateUrl: './sender-view.component.html',
	styleUrls: ['./sender-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderViewComponent implements OnDestroy {
	readonly Status = EnabledDisabledStatus;
	readonly EventStatus = SenderSubscriptionEventStatus;
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	index: number | null = null;
	senderId: SenderSubscription;

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly sendersService: SendersService,
		public readonly senderTemplates: SharedTemplatesService,
		public readonly cu: ChannelUtilsService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService
	) {
		this.senderId = this.inputData.senderId as SenderSubscription;
	}

	close(action?: SendersDialogType): void {
		this.editPanel.close(action);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
