import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { AlarisProfileService, PROFILE_SERVICE_INJECTOR, RouterDataLink } from '@campaign-portal/components-library';

import { routerLinks } from '@helpers/shared/router-links.const';

@Component({
	selector: 'app-home',
	template: ``,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoHomeComponent {

	constructor(
		private readonly router: Router,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService
	) {
		const link = Object.values(routerLinks).find(
			(route: RouterDataLink) => {
				return this.profile.allowed(
					route.data.permissions,
					route.data.permissionComparison ?? 'every'
				);
			}
		);
		this.router.navigate([link?.path ?? '']).then();
	}

}
