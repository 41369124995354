<alaris-card size="auto">
	<div [alarisLoading]="loading$" style="width: 100%;border-radius: 0.5rem 0.5rem 0 0;">
		<div class="head">
			<alaris-icon (click)="close()"
						 class="standard close" data-testid="closeAdditionalBtn"
						 name="deprecated/modal-close">
			</alaris-icon>
			<h3 [ngSwitch]="data.type">
				<ng-container *ngSwitchCase="'Delete'">{{ "campaigns.delete" | translate }}</ng-container>
				<ng-container *ngSwitchCase="'Captcha'">{{ "campaigns.readyToSent" | translate }}</ng-container>
			</h3>
		</div>

		<div class="content">
			<div [ngSwitch]="data.type" class="note">
				<ng-container *ngSwitchCase="'Delete'">
					<p *ngIf="campaign.info.status === campaignStatus.SCHEDULED">
						{{ 'campaigns.deleteScheduledNote' | translate }}
						{{ campaign.info.scheduled | date: 'd MMM y, HH:mm' }}
					</p>
					<p>{{ 'campaigns.deleteNote' | translate }}</p>
				</ng-container>
				<ng-container *ngSwitchCase="'Captcha'">
					<label>{{'campaigns.campaign' | translate}}</label>
					<ul>
						<li>{{'campaigns.name' | translate}}:
							{{campaign.info.name ? campaign.info.name : '-'}}</li>
						<li>
							{{'campaigns.scheduledDate' | translate}}
							: {{(campaign.info.scheduled | date: 'd MMM y, HH:mm') || ('gl.now' | translate)}}
						</li>
						<li>{{'campaigns.recipients' | translate}}: {{ total }}</li>
					</ul>

					<alaris-text-area [formControl]="description" data-testid="descriptionTextarea">
						{{'campaigns.description' | translate}}
					</alaris-text-area>
				</ng-container>
			</div>

			<div *ngIf="data.type === 'Captcha'" class="details">
				<p>{{'gl.completeCaptcha' | translate}}</p>
				<alaris-captcha (key)="captcha.controls.key.setValue($event)"
								[forceUpdate$]="updateCaptcha$"
								[formControl]="captcha.controls.answer" data-testid="captcha">
				</alaris-captcha>
			</div>
		</div>

		<div class="footer">
			<button (click)="close()"
					[disabled]="loading$.getValue()"
					alaris-button
					bType="outline" data-testid="closeBtn">
				{{ 'actions.close' | translate }}
			</button>
			<button (click)="confirm()"
					[disabled]="disabled"
					alaris-button
					bType="primary" data-testid="closeBtn">
				{{ 'actions.confirm' | translate }}
			</button>
		</div>
	</div>
</alaris-card>

