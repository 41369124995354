import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { ChannelUtilsService, TOOLTIP_COMPONENT_DATA } from '@campaign-portal/components-library';

@Component({
	selector: 'app-campaign-template-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignTemplateTooltipComponent {

	constructor(
		@Inject(TOOLTIP_COMPONENT_DATA) public readonly campaign: Campaign,
		public readonly cu: ChannelUtilsService
	) {
	}

}
