<div class="edit-panel-header">
	<alaris-icon name="icon-sender"></alaris-icon>
	{{ 'senderIds.senderIdDetails' | translate}}
</div>

<div alarisFade="vertical" class="edit-panel-content">
	<div class="grid">
		<strong>{{'gl.state' | translate}}</strong>
		<ng-container
			*ngTemplateOutlet="senderTemplates.get('enabled'); context: {data: senderId}">
		</ng-container>

		<strong>{{'gl.channel' | translate}}</strong>
		{{cu.name(senderId.trafficType) | translate}}

		<strong>{{'senderIds.lastUpdateStatus' | translate}}</strong>
		<ng-container
			*ngTemplateOutlet="senderTemplates.get('lastEvent'); context: {data: senderId, key: 'lastEvent'}">
		</ng-container>

		<ng-container *ngIf="sendersService.validPeriodStatuses.includes(senderId.lastEvent)">
			<strong>{{'senderIds.periodOfValidity' | translate}}</strong>
			<div>
				<div>
					{{senderId.activeFrom | date:'d MMM y, HH:mm'}} -
					{{
					senderId.activeTill
						? (senderId.activeTill | date:'d MMM y, HH:mm')
						: ('senderIds.neverExpires' | translate)
					}}
				</div>
			</div>
		</ng-container>

		<strong>{{'senderIds.comment' | translate}}</strong>
		{{senderId.comment || '&mdash;'}}

		<strong *ngIf="senderId.documents?.length">{{'gl.documents' | translate}}</strong>
		<div *ngIf="senderId.documents?.length">
			<alaris-file-loader [exportInternally]="true"
								[files]="senderId.documents ?? []"
								[readonly]="true" data-testid="documentFile">
			</alaris-file-loader>
		</div>
	</div>
</div>

<div class="edit-panel-footer" *alarisPBAC="[CP_PERMISSIONS.SENDERS_E]">
	<button (click)="close()" alaris-button bType="primary" data-testid="closeBtn" size="lg" type="button">
		{{'actions.close' | translate}}
	</button>
	<button (click)="close('Unsubscribe')"
			*ngIf="senderId.lastEvent === EventStatus.CONFIRMED"
			alaris-button bType="outline" data-testid="unsubscribeBtn" size="lg"
			type="button">
		{{'actions.unsubscribe' | translate}}
	</button>
	<button (click)="close('Cancel')"
			*ngIf="senderId.lastEvent === EventStatus.NEW"
			alaris-button bType="outline" data-testid="cancelRequestBtn" size="lg"
			type="button">
		{{'actions.cancelRequest' | translate}}
	</button>
</div>



