import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignFromFileComponent } from './campaign-from-file/campaign-from-file.component';
import { AlarisEditPanelService, EditPanelWidth } from '@campaign-portal/components-library';

@Component({
	selector: 'app-campaigns',
	template: `
        <app-campaign-list>
            <app-empty-table (newCampaign)="create()"
                             (newCampaignFromFile)="createFromFile()"
                             slot="empty"
                             type="campaigns-list">
            </app-empty-table>
        </app-campaign-list>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignsComponent {
	constructor(
		private readonly router: Router,
		private readonly editPanel: AlarisEditPanelService
	) {
	}

	create(): void {
		this.router.navigate(['campaigns', 'campaign-wizard']);
	}

	createFromFile(): void {
		this.editPanel.open(CampaignFromFileComponent, EditPanelWidth.MD, {});
	}
}
