<ng-container *ngIf="value.length; else stub">
	<div class="container">
		<div class="title">{{ 'dashboard.lastCampaign' | translate }}</div>
		<div [class.dark]="alarisConfig.themeType === 'dark'" [class.seen]="isSeenExist"
			 class="chart">
			<div [alarisLoading]="loading$" [backdrop]="false" class="ring">
				<tui-ring-chart [(activeItemIndex)]="activeItemIndex"
								[value]="value">
					<div class="amount">{{ amount }}</div>
					<div class="secondary">{{ label | translate }}</div>
				</tui-ring-chart>
			</div>
			<div class="legend">
				<tui-legend-item
					(mouseleave)="makeNaN()"
					(mouseover)="onHover(index, $event)"
					*ngFor="let label of labels; let index = index"
					[active]="isItemActive(index)"
					[color]="cu.chartHintColorMapper(label, alarisConfig.themeType)"
					[text]="label | translate"
					class="item"
					size="s">
					<b>{{ value[index] }}</b>
				</tui-legend-item>
			</div>
		</div>
		<div class="total">
			{{ 'campaigns.totalCost' | translate }}
			<span class="cost">{{ totalCost | currency: bs.currencyCode }}</span>
		</div>
	</div>
</ng-container>

<ng-template #stub>
	<div class="title">
		<span class="label">{{ 'dashboard.lastCampaign' | translate }}</span>
	</div>
	<div [alarisLoading]="loading$" [backdrop]="false" class="no-data">
		<alaris-icon class="icon" name="icon-paper-plane-tilt"></alaris-icon>
		<b>{{ 'dashboard.noLaunch' | translate }}</b>
		<span class="note">{{ 'dashboard.create&send' | translate }}</span>
	</div>
</ng-template>
