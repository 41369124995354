import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, switchMap } from 'rxjs';

import {
	AlarisBalanceService,
	AlarisConfigService,
	ChartUtilsService,
	SharedTemplatesService
} from '@campaign-portal/components-library';

import { CubeTypeENUM, FilterType, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ChartCostMessagePerChannels } from '@campaign-portal/namespace/entities/charts/specs';
import { TrafficType } from '@campaign-portal/namespace/common/enums';

import { calculateBarChartMax, generateAxisYLabels, sumElementsInArray } from '@helpers/utils/math';
import { TotalProgressChart } from '@helpers/types/app.classes-interfaces';
import { ChannelsService } from '@helpers/services/channels.service';
import { ChartsService } from '../charts.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type ChartControls = {
	[channelType in TrafficType]?: FormControl<boolean>;
};

@Component({
	selector: 'app-message-by-channel-chart',
	templateUrl: './message-by-channel-chart.component.html',
	styleUrls: ['./message-by-channel-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageByChannelChartComponent implements OnInit, OnDestroy, AfterViewInit {
	readonly periodType = new FormControl<string | undefined>(this.cu.periodTypes[2].value, { nonNullable: true });
	readonly range = new FormControl();
	readParams: RPCRequestParams = {};
	// Chart
	totalMsgValues: number[][] = [];
	totalMsgCostValues: number[][] = [];
	labelsX: string[] = [];
	labelsY: string[] = [];
	hintX: string[] = [];
	legend: string[] = [];
	maxChartHeight = 0;
	totalMessages = 0;
	totalCost = 0;
	totals: TotalProgressChart[] = [];
	sentDelta = 0;
	costDelta = 0;
	collapsed = false;
	chartControls = new FormGroup<ChartControls>({});
	chartVisibilityConfig: { control: FormControl<boolean>; type: string; percent: string }[] = [];

	// Ring
	totalCostValues: number[] = [];
	activeItemIndex = NaN;
	readonly loading$ = new BehaviorSubject<boolean>(false);
	protected readonly CubeTypeENUM = CubeTypeENUM;
	private readonly destroyedRef = inject(DestroyRef);

	constructor(
		public readonly templates: SharedTemplatesService,
		public readonly ch: ChannelsService,
		public readonly bs: AlarisBalanceService,
		public readonly cu: ChartUtilsService,
		public readonly config: AlarisConfigService,
		private readonly chartsService: ChartsService,
		private readonly cd: ChangeDetectorRef
	) {
	}

	get amount(): number {
		return isNaN(this.activeItemIndex) ? this.totalCost : this.totalCostValues[this.activeItemIndex];
	}

	get label(): string {
		return isNaN(this.activeItemIndex) ? 'gl.total' : this.legend[this.activeItemIndex];
	}

	ngOnInit(): void {
		this.range.valueChanges
			.pipe(
				switchMap(range => {
					this.loading$.next(false); // Avoid set more than one portal
					this.readParams = {
						...this.readParams,
						Grouping: {
							CubeType: this.cu.periodCubeTypeReducer(this.periodType.value),
							Groups: [], isDetailed: false, isTotal: false
						},
						Filters: [{ Field: 'groupingDate', Type: FilterType.BETWEEN, Value: range }]
					};
					if (
						this.chartControls.value.hasOwnProperty('SMS') ||
						this.chartControls.value.hasOwnProperty('VIBER')
					) {
						const Value = Object.entries(this.chartControls.value).reduce(
							(acc: string[], cV) => cV && cV[1] ? [...acc, cV[0]] : acc, []
						);
						this.readParams.Filters?.push({
							Field: 'trafficType',
							Type: FilterType.IN,
							Value
						});

					}
					this.loading$.next(true);
					return this.chartsService.costMessagePerChannelsChartRead(this.readParams);
				}),
				takeUntilDestroyed(this.destroyedRef)
			)
			.subscribe(({ Data }) => {
				this.updateChartData(Data);
				// rerender
				this.loading$.next(false);
				this.cd.markForCheck();
			});

		this.chartControls.valueChanges
			// to update chart data
			.pipe(takeUntilDestroyed(this.destroyedRef))
			.subscribe(() => {
				this.range.updateValueAndValidity();
			});
	}

	ngAfterViewInit(): void {
		// apply shared templates
		this.cd.markForCheck();
	}

	updateChartData(data: ChartCostMessagePerChannels): void {
		// bar chart
		this.totalMsgValues = data.totalMsgValues;
		this.maxChartHeight = calculateBarChartMax(data.totalMsgValues, false);
		this.labelsX = this.cu.reduceLabelsX(this.periodType.value, data.labels);
		this.hintX = data.labels;
		this.labelsY = generateAxisYLabels(this.maxChartHeight, 5);
		this.legend = data.datasetName.map(label => 'dashboard.' + label.toUpperCase());
		this.totalMsgCostValues = data.totalMsgCostValues;

		// totals
		this.totalMessages = sumElementsInArray(data.totalMsgValues.flat());
		this.totals = this.legend.map((label, index) => {
			return this.cu.calcTotals(label, data.totalMsgValues[index] ?? []);
		});
		this.totalCost = sumElementsInArray(data.totalCostValues);

		this.sentDelta = data.deltaMessage ?? 0;
		this.costDelta = data.deltaCost ?? 0;

		// ring chart
		this.totalCostValues = data.totalCostValues;

		this.generateChartControls();
		this.generateChartConfig(data.datasetName);
	}

	isItemActive(index: number): boolean {
		return this.activeItemIndex === index;
	}

	onHover(index: number, hovered: any): void {
		this.activeItemIndex = hovered ? index : 0;
	}

	makeNaN(): void {
		this.activeItemIndex = NaN;
	}

	generateChartControls(): void {
		this.ch.list.forEach(s => {
			if ( !this.chartControls.get(s.channelType) ) {
				this.chartControls.addControl(s.channelType, new FormControl(true), { emitEvent: false });
			}
		});
	}

	generateChartConfig(dataset: string[]): void {
		this.chartVisibilityConfig = [];
		this.ch.list.forEach((s) => {
			const index = dataset.indexOf(s.channelType);
			const percent = index !== -1 ? ((this.totals[index].total * 100) / this.totalMessages) : 0;
			this.chartVisibilityConfig.push({
				control: this.chartControls.get(s.channelType) as FormControl<boolean>,
				type: s.channelType,
				percent: percent ? percent.toFixed(1) : '0'
			});
		});
	}

	ngOnDestroy(): void {
		this.loading$.next(false);
		this.loading$.complete();
	}
}
