<div>
	<div class="edit-panel-header">
		<alaris-icon name="icon-rate-plan-detail"></alaris-icon>
		<span> {{ plan.name }} </span>
	</div>
	<div alarisFade="vertical" class="edit-panel-content">
		<alaris-card class="card" size="auto">
			<div class="header">
				<div class="detail">
					<alaris-icon [name]="cu.icon(plan.trafficType)"></alaris-icon>
					<fieldset>
						<span class="label">
							{{
								cu.purpose(
									plan.trafficType,
									plan.settings?.viberSettings?.messagePurpose) | translate
							}}
						</span> <br>
						<span class="name">{{ plan.name }}</span>
					</fieldset>
				</div>
				<div class="billing-mode">
					<span class="label">{{ 'purchase.billingMode' | translate }}</span>
					<alaris-tag size="lg">
						{{ 'enums.' + plan.billingMode | translate }}
					</alaris-tag>
				</div>
			</div>
			<alaris-table (filterChange)="applyFilter($event)"
						  (sortingChange)="applySorting($event)"
						  [alarisLoading]="loading$"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="rates"
						  [templates]="{price, country,network}">
			</alaris-table>
		</alaris-card>
	</div>

	<div class="edit-panel-footer">
		<button (click)="selectPlan(plan)" *ngIf="!plan.isSubscribed"
				alaris-button bType="primary" data-testid="selectPlanBtn"
				size="lg" type="button">
			{{ 'purchase.selectPlan' | translate }}
		</button>
	</div>
</div>


<ng-template #price [alarisCellCtx]="rates[0]" let-data="data" let-key="key">
	{{ data.price | currency: bs.currencyCode : 'symbol' : '1.2-5' }}
</ng-template>

<ng-template #country [alarisCellCtx]="rates[0]" let-data="data" let-key="key">
	{{ data.country.name }}
</ng-template>

<ng-template #network [alarisCellCtx]="rates[0]" let-data="data" let-key="key">
	{{ data.network ? data.network.name : '' }}
</ng-template>
