import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { ChannelUtilsService } from '@campaign-portal/components-library';

import { CampaignsService } from '../../../services/campaigns.service';

@Component({
	selector: 'app-campaign',
	templateUrl: './campaign.component.html',
	styleUrls: ['./campaign.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignComponent {

	@Input() campaign!: Campaign;

	constructor(
		public readonly cu: ChannelUtilsService,
		public readonly campaignsService: CampaignsService
	) {
	}

}
