<ng-container *ngIf="(loading$ | async); else loaded">
	<app-empty-table type="campaign-loading">
	</app-empty-table>
</ng-container>

<ng-template #loaded>
	<app-empty-table *ngIf="hasSubscriptions && !hasSenders"
					 (senderRequest)="senderRequest.emit()"
					 type="senders">
	</app-empty-table>
	<app-empty-table *ngIf="!hasSubscriptions && hasSenders"
					 (financeTransition)="financeTransition.emit()"
					 type="plans-packages">
	</app-empty-table>
	<app-empty-table *ngIf="!hasSubscriptions && !hasSenders"
					 (senderRequest)="senderRequest.emit()"
					 (financeTransition)="financeTransition.emit()"
					 type="senders-plans-packages">
	</app-empty-table>
</ng-template>
