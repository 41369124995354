import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, finalize, merge, Subject, switchMap, takeUntil } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisProfileService,
	EditPanelWidth,
	filterWildcardData,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import {
	ContactsImportMergeType,
	ContactsImportStopListAction
} from '@campaign-portal/namespace/entities/contacts/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { FileParsedColumn, FilesParseResponse } from '@campaign-portal/namespace/entities/files/specs';
import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { CampaignInfo } from '@campaign-portal/namespace/entities/campaigns/specs';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { ContactFieldsService } from '../services/contact-fields.service';
import { ContactGroupsService } from '../services/contact-groups.service';
import { EditGroupComponent } from '../tabs/contacts-groups-table/edit-group/edit-group.component';
import { ContactsService } from '../services/contacts.service';
import { ContactCampaignUsageComponent } from '../dialogs/contact-campaign-usage/contact-campaign-usage.component';

const FORMATS: Record<InputType, unknown> & Record<InputComplexType, unknown> = {
	[InputType.TEXT]: 'text',
	[InputType.TIME]: '12:35',
	[InputType.TEL]: '11111',
	[InputType.NUMBER]: '11111',
	[InputType.LOGIN]: 'login',
	[InputType.PASSWORD]: 'password',
	[InputType.EMAIL]: 'email@email.email',
	[InputType.URL]: 'https://',
	[InputComplexType.SELECT]: 'option',
	[InputComplexType.MULTISELECT]: 'option',
	[InputComplexType.TEXTAREA]: 'text',
	[InputComplexType.RANGE]: '10-100',
	[InputComplexType.CHECKBOX]: 'true',
	[InputComplexType.DATE]: '2000-01-01T00:00:00.000Z',
	[InputComplexType.DATE_RANGE]: '2000.01.01-2100.01.01'
};


@Component({
	selector: 'app-contacts-import',
	templateUrl: './contacts-import.component.html',
	styleUrls: ['./contacts-import.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsImportComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	@ViewChild('accordionItem1') readonly accordionItem1!: CdkAccordionItem;
	@ViewChild('accordionItem2') readonly accordionItem2!: CdkAccordionItem;
	@ViewChild('accordionItem3') readonly accordionItem3!: CdkAccordionItem;

	@Output() readonly importDone = new EventEmitter<boolean>(false);

	file?: FileInfo;
	parsedFile: FilesParseResponse = {
		Success: false,
		Total: 1,
		Data: []
	};
	initialColumns: FileParsedColumn[] = [];
	columns: FileParsedColumn[] = [];
	fields: EntityField[] = [];
	readonly mergeType: FormControl<ContactsImportMergeType> = new FormControl(
		ContactsImportMergeType.MERGE,
		{ nonNullable: true }
	);
	readonly stopListAction: FormControl<ContactsImportStopListAction> = new FormControl(
		ContactsImportStopListAction.EXCLUDE_FILE,
		{ nonNullable: true }
	);
	readonly groups = new FormControl<ContactGroup<exist>[] | null>([]);
	groupsInfo: CampaignInfo[] = [];
	readonly ContactsImportStopListAction = ContactsImportStopListAction;
	readonly ContactsImportMergeType = ContactsImportMergeType;
	createdGroupId: exist | null = null;

	readonly filterControl = new FormControl('');
	filteredGroupsList: ContactGroup<exist>[] = [];

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService,
		@Inject(DOCUMENT) private readonly document: Document,
		public readonly contactFieldsService: ContactFieldsService,
		public readonly contactGroupsService: ContactGroupsService,
		private readonly filesService: AlarisFilesService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly contactsService: ContactsService,
		private readonly dialog: AlarisDialogService,
		private readonly ls: AlarisLanguageService,
		private readonly cd: ChangeDetectorRef,
		private readonly router: Router
	) {
		super();
	}

	get firstStepDisabled(): boolean {
		return this.file === undefined || !this.parsedFile.Success;
	}

	get secondStepDisabled(): boolean {
		return !this.columns.find((col) => {
			return col.columnName === 'phone';
		});
	}

	get thirdStepDisabled(): boolean {
		return this.groups.value !== null &&
			(this.groups.value.length === 0 &&
				this.stopListAction.value !== ContactsImportStopListAction.INSERT_STOPLIST
			);
	}

	ngOnInit(): void {
		this.contactFieldsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				resp =>
					this.fields = resp.filter(
						f => !['contactGroups', 'creationDate', 'lastUpdated', 'modifiedBy'].includes(f.variable)
					)
			);
		this.stopListAction.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(value) => {
				if ( value === ContactsImportStopListAction.INSERT_STOPLIST ) {
					this.groups.setValue([]);
				}
			}
		);

		this.contactGroupsService.map$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			map => {
				if ( this.createdGroupId && map.has(this.createdGroupId) ) {
					this.groups.setValue([map.get(this.createdGroupId) as ContactGroup<exist>]);
					this.createdGroupId = null;
				}
			}
		);

		this.groups.valueChanges
			.pipe(
				switchMap(groups => {
					if (
						groups && groups.length === 0
						|| !this.profile.allowed([CP_PERMISSIONS.CAMPAIGNS_R, CP_PERMISSIONS.CONTACTS_E])
					) {
						this.groupsInfo = [];
						return EMPTY;
					}
					return this.contactGroupsService.checkCampaignUsage(groups);
				}),
				takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				if ( resp.Success ) {
					this.groupsInfo = resp.Data;
					this.cd.markForCheck();
				}
			});

		merge(this.filterControl.valueChanges, this.contactGroupsService.list$)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.filteredGroupsList = filterWildcardData(
					this.filterControl.value, this.contactGroupsService.list, 'name'
				);
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}

	addFiles($event: (FileInfo | undefined)[]): void {
		if ( $event[0] ) {
			this.loading$.next(true);
			this.file = $event[0];
			this.filesService.parse(this.file.id)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((resp) => {
					this.parsedFile = resp;
					this.loading$.next(false);
				});
		} else {
			this.parsedFile.Success = false;
			this.file = undefined;
			this.columns = [];
			this.initialColumns = [];
			this.groups.setValue([]);
		}
	}

	createGroup(): void {
		this.editPanel.open(EditGroupComponent, EditPanelWidth.SM, {
			group: undefined
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((groupId) => {
				if ( groupId ) {
					this.createdGroupId = groupId as exist;
				}
			});
	}

	import(): void {
		if ( this.file ) {
			this.loading$.next(true);
			this.allowedDeactivation.next(false);
			this.contactsService.import(
				this.file.id,
				this.columns,
				this.mergeType.value,
				this.stopListAction.value,
				this.groups.value === null ?
					this.contactGroupsService.list.map(group => group.id) :
					this.groups.value.map(group => group.id)
			)
				.pipe(
					finalize(() => {
						this.loading$.next(false);
						this.allowedDeactivation.next(true);
					}),
					takeUntil(this.ngUnsubscribe))
				.subscribe(() => {
					this.router.navigate(['contacts'], {
						queryParams: { contactTabs: 'importHistory' }
					});
				});
		}
	}

	saveParsedColumns(): void {
		this.initialColumns = this.columns;
	}

	openHintDialog(): void {
		this.dialog.open(ContactCampaignUsageComponent, {
			data: {
				details: [],
				additionalDetails: this.groupsInfo,
				groupsChanged: this.groups.dirty,
				type: 'CampaignUsage'
			},
			autoFocus: false
		});
	}

	generateTemplate(): void {

		const dataCoding = '\ufeff';

		const headers = this.fields
			.sort((a, _b) => {
				if ( a.variable === 'phone' ) {
					return -1;
				} else {
					return 0;
				}
			})
			.reduce((result, header, i, array) => {
				if ( i !== array.length - 1 ) {
					return result + this.ls.translate(header.name) + ';';
				}
				return result + this.ls.translate(header.name) + '\n';
			}, ';');

		const row = this.fields
			.sort((a, _b) => {
				if ( a.variable === 'phone' ) {
					return -1;
				} else {
					return 0;
				}
			})
			.reduce((result, header, i, array) => {
				if ( i !== array.length - 1 ) {
					return result + FORMATS[header.type] + ';';
				}
				return result + FORMATS[header.type] + '\n';
			}, 'example;');

		const csvFile = dataCoding + headers + row;
		const blobFile = new Blob([csvFile], { type: 'text/csv;charset=utf-8' });
		const file = window.URL.createObjectURL(blobFile);
		const anchor = this.document.createElement('a');
		anchor.download = 'contacts_template.csv';
		anchor.href = file;
		anchor.click();
		window.URL.revokeObjectURL(file);
	}
}
