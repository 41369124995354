<alaris-card size="root">
	<div class="banner">
		<div class="campaign-name">
			<label for="name">{{ 'campaigns.campaignName' | translate }}</label>
			<div class="edit-zone">
				<input #input
					   (blur)="hasFocus = false"
					   (focus)="hasFocus = true"
					   (input)="input.style.width = input.value.length + 'ch'"
					   [formControl]="cw.infoControls.name"
					   [placeholder]="'campaigns.setCampaignName' | translate"
					   [style.width]="input.value.length + 'ch'"
					   autocomplete="off"
					   data-testid="nameInput"
					   id="name" name="name">
				<alaris-icon *ngIf="hasFocus"
							 name="icon-edit">
				</alaris-icon>
				<alaris-icon *ngIf="!hasFocus"
							 name="icon-checkmark">
				</alaris-icon>
			</div>
		</div>
		<img [ngSrc]="'assets/img/illustration-campaign-wizard-' + bannerIllustration"
			 height="280" width="400"
			 alt="channel-icon">
	</div>

	<div class="stepper">
		<alaris-stepper #stepper style="margin-bottom: 1rem">
			<alaris-step [completed]="stepSetupCompleted">
				<alaris-step-label>
					{{ 'campaigns.setup' | translate }}
				</alaris-step-label>
				<alaris-step-content>
					<app-campaign-setup *ngIf="stepper.selectedIndex === 0"></app-campaign-setup>
				</alaris-step-content>
			</alaris-step>

			<alaris-step [completed]="stepRecipientsCompleted" [disabled]="!stepSetupCompleted">
				<alaris-step-label>
					{{ 'campaigns.recipients' | translate }}
				</alaris-step-label>
				<alaris-step-content>
					<app-campaign-recipients *ngIf="stepper.selectedIndex === 1"></app-campaign-recipients>
				</alaris-step-content>
			</alaris-step>

			<alaris-step [completed]="stepCompleteCompleted && stepper.selectedIndex === 2"
						 [disabled]="!stepRecipientsCompleted || !stepSetupCompleted">
				<alaris-step-label>
					{{ 'campaigns.completeSend' | translate }}
				</alaris-step-label>
				<alaris-step-content>
					<app-campaign-completed *ngIf="stepper.selectedIndex === 2"></app-campaign-completed>
				</alaris-step-content>
			</alaris-step>
		</alaris-stepper>
		<div class="stepper-move-buttons">
			<button (click)="stepper.previous()"
					*ngIf="stepper.selectedIndex > 0"
					[disabled]="previousStepDisabled(stepper.selectedIndex)"
					alarisButton
					bType="outline"
					data-testid="previousStepBtn" size="md">
				{{ 'campaigns.prevStep' | translate }}
			</button>
			<button (click)="stepper.next()"
					*ngIf="stepper.selectedIndex !== 2"
					[disabled]="nextStepDisabled(stepper.selectedIndex)"
					alarisButton
					bType="primary"
					data-testid="nextStepBtn" size="md">
				{{ 'campaigns.nextStep' | translate }}
			</button>
			<button (click)="createCampaign()"
					*ngIf="stepper.selectedIndex === 2"
					[disabled]="!this.stepCompleteCompleted"
					alarisButton
					bType="primary"
					data-testid="createCampaignBtn" size="md">
				{{ 'campaigns.createCampaign' | translate }}
			</button>
		</div>
	</div>


</alaris-card>
