import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject } from 'rxjs';

import { CampaignInfo } from '@campaign-portal/namespace/entities/campaigns/specs';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { Contact } from '@campaign-portal/namespace/entities/contacts/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import {
	AlarisLanguageService,
	AlarisProfileService,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { equalitySets } from '@helpers/utils/math';
import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { ContactsDialogType } from '../contact-actions/contact-actions.component';
import { ContactsImportHistory } from '@campaign-portal/namespace/entities/contacts-import-history/specs';

export interface CampaignUsageDialogData {
	details: CampaignInfo[];
	additionalDetails: CampaignInfo[];
	groupsChanged: boolean;
	type: ContactsDialogType;
}

@Component({
	selector: 'app-contact-campaign-usage',
	templateUrl: '../contact-and-groups-actions.component.html',
	styleUrls: ['../contact-and-groups-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCampaignUsageComponent implements OnInit {
	readonly details: CampaignInfo[] = [];
	readonly additionalDetails: CampaignInfo[] = [];

	readonly confirmButtonText = '';

	// unified with contacts-actions
	readonly activeImports = signal<ContactsImportHistory[]>([]);
	readonly contacts: Contact<exist>[] = [];
	readonly contactGroups: ContactGroup[] = [];
	readonly groupFilter = new FormControl();
	readonly groupControl = new FormControl();
	readonly groups: ContactGroup<exist>[] = [];
	readonly disabled = false;
	readonly loading$ = new BehaviorSubject<boolean>(false);

	protected readonly CP_PERMISSIONS = CP_PERMISSIONS;

	constructor(
		@Inject(DIALOG_DATA) public readonly data: CampaignUsageDialogData,
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profile: AlarisProfileService,
		private readonly dialogRef: DialogRef,
		private readonly ls: AlarisLanguageService
	) {
		this.details = data.details;
		this.additionalDetails = data.additionalDetails;
	}

	get sourceEntity(): string {
		return this.ls.translate('notifications.entities.contact');
	}

	get destinationEntity(): string {
		return this.ls.translate('notifications.titles.group');
	}

	get showPriceChangeNote(): boolean {
		const sourceDetails = new Set(this.details.map(details => details.id));
		const destinationDetails = new Set(this.additionalDetails.map(details => details.id));
		return !equalitySets(sourceDetails, destinationDetails) && this.data.groupsChanged;
	}

	ngOnInit(): void {
	}

	close(): void {
		this.dialogRef.close();
	}

	confirm(): void {
	}
}
