<div [alarisLoading]="loading$">

	<div *ngIf="hasSenders && hasSubscriptions; else campaignNotAvailable" class="setup">
		<cdk-accordion *ngIf="channelsSetupForm"
					   [formGroup]="channelsSetupForm"
					   class="channels" multi>
			<h3>
				{{ 'campaigns.selectChannel' | translate }}
				<alaris-icon [alarisTooltip]="'campaigns.useChannelsInfo' | translate"
							 class="info-icon"
							 name="icon-info">
				</alaris-icon>
			</h3>
			<cdk-accordion-item #accordionItem="cdkAccordionItem"
								*ngFor="let channel of channelsService.list$ | async; let index"
								[attr.aria-controls]="'accordion-body-' + index"
								[attr.aria-expanded]="accordionItem.expanded"
								[attr.id]="'accordion-header-' + index"
								[expanded]="!!cw.findChannel(channel.channelType)"
								role="button"
								tabindex="0">

				<div [class.channel-card-header_active]="accordionItem.expanded" class="header">
					<div class="data">
						<alaris-icon [name]="cu.icon(channel.channelType)"></alaris-icon>
						<span [style.color]="(senders$ | async)?.get(channel.channelType)?.length ?
												'var(--as-TextPrimary)' : 'var(--as-TextSecondary)'">
												{{ cu.name(channel.channelType) | translate }}
						</span>
						<alaris-toggle [attr.data-testid]="'channelTypeToggle/' + channel.channelType"
									   [formControlName]="channel.channelType"
									   [readonly]="!((senders$ | async)?.get(channel.channelType)?.length)"
									   style="margin-left: auto">
						</alaris-toggle>
					</div>
					<div *ngIf="!(senders$ | async)?.get(channel.channelType)?.length" class="no-senderId">
						{{ 'campaigns.noActiveSenderId' | translate }}
						<ng-container *alarisPBAC="[ CP_PERMISSIONS.SENDERS_R, CP_PERMISSIONS.SENDERS_E]">
							<br>
							{{ 'campaigns.youCan' | translate }}
							<alaris-link (click)="openSenderIdRequestPanel()" data-testid="requestSenderLink">
								{{ 'senderIds.requestSenderId' | translate }}
							</alaris-link>
							{{ 'campaigns.forCampaign' | translate }}
						</ng-container>
					</div>
				</div>

				<div *ngIf="accordionItem.expanded"
					 [alarisLoading]="templatesService.loading$"
					 [attr.aria-labelledby]="'accordion-header-' + index"
					 [attr.id]="'accordion-body-' + index"
					 class="body"
					 role="region">
					<app-campaign-template
						[channelForm]="cw.findChannel(channel.channelType)!"
						[senders]="(senders$ | async)?.get(channel.channelType) || []"
						[templates]="(templates$ | async)?.get(channel.channelType) ?? []"
						[trafficType]="channel.channelType"
					></app-campaign-template>
					<div *alarisPBAC="[ CP_PERMISSIONS.MESSAGE_TEMPLATES_R, CP_PERMISSIONS.MESSAGE_TEMPLATES_E]"
						 class="footer">
						<button (click)="saveTemplate(channel)" alaris-button
								bType="outline"
								data-testid="saveTemplateBtn" size="md">
							{{ 'templates.saveAsTemplate' | translate }}
						</button>
					</div>
				</div>
			</cdk-accordion-item>


		</cdk-accordion>

		<div class="template">
			<ng-container *ngIf="channels.length > 0">
				<div *ngIf="channels.length > 1">
					<alaris-tabs-button [formControl]="selectedChannelInTemplate"
										[tabs]="channels"
										class="channel-tabs"
										data-testid="channelsTabs" size="sm">
					</alaris-tabs-button>
				</div>
				<app-phone-message [buttonActionUrl]="buttonActionUrl"
								   [buttonCaption]="buttonCaption"
								   [imageUrl]="imageUrl"
								   [message]="message"
								   [sender]="sender"
								   [trafficType]="selectedChannelInTemplate.value" class="sticky">
				</app-phone-message>
			</ng-container>
		</div>
	</div>
	<ng-template #campaignNotAvailable>
		<app-empty-campaign-card (financeTransition)="moveToPurchase()"
								 (senderRequest)="openSenderIdRequestPanel()"
								 [hasSenders]="hasSenders"
								 [hasSubscriptions]="hasSubscriptions"
								 [loading$]="loading$">
		</app-empty-campaign-card>
	</ng-template>
</div>
