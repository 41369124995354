import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { NamedObject } from '@campaign-portal/namespace/common/namedObject';
import { IdObject } from '@campaign-portal/namespace/common/idObject';

export const URL_REGEXP = /^(https?):\/\/((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|([a-z0-9-]+(\.[a-z]{2,6})+))(:\d+)?\/?([a-zA-Z0-9]*\/?)*(\??\.?\$?#?[a-zA-Z0-9]*)*/;

export class CPCustomValidators {

	static URL_REGEXP = URL_REGEXP;

	constructor() {
	}

	static hasTemplateDuplicate<T extends IdObject<exist> & NamedObject>(
		id: Id, entities: T[]
	): ValidatorFn {
		return (control: AbstractControl<string>): ValidationErrors | null => {
			const duplicate = entities.find(entity => control.value === entity.name && id !== entity.id);
			return duplicate ? { duplicate: true } : null;
		};
	}

	static recipientsLimitAndPattern(max: number, splitPattern: RegExp): ValidatorFn {
		return (control: AbstractControl<string>): ValidationErrors | null => {
			const contacts = control.value
				.split(splitPattern);

			const error = contacts.find((contact) => {
				const match = /^\d+$/.test(contact);
				return !match;
			});
			if ( error ) {
				return { pattern: true };
			}
			return contacts.length > max ? { limitExceeded: true } : null;
		};
	}
}
