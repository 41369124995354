<div class="header">
	<span class="title">{{ 'dashboard.totalMessages' | translate }}</span>

	<button (click)="$event.stopPropagation()"
			(isOpened)="isTrafficFilterOpen = $event"
			[alarisDropdown]="dropdown"
			class="filter-by-traffic" data-testid="actionsDropdownToggle">
		<div class="flex-box">
			{{ (trafficTypeControl.value ? channelUtils.name(trafficTypeControl.value!) : 'dashboard.allTraffic') | translate }}
			<alaris-icon [name]="isTrafficFilterOpen ? 'icon-caret-up' : 'icon-caret-down'"></alaris-icon>
		</div>
	</button>
	<alaris-dropdown #dropdown>
		<alaris-dropdown-actions [actions]="actions" [row]="''" data-testid=""></alaris-dropdown-actions>
	</alaris-dropdown>

	<div class="range">
		<alaris-dashboard-time-filter (periodTypeChange)="periodType.setValue($event)"
									  [formControl]="range"
									  [min]="periodType.value === CubeTypeENUM.DAY
													? lastWeek: FIRST_DAY"
									  [toLocalDate]="true"
									  data-testid="periodDateRange">
		</alaris-dashboard-time-filter>
	</div>
</div>


<ng-container *ngTemplateOutlet="templates.get('lineCharts');
			context: {
				hint,
				labelsX,
				labelsY,
				loading$,
				maxChartHeight,
				value,
				legend,
				configs: chartVisibilityConfig,
				stubChart: stubBarChart,
				themeType:alarisConfig.themeType
			}">
</ng-container>


<div class="footer">
	<ng-container *ngTemplateOutlet="templates.get('chartVisibility');
			context: {
					configs: chartVisibilityConfig,
					themeType:alarisConfig.themeType
			}">
	</ng-container>
	<div class="total-cost">
		{{ 'campaigns.totalCost' | translate }} &nbsp;
		<span class="cost">{{ totalCost | currency: bs.currencyCode }}</span>
	</div>
</div>

<ng-template #hint let-point>
	<div class="chart-tooltip">
		<div class="date">{{ hintX[point[0][0]] | date : cu.chartHintFormatMapper(periodType.value) }}</div>
		@for (item of value; track item; let index = $index) {
			<div class="flex">
				@if (chartVisibilityConfig[index].control.value) {
					<div class="grid three-column">
						<div [style.background]="cu.chartHintColorMapper(legend[index], alarisConfig.themeType)"
							 class="dot"></div>
						<div>{{ legend[index] | translate }}</div>
						<div class="last">{{ item[point[0][0]][1] }}</div>
					</div>
				}
			</div>
		}
	</div>
</ng-template>

<ng-template #stubBarChart>
	<ng-container *ngTemplateOutlet="templates.get('lineChartSkeleton')"></ng-container>
	<div class="stub-chart-note">{{ 'dashboard.noData' | translate }}</div>
</ng-template>
