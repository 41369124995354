import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisBalanceService,
	AlarisEditPanelService,
	ChannelUtilsService,
	EditPanelInputData,
	LocalTableUtils,
	TableEntityField,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { exist } from '@campaign-portal/namespace/common/id';
import { Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { Rate } from '@campaign-portal/namespace/entities/rates/specs';

import { RatesService } from '../rates.service';
import { PlanDetailsFieldService } from './plan-details-field.service';
import { PurchaseService } from '../purchase.service';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';

@Component({
	selector: 'app-plan-details',
	templateUrl: './plan-details.component.html',
	styleUrls: ['./plan-details.component.scss'],
	providers: [PlanDetailsFieldService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanDetailsComponent implements OnInit, OnDestroy {
	readonly plan!: Plan<exist>;
	tHeaders: TableEntityField[] = [];
	rates: Rate[] = [];

	readonly filters: TableFiltersIndicator = new Map()
		.set('country', { enabled: true, value: undefined });
	readonly sorting: TableSortIndicator = new Map()
		.set('country', { enabled: true, value: SortDirection.EMPTY })
		.set('network', { enabled: true, value: SortDirection.EMPTY })
		.set('price', { enabled: true, value: SortDirection.EMPTY });

	readonly loading$ = new BehaviorSubject(false);
	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
	private tRatesDefault: Rate[] = this.rates;

	constructor(
		public readonly cu: ChannelUtilsService,
		public readonly bs: AlarisBalanceService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		private readonly purchaseService: PurchaseService,
		private readonly fieldsService: PlanDetailsFieldService,
		private readonly ratesService: RatesService,
		private readonly cd: ChangeDetectorRef
	) {
		this.plan = this.inputData.plan as Plan<exist>;
	}

	ngOnInit(): void {
		this.loading$.next(true);
		this.fieldsService.read()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(fields => {
				this.tHeaders = LocalTableUtils.toTableFields(fields.Data);
			});
		this.ratesService.read(this.plan.id)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.tRatesDefault = this.rates = resp.Data;
				this.loading$.next(false);
				this.cd.markForCheck();
			});
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	applyFilter($event: TableFiltersIndicator): void {
		this.rates = [...this.tRatesDefault];
		for ( const [variable, filter] of $event.entries() ) {
			if ( filter?.value && filter.enabled ) {
				switch (variable) {
					case 'country': {
						const subStr = (filter.value as string).trim().toLowerCase();
						this.rates = this.rates.filter(el => {
							return el.country.name.toLowerCase().includes(subStr);
						});
						break;
					}
					default:
						break;
				}
			}
		}
	}

	applySorting($event: TableSortIndicator): void {
		for ( const [variable, sort] of $event.entries() ) {
			if ( sort?.value && sort.enabled ) {
				this.rates.sort((a, b) => {
					let first;
					let second;
					switch (variable) {
						case 'country':
						case 'network':
							first = a[variable]?.name ?? '';
							second = b[variable]?.name ?? '';
							break;
						default:
							first = a[variable];
							second = b[variable];
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.value ?? SortDirection.EMPTY, first, second);
				});
			}
			this.rates = [...this.rates];
		}
	}

	selectPlan(plan: Plan<exist>): void {
		this.purchaseService.openSpecificDialog('SelectPlan', { plan })
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(result) => {
					if ( result ) {
						this.editPanel.close(result);
					}
				}
			);
	}
}

