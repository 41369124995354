import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject, takeUntil } from 'rxjs';

import {
	AlarisBalanceService,
	AlarisEditPanelService,
	AlarisFilesService,
	ChannelUtilsService,
	EditPanelInputData,
	LocalTableUtils,
	TableEntityField
} from '@campaign-portal/components-library';
import {
	TransactionHistory,
	TransactionHistoryCampaignDetail
} from '@campaign-portal/namespace/entities/transaction-history/specs';

import { InvoicesService } from '../invoices.service';
import { InvoiceDetailFieldsService } from './invoice-detail-fields.service';
import { exist, Id } from '@campaign-portal/namespace/common/id';

@Component({
	selector: 'app-invoice-details',
	templateUrl: './invoice-details.component.html',
	styleUrls: ['./invoice-details.component.scss'],
	providers: [InvoiceDetailFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush

})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {

	readonly invoice!: TransactionHistory;
	campaignDetails!: TransactionHistoryCampaignDetail[];

	tHeaders: TableEntityField[] = [];

	readonly loading$ = new BehaviorSubject(false);
	readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public fieldsService: InvoiceDetailFieldsService,
		public invoicesService: InvoicesService,
		public bs: AlarisBalanceService,
		public cu: ChannelUtilsService,
		@Inject(EditPanelInputData) private inputData: EditPanelInputData,
		private editPanel: AlarisEditPanelService,
		private fs: AlarisFilesService,
		private cd: ChangeDetectorRef
	) {
		this.invoice = this.inputData.invoice as TransactionHistory;
	}

	ngOnInit(): void {
		this.loading$.next(true);
		forkJoin([
			this.invoicesService.details({
				Data: {
					Ids: [this.invoice.id as Id<exist>]
				}
			}),
			this.fieldsService.read()
		])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				const [details, fields] = resp;
				this.campaignDetails = details.Data;
				this.tHeaders = LocalTableUtils.toTableFields(fields.Data);
				this.loading$.next(false);
				this.cd.markForCheck();
			});

	}

	export(): void {
		this.loading$.next(true);
		this.invoicesService.export({
			Data: {
				Ids: [this.invoice.id as Id<exist>]
			}
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.fs.export(resp.Data.fileId)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(() => {
						this.loading$.next(false);
						this.editPanel.close();
					});
			});
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
