<div class="container">
	<div class="settings-zone">
		<h3 class="title">
			{{'campaigns.scheduleCampaign' | translate}}
		</h3>
		<alaris-radio [attr.data-testid]="'sendTypeRadio/' + CampaignSchedule.NOW"
					  [formControl]="sendTypeControl"
					  [value]="CampaignSchedule.NOW" name="scheduled">
			{{'campaigns.sendNow' | translate}}
		</alaris-radio>
		<alaris-radio [attr.data-testid]="'sendTypeRadio/' + CampaignSchedule.SCHEDULED"
					  [formControl]="sendTypeControl"
					  [value]="CampaignSchedule.SCHEDULED" name="scheduled">
			{{'campaigns.schedule' | translate}}
		</alaris-radio>
		<ng-container *ngIf="sendTypeControl.value === CampaignSchedule.SCHEDULED">
			<alaris-scheduled [control]="cw.infoControls.scheduled"></alaris-scheduled>
		</ng-container>

		<div class="separate-line"></div>
		<h3 class="title">
			{{'campaigns.notification' | translate}}
		</h3>
		<div>
			<alaris-toggle [formControl]="notificationControl" data-testid="statusUpdatedToggle">
				{{'campaigns.sendStatusUpdates' | translate}}
			</alaris-toggle>
			<div *ngIf="!notificationControl.value" class="notification-note">
				<alaris-icon name="icon-warning-circle"></alaris-icon>
				<div>
					<b>{{'campaigns.notificationDisabled' | translate}}</b> <br>
					{{'campaigns.notificationDisabledNote' | translate}}
				</div>
			</div>
			<ng-container *ngIf="notificationControl.value">
				<div class="emails">
					<label class="label" for="email">{{'campaigns.reportEmailNote' | translate}}</label>
					<alaris-text-area [formControl]="cw.settingsControls.emailReportAddresses"
									  data-testid="alertEmailsTextarea" id="email">
						{{ 'campaigns.alertEmails' | translate }}
					</alaris-text-area>

					<alaris-input-number [allowNegativeNumbers]="false"
										 [formControl]="cw.settingsControls.failureAlertThreshold"
										 data-testid="alertThresholdInputNumber" id="alert">
						{{ 'campaigns.reportAlertNote' | translate }}
					</alaris-input-number>
				</div>
			</ng-container>

		</div>
		<div *ngIf="cw.channelsArray.length > 1" class="campaign-statistics">
			<label class="title">{{'campaigns.sequenceOfMessages' | translate}}</label>
			<table>
				<tbody>
				<tr *ngFor="let control of channels;
									let i = index; let first = first"
					[class.top-border]="first">
					<td>
								<span class="icon-container">
										<alaris-icon [name]="cu.icon(control.value.trafficType)"
													 class="channel-icon"></alaris-icon>
									{{('gl.step' | translate) + (i + 1) + '. ' + (cu.name(control.value.trafficType) | translate)}}
								</span>
					</td>
					<td>
						<span *ngIf="control.controls.omni?.controls?.ttl?.value" class="ttl-time">
							{{control.controls.omni?.controls?.ttl?.value }} {{ 'gl.sec' | translate }}
						</span>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div [alarisLoading]="campaignsService.loading$" class="mobile-zone">
		<ng-container *ngIf="approximateCampaignData; else button">
			<alaris-alert *ngIf="!approximateCampaignData.recipients" type="error">
				<div title>{{ 'campaigns.noDataWarning' | translate }}</div>
				<div>{{ 'campaigns.noDataExplanation' | translate }}</div>
			</alaris-alert>
			<div class="approximate-data">
				{{'campaigns.approximateCost' |translate}}
				<span class="value">
							{{ cw.channelsArray.length > 1 ?
					(((approximateCampaignData.cost.min || 0) | currency: balance.currencyCode)
						+ ' - ' + (approximateCampaignData.cost.max || 0)) :
					(approximateCampaignData.cost.max || 0 | currency: balance.currencyCode)
					}}
							</span>
			</div>
			<div class="approximate-data">
				{{'campaigns.totalRecipients' | translate}}
				<span class="value">
							{{approximateCampaignData.recipients}}
						</span>
			</div>
			<div class="content">
				<ng-container
					*ngIf="approximateCampaignData.chart !== null && approximateCampaignData.chart.length > 0; else stubChart">
					<div class="chart">
						<tui-ring-chart [(activeItemIndex)]="activeItemIndex"
										[value]="chartValue">
							<div>{{amount}}</div>
							<div>{{label}}</div>
						</tui-ring-chart>
					</div>
					<div class="legend">
						<tui-legend-item
							(mouseleave)="makeNaN()"
							(mouseover)="onHover(index, $event)"
							*ngFor="let label of chartLabels; let index = index"
							[active]="isItemActive(index)"
							[color]="getColor(index)"
							[text]="label"
							class="item"
							size="s">
							<b>{{chartValue[index] || 0}}</b>
						</tui-legend-item>
					</div>
				</ng-container>
				<ng-template #stubChart>
					<div class="chart">
						<tui-ring-chart size="l" style="margin: auto;">
							<span style="color: var(--as-TextSecondary)">{{ 'gl.notAvailable' | translate }}</span>
						</tui-ring-chart>
						<div class="stub-chart-note">{{'campaigns.noData' | translate}}</div>
					</div>
				</ng-template>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #button>
	<button (click)="analyseCampaign()" alarisButton bType="primary" data-testid="analyseCampaignBtn" size="md">
		{{'campaigns.doAnalysis' | translate}}
	</button>
</ng-template>
