<ng-container *ngIf="campaign.setup.native">
	<ng-container *ngIf="campaign.setup.native.contacts.file">
		<div (click)="export(campaign.setup.native.contacts.file.id, campaign.setup.native.contacts.file.name)"
			 class="file text-truncate" data-testid="exportRecipientBtn">
			<alaris-icon name="icon-attach"></alaris-icon>
			{{ campaign.setup.native.contacts.file.name }} {{ formatBytes(campaign.setup.native.contacts.file.size) }}
		</div>
	</ng-container>

	<ng-container *ngIf="campaign.setup.native.contacts.plain">
		<span class="text-truncate">
			{{ 'gl.total' | translate}}: {{campaign.setup.native.contacts.plain.text.split(',').length}}
		</span>
	</ng-container>

	<ng-container *ngIf="campaign.setup.native.contacts.groups">
		<div *ngFor="let cg of campaign.setup.native.contacts.groups; let i = index">
			<alaris-tag *ngIf="i < 1" class="text-truncate" size="md">
				{{ (cg | cGroup)?.name ?? '' | translate  }}
			</alaris-tag>
		</div>
		<div *ngIf="campaign.setup.native.contacts.groups.length > 1" class="more text-truncate">
			+ {{campaign.setup.native.contacts.groups.length - 1 }} more
		</div>
	</ng-container>
</ng-container>

<ng-container *ngIf="campaign.setup.file">
	<div (click)="export(campaign.setup.file.file.id, campaign.setup.file.file.name)"
		 *ngIf="campaign.setup.file"
		 class="file text-truncate" data-testid="exportRecipientBtn">
		<alaris-icon name="icon-attach"></alaris-icon>
		{{ campaign.setup.file.file.name }}
	</div>
</ng-container>
