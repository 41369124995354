import {
	AfterViewInit,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';

import { InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ImportStatus } from '@campaign-portal/namespace/common/enums';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import { TableCellInput } from '@campaign-portal/components-library/lib/table/src/base';
import { TagMode } from '@campaign-portal/components-library/lib/tag/src/base';
import { ChannelUtilsService, SharedTemplatesService } from '@campaign-portal/components-library';

import { ContactFieldsService } from '../contact-fields.service';
import { FilterInterval } from '@campaign-portal/namespace/common/rpc.params';

@Component({
	selector: 'app-contacts-templates',
	templateUrl: './contacts-templates.component.html',
	styleUrls: ['./contacts-templates.component.scss']
})
export class ContactsTemplatesComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('channels') readonly channels!: TemplateRef<TableCellInput>;
	@ViewChild('contactGroups') readonly contactGroups!: TemplateRef<TableCellInput>;
	@ViewChild('status') readonly status!: TemplateRef<TableCellInput>;
	@ViewChild('user') readonly user!: TemplateRef<TableCellInput>;
	@ViewChild('creationDate') readonly creationDate!: TemplateRef<TableCellInput>;
	@ViewChild('lastUpdated') readonly lastUpdated!: TemplateRef<TableCellInput>;
	@ViewChild('file') readonly file!: TemplateRef<TableCellInput>;
	@ViewChild('name') readonly name!: TemplateRef<TableCellInput>;
	@ViewChild('description') readonly description!: TemplateRef<TableCellInput>;

	@ViewChild('general') readonly general!: TemplateRef<TableCellInput>;
	@ViewChild('defaultPassword') readonly defaultPassword!: TemplateRef<TableCellInput>;
	@ViewChild('defaultUrl') readonly defaultUrl!: TemplateRef<TableCellInput>;
	@ViewChild('defaultCheckbox') readonly defaultCheckbox!: TemplateRef<TableCellInput>;
	@ViewChild('defaultDate') readonly defaultDate!: TemplateRef<TableCellInput>;
	@ViewChild('defaultRange') readonly defaultRange!: TemplateRef<TableCellInput>;
	@ViewChild('defaultSelect') readonly defaultSelect!: TemplateRef<TableCellInput>;
	@ViewChild('defaultDateRange') readonly defaultDateRange!: TemplateRef<TableCellInput>;
	@ViewChild('defaultMultiSelect') readonly defaultMultiSelect!: TemplateRef<TableCellInput>;

	@Output() readonly changeGroup = new EventEmitter<Id<exist>>();

	readonly rangeCtx: Record<string, FilterInterval<number | null> | null> = {};
	readonly dateRangeCtx: Record<string, FilterInterval<string | null> | null> = {};
	
	private readonly ngUnsubscribe = new ReplaySubject<boolean>(1);

	constructor(
		public readonly cu: ChannelUtilsService,
		private readonly contactFieldsService: ContactFieldsService,
		private readonly templates: SharedTemplatesService
	) {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		const templates: { [templateName: string]: TemplateRef<any> } = {
			channels: this.channels,
			contactGroups: this.contactGroups,
			status: this.status,
			user: this.user,
			creationDate: this.creationDate,
			lastUpdated: this.lastUpdated,
			file: this.file,
			name: this.name,
			description: this.description
		};

		const defaultComplexTemplates = new Map<string, TemplateRef<any>>()
			.set(InputType.PASSWORD, this.defaultPassword)
			.set(InputType.URL, this.defaultUrl)
			.set(InputComplexType.CHECKBOX, this.defaultCheckbox)
			.set(InputComplexType.DATE_RANGE, this.defaultDateRange)
			.set(InputComplexType.DATE, this.defaultDate)
			.set(InputComplexType.SELECT, this.defaultSelect)
			.set(InputComplexType.MULTISELECT, this.defaultMultiSelect)
			.set(InputComplexType.RANGE, this.defaultRange);

		this.contactFieldsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.contactFieldsService.list.forEach((field) => {
					if ( !templates[field.variable] ) {
						this.templates.share({
							[field.variable]: defaultComplexTemplates.get(field.type) ?? this.general
						});
					}
				});
				this.templates.share(templates);
			});
	}

	map(data: { value: unknown }[]): unknown[] {
		return data.map(option => option.value ?? '');
	}

	isArray(value: unknown): boolean {
		return Array.isArray(value);
	}

	statusMode(status: ImportStatus): TagMode {
		switch (status) {
			case ImportStatus.NEW:
				return 'info';
			case ImportStatus.PENDING:
				return 'gray';
			case ImportStatus.ERROR:
				return 'error';
			case ImportStatus.SUCCESS:
				return 'success';
			default:
				return 'success';
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
		this.templates.clear();
	}
}
