import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	inject,
	Inject,
	OnDestroy,
	OnInit,
	signal
} from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FormControl, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, EMPTY, finalize, Observable, of, switchMap } from 'rxjs';

import { exist } from '@campaign-portal/namespace/common/id';
import { CampaignInfo } from '@campaign-portal/namespace/entities/campaigns/specs';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { Contact } from '@campaign-portal/namespace/entities/contacts/specs';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { ImportStatus } from '@campaign-portal/namespace/common/enums';
import { ContactsImportHistory } from '@campaign-portal/namespace/entities/contacts-import-history/specs';

import {
	AlarisLanguageService,
	AlarisProfileService,
	filterWildcardData,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { ContactGroupsService } from '../../services/contact-groups.service';
import { ContactGroupDialogData } from '../../tabs/contacts-groups-table/contacts-groups-table.component';
import { ContactImportService } from '../../services/contact-import.service';

@Component({
	selector: 'app-contact-group-actions',
	templateUrl: '../contact-and-groups-actions.component.html',
	styleUrls: ['../contact-and-groups-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactGroupActionsComponent implements OnInit, OnDestroy {

	details: CampaignInfo[] = [];
	additionalDetails: CampaignInfo[] = [];
	readonly activeImports = signal<ContactsImportHistory[]>([]);
	readonly groups: ContactGroup<exist>[] = [];
	readonly groupControl = new FormControl<ContactGroup<exist> | null>(null, { validators: Validators.required });
	readonly groupFilter = new FormControl();

	// unified with contacts-actions
	readonly contacts?: Contact<exist>[];
	contactGroups: ContactGroup[] = [];
	readonly loading$ = new BehaviorSubject<boolean>(true);

	protected readonly CP_PERMISSIONS = CP_PERMISSIONS;

	private _contactGroups: ContactGroup[] = [];
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) public profile: AlarisProfileService,
		@Inject(DIALOG_DATA) public data: ContactGroupDialogData,
		public contactGroupsService: ContactGroupsService,
		private readonly contactImportService: ContactImportService,
		private dialogRef: DialogRef<unknown>,
		private ls: AlarisLanguageService,
		private cd: ChangeDetectorRef
	) {
		this.groups = Array.isArray(data.groups) ? data.groups : data.groups !== null ? [data.groups] : [];
	}

	get confirmButtonText(): string {
		return this.activeImports().length ? '' : 'actions.confirm';
	}

	get disabled(): boolean {
		let result = this.loading$.getValue();
		if (
			this.data.type === 'addToGroup' || this.data.type === 'moveToGroup'
		) {
			result = result || !this.groupControl.value;
		}
		return result;
	}

	get sourceEntity(): string {
		return this.ls.translate('notifications.titles.group');
	}

	get destinationEntity(): string {
		return this.ls.translate('notifications.titles.group');
	}

	get showPriceChangeNote(): boolean {
		let show = true;
		const selectedGroup: ContactGroup<exist> | null = this.groupControl.value;
		switch (this.data.type) {
			case 'addToGroup':
				if ( !selectedGroup ) {
					return false;
				}
				show = !!this.additionalDetails.length;
				break;
			case 'moveToGroup':
				const noAffectedCampaigns = !this.details.length && !this.additionalDetails.length;
				if ( !selectedGroup || noAffectedCampaigns ) {
					return false;
				}
				break;
			case 'Delete':
				return !!this.details.length && this.activeImports().length === 0;
		}
		return show;
	}

	ngOnInit(): void {
		if ( this.profile.allowed([CP_PERMISSIONS.CONTACTS_E]) ) {
			this.contactGroupsService.checkCampaignUsage(this.groups.length ? this.groups : null, this.data.params)
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe(
					(resp) => {
						this.loading$.next(false);
						this.details = resp.Data ?? [];
						this.cd.markForCheck();
					}
				);
		}

		if ( this.data.type === 'Delete' ) {
			this.checkActiveImports();
		}

		this.groupControl.valueChanges
			.pipe(
				switchMap(group => {
					if ( group ) {
						this.loading$.next(true);
						return this.contactGroupsService.checkCampaignUsage(group);
					}
					return EMPTY;
				}),
				takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.loading$.next(false);
				if ( resp.Success ) {
					this.additionalDetails = resp.Data ?? [];
					this.cd.markForCheck();
				}
			});

		this.contactGroupsService.list$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((list) => {
				const passedGroups = this.groups.map((cg) => {
					return cg.id;
				}) ?? [];
				this.groupFilter.setValue('', { emitEvent: false });
				this.contactGroups = this._contactGroups = (
					this.data.type === 'addToGroup' || this.data.type === 'moveToGroup'
				) && passedGroups.length === 1
					? list.filter((cg) => {
						return !passedGroups.includes(cg.id);
					})
					: list;
				this.cd.detectChanges();
			});

		this.groupFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.contactGroups = filterWildcardData(value, this._contactGroups, 'name');
			});
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });
		const groups: ContactGroup<exist>[] | null = this.groups.length
			? this.groups
			: null;

		switch (this.data.type) {
			case 'Delete':
				caller = this.contactGroupsService.delete(groups);
				break;
			case 'addToGroup':
				caller = this.contactGroupsService.copyContacts(groups, this.groupControl.value as ContactGroup<exist>);
				break;
			case 'moveToGroup':
				caller = this.contactGroupsService.moveContacts(groups, this.groupControl.value as ContactGroup<exist>);
				break;
			default:
				break;

		}

		caller.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
			(resp) => {
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	private checkActiveImports(): void {
		this.loading$.next(true);
		this.contactImportService.read({
			Filters: [
				{
					Field: 'status',
					Type: FilterType.EXACT,
					Value: ImportStatus.NEW
				},
				{
					Field: 'contactGroups',
					Property: 'id',
					Type: FilterType.IN,
					Value: Array.isArray(this.data.groups) ? this.data.groups : [this.data.groups]
				}
			]
		})
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				finalize(() => this.loading$.next(false))
			)
			.subscribe((resp) => {
				this.activeImports.set(resp.Data);
			});
	}

}
