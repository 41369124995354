import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import {
	AlarisTableStateService,
	filterWildcardFn,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';

import { EnabledDisabledStatus } from '@campaign-portal/namespace/common/enums';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';

import { SendersService } from '../services/senders.service';
import { SenderStatus } from './senders.component';

@Injectable()
export class SendersTableService extends LocalTableService<SenderSubscription<exist>> implements OnDestroy {

	readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public override readonly service: SendersService,
		stateService: AlarisTableStateService
	) {
		super(service, stateService);
		this.service.refresh$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.firstLoad = true;
		});
	}

	applyFilters(
		result: ReadResponse<SenderSubscription<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<SenderSubscription<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'name':
						data = data.filter((item) => {
							return filterWildcardFn(item.name.toLowerCase(), filter.Value as string);
						});
						break;
					case 'enabled':
						if ( filter.Value ) {
							if ( filter.Value === SenderStatus.WAITING_FOR_APPROVAL ) {
								data = data.filter(i => this.service.isWaitingForApproval(i));
								return;
							}
							data = data.filter(i =>
								filter.Value === i.enabled && !this.service.isWaitingForApproval(i)
							);
						}
						break;
					case 'trafficType':
						if ( filter.Value ) {
							data = data.filter((item) => {
								return (filter.Value as string[]).includes(item.trafficType);
							});
						}
						break;
					case 'activeTill':
						data = (LocalTableUtils.filterDateRange(
							filter,
							data as unknown as Record<string, string>[]
						) as SenderSubscription<exist>[])
							.filter(i => this.service.validPeriodStatuses.includes(i.lastEvent));
						break;
					case 'lastUpdated':
						data = LocalTableUtils.filterDateRange(
							filter,
							data as unknown as Record<string, string>[]
						) as SenderSubscription<exist>[];
						break;
					case 'lastEvent':
						if ( filter.Value ) {
							data = data.filter((item) => {
								return item.lastEvent ? (filter.Value as string[]).includes(item.lastEvent) : true;
							});
						}
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	applySorting(
		result: ReadResponse<SenderSubscription<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<SenderSubscription<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'name':
							first = a.name;
							second = b.name;
							break;
						case 'enabled': {
							const transformer = (sender: SenderSubscription): number => {
								return this.service.isWaitingForApproval(sender)
									? 2
									: sender.enabled === EnabledDisabledStatus.ENABLED ? 1 : 0;
							};
							first = transformer(a);
							second = transformer(b);
							break;
						}
						case 'trafficType':
							first = a.trafficType;
							second = b.trafficType;
							break;
						case 'lastUpdated':
							first = a.lastUpdated ? new Date(a.lastUpdated) : a.lastUpdated;
							second = b.lastUpdated ? new Date(b.lastUpdated) : b.lastUpdated;
							break;
						case 'activeTill': {
							const transformer = (sender: SenderSubscription): number | null => {
								return this.service.validPeriodStatuses.includes(sender.lastEvent)
									? sender.activeTill
										? new Date(sender.activeTill).getTime()
										: Infinity
									: null;
							};
							first = transformer(a);
							second = transformer(b);
							break;
						}
						case 'lastEvent':
							first = a.lastEvent;
							second = b.lastEvent;
							break;
						default:
							break;
					}
					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
