import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
	AlarisComplexTableComponent,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	RowActionSimple,
	SharedTemplatesService,
	TableFiltersIndicator,
	TableSelectionIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { BehaviorSubject } from 'rxjs';

import { ContactsImportHistory } from '@campaign-portal/namespace/entities/contacts-import-history/specs';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { EntityField } from '@campaign-portal/namespace/common/entityField';

import { EnumsMapperService } from '@helpers/services/enums.service';
import { ContactImportTableFieldsService } from '../../services/contact-import-table-fields.service';
import { ContactGroupsService } from '../../services/contact-groups.service';
import { ContactImportService } from '../../services/contact-import.service';

@Component({
	selector: 'app-contacts-imports-table',
	templateUrl: '../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.html',
	styleUrls: [
		'../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./contacts-imports-table.component.scss'
	],
	providers: [
		ContactImportTableFieldsService
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class ContactsImportsTableComponent extends AlarisComplexTableComponent<ContactsImportHistory> implements OnInit, OnChanges, OnDestroy {
	@Input() createImport: Record<string, unknown> | undefined;
	@Output() readonly initiateImport = new EventEmitter<void>();

	override sorting: TableSortIndicator = new Map()
		.set('creationDate', { enabled: true, value: SortDirection.DESC })
		.set('contactGroups', { enabled: false, value: SortDirection.EMPTY });

	override rowActions: RowActionSimple<ContactsImportHistory | ContactsImportHistory[]>[] = [
		{
			icon: 'icon-export-1',
			label: 'actions.export',
			// @ts-ignore
			action: this.export.bind(this)
		}
	];
	override mainActions: RowActionSimple<ContactsImportHistory | ContactsImportHistory[]>[] = [
		{
			icon: 'icon-export-1',
			label: 'actions.export',
			// @ts-ignore
			action: this.export.bind(this),
			disabled: (): boolean => {
				return this.selection === null;
			}
		}

	];

	override ctxActions: RowActionSimple<ContactsImportHistory | ContactsImportHistory[]>[] = this.rowActions;
	override readonly id = 'imports';

	constructor(
		public override readonly dataService: ContactImportService,
		public override readonly fieldsService: ContactImportTableFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		public readonly sharedTemplates: SharedTemplatesService,
		private readonly contactGroupsService: ContactGroupsService,
		private readonly filesService: AlarisFilesService,
		private readonly enums: EnumsMapperService
	) {
		super(
			dataService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.IMPORT_HISTORY,
			{
				filters: {
					title: 'gl.filter.filters',
					enabled: true
				},
				settings: {
					title: 'table.settings.tableSettings',
					enabled: false
				},
				refresh: {
					title: 'actions.refreshPage',
					enabled: true
				},
				import: {
					title: 'actions.importContacts',
					enabled: true
				},
				create: {
					title: 'contacts.actions.addContact',
					enabled: false
				}
			},
			stateService
		);
		this.templates = this.sharedTemplates.templates;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.createImport.currentValue ) {
			this.import();
		}
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			contactGroups: this.contactGroupsService,
			status: { list$: new BehaviorSubject<string[]>(Object.keys(this.enums.get('ImportStatus'))) }
		});
	}

	override applyFilter(event: TableFiltersIndicator): void {
		super.applyFilter(event);

		this.readParams.Filters?.forEach(f => f.Field === 'status' ?
			f.Value = this.enums.get('ImportStatus')[f.Value as string] : f
		);
	}

	override refresh(): void {
		this.load();
	}

	export(
		item: ContactsImportHistory | ContactsImportHistory[] | TableSelectionIndicator<ContactsImportHistory>
	): void {
		this.loading$.next(true);
		if ( item === undefined ) {
			item = this.selection;
		}
		const fileId = Array.isArray(item) ? item.map((history) => history.file.id) :
			item !== null ? item.file.id : '';
		const fileName = Array.isArray(item) ? '' : item?.file.name;
		this.filesService.export(fileId, fileName).pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.loading$.next(false);
			});
	}

	override import(): void {
		this.initiateImport.emit();
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.completeRefBooksInFields(['status']);
	}
}
