import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Injectable()
export class ContactGroupsTableFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly headers: EntityField[] = [
		{
			id: 0,
			name: 'columns.name',
			variable: 'name',
			required: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			readonly: true
		},
		{
			id: 1,
			name: 'columns.contacts',
			variable: 'contactsIncluded',
			required: true,
			type: InputComplexType.RANGE,
			filterType: FilterType.BETWEEN,
			readonly: true
		},
		{
			id: 2,
			name: 'columns.creationDate',
			variable: 'creationDate',
			required: true,
			type: InputComplexType.DATE_RANGE,
			filterType: FilterType.BETWEEN,
			readonly: true
		},
		{
			id: 3,
			name: 'columns.description',
			variable: 'description',
			required: false,
			type: InputComplexType.TEXTAREA,
			filterType: FilterType.LIKE,
			readonly: true
		}
	];

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}
