import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { AlarisBalanceService, ChannelUtilsService } from '@campaign-portal/components-library';
import { Package } from '@campaign-portal/namespace/entities/subscriptions/specs';

import { PurchaseView } from '../purchase-container/purchase-container.component';
import { PurchaseService } from '../../purchase.service';

@Component({
	selector: 'app-package',
	templateUrl: './package.component.html',
	styleUrls: ['./package.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageComponent implements OnDestroy {
	@Input() view: PurchaseView = PurchaseView.GRID;
	@Input() package!: Package;

	readonly PurchaseView = PurchaseView;


	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly cu: ChannelUtilsService,
		public readonly bs: AlarisBalanceService,
		private readonly purchaseService: PurchaseService
	) {
	}

	@HostBinding('class.w-100') get w100(): boolean {
		return this.view === PurchaseView.LIST;
	}

	buyNow(pack: Package): void {
		this.purchaseService.openSpecificDialog('ShopNow', { package: pack })
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe();
	}

	seeAllCountries(pack: Package): void {
		this.purchaseService.openSpecificHintDialog('AllCountries', pack);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

}
