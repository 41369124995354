import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Campaign, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';

@Component({
	selector: 'app-campaign-list-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

	@Input() campaign!: Campaign;
	readonly CampaignStatus = CampaignStatus;

	constructor() {
	}

}
