export function sumElementsInArray(array: number[]): number {
	return array.reduce((a, b) => a + b, 0);
}

export function calculateBarChartMax(array: number[][], allArraySum = false): number {
	let max = 0;
	if ( allArraySum ) {
		max = array.map(items => Math.max(...items)).reduce((a, b) => a + b, 0);
	} else {
		max = Math.max(...array.flat());
	}
	return Math.ceil(max);
}


export function generateAxisYLabels(max: number, horizontalLines: number): string[] {
	const step = max / horizontalLines;
	if ( step === 0 ) {
		return [];
	}
	const arr = [];
	for ( let i = 0; i <= horizontalLines; i++ ) {
		arr.push((i * step).toFixed(step < 1 ? 2 : 0));
	}
	return arr;
}

// Cartesian product
// https://resultfor.dev/234401-cartesian-product-of-javascript-object-properties
// https://stackoverflow.com/questions/12303989/cartesian-product-of-multiple-arrays-in-javascript

export function product(args: Array<number[]>): Array<number[]> {
	if ( !args.length ) {
		return [[]];
	}
	const prod = product(args.slice(1));
	const r: Array<number[]> = [];
	args[0].forEach(x => {
		prod.forEach(p => {
			r.push([x].concat(p));
		});
	});
	return r;
}

export function equalitySets(a: Set<unknown>, b: Set<unknown>): boolean {
	return a.size === b.size && [...a].every(el => b.has(el));
}
