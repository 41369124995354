<app-campaign-list-avatar [campaign]="campaign" class="status"></app-campaign-list-avatar>
<div (click)="campaignsService.openDetailsPanel(campaign)"
	 [attr.data-testid]="'openDetailsPanelBtn/' + campaign.id" class="info">
	<div class="name box-truncate w-100">
		{{ campaign.info.name
		? campaign.info.name
		: ('campaigns.campaign' | translate) + ' ' + (campaign.info.scheduled | date: 'd MMM y, HH:mm')
		}}
	</div>
	<div class="box-truncate w-100">
		<div class="channels">
			<alaris-icon *ngFor="let ch of campaign.setup.channels"
						 [name]="cu.icon(ch.trafficType)"
						 state="simple">
			</alaris-icon>
			{{
			campaign.setup.channels.length === 1
				? campaign.setup.channels.at(0)?.trafficType
				: 'campaigns.cascade'
					| translate
			}}
		</div>
	</div>
</div>
