<div class="banner">
	<h3>{{ 'contacts.import.note' | translate }}</h3>
	<img alt="excel-icon" src="assets/img/excel-illustration.png">
</div>
<alaris-stepper #stepper [alarisLoading]="loading$" orientation="vertical">
	<alaris-step [completed]="!firstStepDisabled">
		<alaris-step-label>
			{{ 'contacts.import.step1' | translate }}
		</alaris-step-label>
		<alaris-step-content>
			<div class="content">
				<alaris-file-loader (fileListChanges)="addFiles($event)"
									[exportInternally]="true"
									[fileTypes]="'.csv,.xls,.xlsx,.numbers'"
									[files]="file? [file] : []"
									[multiple]="false" data-testid="contactsFile">
					<div class="loader-hint">
						{{ 'contacts.import.fileHintPart1' | translate }}
						<alaris-link (click)="generateTemplate()" data-testid="sampleTemplateLink" type="accent">
							{{ 'contacts.import.fileHintPart2' | translate }}
						</alaris-link>
						{{ 'contacts.import.fileHintPart3' | translate }}
					</div>
				</alaris-file-loader>
				<div class="next">
					<button (click)="stepper.next()"
							[disabled]="firstStepDisabled" alaris-button
							bType="primary" data-testid="toSecondStepBtn"
							size="lg">{{ 'gl.continue' | translate }}
					</button>
				</div>
			</div>
		</alaris-step-content>
	</alaris-step>
	<alaris-step (closed)="saveParsedColumns()"
				 [completed]="!secondStepDisabled"
				 [disabled]="firstStepDisabled">
		<alaris-step-label>
			{{ 'contacts.import.step2' | translate }}
		</alaris-step-label>
		<alaris-step-content>
			@if (stepper.selectedIndex === 1) {
				<div class="content">
					<div>{{ 'contacts.import.columnsHint' | translate }}</div>

					<alaris-alert>
						{{ 'contacts.import.notice' | translate }}
					</alaris-alert>

					<alaris-parsed-file-table (columns)="columns = $event"
											  [fields]="fields"
											  [initialColumns]="initialColumns"
											  [rows]="parsedFile.Data"
											  class="parsing-table">
					</alaris-parsed-file-table>

					<div class="actions">
						<fieldset class="column">
							<h6>{{ 'contacts.import.detailsQuestion' | translate }}</h6>
							<alaris-radio [attr.data-testid]="'mergeTypeRadio/' + ContactsImportMergeType.MERGE"
										  [formControl]="mergeType" [id]="'merge'"
										  [value]="ContactsImportMergeType.MERGE"
										  name="details">
								{{ 'contacts.import.mergeDetails' | translate }}
							</alaris-radio>
							<alaris-radio [attr.data-testid]="'mergeTypeRadio/' + ContactsImportMergeType.ADD"
										  [formControl]="mergeType" [id]="'add'" [value]="ContactsImportMergeType.ADD"
										  name="details">
								{{ 'contacts.import.addNewDetails' | translate }}
							</alaris-radio>
							<alaris-radio [attr.data-testid]="'mergeTypeRadio/' + ContactsImportMergeType.UPDATE"
										  [formControl]="mergeType" [id]="'update'"
										  [value]="ContactsImportMergeType.UPDATE"
										  name="details">
								{{ 'contacts.import.updateExistingDetails' | translate }}
							</alaris-radio>
						</fieldset>
						<fieldset class="column">
							<h6>{{ 'contacts.import.stopListQuestion' | translate }}</h6>

							<alaris-radio [attr.data-testid]="'stopListActionRadio/'
															+ ContactsImportStopListAction.EXCLUDE_FILE"
										  [formControl]="stopListAction" [id]="'exclude_file'"
										  [value]="ContactsImportStopListAction.EXCLUDE_FILE"
										  name="stoplist">
								{{ 'contacts.import.excludeStoplistedFromFile' | translate }}
							</alaris-radio>
							<alaris-radio [attr.data-testid]="'stopListActionRadio/'
															+ ContactsImportStopListAction.EXCLUDE_STOPLIST"
										  [formControl]="stopListAction" [id]="'exclude_stoplist'"
										  [value]="ContactsImportStopListAction.EXCLUDE_STOPLIST"
										  name="stoplist">
								{{ 'contacts.import.excludeFromStopList' | translate }}
							</alaris-radio>
							<alaris-radio [attr.data-testid]="'stopListActionRadio/'
															+ ContactsImportStopListAction.INSERT_STOPLIST"
										  [formControl]="stopListAction" [id]="'insert_stoplist'"
										  [value]="ContactsImportStopListAction.INSERT_STOPLIST"
										  name="stoplist">
								{{ 'contacts.import.importToStopList' | translate }}
							</alaris-radio>
						</fieldset>
					</div>

					<div class="next">
						<button (click)="stepper.next()"
								[disabled]="secondStepDisabled" alaris-button
								bType="primary" data-testid="toThirdStepBtn"
								size="lg">{{ 'gl.continue' | translate }}
						</button>
					</div>
				</div>
			}
		</alaris-step-content>
	</alaris-step>
	<alaris-step [completed]="!thirdStepDisabled"
				 [disabled]="firstStepDisabled || secondStepDisabled">
		<alaris-step-label>
			{{ 'contacts.import.step3' | translate }}
		</alaris-step-label>
		<alaris-step-content>
			<div class="content">
				@if (stopListAction.value !== ContactsImportStopListAction.INSERT_STOPLIST) {
					<div class="actions">
						<div class="column">
							<alaris-multi-select2 [formControl]="groups"
												  [label]="'contacts.contactGroup' | translate"
												  [optionsCount]="(contactGroupsService.list$ | async)?.length || 0"
												  [placeholder]="'contacts.choseContactGroups' | translate"
												  [selectedLabel]="'name'"
												  data-testid="groupsMultiSelect"
												  mode="tag">
								@if (((contactGroupsService.list$ | async)?.length ?? 0) > 5) {
									<alaris-select-filter2 [formControl]="filterControl" data-testid="groupsFilter">
									</alaris-select-filter2>
								}
								@for (cg of filteredGroupsList; track cg) {
									<alaris-multi-options2 [attr.data-testid]="'groupsOption/' + cg.name" [value]="cg">
										{{ cg.name | translate }}
									</alaris-multi-options2>
								}
							</alaris-multi-select2>
							@if (contactGroupsService.loading$ | async) {
								<div class="loading">
									<alaris-spinner></alaris-spinner>
									{{ 'gl.loading' | translate }}
								</div>
							}
							@if (groupsInfo.length) {
								<alaris-link (click)="openHintDialog()" data-testid="campaignUsageLink"
											 type="secondary">
									{{ 'contacts.showCampaignsUsage' | translate }}
								</alaris-link>
							}
						</div>

						<button (click)="createGroup()"
								alaris-button bType="outline" data-testid="createGroupBtn" size="xl">
							<alaris-icon class="in-outline" name="icon-plus"></alaris-icon>
							{{ 'contacts.addGroup' | translate }}
						</button>
					</div>
				}
				<div class="next">
					<button (click)="import()" [disabled]="thirdStepDisabled" alaris-button
							bType="primary" data-testid="importBtn"
							size="lg">
						{{ 'gl.continue' | translate }}
					</button>
				</div>
			</div>
		</alaris-step-content>
	</alaris-step>
</alaris-stepper>

