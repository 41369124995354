<div class="edit-panel-header">
	<alaris-icon name="icon-invoice-_glass"></alaris-icon>
	<span> {{invoice.id}}: {{ 'invoices.details' | translate}} </span>
</div>
<div alarisFade="vertical" [alarisLoading]="loading$" class="edit-panel-content">
	<div class="price-zone">
		<fieldset>
			<span class="label">{{'invoices.totalAmount' | translate}}</span> <br>
			<h1 class="amount">{{invoice.amount | currency: bs.currencyCode}}</h1>
		</fieldset>
		<!--			<button (click)="export()" alaris-button bType="primary" size="lg" type="button">-->
		<!--				<alaris-icon class="in-primary" name="icon-import"></alaris-icon>-->
		<!--				{{'invoices.exportDetails' | translate}}-->
		<!--			</button>-->
	</div>
	<div class="details-zone">
		<fieldset>
			<span class="label">{{'gl.date' | translate}}</span> <br>
			<span class="details">{{invoice.creationDate | date: 'd MMM y, HH:mm'}}</span>
		</fieldset>
		<fieldset *ngIf="invoice.initiator">
			<span class="label">{{'invoices.initiator' | translate}}</span> <br>
			<span class="details">{{invoice.initiator}}</span>
		</fieldset>
		<fieldset>
			<span class="label">{{'invoices.description' | translate}}</span> <br>
			<span class="details">{{invoice.description}}</span>
		</fieldset>
	</div>
	<div *ngFor="let campaign of campaignDetails" class="card">
		<div class="header">
			<div class="detail">
				<alaris-icon
					[name]="cu.icon(campaign.channel)"></alaris-icon>
				<fieldset>
					<div class="label">
						{{ cu.purpose(campaign.channel, campaign.viberSettings?.messagePurpose) | translate}}
					</div>
					<div class="rate">{{campaign.rate}}</div>
				</fieldset>
			</div>
			<b>{{campaign.amount | currency: bs.currencyCode}}</b>
		</div>
		<div class="body">
			<alaris-table [tHeaders]="tHeaders"
						  [tRows]="campaign.details"
						  [templates]="{amount}">
			</alaris-table>
		</div>
	</div>
</div>

<ng-template #amount let-data="data" let-key="key">
	<span>{{data.amount | currency: bs.currencyCode}}</span>
</ng-template>
