<div class="edit-area">
	<div class="edit-area__label">
		{{ (channel === TrafficType.SMS ? 'templates.smsMessage' : 'templates.imMessage') | translate }}
	</div>

	<textarea #editor
			  [formControl]="control"
			  class="edit-area__text" data-testid="textarea">
	</textarea>

	<div class="edit-area__actions">
		<div *ngIf="channel !== TrafficType.SMS" class="edit-area__actions_viber">
			<button (click)="bold()"
					[disabled]="control.disabled"
					alarisButton bType="secondary" data-testid="boldBtn" size="md">
				B
			</button>
			<button (click)="italic()"
					[disabled]="control.disabled"
					alarisButton bType="secondary" data-testid="italicBtn" size="md">
				<i>I</i>
			</button>
			<button (click)="strike()"
					[disabled]="control.disabled"
					alarisButton bType="secondary" data-testid="strikeBtn" size="md">
				<s>S</s>
			</button>
		</div>

		<button [alarisDropdown]="dropdown"
				[disabled]="control.disabled"
				[maxDropdownHeight]="300"
				alarisButton bType="secondary" data-testid="emojiDropdownToggle" size="md">
			<alaris-icon name="icon-emoji"></alaris-icon>
		</button>

		<alaris-dropdown #dropdown [closeOnClick]="false">
			<emoji-mart (emojiSelect)="addEmoji($event)"
						[i18n]="emojiI18n"
						[isNative]="true"
						[include]="EMOJI_CATEGORIES"
						[perLine]="6"
						[style]="{ width: '245px' }"
						[darkMode]="config.themeType === 'dark'"
						[showPreview]="false"
						[showSingleCategory]="true"
						[totalFrequentLines]="2"
						class="picker"
						size="16">
			</emoji-mart>
		</alaris-dropdown>

		<alaris-select2 [displayWith]="displayVariable.bind(this)"
						[formControl]="variable"
						[label]="'gl.variable' | translate"
						[placeholder]="'gl.variable' | translate"
						class="variables" data-testid="variableSelect">
			<alaris-options2 *ngFor="let opt of contactFields"
							 [attr.data-testid]="'variableOption/' + opt.name"
							 [value]="opt">
				{{ opt.name | translate }}
			</alaris-options2>
		</alaris-select2>

		<app-message-counter
			#counter
			[channel]="channel"
			[text]="control.value || ''"
			[variables]="(contactFieldsService.list$ | async) ?? []"
		></app-message-counter>
	</div>
</div>

<alaris-alert *ngIf="counter.hasVariable" type="warning">
	{{ 'templates.variablesNote' | translate }}
</alaris-alert>
