import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatestWith, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';

import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { ContactsImportHistory } from '@campaign-portal/namespace/entities/contacts-import-history/specs';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import {
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisProfileService,
	EditPanelInputData,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { ContactGroupsService } from '../../../services/contact-groups.service';
import { ContactImportService } from '../../../services/contact-import.service';

@Component({
	selector: 'app-edit-group',
	templateUrl: './edit-group.component.html',
	styleUrls: ['./edit-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditGroupComponent extends CanDeactivateComponent implements OnInit, OnDestroy {

	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	group: ContactGroup;
	readonly importHistory$ = new BehaviorSubject<ContactsImportHistory[]>([]);
	readonly form = new FormGroup({
		name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
		description: new FormControl<string>('', { nonNullable: true })
	});
	readonly errors = [
		{ key: 'required', value: 'Please use this field' }
	];
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	readonly loading$: Observable<boolean> = this.contactGroupsService.loading$.pipe(
		combineLatestWith(this.contactsImportService.loading$),
		map(([cG, cI]) => cG || cI));

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly contactGroupsService: ContactGroupsService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		private readonly guard: CanDeactivateGuardService,
		private readonly contactsImportService: ContactImportService,
		private readonly filesService: AlarisFilesService,
		private readonly langService: AlarisLanguageService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profileService: AlarisProfileService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.group = (this.inputData.group as ContactGroup) ?? {
			id: null,
			name: '',
			isDefault: false,
			description: ''
		};
		this.form.setValue({
			name: this.group.name ? this.langService.translate(this.group.name) : '',
			description: this.group.description ? this.langService.translate(this.group.description) : ''
		});
	}

	ngOnInit(): void {
		if ( this.group.id ) {
			this.contactsImportService.read({
				Filters: [{
					Field: 'contactGroups',
					Type: FilterType.IN,
					Value: [this.group.id]
				}],
				Sorting: [{ Field: 'creationDate', Dir: SortDirection.DESC }]
			})
				.pipe(map(r => r.Data || []), takeUntil(this.ngUnsubscribe))
				.subscribe((resp) => {
					this.importHistory$.next(resp);
				});
		}
		if ( !this.profileService.allowed([CP_PERMISSIONS.CONTACTS_E]) ) {
			this.form.disable();
		}
	}

	getErrorReport(id: Id<exist>): void {
		this.contactsImportService.errorsReport([id])
			.pipe(
				switchMap(({ id, name }) => this.filesService.export(id, name)),
				takeUntil(this.ngUnsubscribe)
			).subscribe();
	}

	export(item: ContactsImportHistory): void {
		this.contactGroupsService.loading$.next(true);
		this.filesService.export(item.file.id, item.file.name).pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.contactGroupsService.loading$.next(false);
			});
	}

	save(): void {
		const group: ContactGroup = { ...this.group, ...this.form.value };
		this.allowedDeactivation.next(false);
		this.contactGroupsService.update(group)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(resp) => {
					this.allowedDeactivation.next(true);
					if ( resp.Success ) {
						this.close(resp.Data?.[0].id);
					}
				}
			);
	}

	close(groupId?: Id): void {
		this.editPanel.close(groupId);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.importHistory$.complete();
	}
}
