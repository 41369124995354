<alaris-tabs #contactsTabs (tabChanged)="cd.detectChanges()" [underline]="false" id="contactTabs">
	<alaris-tab [label]="'contacts.title' | translate" tabId="contacts">
		<app-contacts-table (initiateImport)="initiateImport()"
							*ngIf="contactsTabs.activeTab?.tabId === 'contacts'"
							[createContact]="newContact"
							[groupId]="groupId">
			<app-empty-table (newContact)="createContactsEvent()"
							 (newImport)="initiateImport()"
							 slot="empty"
							 type="contacts">
			</app-empty-table>
		</app-contacts-table>
	</alaris-tab>
	<alaris-tab [label]="'contacts.groups' | translate" tabId="groups">
		<app-contacts-groups-table (initiateImport)="initiateImport()"
								   *ngIf="contactsTabs.activeTab?.tabId === 'groups'"
								   [createGroup]="newGroup">
			<app-empty-table (newGroup)="createGroupEvent()"
							 slot="empty"
							 type="groups">
			</app-empty-table>
		</app-contacts-groups-table>
	</alaris-tab>
	<alaris-tab *alarisPBAC="[CP_PERMISSIONS.CONTACTS_E]"
				[label]="'contacts.importHistory' | translate"
				tabId="importHistory">
		<app-contacts-imports-table (initiateImport)="initiateImport()"
									*ngIf="contactsTabs.activeTab?.tabId === 'importHistory'">
			<app-empty-table (newImport)="initiateImport()"
							 slot="empty"
							 type="imports">
			</app-empty-table>
		</app-contacts-imports-table>
	</alaris-tab>

	<alaris-tab [label]="'contacts.stopList' | translate" tabId="stopList">
		<app-contacts-stoplist-table (initiateImport)="initiateImport()"
									 *ngIf="contactsTabs.activeTab?.tabId === 'stopList'">
			<app-empty-table (newStopList)="goToContacts()"
							 slot="empty"
							 type="stop-list">
			</app-empty-table>
		</app-contacts-stoplist-table>
	</alaris-tab>
</alaris-tabs>
<app-contacts-templates (changeGroup)="changeGroup($event)"></app-contacts-templates>
