import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	formatBytes,
	TableFiltersIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { EdrExport } from '@campaign-portal/namespace/entities/edr/specs';
import { EdrExportStatus } from '@campaign-portal/namespace/common/enums';
import { Filter, FilterInterval, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { EdrExportTaskFieldsService } from './edr-export-task-fields.service';
import { EdrExportFieldsService } from '../edr-export/edr-export-fields.service';
import { ExportTableService } from './export.table.service';
import { EdrExportTaskDetailsDialogComponent } from './task-details-dialog/task-details-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface ExternalFiltersControls {
	name: FormControl<string | null>;
	creationDate: FormControl<FilterInterval<string | null> | null>;
}

@Component({
	selector: 'app-edr-export-task',
	templateUrl: './edr-export-task.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./edr-export-task.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [EdrExportTaskFieldsService, ExportTableService]
})
export class EdrExportTaskComponent extends AlarisComplexTableComponent<EdrExport<exist>> implements OnInit {

	readonly EdrExportStatus = EdrExportStatus;
	readonly formatBytes = formatBytes;

	readonly filtersGroup = new FormGroup<ExternalFiltersControls>({
		name: new FormControl<string | null>(null),
		creationDate: new FormControl<FilterInterval<string | null> | null>(null)
	});
	override readonly id = 'edr-export-task';

	override sorting = new Map()
		.set('creationDate', { enabled: true, value: SortDirection.DESC })
		.set('fileId', { enabled: false });

	override filters: TableFiltersIndicator = new Map()
		.set('fileId', { enabled: false })
		.set('size', { enabled: false });

	constructor(
		public readonly edrExportTaskService: ExportTableService,
		public readonly edrFields: EdrExportFieldsService,
		public override readonly fieldsService: EdrExportTaskFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		private readonly filesService: AlarisFilesService,
		private readonly dialog: AlarisDialogService
	) {
		super(
			edrExportTaskService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.edrExportTaskService.stateID = this.id;
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.filtersGroup.controls.name.valueChanges
			.pipe(
				debounceTime(500),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((name) => {
				if ( this.filters.get('name')?.value !== name ) {
					this.filters.set('name', { enabled: true, value: name });
					this._applyBaseFilter();
				}
			});

		this.filtersGroup.controls.creationDate.valueChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((interval) => {
				const creationDate = (interval?.Start || interval?.End) ? interval : null;
				const filterValue = this.filters.get('creationDate')?.value as FilterInterval<string | null> | null;
				if ( filterValue?.Start !== creationDate?.Start
					|| filterValue?.End !== creationDate?.End ) {
					this.filters.set('creationDate', { enabled: true, value: creationDate });
					this._applyBaseFilter();
				}
			});

		this.edrFields.map$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.cd.detectChanges();
			});
	}

	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		this.filtersGroup.setValue({
			name: ($event.get('name')?.value ?? null) as string | null,
			creationDate: ($event.get('creationDate')?.value ?? null) as FilterInterval<string | null> | null
		});
	}

	override refresh(): void {
		this.edrExportTaskService.firstLoad = true;
		this.load();
	}

	override restoreState(): void {
		super.restoreState();

		if ( this.filters.get('name')?.value ) {
			this.filtersGroup.controls
				.name.setValue(this.filters.get('name')?.value as string || '');
		}
		if ( this.filters.get('creationDate')?.value ) {
			this.filtersGroup.controls
				.creationDate.setValue(this.filters.get('creationDate')?.value as FilterInterval<string | null>);
		}

	}

	getName(status: EdrExportStatus): string {
		const iconMap: { [key in EdrExportStatus]: string } = {
			[EdrExportStatus.WAITING]: 'icon-edr-export-waiting',
			[EdrExportStatus.SENT]: 'icon-edr-export-sent',
			[EdrExportStatus.CANCELLED]: 'icon-edr-export-canceled',
			[EdrExportStatus.READY]: 'icon-edr-export-sent',
			[EdrExportStatus.FAILED]: 'icon-edr-export-failed'
		};
		return iconMap[status];
	}

	getColor(status: EdrExportStatus): string {
		const map: { [key in EdrExportStatus]: string } = {
			[EdrExportStatus.WAITING]: 'var(--as-BgInfo)',
			[EdrExportStatus.SENT]: 'var(--as-BgPositive)',
			[EdrExportStatus.CANCELLED]: 'var(--as-BgTertiaryExtra)',
			[EdrExportStatus.READY]: 'var(--as-BgPositive)',
			[EdrExportStatus.FAILED]: 'var(--as-BgNegative)'
		};
		return map[status];
	}

	getPeriod(filters: Filter[]): FilterInterval<string | null> {
		return (filters.find(f => f.Field === 'edrDate')?.Value as FilterInterval<string | null> | undefined) ?? {
			Start: null,
			End: null
		};
	}

	download(fileId: string, fileName?: string): void {
		this.filesService.export(fileId, fileName)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}

	cancel(task: EdrExport<exist>): void {
		this.edrExportTaskService.cancel(task)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.refresh());

	}

	restart(task: EdrExport<exist>): void {
		this.edrExportTaskService.restart(task)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.refresh());
	}

	openTaskDetailDialog(task?: EdrExport<exist>): void {
		this.dialog.open(EdrExportTaskDetailsDialogComponent, {
			data: { task },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	private _applyBaseFilter(): void {
		super.applyFilter(this.filters);
		this.load();
	}
}
