import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import {
	AlarisComplexTableComponent,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisSettingsManagerComponent,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple,
	SharedTemplatesService,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { Campaign, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { EntityField } from '@campaign-portal/namespace/common/entityField';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { CampaignAction, CampaignsService } from '../services/campaigns.service';
import { CampaignFieldsService } from './campaign-fields.service';
import { CampaignsTemplatesComponent } from './campaigns-templates/campaigns-templates.component';
import { CampaignFromFileComponent } from '../campaign-from-file/campaign-from-file.component';
import { MessageTemplatesService } from '../services/message-templates.service';
import { CampaignWizardService } from '../campaign-wizard/campaign-wizard.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-campaign-list',
	templateUrl: './campaign-list.component.html',
	styleUrls: [
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./campaign-list.component.scss'
	],
	providers: [CampaignFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class CampaignListComponent extends AlarisComplexTableComponent<Campaign> implements OnDestroy {
	readonly CP_PERMISSIONS = CP_PERMISSIONS;

	// @ts-ignore
	override dropDownRowActions: RowActionSimple<any>[] = [
		{
			icon: '',
			label: 'actions.details',
			action: (campaign): void => this.campaignsService.openDetailsPanel(campaign as Campaign)
		},
		{
			icon: 'icon-play',
			label: 'actions.start',
			action: (campaign: Campaign<exist>): void => {
				this.campaignsService.update(
					{
						...campaign,
						info: {
							...campaign.info,
							scheduled: new Date().toISOString()
						}
					}
				)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			shown: (campaign: Campaign<exist>): boolean => campaign?.info?.status === CampaignStatus.SCHEDULED
		},
		{
			icon: 'icon-play',
			label: 'actions.resume',
			action: (campaign: Campaign<exist>): void => {
				this.campaignsService.action(CampaignAction.RESUME, campaign)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			shown: (campaign: Campaign<exist>): boolean => campaign?.info?.status === CampaignStatus.PAUSED
		},
		{
			icon: 'icon-pause-circle',
			label: 'actions.pause',
			action: (campaign: Campaign<exist>): void => {
				this.campaignsService.action(CampaignAction.PAUSE, campaign)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			disabled: (campaign: Campaign<exist>): boolean => campaign.info.status !== CampaignStatus.IN_PROGRESS
		},
		{
			icon: 'deprecated/stop',
			label: 'actions.cancel',
			action: (campaign: Campaign<exist>): void => {
				this.campaignsService.action(CampaignAction.CANCEL, campaign)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			disabled: (campaign: Campaign<exist>): boolean =>
				campaign.info.status === CampaignStatus.IN_PROGRESS ||
				campaign.info.status === CampaignStatus.COMPLETED ||
				campaign.info.status === CampaignStatus.FAILED ||
				campaign.info.status === CampaignStatus.CANCELLED
		},
		{
			icon: 'icon-arrow-move-list',
			label: 'actions.repeat',
			action: (campaign: Campaign<exist>): void => {
				this.cw.repeat(campaign);
			},
			disabled: (campaign: Campaign<exist>): boolean =>
				campaign.info.status === CampaignStatus.PAUSED ||
				campaign.info.status === CampaignStatus.IN_PROGRESS ||
				campaign.info.status === CampaignStatus.SCHEDULED,
			shown: (campaign: Campaign<exist>): boolean => !campaign.setup.hasOwnProperty('file')
		},
		{
			icon: '',
			label: 'actions.delete',
			action: (campaign: Campaign<exist>): void => {
				this.campaignsService.openDialog('Delete', campaign)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			},
			disabled: (campaign: Campaign<exist>): boolean => campaign.info.status === CampaignStatus.IN_PROGRESS,
			highlight: true,
			separator: true
		}
	];
	override ctxActions: RowActionSimple<Campaign | Campaign[]>[] = this.dropDownRowActions;

	override sorting: TableSortIndicator = new Map()
		.set('lastUpdated', { enabled: true, value: SortDirection.DESC })
		.set('template', { enabled: false, value: SortDirection.EMPTY })
		.set('recipients', { enabled: false, value: SortDirection.EMPTY });

	override filters: TableFiltersIndicator = new Map()
		.set('progress', { enabled: false })
		.set('recipients', { enabled: false });

	override readonly id = 'campaign-list';

	constructor(
		public campaignsService: CampaignsService,
		public campaignFieldsService: CampaignFieldsService, // public fieldsService,
		public override tableSettingsService: AlarisTableSettingsService, // public tableSettingsService,
		public override editPanel: AlarisEditPanelService,
		public override cd: ChangeDetectorRef,
		public sharedTemplates: SharedTemplatesService,
		private viewRef: ViewContainerRef,
		private router: Router,
		private route: ActivatedRoute,
		private templatesService: MessageTemplatesService,
		private enums: EnumsMapperService,
		private cw: CampaignWizardService,
		stateService: AlarisTableStateService,
		@Inject(PROFILE_SERVICE_INJECTOR) public profileService: AlarisProfileService
	) {
		super(
			campaignsService,
			campaignFieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.CAMPAIGN_LIST,
			DEFAULT_BUTTONS,
			stateService
		);
		// add templates
		this.viewRef.createComponent(CampaignsTemplatesComponent);

		if ( !this.profileService.allowed([CP_PERMISSIONS.CAMPAIGNS_E]) ) {
			this.removeActions();
		}
	}

	removeActions(): void {
		this.dropDownRowActions = this.ctxActions = [
			{
				icon: '',
				label: 'actions.details',
				action: (campaign): void => this.campaignsService.openDetailsPanel(campaign as Campaign)
			}
		];
	}

	override dblClickRowAction(campaign?: Campaign): void {
		this.campaignsService.openDetailsPanel(campaign as Campaign);
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			template: this.templatesService,
			status: { list$: new BehaviorSubject<string[]>(Object.keys(this.enums.get('CampaignStatus'))) }
		});
	}

	override applyFilter(event: TableFiltersIndicator): void {
		super.applyFilter(event);
		this.readParams.Filters?.forEach(f => f.Field === 'status' ?
			f.Value = (f.Value as string[]).map(i => this.enums.get('CampaignStatus')[i]) : f
		);
	}

	//overwrite
	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	override ngOnInit(): void {
		super.ngOnInit();
		if ( this.route.snapshot.queryParamMap.get('mode') === 'fromFile' ) {
			this.import();
		}
		this.campaignsService.forceLoad$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.refresh();
			});

		interval(2.5 * 60000).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.refresh(false);
		});
	}

	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	override ngAfterViewInit(): void {
		super.ngAfterViewInit();
		this.templates = this.sharedTemplates.templates;
		this.cd.markForCheck();
	}

	override load(showLoading = true): void {
		this.saveState();
		if ( showLoading ) {
			this.loading$.next(true);
		}
		this.campaignsService.read(this.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.tRows = resp.Data;
				this.total = resp.Total;
				if ( showLoading ) {
					this.loading$.next(false);
				}
				this.cd.markForCheck();
			});
		this.cd.markForCheck();
	}

	override refresh(showLoading = true): void {
		this.load(showLoading);
	}

	override editTableSettings(): void {
		this.editPanel.open(AlarisSettingsManagerComponent, EditPanelWidth.MD, {
			tHeads: this.tHeaders,
			tSettings: this.tSettings,
			creationFieldAvailable: false,

			tHeadsChange: this.tHeadsChange,
			tSettingsChange: this.tSettingsChange,
			headEvent: this.tHeadEvent
		});
	}

	override import(): void {
		this.editPanel.open(CampaignFromFileComponent, EditPanelWidth.MD, {});
	}

	override create(): void {
		this.router.navigate(['campaigns', 'campaign-wizard']);
	}


	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.completeRefBooksInFields(['status']);
	}
}
