<div [alarisLoading]="loading$" class="wrapper gap">
	<div class="buy-note">{{'purchase.buy' | translate}}</div>

	<ng-container *ngIf="subscribed.packs.size || subscribed.plans.length; else noPackagesOrPlansNote">
		<h6 class="purchase-title">
			{{'purchase.yourPackages' | translate}}
		</h6>
		<div [class.grid-row]="subscribed.packs.size <= 3"
			 [class.grid-two-rows]="subscribed.packs.size > 3"
			 class="purchase-card-zone">
			<app-empty-purchase-card (requestSubscription)="$event === 'packs' ? packageRequest() : planRequest()"
									 *ngIf="subscribed.packs.size === 0"
									 [mode]="'packs'"></app-empty-purchase-card>
			<ng-container *ngIf="subscribed.packs.size">
				<app-subscribed-card (click)="openPackageDetailsPanel(p[0])"
									 *ngFor="let p of subscribed.packs.entries()"
									 [attr.data-testid]="'subscribedPackCard/' + p[0]"
									 [packs]="p[1]"></app-subscribed-card>
			</ng-container>
		</div>

		<h6 class="purchase-title">
			{{'purchase.yourPlans' | translate}}
		</h6>
		<div class="purchase-card-zone">
			<app-empty-purchase-card (requestSubscription)="$event === 'packs' ? packageRequest() : planRequest()"
									 *ngIf="subscribed.plans.length === 0"
									 [mode]="'plans'"></app-empty-purchase-card>
			<ng-container *ngIf="subscribed.plans.length">
				<app-subscribed-card (click)="openPlanDetailsPanel(plan)" *ngFor="let plan of subscribed.plans"
									 [attr.data-testid]="'subscribedPlanCard/' + plan.id"
									 [plan]="plan"></app-subscribed-card>
			</ng-container>
		</div>
	</ng-container>
	<ng-template #noPackagesOrPlansNote>
		<alaris-alert class="no-purchase-note">
			{{'purchase.noPackagesAndRates' | translate}}
		</alaris-alert>
	</ng-template>

	<alaris-tabs *alarisPBAC="[CP_PERMISSIONS.SUBSCR_E]"
				 #purchaseTabs
				 (tabChanged)="cd.detectChanges()"
				 [underline]="false"
				 class="wrapper"
				 id="purchaseTabs">
		<alaris-tab [label]="'purchase.packages' | translate" tabId="packs">
			<alaris-card class="wrapper" size="auto">
				<ng-container *ngIf="packs.length; else emptyPackages">
					<app-purchase-container [packages]="packs"></app-purchase-container>
				</ng-container>
				<ng-template #emptyPackages>
					<app-empty-table (packageRequest)="packageRequest()"
									 class="purchase-empty"
									 slot="empty"
									 type="packages">
					</app-empty-table>
				</ng-template>
			</alaris-card>
		</alaris-tab>
		<alaris-tab [label]="'purchase.plans' | translate" tabId="plans">
			<alaris-card class="wrapper" size="auto">
				<ng-container *ngIf="purchaseTabs.activeTab?.tabId === 'plans'">
					<ng-container *ngIf="plans.length; else emptyPlans">
						<app-purchase-container [plans]="plans"></app-purchase-container>
					</ng-container>
				</ng-container>
				<ng-template #emptyPlans>
					<app-empty-table (planRequest)="planRequest()"
									 class="purchase-empty"
									 slot="empty"
									 type="plans">
					</app-empty-table>
				</ng-template>
			</alaris-card>
		</alaris-tab>
	</alaris-tabs>
</div>
