import { Injectable } from '@angular/core';

import { AlarisLanguageService, ExtendableEnumsMapperService } from '@campaign-portal/components-library';

import { CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';
import {
	EdrStatus,
	EnabledDisabledStatus,
	ImportStatus,
	MessagePurpose,
	SenderSubscriptionEventStatus
} from '@campaign-portal/namespace/common/enums';

import { SenderStatus } from '../../pages/campaigns/senders/senders.component';

type Enums =
	'CampaignStatus'
	| 'SenderSubscriptionEventStatus'
	| 'EnabledDisabledStatus'
	| 'ImportStatus'
	| 'EdrStatus'
	| 'MessagePurpose'
	| 'SenderStatus';

@Injectable({
	providedIn: 'root'
})
export class EnumsMapperService extends ExtendableEnumsMapperService<Enums> {
	constructor(public override readonly langService: AlarisLanguageService) {
		super(langService);
	}

	override init(): void {
		this.set('CampaignStatus', this.transform(CampaignStatus));
		this.set('SenderSubscriptionEventStatus', this.transform(SenderSubscriptionEventStatus));
		this.set('EnabledDisabledStatus', this.transform(EnabledDisabledStatus));
		this.set('ImportStatus', this.transform(ImportStatus));
		this.set('EdrStatus', this.transform(EdrStatus));
		this.set('MessagePurpose', this.transform(MessagePurpose));
		this.set('SenderStatus', this.transform(SenderStatus));
	}
}
