<div class="card">
	<div class="note">
		{{(mode === 'packs' ? 'purchase.noPackages' : 'purchase.noPlans') | translate}}
	</div>
	<alaris-link (click)="requestAdd()" data-testid="requestLink" type="primary">
		{{(mode === 'packs' ? 'purchase.addNewPackage' : 'purchase.addNewPlan') | translate}}
	</alaris-link>
</div>



