<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<button (click)="editTableFilter()"
					alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
			<alaris-link (click)="editTableSettings()" data-testid="editSettingsLink">
				{{ enableButtons.settings.title | translate }}
			</alaris-link>
		</div>
		<div class="right">
			<button (click)="load()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
			<button (click)="edrExportSettings()" alaris-button bType="primary" data-testid="exportBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-export-1"></alaris-icon>
				{{ 'actions.export' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table type="edr-export"></app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [dblClickRowAction]="edrExportDetails.bind(this)"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [tSettings]="tSettings"
						  [templates]="{campaignName, edrDate, channelId, edrStatus, cost, message, messagePurpose}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-alert *ngIf="total > pageSize"
					  bg="primary" class="note">
			{{ 'statistics.showMoreNote' | translate : {amount: pageSize} }}
		</alaris-alert>
	</div>
</alaris-card>

<ng-template #edrDate [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="edrDate-column">
		<alaris-icon
			[name]="cu.icon(channelService.map.get(data.channelId)?.channelType)"></alaris-icon>
		{{ data.edrDate | date: 'd MMM y, HH:mm' }}
	</div>
</ng-template>

<ng-template #edrStatus [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="status">
		<div [style.background]="edrExportService.getStatusColor(data)" class="dot"></div>
		{{ 'enums.' + data.edrStatus | translate }}
	</div>
</ng-template>

<ng-template #messagePurpose [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.messagePurpose ? ('enums.' + data.messagePurpose | translate) : '' }}
</ng-template>

<ng-template #cost [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.cost | currency: bs.currencyCode : 'symbol' : '1.2-5' }}
</ng-template>

<ng-template #channelId [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ (data.channelId | channel)?.name }}
</ng-template>

<ng-template #message [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="message-column">
		{{ data.message }}
	</div>
</ng-template>

<ng-template #campaignName [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	@if (data.labelAPI) {
		<div class="label-api">
			<alaris-tag mode="gray-light">{{ 'api.request' | translate }}</alaris-tag>
		</div>
	} @else {
		{{ data.campaignName }}
	}
</ng-template>
