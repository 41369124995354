<div class="title">
	{{'campaigns.totalRecipients' | translate}}
	<span class="total">{{totalRecipients}}</span>
</div>


<div class="content">
	<div class="chart">
		<tui-ring-chart [(activeItemIndex)]="activeItemIndex"
						[value]="value">
			<div>{{amount}}</div>
			<div>{{label}}</div>
		</tui-ring-chart>
	</div>
	<div class="legend">
		<tui-legend-item
				(mouseleave)="makeNaN()"
				(mouseover)="onHover(index, $event)"
				*ngFor="let label of labels; let index = index"
				[active]="isItemActive(index)"
				[color]="getColor(index)"
				[text]="label"
				class="item"
				size="s">
			<b>{{value[index] || 0}}</b>
		</tui-legend-item>
	</div>
</div>



