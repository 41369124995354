<ng-template #campaign [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	<app-campaign [campaign]="data"></app-campaign>
</ng-template>

<ng-template #template [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	<app-template [campaign]="data"></app-template>
</ng-template>

<ng-template #status [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	<alaris-tag [mode]="campaignUtils.selectStatusColor(data.info.status)"
				[rounded]="true"
				class="status w-100">
		{{ 'enums.' + data.info.status | translate }}
	</alaris-tag>
</ng-template>

<ng-template #progress [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	<alaris-progress-line [alarisComplexTooltip]="CampaignProgressTooltipComponent"
						  [componentData]="data"
						  [content]="true"
						  [percentageComplete]="campaignUtils.calculatePercentage(data)"
						  [type]="campaignUtils.selectCampaignProgressColor(data)">
		<label>
			{{ campaignUtils.calculatePercentage(data) ?
			(campaignUtils.calculatePercentage(data) | number: '1.0-1') + '%' :
			('gl.notAvailable' | translate)
			}}
		</label>
	</alaris-progress-line>
</ng-template>

<ng-template #recipients [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	<app-recipients [campaign]="data"></app-recipients>
</ng-template>

<ng-template #cost [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	{{ data.statistics?.cost | currency: bs.currencyCode}}
</ng-template>

<ng-template #startDate [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	{{ (data.info.startDate | date: 'd MMM y, HH:mm') ?? ('gl.notAvailable' | translate) }}
</ng-template>

<ng-template #finishedDate [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	{{ (data.info.finishedDate | date: 'd MMM y, HH:mm') ?? ('gl.notAvailable' | translate) }}
</ng-template>

<ng-template #scheduled [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	{{ (data.info.scheduled | date: 'd MMM y, HH:mm') ?? ('gl.notAvailable' | translate) }}
</ng-template>

<ng-template #lastUpdated [alarisCellCtx]="campaignCtx" let-data="data" let-key="key">
	{{ (data.audit?.lastUpdated | date: 'd MMM y, HH:mm') ?? ('gl.notAvailable' | translate) }}
</ng-template>
