import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { MessageTemplate } from '@campaign-portal/namespace/entities/templates/specs';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { RowActionSimple } from '@campaign-portal/components-library';

@Component({
	selector: 'app-template-card',
	templateUrl: './template-card.component.html',
	styleUrls: ['./template-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCardComponent {

	@Input() template!: MessageTemplate;
	@Input() actions: RowActionSimple<MessageTemplate>[] = [];

	readonly TrafficType = TrafficType;

	readonly position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: 0,
			offsetX: 0
		}
	];

	constructor() {
	}

}

//	actions: TemplateCardAction<MessageTemplate>[] = [
// 		{
// 			label: 'Create Campaign',
// 			action: this.createCampaign.bind(this)
// 		},
// 		{
// 			label: 'Delete',
// 			action: this.deleteTemplate.bind(this)
// 		}
// 	];

//createCampaign(template?: MessageTemplate): void {
// 		if ( template ) {
// 			this.router.navigate(['campaigns', 'campaign-wizard'],
// 				{ queryParams: { channelType: template.channelType, templateId: template.id } }
// 			);
// 		}
// 	}

//	deleteTemplate(template?: MessageTemplate<exist>): void {
// 		if ( template ) {
// 			this.openDialog('Delete', template);
// 		}
// 	}
