import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import {
	AlarisBalanceService,
	AlarisComplexTableComponent,
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	ChannelUtilsService,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { OperationType } from '@campaign-portal/namespace/common/enums';
import { TransactionHistory } from '@campaign-portal/namespace/entities/transaction-history/specs';
import { FilterInterval, FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { InvoicesService } from './invoices.service';
import { InvoicesFieldsService } from './invoices-fields.service';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { InvoicesTableSettingsService } from './invoices-table-settings.service';
import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface InvoiceFormControls {
	operationType: FormControl<OperationType | null>;
	period: FormControl<FilterInterval<string>>;
}

@Component({
	selector: 'app-invoices',
	templateUrl: './invoices.component.html',
	styleUrls: [
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./invoices.component.scss'
	],
	providers: [InvoicesFieldsService, InvoicesTableSettingsService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesComponent extends AlarisComplexTableComponent<TransactionHistory> implements OnInit {
	readonly OperationType = OperationType;
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	readonly operationTypes: (OperationType | null)[] = [
		null,
		...Object.values(OperationType).filter(type => type !== OperationType.ADJUSTMENT)
	];

	readonly invoiceForm = new FormGroup<InvoiceFormControls>({
		operationType: new FormControl(null),
		period: new FormControl()
	});
	
	override sorting: TableSortIndicator = new Map()
		.set('creationDate', { enabled: true, value: SortDirection.DESC });

	constructor(
		public readonly invoicesService: InvoicesService,
		public override readonly fieldsService: InvoicesFieldsService,
		public override readonly tableSettingsService: InvoicesTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public readonly cu: ChannelUtilsService,
		public readonly bs: AlarisBalanceService,
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profile: AlarisProfileService,
		private readonly ls: AlarisLanguageService
	) {
		super(
			invoicesService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			'invoices' as TableTypes
		);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.invoiceForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.applyFormFilters();
			});
	}

	override dblClickRowAction(tHistory?: TransactionHistory): void {
		if ( tHistory?.operationType === OperationType.CAMPAIGN &&
			this.profile.allowed([CP_PERMISSIONS.CAMPAIGNS_R])
		) {
			this.openCampaignDetailsPanel(tHistory);
		}
	}

	applyFormFilters(): void {
		this.constructReadParams();
		this.load();
	}

	constructReadParams(): void {
		this.readParams.Filters = [];
		this.activePage = 0; // Reset pagination to first page
		if ( this.invoiceForm.value.operationType ) {
			this.readParams.Filters.push({
				Field: 'operationType', Type: FilterType.EXACT, Value: this.invoiceForm.value.operationType
			});
		}
		if ( this.invoiceForm.value.period ) {
			this.readParams.Filters.push({
				Field: 'date', Type: FilterType.BETWEEN, Value: this.invoiceForm.value.period
			});
		}
		this.readParams.Paging = {
			Skip: this.activePage,
			Take: this.pageSize
		};
	}

	displayOperationType = (operationType: OperationType | null): string => {
		return operationType === null
			? this.ls.translate('invoices.allOperationTypes')
			: this.ls.translate('enums.' + operationType);
	};
	
	openCampaignDetailsPanel(invoice?: TransactionHistory): void {
		this.editPanel.open(InvoiceDetailsComponent, EditPanelWidth.MD, {
			invoice
		});
	}

}
