<div [alarisLoading]="templatesService.loading$" class="template">
	<div class="edit-panel-header">
		{{
		template.id ? ('templates.template' | translate) + ': ' + template.name :
			'templates.createTemplate' | translate
		}}
	</div>
	<div [formGroup]="templateForm" alarisFade="vertical" class="edit-panel-content">

		<div class="form-title">{{'templates.messageType' | translate}}</div>
		<alaris-tabs-button [formControl]="templateForm.controls.channelType"
							[tabs]="tabs" data-testid="channelTypeTabs">
		</alaris-tabs-button>

		<alaris-input [errors]="templateFormErrors"
					  [label]="'templates.templateName' | translate"
					  [placeholder]="'templates.templateName' | translate"
					  data-testid="nameInput" formControlName="name">
		</alaris-input>

		<alaris-select2 [formControl]="templateForm.controls.sender"
						[label]="'senderIds.senderId' | translate"
						[placeholder]="'senderIds.senderId' | translate"
						[readonly]="activeSenders(templateForm.controls.channelType.value).length === 0 && !templateForm.controls.sender.value"
						data-testid="senderIdsSelector" selectedLabel="name">
			<alaris-select-filter2 *ngIf="activeSenders(templateForm.controls.channelType.value).length > 5"
								   [formControl]="filterSendersControl" data-testid="senderIdsFilter">
			</alaris-select-filter2>
			<alaris-options2 [value]="undefined" data-testid="senderIdsOption_none">
				{{ 'gl.unused' | translate }}
			</alaris-options2>
			<alaris-options2
				*ngFor="let opt of activeSenders(templateForm.controls.channelType.value, filterSendersList)"
				[attr.data-testid]="'senderIdsOption/' + opt.name" [value]="opt">{{ opt.name }}
			</alaris-options2>
		</alaris-select2>
		<div *ngIf="templateForm.controls.sender?.value?.enabled === status.DISABLED" class="note">
			{{'senderIds.inactiveSender' | translate}}
		</div>
		<alaris-alert *ngIf="activeSenders(templateForm.controls.channelType.value).length === 0" type="info">
			{{'senderIds.noActiveSenders' | translate}}
		</alaris-alert>

		<app-text-area-editor [channel]="templateForm.controls.channelType.value"
							  [formControl]="templateForm.controls.messageTemplate" data-testid="editor">
		</app-text-area-editor>


		<ng-container *ngIf="templateForm.get('channelType')?.value !== TrafficType.SMS">
			<span class="form-title">{{'templates.image' | translate}}</span>
			<alaris-input [label]="'templates.urlImage' | translate"
						  [placeholder]="'templates.urlImage' | translate"
						  data-testid="urlImageInput" formControlName="imageUrl" type="url">
			</alaris-input>

			<span class="form-title">{{'templates.actionButton' | translate}}</span>
			<alaris-input [label]="'templates.buttonText' | translate"
						  [placeholder]="'templates.buttonText' | translate"
						  data-testid="buttonTextInput" formControlName="buttonText">
			</alaris-input>
			<alaris-input [label]="'templates.actionUrl' | translate"
						  [placeholder]="'templates.actionUrl' | translate"
						  data-testid="actionUrlInput" formControlName="actionUrl">
			</alaris-input>
		</ng-container>
	</div>
	<div class="edit-panel-footer" *alarisPBAC="[CP_PERMISSIONS.MESSAGE_TEMPLATES_E]">
		<button (click)="save()"
				[disabled]="!!(templatesService.loading$ | async) || (templateForm.invalid || !templateForm.dirty)"
				alaris-button bType="primary" data-testid="saveBtn" size="lg"
				type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{'actions.save' | translate}}
		</button>
		<button (click)="close()"
				[disabled]="!!(templatesService.loading$ | async)"
				alaris-button bType="outline" data-testid="closeBtn" size="lg"
				type="button">
			{{'actions.cancel' | translate}}
		</button>

		<button (click)="delete()" *ngIf="template.id" [disabled]="!!(templatesService.loading$ | async) " alaris-button
				bType="outline" data-testid="deleteBtn"
				size="lg"
				style="margin-left: auto" type="button">
			<alaris-icon class="in-outline" name="icon-delete"></alaris-icon>
			{{'actions.delete' | translate}}
		</button>
	</div>
</div>
<div class="phone">
	<app-phone-message [buttonActionUrl]="templateForm.controls.actionUrl.value"
					   [buttonCaption]="templateForm.controls.buttonText.value"
					   [imageUrl]="templateForm.controls.imageUrl.value"
					   [message]="templateForm.controls.messageTemplate.value"
					   [sender]="templateForm.controls.sender.value?.name!"
					   [trafficType]="templateForm.controls.channelType.value">
	</app-phone-message>
</div>
