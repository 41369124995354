import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { AlarisBalanceService, AlarisConfigService, ChartUtilsService } from '@campaign-portal/components-library';

import { ChartsService } from '../charts.service';
import { sumElementsInArray } from '@helpers/utils/math';
import { EdrStatus } from '@campaign-portal/namespace/common/enums';

@Component({
	selector: 'app-last-campaign-chart',
	templateUrl: './last-campaign-chart.component.html',
	styleUrls: ['./last-campaign-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastCampaignChartComponent implements OnInit, OnDestroy {
	activeItemIndex = NaN;
	labels: string[] = [];
	value: number[] = [];
	totalCost = 0;
	totalMessageValue = 0;
	isSeenExist = false;
	readonly loading$ = new BehaviorSubject<boolean>(true);
	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly bs: AlarisBalanceService,
		public readonly alarisConfig: AlarisConfigService,
		private readonly chartsService: ChartsService,
		private readonly cd: ChangeDetectorRef,
		public readonly cu: ChartUtilsService
	) {
	}


	get amount(): number {
		return isNaN(this.activeItemIndex) ? this.totalMessageValue : this.value[this.activeItemIndex];
	}

	get label(): string {
		return isNaN(this.activeItemIndex) ? 'gl.total' : this.labels[this.activeItemIndex];
	}

	isItemActive(index: number): boolean {
		return this.activeItemIndex === index;
	}

	onHover(index: number, hovered: any): void {
		this.activeItemIndex = hovered ? index : 0;
	}

	ngOnInit(): void {
		this.chartsService.lastCampaignChartRead()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(result => {
				this.labels = result.Data.labels.map(label => 'enums.' + label);
				this.isSeenExist = result.Data.labels.includes(EdrStatus.SEEN);
				this.value = result.Data.values;
				this.totalCost = result.Data.totalCost;
				this.totalMessageValue = sumElementsInArray(this.value);
				this.loading$.next(false);
				this.cd.markForCheck();
			});
	}

	makeNaN(): void {
		this.activeItemIndex = NaN;
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
