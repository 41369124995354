<alaris-tag [mode]="template.channelType === TrafficType.SMS ? 'gray-light' : 'viber'"
			class="channel"
			size="md">
	{{template.channelType}}
</alaris-tag>

<div class="template-name">{{template.name}}</div>

<div *ngIf="template.lastUpdated" class="template-date">
	{{('gl.updated' | translate) + ' ' + (template.lastUpdated | date: 'd MMM y, HH:mm')}}
</div>

<button (click)="$event.stopPropagation()"
		[alarisDropdown]="dropdown"
		[attr.data-testid]="'templateOptionsDropdownToggle/' + template.id"
		[positions]="position" class="action__button">
	<alaris-icon name="icon-dot-vertical"></alaris-icon>
</button>
<alaris-dropdown #dropdown>
	<div class="dropdown-container">
		<div (click)="action?.action(template)" *ngFor="let action of actions; let last = last"
			 [attr.data-testid]="'templateAction/' + action.label"
			 [class.dropdown-item_last]="last"
			 class="dropdown-item">
			{{action.label | translate}}
		</div>
	</div>
</alaris-dropdown>
