import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { convertToHTMLFormat } from '@helpers/utils/template-message.serializer';
import { ContactFieldsService } from '../../../contacts/services/contact-fields.service';
import { AlarisFilesService } from '@campaign-portal/components-library';

@Component({
	selector: 'app-phone-message',
	templateUrl: './phone-message.component.html',
	styleUrls: ['./phone-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneMessageComponent implements OnChanges, AfterViewInit, OnDestroy {
	@ViewChild('messageTemplate') readonly messageTemplate!: ElementRef<HTMLDivElement>;

	// main message params
	@Input() trafficType!: TrafficType;
	@Input() sender = '';
	@Input() message = '';
	// omni message params
	@Input() buttonActionUrl?: string;
	@Input() imageUrl?: string;
	@Input() buttonCaption?: string;
	// message from file params
	@Input() file?: FileInfo;

	readonly TrafficType = TrafficType;
	readonly today = new Date();

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private readonly fs: AlarisFilesService,
		private readonly contactFieldsService: ContactFieldsService
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.message && this.messageTemplate ) {
			this.messageTemplate.nativeElement.innerHTML = convertToHTMLFormat(
				this.message,
				this.trafficType,
				this.contactFieldsService.variables$.getValue()
			);
		}
	}

	ngAfterViewInit(): void {
		if ( this.messageTemplate ) {
			this.messageTemplate.nativeElement.innerHTML = convertToHTMLFormat(
				this.message,
				this.trafficType,
				this.contactFieldsService.variables$.getValue()
			);
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	download(): void {
		if ( this.file?.id ) {
			this.fs.export(this.file.id, this.file.name)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe();
		}
	}


}
