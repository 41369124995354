import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';

import { ContactGroupsTableFieldsService } from '../../services/contact-groups-table-fields.service';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple,
	SharedTemplatesService,
	TableSelectionIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { FilterType, RPCRequestParams, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { ContactGroupsService } from '../../services/contact-groups.service';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { ContactsService } from '../../services/contacts.service';
import { ContactGroupActionsComponent } from '../../dialogs/contact-group-actions/contact-group-actions.component';
import { GroupsTableService } from './groups.table.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type ContactGroupsDialogType = 'Delete' | 'addToGroup' | 'moveToGroup';

export interface ContactGroupDialogData {
	groups: ContactGroup<exist> | ContactGroup<exist>[] | TableSelectionIndicator<ContactGroup<exist>>;
	type: ContactGroupsDialogType;
	params?: RPCRequestParams;
}


@Component({
	selector: 'app-contacts-groups-table',
	// eslint-disable-next-line max-len
	templateUrl: '../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./contacts-groups-table.component.scss'
	],
	providers: [
		ContactGroupsTableFieldsService,
		GroupsTableService
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class ContactsGroupsTableComponent extends AlarisComplexTableComponent<ContactGroup> implements OnChanges {

	@Input() createGroup: Record<string, unknown> | undefined;
	@Output() readonly initiateImport = new EventEmitter<void>();

	override readonly checkboxIcon = 'icon-folder';

	override readonly id = 'groups';
	override sorting: TableSortIndicator = new Map()
		.set('creationDate', { enabled: true, value: SortDirection.DESC });

	override rowActions: RowActionSimple<ContactGroup | ContactGroup[]>[] = [
		{
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.edit.bind(this)
		},
		// {
		// 	icon: 'icon-play',
		// 	label: this.lService.translate('campaigns.createCampaign'),
		// 	action: this.sendCampaign.bind(this)
		// },
		{
			icon: 'icon-delete',
			label: 'contacts.actions.deleteGroup',
			// @ts-ignore
			action: this.delete.bind(this),
			disabled: (): boolean => {
				return this.contactGroupsService.list.length === 0;
			},
			highlight: true,
			separator: true
		}
	];
	override mainActions: RowActionSimple<ContactGroup | ContactGroup[]>[] = [
		// {
		// 	icon: 'icon-play',
		// 	label: this.lService.translate('campaigns.createCampaign'),
		// 	action: this.sendCampaign.bind(this)
		// },
		{
			icon: 'icon-export-1',
			label: 'actions.export',
			action: this.export.bind(this)
		}

	];
	override additionalActions: RowActionSimple<ContactGroup | ContactGroup[]>[] = [
		{
			icon: 'icon-arrow-move-list',
			label: 'contacts.actions.moveToGroup',
			// @ts-ignore
			action: this.moveToGroup.bind(this)
		},
		{
			icon: 'icon-copy',
			label: 'contacts.actions.addToGroup',
			// @ts-ignore
			action: this.addToGroup.bind(this)
		},
		{
			icon: 'icon-delete',
			label: 'contacts.actions.deleteGroup',
			// @ts-ignore
			action: this.delete.bind(this),
			disabled: (): boolean => {
				return this.contactGroupsService.list.length === 1;
			}
		}
	];
	override ctxActions: RowActionSimple<ContactGroup | ContactGroup[]>[] = [...this.rowActions].map(a => ({
		...a,
		icon: ''
	}));

	// eslint-disable-next-line @typescript-eslint/member-ordering
	override readonly create = this.edit;

	constructor(
		public override readonly dataService: GroupsTableService,
		public readonly contactGroupsService: ContactGroupsService,
		public override readonly fieldsService: ContactGroupsTableFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		public readonly sharedTemplates: SharedTemplatesService,
		private readonly contactsService: ContactsService,
		private readonly filesService: AlarisFilesService,
		private readonly dialog: AlarisDialogService,
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profileService: AlarisProfileService
	) {
		const enabled = profileService.allowed([CP_PERMISSIONS.CONTACTS_E]);

		super(
			dataService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.GROUPS,
			{
				filters: {
					title: 'gl.filter.filters',
					enabled: true
				},
				settings: {
					title: 'table.settings.tableSettings',
					enabled: false
				},
				refresh: {
					title: 'actions.refreshPage',
					enabled: false
				},
				import: {
					title: 'actions.importContacts',
					enabled
				},
				create: {
					title: 'contacts.addGroup',
					enabled
				}
			},
			stateService
		);
		this.templates = {
			creationDate: this.sharedTemplates.templates.creationDate,
			name: this.sharedTemplates.templates.name,
			description: this.sharedTemplates.templates.description
		};
		this.dataService.stateID = this.id;

		if ( !enabled ) {
			this.removeActions();
		}
	}

	override dblClickRowAction(cGroup?: ContactGroup): void {
		this.edit(cGroup);
	}

	override refresh(): void {
		this.contactGroupsService.refresh$.next();
		this.load();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.createGroup.currentValue ) {
			this.create();
		}
	}

	removeActions(): void {
		this.rowActions = [
			{
				icon: 'icon-password-show',
				label: 'actions.details',
				action: this.edit.bind(this)
			}
		];
		this.dropDownRowActions = [];
		this.mainActions = [];
		this.additionalActions = [];
		this.ctxActions = [];
	}

	edit(item?: ContactGroup | ContactGroup[]): void {
		if ( Array.isArray(item) ) {
			throw new Error('You can edit only one contact group at a time');
		}
		this.editPanel.open(EditGroupComponent, EditPanelWidth.SM, {
			group: item
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(
				(result) => {
					if ( result ) {
						this.load();
					}
				}
			);
	}

	sendCampaign(): void {
	}

	export(): void {
		this.loading$.next(true);
		const params: RPCRequestParams =
			this.selection !== null ? {
				Filters: [
					{
						Field: 'contactGroups',
						Type: FilterType.IN,
						Property: 'id',
						Value: this.selection.map(
							(item) => {
								return item.id;
							}
						)
					}
				]
			} : {};
		this.contactsService.export(null, params).pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(
				(resp) => {
					this.loading$.next(false);
					this.filesService.export(resp.Data[0]?.id, resp.Data[0]?.name)
						.pipe(takeUntilDestroyed(this.destroyRef))
						.subscribe();
				}
			);
	}

	moveToGroup(): void {
		this.openSpecificDialog('moveToGroup', this.selection);
	}

	addToGroup(): void {
		this.openSpecificDialog('addToGroup', this.selection);
	}

	delete(item?: ContactGroup<exist> | ContactGroup<exist>[] | TableSelectionIndicator<ContactGroup>): void {
		if ( item === undefined ) {
			item = this.selection;
		}
		this.openSpecificDialog('Delete', item);
	}

	override import(): void {
		this.initiateImport.emit();
	}

	private openSpecificDialog(
		type: ContactGroupsDialogType,
		item?: ContactGroup | ContactGroup[] | TableSelectionIndicator<ContactGroup>
	): void {
		this.dialog.open(ContactGroupActionsComponent, {
			data: {
				groups: item,
				type,
				params: this.readParams
			},
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.selection = [];
					this.load();
				}
			});
	}
}
