<ng-container *ngTemplateOutlet="templates.get('period');
			context: {
				title: 'dashboard.totalMessages&CostByChannel',
				cu, periodType, range, reduceDayPeriod: true
			}">
</ng-container>

<div [class.dark]="config.themeType === 'dark'" class="content">
	<div [class.sms]="legend[0] === 'dashboard.SMS'"
		 [class.viber]="legend[0] === 'dashboard.VIBER'"
		 class="messages-chart">
		<ng-container *ngTemplateOutlet="templates.get('barChart');
			context: {
				hint, labelsX, labelsY, loading$, maxChartHeight, legend,
				stubChart: stubBarChart,
				value: totalMsgValues,
				collapsed
			}">
		</ng-container>

		<div class="chart-controls">
			<ng-container *ngTemplateOutlet="templates.get('chartVisibility');
					context: {
							configs: chartVisibilityConfig,
							themeType:config.themeType
					}">
			</ng-container>
			<div class="total-cost">
				{{ 'dashboard.totalSent' | translate }} &nbsp;
				<span class="cost">{{ totalMessages }}</span>
			</div>
		</div>
	</div>

	<div class="divider"></div>

	@if ((ch.loading$ | async) === false && ch.list.length > 1) {
		<div [alarisLoading]="loading$"
			 [backdrop]="false"
			 [class.sms]="legend[0] === 'dashboard.SMS'"
			 [class.viber]="legend[0] === 'dashboard.VIBER'"
			 class="cost-chart">
			@if (totalCostValues.length) {
				<tui-ring-chart [(activeItemIndex)]="activeItemIndex"
								[value]="totalCostValues"
								size="l">
					<div class="amount">{{ amount | currency: bs.currencyCode }}</div>
					<div class="secondary">{{ label | translate }}</div>
				</tui-ring-chart>
				<div class="legend">
					@for (label of legend; track label; let index = $index) {
						<tui-legend-item (mouseleave)="makeNaN()"
										 (mouseover)="onHover(index, $event)"
										 [active]="isItemActive(index)"
										 [color]="cu.chartHintColorMapper(label, config.themeType)"
										 [text]="label | translate"
										 class="item"
										 size="s">
							<b>{{ totalCostValues[index] | currency: bs.currencyCode }}</b>
						</tui-legend-item>
					}
				</div>
			} @else {
				<div class="chart">
					<tui-ring-chart size="l" style="margin: auto;">
						<span style="color: var(--as-TextSecondary)">{{ 'gl.notAvailable' | translate }}</span>
					</tui-ring-chart>
					<div class="stub-chart-note">{{ 'dashboard.noData' | translate }}</div>
				</div>
			}
		</div>
	}
</div>

<ng-template #hint let-setIndex>
	<div class="chart-tooltip">
		<div class="date">{{ hintX[setIndex] | date : cu.chartHintFormatMapper(periodType.value) }}</div>
		@for (item of totalMsgValues; track item; let index = $index) {
			<div class="flex">
				<div class="grid four-column">
					<div [style.background]="cu.chartHintColorMapper(legend[index], config.themeType)"
						 class="dot"></div>
					<div>{{ legend[index] | translate }}</div>
					<div>{{ item[setIndex] }}</div>
					<div class="last">{{ totalMsgCostValues[index][setIndex] | currency: bs.currencyCode }}</div>
				</div>
			</div>
		}
	</div>
</ng-template>

<ng-template #stubBarChart>
	<ng-container *ngTemplateOutlet="templates.get('barChartSkeleton')"></ng-container>
	<div class="stub-chart-note">{{ 'dashboard.noData' | translate }}</div>
</ng-template>
