import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MessagePurpose } from '@campaign-portal/namespace/common/enums';
import { TOOLTIP_COMPONENT_DATA } from '@campaign-portal/components-library';

@Component({
	selector: 'app-viber-priority',
	templateUrl: './viber-priority.component.html',
	styleUrls: ['./viber-priority.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViberPriorityComponent {
	readonly data = {
		[MessagePurpose.TRANSACTION]: {
			type: 'campaigns.transactional',
			options: [
				'campaigns.viber.order',
				'campaigns.viber.delivery',
				'campaigns.viber.registration',
				'campaigns.viber.boarding',
				'campaigns.viber.meetings'
			]
		},
		[MessagePurpose.PROMOTION]: {
			type: 'campaigns.promotional',
			options: [
				'campaigns.viber.discounts',
				'campaigns.viber.coupons',
				'campaigns.viber.offers',
				'campaigns.viber.birthday',
				'campaigns.viber.loyalty',
				'campaigns.viber.reminders'
			]
		}
	};

	constructor(
		@Inject(TOOLTIP_COMPONENT_DATA) public readonly purpose: MessagePurpose
	) {
	}

}
