import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlarisLanguageService } from '@campaign-portal/components-library';

@Component({
	selector: 'app-campaign-statistics-chart',
	templateUrl: './campaign-statistics-chart.component.html',
	styleUrls: ['./campaign-statistics-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignStatisticsChartComponent {
	@Input() value: number[] = [1, 2];
	@Input() labels: string[] = ['Test 1', 'Test 2'];
	@Input() totalRecipients = 0;

	activeItemIndex = NaN;

	constructor(
		private readonly langService: AlarisLanguageService
	) {
	}

	get amount(): number {
		return isNaN(this.activeItemIndex) ? this.totalRecipients : this.value[this.activeItemIndex];
	}

	get label(): string {
		return isNaN(this.activeItemIndex) ? this.langService.translate('gl.total') : this.labels[this.activeItemIndex];
	}

	isItemActive(index: number): boolean {
		return this.activeItemIndex === index;
	}

	onHover(index: number, hovered: any): void {
		this.activeItemIndex = hovered ? index : 0;
	}

	getColor(index: number): string {
		return `var(--tui-chart-${index})`;
	}

	makeNaN(): void {
		this.activeItemIndex = NaN;
	}
}
