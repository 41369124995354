import { environment } from '../environments/environment';
import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';

import { ResetComponent } from './auth/reset/reset.component';
import {
	ACCOUNT_SETTINGS_CONFIG,
	ACCOUNT_SETTINGS_SERVICE_INJECTOR,
	AccountSettingsConfig,
	AlarisAccountSettingsService,
	AlarisApiService,
	AlarisAuthService,
	AlarisBalanceService,
	AlarisConfigService,
	AlarisErrorInterceptor,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisLocalStorageService,
	AlarisPaymentMethodsService,
	AlarisProfileService,
	AlarisResponseInterceptor,
	AlarisRpcInterceptor,
	AlarisSignUpService,
	AlarisTableStateService,
	AUTH_SERVICE_INJECTOR,
	BALANCE_SERVICE_INJECTOR,
	CAPTCHA_CONFIG,
	CaptchaConfig,
	CONFIG_SERVICE_INJECTOR,
	DATE_FILTER_END_OF_THE_DAY_INJECTOR,
	English,
	ENVIRONMENT,
	FILES_SERVICE_INJECTOR,
	ICONS_CONFIG,
	LANGUAGE_CONFIG,
	LANGUAGE_SERVICE_INJECTOR,
	LanguageConfig,
	LocaleIdService,
	LOCALSTORAGE_SERVICE_INJECTOR,
	MENU_MQ_SERVICE_INJECTOR,
	MENU_MQ_WIDTH_VALUE,
	MenuSidebarService,
	PAYMENT_METHODS_SERVICE_INJECTOR,
	PROFILE_CONFIG,
	PROFILE_SERVICE_INJECTOR,
	ProfileConfig,
	REPO_SERVICE_INJECTOR,
	Russian,
	SIGN_IN_CONFIG,
	SIGN_IN_SERVICE_INJECTOR,
	SIGN_UP_CONFIG,
	SIGN_UP_SERVICE_INJECTOR,
	SignInConfig,
	SignUpConfig,
	Spain,
	TABLE_STATE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MissingTranslationHandler,
	MissingTranslationHandlerParams,
	TranslateDefaultParser,
	TranslateLoader,
	TranslateModule,
	TranslateParser
} from '@ngx-translate/core';
import { ActivateComponent } from './auth/activate/activate.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SignUpLayoutComponent } from './layouts/sign-up-layout/sign-up-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { BrowserModule } from '@angular/platform-browser';
import { SignInLayoutComponent } from './layouts/sign-in-layout/sign-in-layout.component';
import { GoHomeComponent } from './layouts/go-home.component';
import { StyleComponentsLibsModule } from '@helpers/shared/style-components-libs.module';
import { ChannelPipe, ContactGroupPipe, MessageTemplatePipe, SenderPipe } from '@helpers/repo/repo.pipe';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { LostDataDialogComponent } from '@helpers/shared/lost-data-dialog/lost-data-dialog.component';
import { FilterPipe } from '@helpers/pipes/filter.pipe';
import { RepoService } from '@helpers/repo/repo.service';
import { EmptyTableComponent } from '@helpers/empty-table/empty-table.component';
import { LastCampaignChartComponent } from './pages/dashboard/last-campaign-chart/last-campaign-chart.component';
import { MessageByStatusChartComponent } from './pages/dashboard/message-by-status-chart/message-by-status-chart.component';
import { LinkCardComponent } from './pages/dashboard/overview/link-card/link-card.component';
import { PurchaseCardComponent } from './pages/dashboard/overview/purchase-card/purchase-card.component';
import { ChartTemplatesComponent } from './pages/dashboard/chart-utils/chart-templates.component';
import { OverviewComponent } from './pages/dashboard/overview/overview.component';
import { MessageByChannelChartComponent } from './pages/dashboard/message-by-channel-chart/message-by-channel-chart.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InvoicesComponent } from './pages/finance/invoices/invoices.component';
import { ContactsTemplatesComponent } from './pages/contacts/services/contacts-templates/contacts-templates.component';
import { UpdateTemplateComponent } from './pages/campaigns/message-templates/update-template/update-template.component';
import { EditContactComponent } from './pages/contacts/tabs/contacts-table/edit-contact/edit-contact.component';
import { CampaignFromFileComponent } from './pages/campaigns/campaign-from-file/campaign-from-file.component';
import { CampaignTemplateComponent } from './pages/campaigns/campaign-wizard/campaign-setup/campaign-template/campaign-template.component';
import { SenderRequestComponent } from './pages/campaigns/senders/sender-request/sender-request.component';
import { CampaignTemplateTooltipComponent } from './pages/campaigns/campaign-list/campaigns-templates/template/campaign-template-tooltip/tooltip.component';
import { EmptyPurchaseCardComponent } from './pages/finance/purchase/empty-purchase-card/empty-purchase-card.component';
import { CampaignDetailsComponent } from './pages/campaigns/campaign-list/campaign-details/campaign-details.component';
import { CampaignRecipientsComponent } from './pages/campaigns/campaign-wizard/campaign-recipients/campaign-recipients.component';
import { CampaignsTemplatesComponent } from './pages/campaigns/campaign-list/campaigns-templates/campaigns-templates.component';
import { SendersComponent } from './pages/campaigns/senders/senders.component';
import { ContactsImportComponent } from './pages/contacts/contacts-import/contacts-import.component';
import { PurchaseDialogsComponent } from './pages/finance/purchase/dialogs/dialogs.component';
import { CampaignCompletedComponent } from './pages/campaigns/campaign-wizard/campaign-completed/campaign-completed.component';
import { EdrExportComponent } from './pages/statistics/edr-export/edr-export.component';
import { PurchaseComponent } from './pages/finance/purchase/purchase.component';
import { PackageDetailsComponent } from './pages/finance/purchase/package-details/package-details.component';
import { SenderDialogComponent } from './pages/campaigns/senders/sender-dialog/sender-dialog.component';
import { MessageCounterComponent } from './pages/campaigns/shared/text-area-editor/message-counter/message-counter.component';
import { PlanComponent } from './pages/finance/purchase/tabs/plan/plan.component';
import { ContactsStoplistTableComponent } from './pages/contacts/tabs/contacts-stoplist-table/contacts-stoplist-table.component';
import { TemplateCardComponent } from './pages/campaigns/message-templates/template-card/template-card.component';
import { ContactsTableComponent } from './pages/contacts/tabs/contacts-table/contacts-table.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { ContentEditableDirective } from './pages/campaigns/shared/text-area-editor/content-editable.directive';
import { ContactGroupActionsComponent } from './pages/contacts/dialogs/contact-group-actions/contact-group-actions.component';
import { EdrExportTaskComponent } from './pages/statistics/edr-export-task/edr-export-task.component';
import { MessageTemplatesComponent } from './pages/campaigns/message-templates/message-templates.component';
import { PlanDetailsComponent } from './pages/finance/purchase/plan-details/plan-details.component';
import { RecipientsComponent } from './pages/campaigns/campaign-list/campaigns-templates/recipients/recipients.component';
import { TextAreaEditorComponent } from './pages/campaigns/shared/text-area-editor/text-area-editor.component';
import { ContactActionsComponent } from './pages/contacts/dialogs/contact-actions/contact-actions.component';
import { CampaignSetupComponent } from './pages/campaigns/campaign-wizard/campaign-setup/campaign-setup.component';
import { TemplatesDialogComponent } from './pages/campaigns/shared/dialog/templates-dialog.component';
import { ContactsGroupsTableComponent } from './pages/contacts/tabs/contacts-groups-table/contacts-groups-table.component';
import { CampaignWizardComponent } from './pages/campaigns/campaign-wizard/campaign-wizard.component';
import { CampaignsDialogComponent } from './pages/campaigns/shared/dialog/campaigns-dialog.component';
import { SenderViewComponent } from './pages/campaigns/senders/sender-view/sender-view.component';
import { SubscribedCardComponent } from './pages/finance/purchase/subscribed-card/subscribed-card.component';
import { CampaignStatisticsChartComponent } from './pages/campaigns/campaign-wizard/campaign-statistics-chart/campaign-statistics-chart.component';
import { StatisticsComponent } from './pages/statistics/statistics.component';
import { CampaignListComponent } from './pages/campaigns/campaign-list/campaign-list.component';
import { CampaignComponent } from './pages/campaigns/campaign-list/campaigns-templates/campaign/campaign.component';
import { InvoiceDetailsComponent } from './pages/finance/invoices/invoice-details/invoice-details.component';
import { PurchaseContainerComponent } from './pages/finance/purchase/tabs/purchase-container/purchase-container.component';
import { TemplateComponent } from './pages/campaigns/campaign-list/campaigns-templates/template/template.component';
import { AvatarComponent } from './pages/campaigns/campaign-list/campaigns-templates/campaign/avatar/avatar.component';
import { PackageComponent } from './pages/finance/purchase/tabs/package/package.component';
import { PhoneMessageComponent } from './pages/campaigns/shared/phone-message/phone-message.component';
import { ContactsImportsTableComponent } from './pages/contacts/tabs/contacts-imports-table/contacts-imports-table.component';
import { EdrExportSettingsPanelComponent } from './pages/statistics/edr-export/edr-export-settings-panel/edr-export-settings-panel.component';
import { EditGroupComponent } from './pages/contacts/tabs/contacts-groups-table/edit-group/edit-group.component';
import { CampaignProgressTooltipComponent } from './pages/campaigns/campaign-list/campaigns-templates/campaign-progress-tooltip/campaign-progress-tooltip.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { AllCountriesDialogComponent } from './pages/finance/purchase/dialogs/all-countries-dialog/all-countries-dialog.component';
import { EmptyCampaignCardComponent } from './pages/campaigns/shared/empty-campaign-card/empty-campaign-card.component';
import { EdrExportDetailsPanelComponent } from './pages/statistics/edr-export/edr-export-details-panel/edr-export-details-panel.component';
import { EdrExportTaskDetailsDialogComponent } from './pages/statistics/edr-export-task/task-details-dialog/task-details-dialog.component';
import { PasswordTemplateComponent } from './pages/contacts/services/contacts-templates/password-template/password-template.component';
import { ContactCampaignUsageComponent } from './pages/contacts/dialogs/contact-campaign-usage/contact-campaign-usage.component';
import { ViberPriorityComponent } from '@helpers/components/viber-priority/viber-priority.component';
import { AuthGuardService } from './auth/auth.guard';
import { DropAuthGuardService } from './auth/drop-auth.guard';
import { ActivateGuardService } from './auth/activate/activate.guard';
import { ResetGuardService } from './auth/reset/reset.guard';
import { RoleType } from '@campaign-portal/namespace/common/enums';
import { ApiKeysComponent } from './pages/api/api-keys/api-keys.component';
import { ApiKeyComponent } from './pages/api/api-keys/key/key.component';
import { ApiComponent } from './pages/api/api.component';
import { ApiMethodsComponent } from './pages/api/api-methods/api-methods.component';
import { ApiKeyDialogComponent } from './pages/api/api-keys/dialog/dialog.component';
import { CreateApiKeyComponent } from './pages/api/api-keys/create-key/create-key.component';
import { ApiCallbackDialogComponent } from './pages/api/api-keys/dialog/callback-dialog.component';
import { PackageCountryNetworkListComponent } from './pages/finance/purchase/package-details/package-country-network-list/package-country-network-list.component';

export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: './assets/i18n_lib/app.', suffix: '.json?' + environment.version },
		{ prefix: './assets/i18n/app.', suffix: '.json?' + environment.version }
	]);
}

export class MissingTranslationService implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams): string {
		console.warn(`WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`);
		return params.key;
	}
}

@Injectable()
export class TranslateParserService extends TranslateDefaultParser {

	constructor() {
		super();
	}

	override getValue(target: any, key: string): any {
		const result = super.getValue(target, key);
		return typeof result === 'object' ? key : result;
	}

}

/* End of Translations */

/* forRoot() Configs */

const translateConfig = {
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpBackend]
	},
	missingTranslationHandler: {
		provide: MissingTranslationHandler, useClass: MissingTranslationService
	},
	parser: { provide: TranslateParser, useClass: TranslateParserService },
	// if we set `defaultLanguage` to 'en' right here - we force loading en.json with translations
	// so missing translations will be used from en.file
	defaultLanguage: ''
};

const signInConfig: SignInConfig = {
	url: '',
	signInMethod: 'Auth.SignIn',
	refreshTokenMethod: 'Auth.Refresh',
	resetPasswordMethod: 'Auth.ResetPassword',
	signUpLink: '/sign-up',
	supportEmail: ''
};

const signUpConfig: SignUpConfig = {
	url: '',
	signInLink: '/sign-in',
	termsUrl: '',
	privacyUrl: '',
	supportEmail: '',
	signUpMethod: 'Auth.SignUp',
	registrationFieldsMethod: 'RegistrationFields.Read',
	generatePasswordMethod: 'Users.GeneratePassword'
};

const captchaConfig: CaptchaConfig = {
	baseUrl: 'captcha'
};

const profileConfig: ProfileConfig = {
	profileRoute: '/account-settings',
	termsUrl: '',
	privacyUrl: '',
	supportEmail: ''
};

const accountSettingsConfig: AccountSettingsConfig = {
	resetPasswordMethod: 'Users.ResetPassword',
	userUpdateMethod: 'Users.EditPersonalData',
	partnersReadMethod: 'Partners.Read',
	role: RoleType.CAMPAIGN_USER
};

const languageConfig: LanguageConfig = { languages: [English, Spain], currentLanguage: English };
/* End of forRoot() Configs */

/* App Config */
export function loadConfig(
	appConfig: AlarisConfigService,
	store: AlarisLocalStorageService,
	translate: AlarisLanguageService
): () => Promise<void> {
	return (): Promise<void> => appConfig.load().then(
		() => {
			signInConfig.url = appConfig.api;
			signInConfig.supportEmail = appConfig.supportEmail;

			signUpConfig.url = appConfig.api;
			signUpConfig.termsUrl = appConfig.termsUrl;
			signUpConfig.privacyUrl = appConfig.privacyUrl;
			signUpConfig.supportEmail = appConfig.supportEmail;

			profileConfig.termsUrl = appConfig.termsUrl;
			profileConfig.privacyUrl = appConfig.privacyUrl;
			profileConfig.supportEmail = appConfig.supportEmail;

			languageConfig.languages = appConfig.getLanguages();

			const lang = store.get('lang') ?? 'en';
			translateConfig.defaultLanguage = lang;
			registerLocaleData(localeEn, English.id, localeEnExtra);
			registerLocaleData(localeEs, Spain.id, localeEsExtra);
			registerLocaleData(localeRu, Russian.id, localeRuExtra);
			// https://mcvendrell.medium.com/configuring-ngx-translate-to-load-at-startup-in-angular-1995e7dd6fcc
			translate.set(lang);
		}
	);
}

/* End of App Config */

@NgModule({
	declarations: [
		AppComponent,
		// layouts
		SignInLayoutComponent,
		SignUpLayoutComponent,
		GoHomeComponent,
		MainLayoutComponent,
		// auth
		ActivateComponent,
		ResetComponent,
		// // pages
		EmptyTableComponent,
		// Dashboard
		DashboardComponent,
		OverviewComponent,
		LinkCardComponent,
		PurchaseCardComponent,
		LastCampaignChartComponent,
		MessageByStatusChartComponent,
		MessageByChannelChartComponent,
		ChartTemplatesComponent,
		// Contacts
		ContactsComponent,
		EditContactComponent,
		ContactsTableComponent,
		ContactsGroupsTableComponent,
		ContactsImportsTableComponent,
		ContactsStoplistTableComponent,
		ContactActionsComponent,
		EditGroupComponent,
		ContactsImportComponent,
		ContactGroupActionsComponent,
		ContactsTemplatesComponent,
		PasswordTemplateComponent,
		ContactCampaignUsageComponent,
		// Billing
		PurchaseComponent,
		PackageComponent,
		PlanComponent,
		PurchaseContainerComponent,
		PurchaseDialogsComponent,
		EmptyPurchaseCardComponent,
		SubscribedCardComponent,
		PlanDetailsComponent,
		PackageDetailsComponent,
		PackageCountryNetworkListComponent,
		AllCountriesDialogComponent,
		// Invoice
		InvoicesComponent,
		InvoiceDetailsComponent,
		// Statistics
		StatisticsComponent,
		EdrExportComponent,
		EdrExportDetailsPanelComponent,
		EdrExportSettingsPanelComponent,
		EdrExportTaskDetailsDialogComponent,
		EdrExportTaskComponent,
		// Campaigns
		CampaignsComponent,
		CampaignListComponent,
		CampaignComponent,
		CampaignDetailsComponent,
		AvatarComponent,
		TemplateComponent,
		CampaignTemplateTooltipComponent,
		CampaignProgressTooltipComponent,
		RecipientsComponent,
		CampaignsDialogComponent,
		PhoneMessageComponent,
		CampaignWizardComponent,
		CampaignSetupComponent,
		CampaignRecipientsComponent,
		CampaignStatisticsChartComponent,
		CampaignCompletedComponent,
		CampaignTemplateComponent,
		CampaignFromFileComponent,
		SendersComponent,
		SenderRequestComponent,
		SenderViewComponent,
		SenderDialogComponent,
		MessageTemplatesComponent,
		TemplateCardComponent,
		MessageCounterComponent,
		ContentEditableDirective,
		TextAreaEditorComponent,
		CampaignsTemplatesComponent,
		TemplatesDialogComponent,
		UpdateTemplateComponent,
		EmptyCampaignCardComponent,
		// API
		ApiComponent,
		ApiMethodsComponent,
		ApiKeysComponent,
		ApiKeyComponent,
		ApiKeyDialogComponent,
		CreateApiKeyComponent,
		ApiCallbackDialogComponent,
		// // pipes
		ChannelPipe,
		ContactGroupPipe,
		SenderPipe,
		MessageTemplatePipe,
		FilterPipe,
		// // helpers
		LostDataDialogComponent,
		ViberPriorityComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		NgOptimizedImage,

		PickerModule,
		TranslateModule.forRoot(translateConfig),

		StyleComponentsLibsModule
	],
	providers: [
		AlarisConfigService,
		AlarisLocalStorageService,
		AlarisApiService,
		AlarisAuthService,
		AlarisSignUpService,
		AlarisProfileService,
		AlarisLanguageService,
		AlarisFilesService,
		AlarisBalanceService,
		AlarisPaymentMethodsService,
		AlarisAccountSettingsService,
		AlarisTableStateService,

		{ provide: ENVIRONMENT, useValue: environment },
		{ provide: LOCALE_ID, useClass: LocaleIdService },
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfig,
			deps: [AlarisConfigService, AlarisLocalStorageService, AlarisLanguageService],
			multi: true
		},

		{ provide: CAPTCHA_CONFIG, useValue: captchaConfig },
		{ provide: LANGUAGE_CONFIG, useValue: languageConfig },
		{ provide: PROFILE_CONFIG, useValue: profileConfig },
		{ provide: ICONS_CONFIG, useValue: { baseUrl: '/assets/icons' } },
		{ provide: ACCOUNT_SETTINGS_CONFIG, useValue: accountSettingsConfig },

		{ provide: SIGN_IN_CONFIG, useValue: signInConfig },
		{ provide: SIGN_UP_CONFIG, useValue: signUpConfig },

		{ provide: LOCALSTORAGE_SERVICE_INJECTOR, useExisting: AlarisLocalStorageService },
		{ provide: CONFIG_SERVICE_INJECTOR, useExisting: AlarisConfigService },
		{ provide: REPO_SERVICE_INJECTOR, useExisting: RepoService },
		{ provide: AUTH_SERVICE_INJECTOR, useExisting: AlarisAuthService },
		{ provide: SIGN_IN_SERVICE_INJECTOR, useExisting: AlarisAuthService },
		{ provide: SIGN_UP_SERVICE_INJECTOR, useExisting: AlarisSignUpService },
		{ provide: PROFILE_SERVICE_INJECTOR, useExisting: AlarisProfileService },
		{ provide: LANGUAGE_SERVICE_INJECTOR, useExisting: AlarisLanguageService },
		{ provide: FILES_SERVICE_INJECTOR, useExisting: AlarisFilesService },
		{ provide: BALANCE_SERVICE_INJECTOR, useExisting: AlarisBalanceService },
		{ provide: PAYMENT_METHODS_SERVICE_INJECTOR, useExisting: AlarisPaymentMethodsService },
		{ provide: ACCOUNT_SETTINGS_SERVICE_INJECTOR, useExisting: AlarisAccountSettingsService },
		{ provide: TABLE_STATE_SERVICE_INJECTOR, useExisting: AlarisTableStateService },

		{ provide: HTTP_INTERCEPTORS, useClass: AlarisRpcInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AlarisResponseInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AlarisErrorInterceptor, multi: true },

		{ provide: DATE_FILTER_END_OF_THE_DAY_INJECTOR, useValue: true },
		{ provide: MENU_MQ_SERVICE_INJECTOR, useClass: MenuSidebarService },
		{ provide: MENU_MQ_WIDTH_VALUE, useValue: '(max-width: 1365px)' },

		AuthGuardService,
		DropAuthGuardService,
		CanDeactivateGuardService,
		ActivateGuardService,
		ResetGuardService,
		SenderPipe,
		MessageTemplatePipe
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		console.log(environment.version);
	}
}
