import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { CampaignInfo } from '@campaign-portal/namespace/entities/campaigns/specs';
import { MessageTemplate } from '@campaign-portal/namespace/entities/templates/specs';

import { AlarisDialogService } from '@campaign-portal/components-library';

import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { CPCustomValidators } from '@helpers/validators/custom-validators';
import { MessageTemplatesService } from '../../services/message-templates.service';

export type TemplatesDialogType = 'Delete' | 'Update' | 'CreateFromCampaignWizard';

export interface TemplatesDialogData {
	type: TemplatesDialogType;
	template: MessageTemplate<exist>;
}

@Component({
	selector: 'app-templates-dialog',
	templateUrl: './templates-dialog.component.html',
	styleUrls: ['./shared-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesDialogComponent extends CanDeactivateComponent implements OnInit, OnDestroy {

	template!: MessageTemplate<exist>;
	details: CampaignInfo[] = [];

	readonly templateName = new FormControl('',
		[
			Validators.required,
			CPCustomValidators.hasTemplateDuplicate(null, this.templatesService.list)
		]
	);
	readonly errors = [
		{ key: 'duplicate', value: 'errors.duplicate' }
	];

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: TemplatesDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		public readonly templatesService: MessageTemplatesService,
		private readonly dialog: AlarisDialogService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.template = data.template;
	}

	get disabled(): boolean {
		return this.templatesService.loading$.getValue()
			|| this.data.type === 'CreateFromCampaignWizard' && this.templateName.invalid;
	}

	ngOnInit(): void {
		if ( this.data.type === 'CreateFromCampaignWizard' ) {
			return;
		}
		this.templatesService.checkCampaignUsage(this.template)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(resp) => {
					this.details = resp.Data ?? [];
					this.cd.markForCheck();
				}
			);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = this.templatesService.delete(this.template);
				break;
			case 'Update':
				caller = this.templatesService.update(this.template);
				break;
			case 'CreateFromCampaignWizard':
				const template = { ...this.template, name: this.templateName.value ?? '' };
				caller = this.templatesService.update(template);
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.dialogRef.close(
					this.data.type === 'CreateFromCampaignWizard'
						? (resp.Data as [MessageTemplate<exist>])[0]
						: resp.Success
				);
				this.cd.markForCheck();
			}
		);
	}
}
