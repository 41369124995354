import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Package, Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';

import { AlarisProgressLineType, ChannelUtilsService } from '@campaign-portal/components-library';

import { PurchaseService } from '../purchase.service';

@Component({
	selector: 'app-subscribed-card',
	templateUrl: './subscribed-card.component.html',
	styleUrls: ['./subscribed-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscribedCardComponent implements OnChanges {

	@Input() packs: Package[] | null = null;
	@Input() plan: Plan | null = null;

	rest = 0;
	total = 0;

	constructor(
		public readonly cu: ChannelUtilsService,
		private readonly purchaseService: PurchaseService
	) {
	}

	get progressType(): AlarisProgressLineType {
		return PurchaseService.packUsedState(this.rest, this.total);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.packs && this.packs !== null ) {
			const [used, total, locked] = PurchaseService.calcProgress(this.packs);
			this.total = total;
			this.rest = total - used - locked;
		}
	}

	seeAllCountries(clickEvent: MouseEvent, plan: Plan): void {
		clickEvent.stopPropagation();
		this.purchaseService.openSpecificHintDialog('AllCountries', plan);
	}
}
