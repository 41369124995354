<div class="purchase">
	<fieldset class="welcome">
		<div *alarisLet="(profile.user$ | async) as user">
			{{'gl.hi' | translate}},
			{{
			user?.details?.firstName
				? user?.details?.lastName
					? user?.details?.firstName + ' ' + user?.details?.lastName
					: user?.details?.firstName
				: user?.login || user?.email
			}}!
		</div>
		<div class="welcome-text">{{'dashboard.welcome' | translate}}</div>
	</fieldset>

	<div *alarisPBAC="[CP_PERMISSIONS.SUBSCR_R]"
		 [alarisLoading]="loading$"
		 [backdrop]="false" class="card-zone">
		<div class="title">
			<div *ngIf="packs.size && !plans.length">
				{{'dashboard.yourPack' | translate}}
			</div>
			<div *ngIf="!packs.size && plans.length">
				{{'dashboard.yourPlan' | translate}}
			</div>
			<div *ngIf="packs.size && plans.length || !packs.size && !plans.length">
				{{'dashboard.yourPlan&Pack' | translate}}
			</div>
			<alaris-tag *ngIf="plans.length+packs.size > 3"
						[rounded]="true"
						class="card-zone-title"
						mode="white"
						size="lg">
				+ {{plans.length + packs.size - 3}}
			</alaris-tag>
		</div>
		<div class="cards">
			<ng-container *ngIf="packs.size || plans.length; else stub">
				<ng-container *ngFor="let packId of packs.keys(); let index = index">
					<app-purchase-card *ngIf="index<=2" [packId]="packId"></app-purchase-card>
				</ng-container>
				<ng-container *ngFor="let plan of plans; let index = index">
					<app-purchase-card *ngIf="index + packs.size <= 2" [plan]="plan"></app-purchase-card>
				</ng-container>
			</ng-container>
			<div *alarisPBAC="[CP_PERMISSIONS.SUBSCR_E]" (click)="addPurchase()"
				 class="new-purchase" data-testid="addNew">
				+ {{'dashboard.addNew' | translate}}
			</div>
		</div>
	</div>
</div>

<div class="links">
	<app-link-card *alarisPBAC="[CP_PERMISSIONS.CAMPAIGNS_E]" type="Campaigns"></app-link-card>
	<app-link-card *alarisPBAC="[CP_PERMISSIONS.CONTACTS_R, CP_PERMISSIONS.CONTACTS_E]" type="Contacts"></app-link-card>
	<app-link-card *alarisPBAC="[CP_PERMISSIONS.SENDERS_R, CP_PERMISSIONS.SENDERS_E]" type="SenderId"></app-link-card>
</div>

<ng-template #stub>
	<div class="no-purchase">
		{{'dashboard.noPurchase' | translate}}
	</div>
</ng-template>
