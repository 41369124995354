import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisConfigService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import { CreateResponse, ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { Edr, EdrExport, EdrExportRequest } from '@campaign-portal/namespace/entities/edr/specs';
import { getStatusColor } from '@helpers/utils/status';

@Injectable({
	providedIn: 'root'
})
export class EdrExportService implements AbstractCRUDService {
	readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		public readonly config: AlarisConfigService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.edr');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.edr');
	}

	delete(): Observable<undefined> {
		return of(undefined);
	}

	update(): Observable<undefined> {
		return of(undefined);
	}

	create(params?: EdrExportRequest): Observable<CreateResponse<EdrExport>> {
		const notify = (response: CreateResponse<EdrExport>): void => {
			const message = this.langService.translate('notifications.actions.exportEdr');
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<EdrExport>>(
			'EdrExport.Create', params, this.loading$, this.errorNotifier, notify
		);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Edr[]>> {
		return this.api.loader<ReadResponse<Edr[]>>('Edr.Read', params, this.loading$, this.errorNotifier);
	}

	getStatusColor(edr: Edr): string {
		return getStatusColor(edr.edrStatus, this.config.themeType);
	}

// 	getStatusColor(edr: Edr): TagMode {
// 		const map: { [key in EdrStatus]: TagMode } = {
// 			[EdrStatus.DELIVERED]: 'success',
// 			[EdrStatus.SENT]: 'gray',
// 			[EdrStatus.UNDELIVERED]: 'info',
// 			[EdrStatus.FAILED]: 'error',
// 			[EdrStatus.SEEN]: 'viber'
// 		};
// 		return map[edr.edrStatus];
// 	}
}
