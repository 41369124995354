<div [alarisLoading]="apiService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-create-api-key"></alaris-icon>
		{{ 'api.create' | translate }}
	</div>
	<div [formGroup]="form" alarisFade="vertical" class="edit-panel-content flex-box">
		<alaris-input [autofocus]="true"
					  [errors]="errors"
					  [label]="'columns.name' | translate"
					  [placeholder]="'columns.name' | translate"
					  [required]="true"
					  data-testid="nameInput" formControlName="name">
		</alaris-input>
		<alaris-input [errors]="errors"
					  [label]="'columns.callbackURL' | translate"
					  [placeholder]="'columns.callbackURL' | translate"
					  data-testid="callbackUrlInput"
					  formControlName="callbackURL" type="url">
		</alaris-input>

		<alaris-select2 [displayWith]="displayPeriod.bind(this)"
						[label]="'api.expirationDate' | translate"
						[placeholder]="'api.expirationDate' | translate"
						data-testid="expirationDateSelect" formControlName="expirationDate">
			@for (opt of periods | keyvalue : originalOrder; track $index) {
				<alaris-options2 [attr.data-testid]="'expirationDateOption/' + opt.key"
								 [value]="opt.key">
					{{ 'api.periods.' + opt.value | translate }}
				</alaris-options2>
			}
		</alaris-select2>

		@if (form.controls.expirationDate.value === 0) {
			<alaris-alert type="warning">{{ 'api.expirationNote' | translate }}</alaris-alert>
		} @else {
			<span class="secondary">
				{{ 'api.expireOn' | translate }}
				{{ daysToExpiration(form.controls.expirationDate.value) | date: 'd MMM y, HH:mm' }}
			</span>
		}
	</div>

	<div class="edit-panel-footer">
		<button (click)="save()"
				[disabled]="form.invalid || !form.dirty || ((apiService.loading$ | async) === true)"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ 'actions.create' | translate }}
		</button>

		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ 'actions.cancel' | translate }}
		</button>
	</div>
</div>
