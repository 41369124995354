export const EMOJI_I18N_KEYS = {
	search: '',
	emojilist: '',
	notfound: '',
	clear: '',
	categories: {
		search: '',
		recent: '',
		people: '',
		nature: '',
		foods: '',
		activity: '',
		places: '',
		objects: '',
		symbols: '',
		flags: '',
		custom: ''
	},
	skintones: {
		1: '',
		2: '',
		3: '',
		4: '',
		5: '',
		6: ''
	}
};

export const EMOJI_CATEGORIES = Object.keys(EMOJI_I18N_KEYS.categories).filter(category => {
	return category !== 'flags';
});
