import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { AlarisEditPanelService, ChannelUtilsService, EditPanelWidth } from '@campaign-portal/components-library';
import { Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';

import { PlanDetailsComponent } from '../../plan-details/plan-details.component';
import { PurchaseView } from '../purchase-container/purchase-container.component';
import { PurchaseService } from '../../purchase.service';

@Component({
	selector: 'app-plan',
	templateUrl: './plan.component.html',
	styleUrls: [
		'../package/package.component.scss',
		'./plan.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanComponent implements OnDestroy {
	@Input() view: PurchaseView = PurchaseView.GRID;
	@Input() plan!: Plan;

	readonly PurchaseView = PurchaseView;

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly editPanel: AlarisEditPanelService,
		public readonly cu: ChannelUtilsService,
		private readonly purchaseService: PurchaseService
	) {
	}

	@HostBinding('class.w-100') get w100(): boolean {
		return this.view === PurchaseView.LIST;
	}

	openPlanDetailsPanel(plan: Plan): void {
		this.editPanel.open(PlanDetailsComponent, EditPanelWidth.MD, {
			plan
		});
	}

	selectPlan(plan: Plan): void {
		this.purchaseService.openSpecificDialog('SelectPlan', { plan })
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe();
	}

	seeAllCountries(plan: Plan): void {
		this.purchaseService.openSpecificHintDialog('AllCountries', plan);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

}
