<alaris-dialog [closeAction]="close.bind(this)" [confirmButtonText]="''">

	<h3 slot="header">{{ 'columns.callbackURL' | translate }}</h3>
	<div slot="content">
		<alaris-card class="callback text-wrap" size="auto">
			{{ data.callbackURL }}
			<alaris-copy [data]="data.callbackURL ?? ''"></alaris-copy>
		</alaris-card>
	</div>

</alaris-dialog>
