<alaris-card size="auto">

	<div class="row space-between">
		<div class="row">
			<h6 *ngIf="filteredPacks.length" class="header">
				{{filteredPacks.length}} {{'purchase.packs' | translate}}
			</h6>
			<h6 *ngIf="filteredPlans.length" class="header">
				{{filteredPlans.length}} {{ 'purchase.plans' | translate }}
			</h6>

			<alaris-input [formControl]="nameControl"
						  [label]="'purchase.searchByName' | translate"
						  [placeholder]="'purchase.searchByName' | translate"
						  [stateAddonEnabled]="{reset: true, state: false}"
						  data-testid="searchByNameInput" type="text">
				<alaris-icon extra name="icon-search"></alaris-icon>
			</alaris-input>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="xl">
				<alaris-icon name="icon-filter-vertical"></alaris-icon>
				{{ 'gl.filter.filters' | translate}}
				<div *ngIf="hasFilters" class="indicator"></div>
			</button>

			<alaris-select2 [displayWith]="displayWith.bind(this)"
							[formControl]="sortControl" data-testid="sortingSelect">
				<alaris-options2 *ngFor="let mode of sortingModes"
								 [value]="mode" data-testid="sortingOption">
					<div class="option-with-icon">
						{{ mode.name | translate }}
						<alaris-icon [name]="mode.Dir === SortDirection.ASC ? 'icon-arrow-up' : 'icon-arrow-down'">
						</alaris-icon>
					</div>
				</alaris-options2>
				<div class="sorting-label" extra-label>
					<alaris-icon name="icon-table-sort"></alaris-icon>
					{{ 'gl.sorting' | translate }}
				</div>
			</alaris-select2>
			<div class="view">
				{{ 'gl.view' | translate }}
				<alaris-tabs-button [formControl]="viewModeControl"
									[tabs]="viewModes"
									data-testid="viewModesTabs" size="md">
				</alaris-tabs-button>
			</div>
		</div>

	</div>

	<div [alarisFade]="'vertical'" class="container">
		<ng-container *ngIf="packages.length > 0">
			<ng-container *ngFor="let pack of filteredPacks">
				<app-package [package]="pack" [view]="viewModeControl.value"></app-package>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="plans.length > 0">
			<ng-container *ngFor="let plan of filteredPlans">
				<app-plan [plan]="plan" [view]="viewModeControl.value"></app-plan>
			</ng-container>
		</ng-container>
	</div>
</alaris-card>
