<ng-template #channels let-data="data" let-key="key">
	<alaris-icon *ngFor="let ch of data[key]"
				 [name]="cu.icon(ch)">
	</alaris-icon>
</ng-template>

<ng-template #contactGroups let-data="data" let-key="key">
	<div class="contact-groups-tags">
		@for (group of data[key].slice(0, 2); track group.id) {
			<alaris-tag class="text-truncate" size="md">
				{{ (group | cGroup)?.name ?? '' | translate }}
			</alaris-tag>
		} @empty {
			{{ 'contacts.stopList' | translate }}
		}
		@if (data[key].length > 2) {
			<div class="more">
				+ {{ data[key].length - 2 }} {{ 'gl.more' | translate }}
			</div>
		}
	</div>
</ng-template>

<ng-template #status let-data="data" let-key="key">
	<!--	[hovered]="selectedCheck(history)"-->
	<alaris-tag
		[mode]="statusMode(data[key])"
		[rounded]="true">
		{{ 'enums.' + data[key] | translate }}
	</alaris-tag>
</ng-template>

<ng-template #user let-data="data" let-key="key">
	{{ data[key] }}
</ng-template>

<ng-template #creationDate let-data="data" let-key="key">
	{{ data[key] | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #lastUpdated let-data="data" let-key="key">
	{{ data[key] | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #file let-data="data" let-key="key">
	{{ data[key].name }}
</ng-template>

<ng-template #name let-data="data" let-key="key">
	<alaris-link (click)="changeGroup.emit(data.id)" [attr.data-testid]="'groupLink/' + data.id">
		{{ data[key] | translate }}
	</alaris-link>
</ng-template>

<ng-template #description let-data="data" let-key="key">
	{{ data[key] | translate }}
</ng-template>

<!-- DEFAULT TEMPLATES start -->

<ng-template #general let-data="data" let-key="key">
	{{ data[key] || '' }}
</ng-template>

<ng-template #defaultPassword let-data="data" let-key="key">
	<app-password-template [password]="data[key]"></app-password-template>
</ng-template>

<ng-template #defaultUrl let-data="data" let-key="key">
	<alaris-link [href]="data[key]" data-testid="link" type="secondary">
		{{ data[key] }}
	</alaris-link>
</ng-template>

<ng-template #defaultSelect let-data="data" let-key="key">
	{{
		data[key]
			? data[key].value ?? ''
			: ''
	}}
</ng-template>

<ng-template #defaultMultiSelect let-data="data" let-key="key">
	<ng-container *ngIf="data[key] === null">
		{{ 'gl.all' | translate }}
	</ng-container>
	<ng-container *ngIf="isArray(data[key])">
		<div class="multiselect-options">
			<span class="text-truncate">
				{{ map(data[key].slice(0, 2)).join(', ') }}
			</span>
			<alaris-tag *ngIf="data[key].length > 2"
						[rounded]="true"
						style="transform: scale(.75)">
				+{{ data[key].length - 2 }}
			</alaris-tag>
		</div>
	</ng-container>
</ng-template>

<ng-template #defaultDate let-data="data" let-key="key">
	{{ data[key] | date: 'd MMM y' }}
</ng-template>

<ng-template #defaultRange [alarisCellCtx]="rangeCtx" let-date="data" let-key="key">
	@if (date[key]) {
		{{
			(date[key]?.Start ?? ('calendar.before' | translate))
			+ '-'
			+ (date[key]?.End ?? ('calendar.after' | translate))
		}}
	}
</ng-template>

<ng-template #defaultDateRange [alarisCellCtx]="dateRangeCtx" let-date="data" let-key="key">
	@if (date[key]) {
		{{
			((date[key]?.Start | date: 'd MMM y, HH:mm') ?? ('calendar.before' | translate))
			+ ' - '
			+ ((date[key]?.End | date: 'd MMM y, HH:mm') ?? ('calendar.after' | translate))
		}}
	}
</ng-template>

<ng-template #defaultCheckbox let-data="data" let-key="key">
	{{
		(data[key] === true
			? 'gl.yes'
			: data[key] === false
				? 'gl.no'
				: '') | translate
	}}
</ng-template>

<!-- DEFAULT TEMPLATES end -->
