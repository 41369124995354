import { Injectable, OnDestroy } from '@angular/core';
import {
	AlarisTableStateService,
	filterWildcardFn,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';
import { exist } from '@campaign-portal/namespace/common/id';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { ContactGroupsService } from '../../services/contact-groups.service';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
export class GroupsTableService extends LocalTableService<ContactGroup<exist>> implements OnDestroy {

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public override readonly service: ContactGroupsService,
		stateService: AlarisTableStateService
	) {
		super(service, stateService);
		this.service.refresh$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.firstLoad = true;
		});
	}

	applyFilters(
		result: ReadResponse<ContactGroup<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<ContactGroup<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'contactsIncluded':
						data = LocalTableUtils.filterRange(
							filter,
							data as unknown as Record<string, number>[]
						) as unknown as ContactGroup<exist>[];
						break;
					case 'name':
						data = data.filter((item) => {
							return filterWildcardFn(item.name.toLowerCase(), filter.Value as string);
						});
						break;
					case 'creationDate':
						data = LocalTableUtils.filterDateRange(
							filter,
							data as unknown as Record<string, string>[]
						) as unknown as ContactGroup<exist>[];
						break;
					case 'description':
					default:
						data = data.filter((item) => {
							return filterWildcardFn(
								(item as Record<string, any>)[filter.Field],
								filter.Value as string
							);
						});
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	applySorting(
		result: ReadResponse<ContactGroup<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<ContactGroup<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'name':
							first = a.name;
							second = b.name;
							break;
						case 'description':
							first = a.description;
							second = b.description;
							break;
						case 'contactsIncluded':
							first = a.contactsIncluded;
							second = b.contactsIncluded;
							break;
						case 'creationDate':
							first = a.creationDate ? new Date(a.creationDate) : 0;
							second = b.creationDate ? new Date(b.creationDate) : 0;
							break;
						default:
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

}
