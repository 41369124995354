<div [class.completed]="campaign.info.status === CampaignStatus.COMPLETED"
	 [class.failed]="campaign.info.status === CampaignStatus.FAILED"
	 [class.in-progress]="campaign.info.status === CampaignStatus.IN_PROGRESS"
	 [style.background]="campaign.info.photo ? 'transparent url(' + campaign.info.photo + ') no-repeat center center': 'inherit'"
	 class="container">
	<div *ngIf="campaign.info.status === CampaignStatus.IN_PROGRESS" class="spinner"></div>
	<alaris-icon [name]="'campaign-'+ campaign.info.status"
				 [style.color]="campaign.info.photo ? 'var(--as-BgSecondary)' : 'var(--as-TextPrimary)'"
				 class="icon">
	</alaris-icon>
</div>
