import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import {
	ChartCostMessagePerChannels,
	ChartLastCampaignByStatus,
	ChartMessagesByStatus
} from '@campaign-portal/namespace/entities/charts/specs';
import { AlarisApiService, AlarisLanguageService, ErrorNotifierConfig } from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class ChartsService {

	readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.chart');
	}

	lastCampaignChartRead(params?: RPCRequestParams): Observable<ReadResponse<ChartLastCampaignByStatus>> {
		return this.api.loader<ReadResponse<ChartLastCampaignByStatus>>(
			'Charts.LastCampaign', params, this.loading$, this.errorNotifier
		);
	}

	messagesByStatusChartRead(params?: RPCRequestParams): Observable<ReadResponse<ChartMessagesByStatus>> {
		return this.api.loader<ReadResponse<ChartMessagesByStatus>>(
			'Charts.MessagesByStatus', params, this.loading$, this.errorNotifier
		);
	}

	costMessagePerChannelsChartRead(params?: RPCRequestParams): Observable<ReadResponse<ChartCostMessagePerChannels>> {
		return this.api.loader<ReadResponse<ChartCostMessagePerChannels>>(
			'Charts.CostMessagePerChannels', params, this.loading$, this.errorNotifier
		);
	}

}
