<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [disabled]="disabled"
			   [loading$]="loading$">
	<h3 [ngSwitch]="data.type" slot="header">
		<ng-container *ngSwitchCase="'Unsubscribe'">{{"senderIds.unsubscribeRequestTitle" | translate}}</ng-container>
		<ng-container *ngSwitchCase="'Cancel'">{{"senderIds.cancelRequestTitle" | translate}}</ng-container>
	</h3>
	<div class="content" slot="content">
		<div [ngSwitch]="data.type">
			<ng-container *ngSwitchCase="'Unsubscribe'">
				{{ "senderIds.unsubscribeRequestText" | translate }}
				<b>{{ senderId.name }}</b>?
				<p></p>
				<alaris-text-area [formControl]="comment" data-testid="commentTextarea">
					{{ "gl.comment" | translate }}
				</alaris-text-area>
			</ng-container>
			<ng-container *ngSwitchCase="'Cancel'">
				{{ "senderIds.cancelRequestText" | translate }}
				<b>{{ senderId.name }}</b>?
			</ng-container>
		</div>
	</div>
</alaris-dialog>
