<ng-container *ngIf="packs.length > 0">
	<div class="detail">
		<alaris-icon [name]="cu.icon(packs[0].trafficType)"></alaris-icon>
		<fieldset class="title">
			<div>{{ cu.name(packs[0].trafficType) | translate}}</div>
			<div class="name">{{'purchase.pack' | translate}}</div>
		</fieldset>
	</div>
	<div *ngIf="packs.length > 1" class="name">({{packs.length}} {{'purchase.packs' | translate}})</div>

	<alaris-progress-line [content]="true"
						  [percentageComplete]="rest * 100 / total"
						  [type]="progressType(rest, total)">
		<div class="progress">
			{{rest}} <span class="label">{{'gl.of' | translate}} {{total}}</span>
		</div>
	</alaris-progress-line>
</ng-container>

<ng-container *ngIf="plan">
	<div class="detail">
		<alaris-icon [name]="cu.icon(plan.trafficType)"></alaris-icon>
		<fieldset>
			<b>{{ cu.name(plan.trafficType) | translate}}</b><br>
			<span class="name">{{'purchase.plan' | translate}}</span>
		</fieldset>
	</div>

	<div class="country">
		{{'purchase.country' | translate}}
		<div class="country-amount">{{plan.countries!.length}}</div>
	</div>
</ng-container>
