import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import {
	AddToGroupRequest,
	AddToGroupResponse,
	Contact,
	ContactsCheckCampaignUsageRequest,
	ContactsCheckCampaignUsageResponse,
	ContactsExportRequest,
	ContactsExportResponse,
	ContactsImportMergeType,
	ContactsImportResponse,
	ContactsImportStopListAction,
	MoveToGroupRequest,
	MoveToGroupResponse,
	MoveToStopListRequest,
	MoveToStopListResponse,
	RemoveFromGroupRequest,
	RemoveFromGroupResponse,
	RemoveFromStopListRequest,
	RemoveFromStopListResponse
} from '@campaign-portal/namespace/entities/contacts/specs';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { FileParsedColumn } from '@campaign-portal/namespace/entities/files/specs';

import {
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

// type ContactsMethods =
// 	'Contacts.Create' |
// 	'Contacts.Read' |
// 	'Contacts.Update' |
// 	'Contacts.Delete' |
// 	'Contacts.CheckCampaignUsage' |
// 	'Contacts.Export' |
// 	'Contacts.AddToGroup' |
// 	'Contacts.MoveToGroup' |
// 	'Contacts.RemoveFromGroup' |
// 	'Contacts.MoveToStopList' |
// 	'Contacts.RemoveFromStopList' |
// 	'Contacts.Import';

@Injectable({
	providedIn: 'root'
})

export class ContactsService {
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.contact');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.contact');
	}

	update(contact: Contact): Observable<CreateResponse<Contact>> {
		const notify = (response: CreateResponse<Contact>): void => {
			const message = this.langService.translate(
				contact.id ? 'notifications.actions.update' : 'notifications.actions.create', {
					entity: this.entity, name: ''
				});
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};

		return this.api.loader(
			'Contacts.' + (contact.id === null ? 'Create' : 'Update'),
			{ Data: { Entities: [contact] } }, this.loading$, this.errorNotifier, notify
		);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Contact<exist>[]>> {
		return this.api.loader<ReadResponse<Contact<exist>[]>>('Contacts.Read', params, this.loading$);
	}

	delete(contact: Contact<exist> | Contact<exist>[] | null): Observable<DeleteResponse<Contact>> {
		const params: DeleteRequest<Contact<exist>> = {
			Data: {
				Ids: Array.isArray(contact) && contact !== null ?
					contact.map(c => c.id) :
					contact !== null ? [contact.id] : contact
			}
		};
		const notify = (response: DeleteResponse<Contact>): void => {
			const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Contact>>(
			'Contacts.Delete', params, this.loading$, this.errorNotifier, notify
		);
	}

	export(
		contact: Contact<exist> | Contact<exist>[] | null,
		params: RPCRequestParams = {}
	): Observable<ContactsExportResponse> {
		const _params: ContactsExportRequest = {
			...params,
			Data: {
				Ids: Array.isArray(contact) && contact !== null ?
					contact.map(item => item.id) :
					contact !== null ? [contact.id] : contact
			}
		};
		const notify = (response: ContactsExportResponse): void => {
			const message = this.langService.translate('notifications.actions.export', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<ContactsExportResponse>(
			'Contacts.Export', _params, this.loading$, this.errorNotifier, notify
		);
	}

	import(
		fileId: string,
		columns: FileParsedColumn[],
		mergeType: ContactsImportMergeType,
		stopListAction: ContactsImportStopListAction,
		groups: Id<exist>[]
	): Observable<ContactsImportResponse> {
		const params = {
			Data: {
				Entities: [{
					fileId,
					columns,
					mergeType,
					stopListAction,
					groups
				}]
			}
		};
		const notify = (response: ContactsImportResponse): void => {
			const message = this.langService.translate('notifications.actions.importContacts');
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<ContactsImportResponse>(
			'Contacts.Import', params, this.loading$, this.errorNotifier, notify
		);
	}

	checkCampaignUsage(
		contact: Contact<exist> | Contact<exist>[] | null,
		params: RPCRequestParams = {}
	): Observable<ContactsCheckCampaignUsageResponse> {
		const _params: ContactsCheckCampaignUsageRequest = {
			Data: {
				Ids: Array.isArray(contact)
					? contact.map(item => item.id)
					: contact !== null
						? [contact.id]
						: null
			},
			Filters: contact === null ? params.Filters : []
		};
		return this.api.loader<ContactsCheckCampaignUsageResponse>(
			'Contacts.CheckCampaignUsage', _params, this.loading$, this.errorNotifier
		);
	}

	addToGroup(
		contact: Contact<exist> | Contact<exist>[] | null,
		group: ContactGroup<exist>
	): Observable<AddToGroupResponse> {
		const params: AddToGroupRequest = {
			Data: {
				Ids: Array.isArray(contact) && contact !== null ?
					contact.map(item => item.id) :
					contact !== null ? [contact.id] : contact,
				Group: group.id
			}
		};
		const notify = (response: AddToGroupResponse): void => {
			const message = this.langService.translate('notifications.actions.addToGroup', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<AddToGroupResponse>(
			'Contacts.AddToGroup', params, this.loading$, this.errorNotifier, notify
		);
	}

	moveToGroup(
		contact: Contact<exist> | Contact<exist>[] | null,
		group: ContactGroup<exist>
	): Observable<MoveToGroupResponse> {
		const params: MoveToGroupRequest = {
			Data: {
				Ids: Array.isArray(contact) && contact !== null ?
					contact.map(item => item.id) :
					contact !== null ? [contact.id] : contact,
				Group: group.id
			}
		};
		const notify = (response: MoveToGroupResponse): void => {
			const message = this.langService.translate('notifications.actions.moveToGroup', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<MoveToGroupResponse>(
			'Contacts.MoveToGroup', params, this.loading$, this.errorNotifier, notify
		);
	}

	removeFromGroup(
		contact: Contact<exist> | Contact<exist>[] | null,
		group: ContactGroup<exist>
	): Observable<RemoveFromGroupResponse> {
		const params: RemoveFromGroupRequest = {
			Data: {
				Ids: Array.isArray(contact) && contact !== null ?
					contact.map(item => item.id) :
					contact !== null ? [contact.id] : contact,
				Group: group.id
			}
		};
		const notify = (response: RemoveFromGroupResponse): void => {
			const message = this.langService.translate(
				'notifications.actions.removeFromGroup', { entity: this.entity }
			);
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<RemoveFromGroupResponse>(
			'Contacts.RemoveFromGroup', params, this.loading$, this.errorNotifier, notify
		);
	}

	moveToStopList(
		contact: Contact<exist> | Contact<exist>[] | null
	): Observable<MoveToStopListResponse> {
		const params: MoveToStopListRequest = {
			Data: {
				Ids: Array.isArray(contact) && contact !== null ?
					contact.map(item => item.id) :
					contact !== null ? [contact.id] : contact
			}
		};
		const notify = (response: MoveToStopListResponse): void => {
			const message = this.langService.translate('notifications.actions.moveToStopList', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<MoveToStopListResponse>(
			'Contacts.MoveToStopList', params, this.loading$, this.errorNotifier, notify
		);
	}

	removeFromStopList(
		contact: Contact<exist> | Contact<exist>[] | null,
		group: ContactGroup<exist>
	): Observable<RemoveFromStopListResponse> {
		const params: RemoveFromStopListRequest = {
			Data: {
				Ids: Array.isArray(contact) && contact !== null ?
					contact.map(item => item.id) :
					contact !== null ? [contact.id] : contact,
				Group: group.id
			}
		};
		const notify = (response: RemoveFromStopListResponse): void => {
			const message = this.langService.translate(
				'notifications.actions.removeFromStopList', { entity: this.entity }
			);
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<RemoveFromStopListResponse>(
			'Contacts.RemoveFromStopList', params, this.loading$, this.errorNotifier, notify
		);
	}
}
