import { TrafficType } from '@campaign-portal/namespace/common/enums';

class HTMLtoChannelMessageSerializer {
	private message = '';

	constructor(message: string) {
		this.message = message;
	}

	get value(): string {
		return this.message;
	}

	removeUnbreakableSpaces(): HTMLtoChannelMessageSerializer {
		this.message = this.message
			.replace(/&nbsp;/g, ' ')
			.replace(/\u00A0/g, ' ')
			.replace(/ /g, ' ');
		return this;
	}

	// Todo: do correct
	removeLineSpaces(): HTMLtoChannelMessageSerializer {
		this.message = this.message
			.replace(/<div>/g, '')
			.replace(/<\/div>/g, '');
		return this;
	}

	removeVariableStyling(): HTMLtoChannelMessageSerializer {
		this.message = this.message
			.replace(/<span class="variable" contenteditable="false">/g, '%')
			.replace(/<span class="delete"><\/span><\/span>/g, '%');
		return this;
	}

	removeVariables(variableList: string[]): HTMLtoChannelMessageSerializer {
		variableList.forEach(variable => {
			this.message = this.message.replace(
				new RegExp(`<span class="variable" contenteditable="false">${variable}<span class="delete"><\/span><\/span>`, 'g'), ''
			);
		});
		return this;
	}

	changeFontDecorationTag(replaceSymbol?: string): HTMLtoChannelMessageSerializer {
		this.message = this.message
			.replace(/<b[^>]*>/g, replaceSymbol || '*')
			.replace(/<i[^>]*>/g, replaceSymbol || '_')
			.replace(/<strike[^>]*>/g, replaceSymbol || '~')
			.replace(/<\/b>/g, replaceSymbol || '*')
			.replace(/<\/i>/g, replaceSymbol || '_')
			.replace(/<\/strike>/g, replaceSymbol || '~');
		return this;
	}
}

class ChannelMessageToHTMLtoSerializer {
	private message = '';

	constructor(message: string) {
		this.message = message;
	}

	get value(): string {
		return this.message;
	}

	addVariableStyling(variableList: string[]): ChannelMessageToHTMLtoSerializer {
		variableList.forEach(variable => {
			this.message = this.message.replace(
				new RegExp(`%${variable}%`, 'g'),
				`<span class="variable" contenteditable="false">${variable}<span class="delete"><\/span><\/span>`);
		});
		// this.message = this.message.replace(
		// 	/%(.*?)%/g,
		// 	'<span class="variable" contenteditable="false">$1<span class="delete"><\/span><\/span>');
		return this;
	}

	addUnbreakableSpaces(): ChannelMessageToHTMLtoSerializer {
		this.message = this.message
			.replace(/  /g, '&nbsp; ');
		return this;
	}

	changeFontDecorationTag(viberSymbol: string, htmlTag: string): ChannelMessageToHTMLtoSerializer {
		const regex = new RegExp(`${viberSymbol}(.*?)${viberSymbol}`, 'g');
		this.message = this.message.replace(regex, `<${htmlTag}>$1<\/${htmlTag}>`);
		return this;
	}
}

export function convertToChannelMessageFormat(message: string, channel: TrafficType): string {
	const channelTemplateMessage = new HTMLtoChannelMessageSerializer(message)
		.removeVariableStyling()
		.removeUnbreakableSpaces()
		.removeLineSpaces();

	if ( channel !== TrafficType.SMS ) {
		channelTemplateMessage
			.changeFontDecorationTag();
	}

	return channelTemplateMessage.value;
}

export function convertToHTMLFormat(message: string, channel: TrafficType, variableList: string[]): string {
	const channelTemplateMessage = new ChannelMessageToHTMLtoSerializer(message)
		.addVariableStyling(variableList)
		.addUnbreakableSpaces();

	if ( channel !== TrafficType.SMS ) {
		channelTemplateMessage
			.changeFontDecorationTag('\\*', 'b')
			.changeFontDecorationTag('_', 'i')
			.changeFontDecorationTag('~', 'strike');
	}
	return channelTemplateMessage.value;
}

export function convertToMessageCounterFormat(message: string, channel: TrafficType, variableList: string[]): string {
	const channelTemplateMessage = new HTMLtoChannelMessageSerializer(message)
		.removeVariables(variableList)
		.removeUnbreakableSpaces()
		.removeLineSpaces();

	if ( channel !== TrafficType.SMS ) {
		channelTemplateMessage
			.changeFontDecorationTag('');
	}

	return channelTemplateMessage.value;
}
