import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { Package, Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { FilterInterval, FilterType, SortDirection, Sorting } from '@campaign-portal/namespace/common/rpc.params';
import { EntityField, InputComplexType } from '@campaign-portal/namespace/common/entityField';
import { DecisionMode, TrafficType } from '@campaign-portal/namespace/common/enums';

import {
	AlarisEditPanelService,
	AlarisFilterManagerComponent,
	AlarisLanguageService,
	EditPanelWidth,
	TableEntityField,
	TableFiltersIndicator
} from '@campaign-portal/components-library';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';

enum FilterVariables {
	NAME = 'name',
	COUNTRIES = 'countries',
	CHANNELS = 'trafficType',
	TOTAL = 'messagesTotal',
	COST = 'packPrice',
	BILLING_MODE = 'billingMode'
}

const countries = new TableEntityField({
	data: [],
	filterType: FilterType.IN,
	id: null,
	name: 'columns.countries',
	required: false,
	type: InputComplexType.MULTISELECT,
	variable: FilterVariables.COUNTRIES
});
const channels = new TableEntityField({
	data: [],
	filterType: FilterType.IN,
	id: null,
	name: 'columns.trafficType',
	required: false,
	type: InputComplexType.MULTISELECT,
	variable: FilterVariables.CHANNELS
});
const messages = new TableEntityField({
	data: [],
	filterType: FilterType.BETWEEN,
	id: null,
	name: 'columns.messagesTotal',
	required: false,
	type: InputComplexType.RANGE,
	variable: FilterVariables.TOTAL
});
const packPrice = new TableEntityField({
	data: [],
	filterType: FilterType.BETWEEN,
	id: null,
	name: 'columns.packPrice',
	required: false,
	type: InputComplexType.RANGE,
	variable: FilterVariables.COST
});
const billingMode = new TableEntityField({
	data: [],
	filterType: FilterType.IN,
	id: null,
	name: 'columns.billingMode',
	required: false,
	type: InputComplexType.MULTISELECT,
	variable: FilterVariables.BILLING_MODE
});

interface SortType extends Sorting {
	name: string;
}

export enum PurchaseView {
	'GRID' = 'GRID',
	'LIST' = 'LIST'
}

@Component({
	selector: 'app-purchase-container',
	templateUrl: './purchase-container.component.html',
	styleUrls: ['./purchase-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PurchaseContainerComponent implements OnInit, OnChanges, OnDestroy {
	@Input() packages: Package[] = [];
	@Input() plans: Plan[] = [];

	public filteredPacks: Package[] = [];
	public filteredPlans: Plan[] = [];

	readonly viewModes = [
		{
			label: '',
			value: PurchaseView.LIST,
			icon: 'icon-view-list'
		},
		{
			label: '',
			value: PurchaseView.GRID,
			icon: 'icon-view-grid-1'
		}
	];
	readonly sortingModes: SortType[] = [
		{
			name: 'purchase.sorting.name',
			Field: 'name',
			Dir: SortDirection.DESC
		},
		{
			name: 'purchase.sorting.name',
			Field: 'name',
			Dir: SortDirection.ASC
		}
	];

	readonly nameControl = new FormControl('', { nonNullable: true });
	readonly sortControl = new FormControl<SortType | null>(null);
	readonly filtersChange: EventEmitter<TableFiltersIndicator> = new EventEmitter<TableFiltersIndicator>();
	readonly viewModeControl = new FormControl<PurchaseView>(PurchaseView.GRID, { nonNullable: true });
	protected readonly SortDirection = SortDirection;
	private filters: TableFiltersIndicator = new Map()
		.set('name', { enabled: true, filterType: FilterType.LIKE })
		.set(FilterVariables.COUNTRIES, { enabled: true, value: [], filterType: FilterType.IN })
		.set(FilterVariables.CHANNELS, { enabled: true, value: [], filterType: FilterType.IN })
		.set(FilterVariables.TOTAL, { enabled: true, filterType: FilterType.BETWEEN })
		.set(FilterVariables.COST, { enabled: true, filterType: FilterType.BETWEEN })
		.set(FilterVariables.BILLING_MODE, { enabled: true, value: [], filterType: FilterType.IN });
	private headers: EntityField[] = [];
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly editPanel: AlarisEditPanelService,
		private readonly cd: ChangeDetectorRef,
		private readonly lService: AlarisLanguageService
	) {
	}

	get hasFilters(): boolean {
		return Object.values(FilterVariables).reduce((result, variable) => {
			let notNull;
			let notUndefined;
			let notStart;
			let notEnd;
			let isEmptyArray;

			if ( this.filters.get(variable) ) {
				const value = this.filters.get(variable)?.value;
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				switch (this.filters.get(variable)!.filterType) {
					case FilterType.BETWEEN:
						notNull = value !== null;
						notUndefined = value !== undefined;
						if ( variable === FilterVariables.COST ) {
							notStart = (value as FilterInterval<number>)?.Start !== packPrice.data?.at(0);
							notEnd = (value as FilterInterval<number>)?.End !== packPrice.data?.at(1);
						}
						if ( variable === FilterVariables.TOTAL ) {
							notStart = (value as FilterInterval<number>)?.Start !== messages.data?.at(0);
							notEnd = (value as FilterInterval<number>)?.End !== messages.data?.at(1);
						}

						return result = result
							|| !(
								// undefined
								(notNull && !notUndefined)
								// null
								|| (!notNull && notUndefined)
								// has value but Start and End are edge values
								|| (!!this.filters.get(variable)?.value && (!notStart && !notEnd))
							);

					case FilterType.IN:
						notNull = value !== null;
						isEmptyArray = Array.isArray(value) && (value as unknown[]).length === 0;
						return result = result || !notNull || (notNull && !isEmptyArray);

					default:
						return result = result || !!this.filters.get(variable)?.value;
				}
			} else {
				return result;
			}
		}, false);
	}

	displayWith: (value: SortType) => string = (value) => {
		if ( value ) {
			return this.lService.translate(value.name) + ' ' + `${value.Dir === SortDirection.ASC ? `↑` : `↓`}`;
		}
		return '';
	};

	ngOnInit(): void {
		billingMode.data = Object.values(DecisionMode).map((mode) => {
			return {
				value: mode,
				name: this.lService.translate(`enums.${mode}`)
			};
		});

		this.sortControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.applySort();
			});
		this.nameControl.valueChanges
			.pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
			.subscribe((name) => {
				const nameFilter = this.filters.get('name');
				if ( nameFilter ) {
					nameFilter.value = name;
				}
				this.applyFilter();
			});
		this.filtersChange
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((filters) => {
				this.filters = new Map([...this.filters, ...filters]);
				this.applyFilter();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.packages ) {
			const ref = this.packages.reduce((result, pack) => {
				(pack.countries ?? []).forEach((country) => result.countries.add(country));
				result.channels.add(pack.trafficType);
				result.messagesTotal.add(pack.messagesTotal);
				result.packPrice.add(pack.packSettings.packPrice); // / pack.messagesTotal);
				return result;
			}, {
				countries: new Set<string>(),
				channels: new Set<TrafficType>(),
				messagesTotal: new Set<number>(),
				packPrice: new Set<number>()
			});
			countries.data = Array.from(ref.countries).map((country) => {
				return { value: country };
			});
			channels.data = Array.from(ref.channels).map((trafficType) => {
				const name = trafficType === TrafficType.SMS ? 'SMS' : 'Viber';
				return { value: name, trafficType };
			});
			messages.data = [
				Math.min.apply(this, Array.from(ref.messagesTotal)),
				Math.max.apply(this, Array.from(ref.messagesTotal))
			];
			packPrice.data = [
				Math.min.apply(this, Array.from(ref.packPrice)),
				Math.max.apply(this, Array.from(ref.packPrice))
			];

			this.headers = [countries, channels, billingMode, messages, packPrice];
			this.filteredPacks = changes.packages.currentValue;

			this.sortingModes.push(
				{
					name: this.lService.translate('purchase.sorting.priceHighToLow'),
					Field: 'packPrice',
					Dir: SortDirection.DESC
				},
				{
					name: this.lService.translate('purchase.sorting.priceLowToHigh'),
					Field: 'packPrice',
					Dir: SortDirection.ASC
				});
		}
		if ( changes.plans ) {
			const ref = this.plans.reduce((result, plan) => {
				(plan.countries ?? []).forEach((country) => result.countries.add(country));
				result.channels.add(plan.trafficType);
				return result;
			}, {
				countries: new Set<string>(),
				channels: new Set<TrafficType>()
			});
			countries.data = Array.from(ref.countries).map((country) => {
				return { value: country };
			});
			channels.data = Array.from(ref.channels).map((trafficType) => {
				const name = trafficType === TrafficType.SMS ? 'SMS' : 'Viber';
				return { value: name, trafficType };
			});

			this.headers = [countries, channels, billingMode];

			this.filteredPlans = changes.plans.currentValue;
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	editTableFilter(): void {
		this.editPanel.open(AlarisFilterManagerComponent, EditPanelWidth.MD, {
			filtersChange: this.filtersChange,
			filters: this.filters,
			tHeaders: this.headers
		});
	}

	applyFilter(): void {
		this.filteredPacks = [...this.packages];
		this.filteredPlans = [...this.plans];
		this.filters.forEach(
			(filter, variable) => {
				const value = filter?.value;
				switch (true) {
					case (!filter?.enabled):
					case (!filter?.value):
					case (Array.isArray(filter?.value) && filter?.value.length === 0):
						return;
					default:
						switch (variable) {
							case FilterVariables.NAME:
								this.filteredPacks = this.filteredPacks.filter((pack) => {
									return pack.name.toLowerCase().includes((value as string).toLowerCase());
								});
								this.filteredPlans = this.filteredPlans.filter(plan => {
									return plan.name.toLowerCase().includes((value as string).toLowerCase());
								});
								break;
							case FilterVariables.COUNTRIES:
								this.filteredPacks = this.filteredPacks.filter((pack) => {
									return (filter?.value as ValueObject[])
										.some(f => (pack.countries ?? []).includes(f.value));
								});
								this.filteredPlans = this.filteredPlans.filter(plan => {
									return (filter?.value as ValueObject[])
										.some(f => (plan.countries ?? []).includes(f.value));
								});
								break;
							case FilterVariables.CHANNELS:
								this.filteredPacks = this.filteredPacks.filter((pack) => {
									return (value as { value: string; trafficType: TrafficType }[])
										.map(f => f.trafficType)
										.includes(pack.trafficType);
								});
								this.filteredPlans = this.filteredPlans.filter((plan) => {
									return (value as { value: string; trafficType: TrafficType }[])
										.map(f => f.trafficType)
										.includes(plan.trafficType);
								});
								break;
							case FilterVariables.BILLING_MODE:
								this.filteredPacks = this.filteredPacks.filter((pack) => {
									return (value as ValueObject[])
										.map(f => f.value)
										.includes(pack.billingMode);
								});
								this.filteredPlans = this.filteredPlans.filter((plan) => {
									return (value as ValueObject[])
										.map(f => f.value)
										.includes(plan.billingMode);
								});
								break;
							case FilterVariables.TOTAL:
								this.filteredPacks = this.filteredPacks.filter((pack) => {
									const { Start, End } = filter?.value as FilterInterval<number>;
									return pack.messagesTotal >= Start && pack.messagesTotal <= End;
								});
								break;
							case FilterVariables.COST:
								this.filteredPacks = this.filteredPacks.filter((pack) => {
									const { Start, End } = filter?.value as FilterInterval<number>;
									return pack.packSettings.packPrice >= Start && pack.packSettings.packPrice <= End;
								});
								break;
							default:
								return;
						}

				}
			}
		);
		this.cd.detectChanges();
	}

	applySort(): void {
		if ( !this.sortControl.value ) {
			return;
		}
		const { Field, Dir } = this.sortControl.value;
		switch (Field) {
			case 'name':
				this.filteredPlans = this.filteredPlans.sort((a, b) => {
					return Dir === SortDirection.DESC ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
				});
				this.filteredPacks = this.filteredPacks.sort((a, b) => {
					return Dir === SortDirection.DESC ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
				});
				break;
			case 'packPrice':
				this.filteredPacks = this.filteredPacks.sort((a, b) => {
					return Dir === SortDirection.DESC ?
						b.packSettings.packPrice - a.packSettings.packPrice :
						a.packSettings.packPrice - b.packSettings.packPrice;
				});
				break;
		}
	}
}
