import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { AlarisDialogService } from '@campaign-portal/components-library';

import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { creating } from '@campaign-portal/namespace/common/id';
import { TrafficType } from '@campaign-portal/namespace/common/enums';

import { ChannelsService } from '@helpers/services/channels.service';
import { FormCanDeactivateComponent } from '@helpers/shared/can-deactivate/form-deactivate';
import { CampaignWizardService } from './campaign-wizard.service';
import { CampaignsDialogComponent } from '../shared/dialog/campaigns-dialog.component';

enum CampaignSteps {
	SETUP,
	RECIPIENTS,
	COMPLETE
}


@Component({
	selector: 'app-campaign-wizard',
	templateUrl: './campaign-wizard.component.html',
	styleUrls: ['./campaign-wizard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignWizardComponent extends FormCanDeactivateComponent implements AfterViewInit, OnDestroy {

	@ViewChild('input') readonly input!: ElementRef<HTMLInputElement>;
	hasFocus = true;
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly cw: CampaignWizardService,
		private readonly dialog: AlarisDialogService,
		private readonly router: Router,
		public readonly channelsService: ChannelsService
	) {
		super();
		if ( !this.cw.form ) {
			this.cw.create();
		}
	}

	get form(): FormGroup {
		return this.cw.form;
	}

	get stepSetupCompleted(): boolean {
		const length = this.cw.channelsArray.length > 0;
		const valid = this.cw.channelsArray
			.reduce((result, settings) => {
				result = result && settings.valid;
				return result;
			}, true);
		return length && valid;
	}

	get stepRecipientsCompleted(): boolean {
		const [
			plain, groups, file
		] = [
			this.cw.contactsPlain.value,
			this.cw.contactGroups.value,
			this.cw.contactsFile.value
		];
		const [plainvalid, groupsvalid, filevalid] = [
			this.cw.contactsPlain.valid,
			this.cw.contactGroups.valid,
			this.cw.contactsFile.valid
		];
		return (
			(
				(plain.text && plainvalid) ||
				(file && filevalid) ||
				((groups && groups.length > 0 && groupsvalid) || groups === null)
			)
		);
	}

	get stepCompleteCompleted(): boolean {
		return this.stepSetupCompleted &&
			this.stepRecipientsCompleted &&
			((this.cw.form.controls.settings.enabled
					&& this.cw.form.controls.settings.valid)
				|| this.cw.form.controls.settings.disabled) &&
			Boolean(this.cw.totalRecipients);
	}

	get bannerIllustration(): string {
		if ( this.channelsService.list.length > 1 ) {
			return 'cascade.png';
		}
		switch (this.channelsService.list.at(0)?.channelType) {
			case TrafficType.SMS:
				return 'sms.png';
			case TrafficType.VIBER:
				return 'viber.png';
			default:
				return 'cascade.png';
		}
	}

	ngAfterViewInit(): void {
		this.input.nativeElement.focus();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.cw.reset();
	}

	nextStepDisabled(stepIndex: CampaignSteps): boolean {
		switch (stepIndex) {
			case CampaignSteps.SETUP:
				return !this.stepSetupCompleted;
			case CampaignSteps.RECIPIENTS:
				return !this.stepRecipientsCompleted;
			case CampaignSteps.COMPLETE:
				return !this.stepSetupCompleted;
			default:
				return true;
		}
	}

	previousStepDisabled(stepIndex: CampaignSteps): boolean {
		switch (stepIndex) {
			case CampaignSteps.SETUP:
				return true;
			case CampaignSteps.RECIPIENTS:
				return !this.stepSetupCompleted;
			case CampaignSteps.COMPLETE:
				return !this.stepRecipientsCompleted;
			default:
				return true;
		}
	}

	createCampaign(): void {
		const campaign: Campaign<creating> = this.cw.prepare();
		this.dialog.open(CampaignsDialogComponent, {
			data: {
				campaign,
				total: this.cw.totalRecipients,
				type: 'Captcha'
			},
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					this.cw.reset();
					this.router.navigate(['campaigns', 'list']).then();
				}
			});

	}
}
