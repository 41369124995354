import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import {
	TransactionHistory,
	TransactionHistoryCampaignDetail,
	TransactionHistoryCampaignDetailRequest,
	TransactionHistoryExportRequest,
	TransactionHistoryExportResponse
} from '@campaign-portal/namespace/entities/transaction-history/specs';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';


@Injectable({
	providedIn: 'root'
})
export class InvoicesService implements AbstractCRUDService {
	readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService,
		private readonly alarisToaster: AlarisToasterService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.transaction');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.transaction');
	}

	create(): Observable<void> {
		return of(undefined);
	}

	update(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<TransactionHistory[]>> {
		return this.api.loader<ReadResponse<TransactionHistory[]>>(
			'TransactionHistory.Read', params, this.loading$, this.errorNotifier
		);
	}

	details(
		params?: TransactionHistoryCampaignDetailRequest
	): Observable<ReadResponse<TransactionHistoryCampaignDetail[]>> {
		return this.api.loader<ReadResponse<TransactionHistoryCampaignDetail[]>>(
			'TransactionHistory.CampaignDetails', params, this.loading$, this.errorNotifier
		);
	}

	export(params: TransactionHistoryExportRequest): Observable<TransactionHistoryExportResponse> {
		return this.api.loader<TransactionHistoryExportResponse>(
			'TransactionHistory.Export',
			params,
			this.loading$,
			this.errorNotifier,
			(response: TransactionHistoryExportResponse) => {
				const message = this.langService.translate('notifications.actions.export', { entity: this.entity });
				if ( response.Success ) {
					this.alarisToaster.success(message, this.title);
				}
			}
		);
	}

}
