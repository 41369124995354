import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { EnabledDisabledStatus, MessagePurpose, TrafficType } from '@campaign-portal/namespace/common/enums';
import { MessageTemplate } from '@campaign-portal/namespace/entities/templates/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';

import { filterWildcardData } from '@campaign-portal/components-library';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { ViberPriorityComponent } from '@helpers/components/viber-priority/viber-priority.component';
import { CampaignChannelSettingsControls } from '../../../shared/types/form-types';
import { CampaignWizardService } from '../../campaign-wizard.service';
import { SendersService } from '../../../services/senders.service';


@Component({
	selector: 'app-campaign-template [channelForm]',
	templateUrl: './campaign-template.component.html',
	styleUrls: ['./campaign-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignTemplateComponent implements OnChanges, OnInit, OnDestroy {
	@Input() trafficType!: TrafficType;
	@Input() channelForm!: FormGroup<CampaignChannelSettingsControls>;
	@Input() templates: MessageTemplate<exist>[] = [];
	@Input() senders: SenderSubscription<exist>[] = [];
	readonly tooltipPriorityComponent = ViberPriorityComponent;
	readonly filterSendersControl = new FormControl('');
	filterSendersList: SenderSubscription<exist>[] = [];
	readonly filterTemplatesControl = new FormControl('');
	filterTemplatesList: MessageTemplate<exist>[] = [];
	readonly priority = new FormControl<MessagePurpose>(MessagePurpose.TRANSACTION, { nonNullable: true });

	readonly TrafficType = TrafficType;
	readonly MessagePurpose = MessagePurpose;
	readonly CP_PERMISSIONS = CP_PERMISSIONS;

	readonly senderErrors = [
		{ key: 'inactive', value: 'senderIds.inactiveSender' }
	];
	readonly ttlErrors: { key: string; value: string }[] = [
		{ key: 'min', value: 'errors.minTtl' },
		{ key: 'max', value: 'errors.maxTtl' },
		{ key: 'required', value: 'errors.required' }
	];

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly cw: CampaignWizardService,
		private readonly cd: ChangeDetectorRef,
		private readonly senderService: SendersService
	) {
	}

	get selectedTemplate(): MessageTemplate<exist> | null {
		const templateId = this.channelForm.controls.message!.controls.templateID.value;
		return this.templates.find((template) => {
			return template.id === templateId;
		}) ?? null;
	}

	get selectedSender(): SenderSubscription<exist> | null {
		const senderId = this.channelForm.controls.sender.value;
		return this.senders.find((sender) => {
			return sender.id === senderId;
		}) ?? null;
	}

	get urlImageControl(): FormControl<string | undefined> {
		return this.channelForm.controls.omni?.controls.viberSettings?.controls.imageUrl ?? new FormControl();
	}

	get buttonTextControl(): FormControl<string | undefined> {
		return this.channelForm.controls.omni?.controls.viberSettings?.controls.buttonCaption ?? new FormControl();
	}

	get actionUrlControl(): FormControl<string | undefined> {
		return this.channelForm.controls.omni?.controls.viberSettings?.controls.buttonActionUrl ?? new FormControl();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.senders ) {
			this.filterSendersList = [...this.senders];
		}
		if ( changes.templates ) {
			this.filterTemplatesList = [...this.templates];
		}
	}

	ngOnInit(): void {
		this.priority.setValue(
			this.channelForm.controls?.omni?.controls?.viberSettings?.controls.messagePurpose?.value
			?? MessagePurpose.TRANSACTION,
			{ emitEvent: false }
		);

		this.filterTemplatesControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterTemplatesList = filterWildcardData(value, this.templates, 'name');
			});

		this.filterSendersControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterSendersList = filterWildcardData(value, this.senders, 'name');
			});

		this.cw.form.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.cd.markForCheck();
			});

		this.channelForm.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.cw.form.markAsDirty();
			});

		this.channelForm.controls.message?.controls.templateID.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.applyTemplate();
			});

		this.channelForm.controls.message?.controls.text.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((message) => {
				if ( message !== this.selectedTemplate?.messageTemplate ) {
					this.channelForm.controls.message!.controls.templateID.reset(null);
				}
			});

		this.channelForm.controls.sender.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.applySender();
			});

		this.priority.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.channelForm.controls.omni?.controls.viberSettings?.controls.messagePurpose.patchValue(
					value, { emitEvent: false }
				);
			});

		this.channelForm.controls.omni?.controls.viberSettings?.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(({ buttonActionUrl, buttonCaption, imageUrl }) => {
				const promotional = !!(buttonActionUrl || buttonCaption || imageUrl);
				if ( promotional ) {
					this.updateViberSettingsValidators(true);
					this.priority.patchValue(MessagePurpose.PROMOTION);
					this.priority.disable();
				} else {
					this.updateViberSettingsValidators(false);
					this.priority.patchValue(MessagePurpose.TRANSACTION);
					this.priority.enable();
				}
				if (
					imageUrl !== this.selectedTemplate?.imageUrl ||
					buttonCaption !== this.selectedTemplate?.buttonText ||
					buttonActionUrl !== this.selectedTemplate?.actionUrl
				) {
					this.channelForm.controls.message!.controls.templateID.reset(null);
				}
			});

		this.applyTemplate();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	displayTemplate(): string {
		return this.selectedTemplate?.name ?? '';
	}

	displaySender(): string {
		return this.selectedSender?.name ?? '';
	}

	private updateViberSettingsValidators(promotional: boolean): void {
		[this.actionUrlControl, this.buttonTextControl].forEach(control => {
			if ( promotional ) {
				control.addValidators(Validators.required);
			} else {
				control.removeValidators(Validators.required);
			}
			control.updateValueAndValidity({ onlySelf: true });
		});
	}

	private applySender(): void {
		const sender = this.selectedSender;
		if ( sender !== null ) {
			if ( sender.id !== this.selectedTemplate?.sender?.id ) {
				this.channelForm.controls.message!.controls.templateID.reset(null);
			}
		}
	}

	private applyTemplate(): void {
		const template = this.selectedTemplate;
		if ( template !== null ) {
			const sender = template.sender?.id && this.senderService.map.get(template.sender?.id);
			if ( sender && sender.enabled === EnabledDisabledStatus.DISABLED ) {
				this.channelForm.controls.sender.patchValue(null);
				this.channelForm.controls.sender.setErrors({ inactive: true });
			}

			if ( sender && sender.enabled === EnabledDisabledStatus.ENABLED ) {
				this.channelForm.controls.sender.patchValue(sender.id);
				this.channelForm.controls.sender.setErrors(null);
			}

			this.channelForm.controls.message!.controls.text.patchValue(template.messageTemplate);

			if ( this.trafficType !== TrafficType.SMS ) {
				this.channelForm.controls.omni?.controls.viberSettings?.patchValue({
					imageUrl: template.imageUrl,
					buttonActionUrl: template.actionUrl,
					buttonCaption: template.buttonText
				});
			}
		}
	}
}
