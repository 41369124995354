<div [alarisLoading]="contactsService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-add-contact"></alaris-icon>
		<span>
			{{
				contact.id ?
					('contacts.contact' | translate) + ': ' +
					(contact.firstName ?? '') + ' ' +
					(contact.lastName ?? '') :
					('contacts.addNewContact'| translate)
			}}
		</span>
	</div>
	<div alarisFade="vertical" class="edit-panel-content">
		<div [formGroup]="reservedFieldsForm" class="flex-box">

			<div *ngIf="contact.id" class="date-box">
				<div class="date-box__item">
					{{ 'contacts.creationDate' | translate }}:
					{{ contact.creationDate | date: 'd MMM y, HH:mm' }}
				</div>
				<div *ngIf="contact.lastUpdated" class="date-box__item">
					{{ 'gl.lastUpdated' | translate | uppercase }}:
					{{ contact.lastUpdated | date: 'd MMM y, HH:mm' }}
				</div>
			</div>

			<!--			<span class="flex-box__title">Reserved Fields</span>-->
			<alaris-input [errors]="errors"
						  [label]="'contacts.phone' | translate"
						  [placeholder]="'contacts.phone' | translate"
						  class="flex-box__item-full" data-testid="phoneInput"
						  formControlName="phone">
			</alaris-input>
			<alaris-multi-select2 *ngIf="!contact.stopList"
								  [displayWith]="displayGroup.bind(this)"
								  [formControl]="reservedFieldsForm.controls.contactGroups"
								  [label]="'contacts.groups' | translate"
								  [optionsCount]="((contactGroupsService.list$ | async) ?? []).length"
								  [placeholder]="'contacts.selectContactGroup' | translate"
								  [selectedLabel]="'name'"
								  class="flex-box__item-full" data-testid="groupsMultiSelect"
								  selectedLabel="name">
				<alaris-select-filter2 *ngIf="((contactGroupsService.list$ | async) ?? []).length > 5"
									   [formControl]="filterGroupControl" data-testid="groupsFilter">
				</alaris-select-filter2>
				<alaris-multi-options2 *ngFor="let cg of filterGroupList"
									   [attr.data-testid]="'groupsOption/' + cg.name" [value]="cg.id">
					{{ cg.name | translate }}
				</alaris-multi-options2>
			</alaris-multi-select2>

			<alaris-link (click)="openHintDialog()"
						 *ngIf="contactInfo.length || groupsInfo.length"
						 data-testid="campaignUsageLink" type="secondary">
				{{ 'contacts.showCampaignsUsage' | translate }}
			</alaris-link>
		</div>

		<div *ngIf="customFields" [formGroup]="customFieldsForm" class="flex-box">
			<!--			<span class="flex-box__title">Custom Fields</span>-->
			<ng-container *ngFor="let field of customFields">
				<ng-container *ngIf="!field.readonly">
					<ng-container [ngSwitch]="field.type">
						<ng-container *ngSwitchCase="InputComplexType.DATE">
							<alaris-input-date [attr.data-testid]="'inputDate/' + field.id"
											   [formControl]="customFieldsForm.get(field.variable) | as: 'formControl'"
											   [label]="field.name | translate"
											   [toSystemDate]
											   class="flex-box__item-compact">
							</alaris-input-date>
						</ng-container>
						<ng-container *ngSwitchCase="InputType.NUMBER">
							<alaris-input-number
								[attr.data-testid]="'inputNumber/' + field.id"
								[formControl]="customFieldsForm.get(field.variable) | as: 'formControl'"
								[label]="field.name | translate"
								class="flex-box__item-compact">
							</alaris-input-number>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<alaris-filter-control
								[class.flex-box__item-compact]="field.type !== InputComplexType.TEXTAREA &&
													field.type !== InputComplexType.RANGE &&
													field.type !== InputComplexType.DATE_RANGE"
								[class.flex-box__item-full]="field.type === InputComplexType.TEXTAREA ||
													field.type === InputComplexType.RANGE ||
													field.type === InputComplexType.DATE_RANGE"
								[control]="customFieldsForm.get(field.variable) | as: 'formControl'"
								[field]="field">
							</alaris-filter-control>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div class="edit-panel-footer" *alarisPBAC="[CP_PERMISSIONS.CONTACTS_E]">
		<button (click)="save()"
				[disabled]="reservedFieldsForm.invalid || customFieldsForm.invalid ||
							!(reservedFieldsForm.dirty || customFieldsForm.dirty)"
				alaris-button bType="primary" data-testid="saveBtn"
				size="lg"
				type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (contact.id ? 'actions.save' : 'actions.create') | translate }}
		</button>

		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ 'actions.cancel' | translate }}
		</button>
	</div>
</div>

