import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-password-template',
	templateUrl: './password-template.component.html',
	styleUrls: ['./password-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordTemplateComponent implements OnChanges {
	@Input() password = '';
	readonly fc = new FormControl('');

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.password ) {
			this.fc.setValue(this.password);
		}
	}

}
