<ng-container *ngIf="trafficType !== TrafficType.SMS && cw.setupControls.channels.length > 1">
	<div class="ttl">
		<div class="note text-wrap">
			{{ 'campaigns.ttlNote' | translate }}
		</div>
		<alaris-input-number [allowNegativeNumbers]="false"
							 [errors]="ttlErrors"
							 [formControl]="channelForm.controls.omni!.controls.ttl!"
							 [max]="1440"
							 [min]="15"
							 data-testid="ttlInputNumber" size="md">
			<div class="ttl-extra" extra>
				<small>{{ 'gl.sec' | translate }}</small>
				<alaris-icon name="icon-timer"></alaris-icon>
			</div>
		</alaris-input-number>
	</div>
</ng-container>

<alaris-select2 *alarisPBAC="[CP_PERMISSIONS.MESSAGE_TEMPLATES_R]"
				[displayWith]="displayTemplate.bind(this)"
				[formControl]="this.channelForm.controls.message!.controls.templateID"
				[label]="'templates.useTemplate' | translate"
				[placeholder]="'gl.notSelected' | translate"
				[required]="false"
				data-testid="templateSelect" selectedLabel="name">
	<alaris-select-filter2 *ngIf="templates.length > 5"
						   [formControl]="filterTemplatesControl" data-testid="templateFilter">
	</alaris-select-filter2>
	<alaris-options2 *ngFor="let opt of filterTemplatesList"
					 [attr.data-testid]="'templateOption/' + opt.name" [value]="opt.id">
		{{ opt.name }}
	</alaris-options2>
</alaris-select2>
<alaris-select2 [displayWith]="displaySender.bind(this)"
				[errors]="senderErrors"
				[formControl]="this.channelForm.controls.sender"
				[label]="'senderIds.senderId' | translate"
				[placeholder]="'senderIds.senderId' | translate"
				[required]="true"
				data-testid="senderSelect" selectedLabel="name">
	<alaris-select-filter2 *ngIf="senders.length > 5"
						   [formControl]="filterSendersControl" data-testid="senderFilter">
	</alaris-select-filter2>
	<alaris-options2 *ngFor="let opt of filterSendersList"
					 [attr.data-testid]="'senderOption/' + opt.name" [value]="opt.id">
		{{ opt.name }}
	</alaris-options2>
</alaris-select2>

<app-text-area-editor [channel]="trafficType"
					  [formControl]="channelForm.controls.message!.controls.text" data-testid="messageEditor">
</app-text-area-editor>

<ng-container *ngIf="trafficType !== TrafficType.SMS">
	<div class="control-title">{{ 'templates.image' | translate }}</div>
	<alaris-input [formControl]="urlImageControl"
				  [label]="'templates.urlImage' | translate"
				  [placeholder]="'templates.urlImage' | translate"
				  class="control"
				  data-testid="urlImageInput" type="url">
	</alaris-input>

	<div class="control-title">{{ 'templates.actionButton' | translate }}</div>
	<alaris-input [formControl]="buttonTextControl"
				  [label]="'templates.buttonCaption' | translate"
				  [placeholder]="'templates.buttonCaption' | translate"
				  class="control" data-testid="buttonCaptionInput">
	</alaris-input>
	<alaris-input [formControl]="actionUrlControl"
				  [label]="'templates.buttonLink' | translate"
				  [placeholder]="'templates.buttonLink' | translate"
				  class="control"
				  data-testid="buttonLinkInput" type="url">
	</alaris-input>

	<fieldset>
		<div class="control-title">{{ 'campaigns.priority' | translate }}</div>
		<div class="radio-button-zone">
			<alaris-radio [alarisComplexTooltip]="tooltipPriorityComponent"
						  [attr.data-testid]="'priorityRadio/' + MessagePurpose.TRANSACTION"
						  [componentData]="MessagePurpose.TRANSACTION"
						  [formControl]="priority"
						  [id]="'Transactional'"
						  [value]="MessagePurpose.TRANSACTION" name="priority">
				{{ 'campaigns.transactional' | translate }}
			</alaris-radio>
			<alaris-radio [alarisComplexTooltip]="tooltipPriorityComponent"
						  [attr.data-testid]="'priorityRadio/' + MessagePurpose.PROMOTION"
						  [componentData]="MessagePurpose.PROMOTION"
						  [formControl]="priority"
						  [id]="'Promotional'"
						  [value]="MessagePurpose.PROMOTION" name="priority">
				{{ 'campaigns.promotional' | translate }}
			</alaris-radio>
		</div>
	</fieldset>
</ng-container>
