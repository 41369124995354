<div *ngIf="channel === channelsType.SMS; else IM" class="container">
	<span class="sms-chars">{{ ('gl.symbols' | translate) + ': ' + messageCounts.smsChars }}</span>
	<div class="sms-parts"> {{ messageCounts.smsParts + ' ' + ('gl.sms' | translate) }}</div>
</div>

<ng-template #IM>
	<div class="container sms-chars">
		{{ messageCounts.smsChars + ' / 1000' }}
	</div>
</ng-template>
