import { ChangeDetectorRef, Inject } from '@angular/core';
import { BehaviorSubject, EMPTY, finalize, switchMap } from 'rxjs';

import { Contact } from '@campaign-portal/namespace/entities/contacts/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { RPCRequestParams, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR,
	TableEntityField,
	TableSelectionIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { ContactActionsComponent, ContactsDialogType } from '../dialogs/contact-actions/contact-actions.component';
import { EditContactComponent } from './contacts-table/edit-contact/edit-contact.component';
import { ContactsService } from '../services/contacts.service';
import { ContactFieldsService } from '../services/contact-fields.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class ContactsActions extends AlarisComplexTableComponent<Contact<exist>> {

	override sorting: TableSortIndicator = new Map()
		.set('creationDate', { enabled: true, value: SortDirection.DESC });
	override tHeaders: TableEntityField[] = [];
	override readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	// eslint-disable-next-line @typescript-eslint/member-ordering
	override readonly create = this.edit;

	constructor(
		public readonly contactsService: ContactsService,
		public readonly contactFieldsService: ContactFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly tableType: TableTypes,
		public override readonly stateService: AlarisTableStateService,
		public readonly dialog: AlarisDialogService,
		public readonly filesService: AlarisFilesService,
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profileService: AlarisProfileService
	) {
		const enabled = profileService.allowed([CP_PERMISSIONS.CONTACTS_E]);
		super(
			contactsService,
			contactFieldsService,
			tableSettingsService,
			editPanel,
			cd,
			tableType,
			{
				filters: {
					title: 'gl.filter.filters',
					enabled: true
				},
				settings: {
					title: 'table.settings.tableSettings',
					enabled: true
				},
				refresh: {
					title: 'actions.refreshPage',
					enabled: false
				},
				import: {
					title: 'actions.importContacts',
					enabled
				},
				create: {
					title: 'contacts.addContact',
					enabled
				}
			},
			stateService
		);
	}

	edit(item?: Contact | Contact[]): void {
		if ( Array.isArray(item) ) {
			throw new Error('You can edit only one contact at a time');
		}
		this.editPanel.open(EditContactComponent, EditPanelWidth.SM, {
			customFields: this.tHeaders.filter(
				(head) => {
					return head.variable !== 'phone' && head.variable !== 'contactGroups';
				}
			),
			contact: item,
			toStopList: this.id === 'stoplist'
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(
				(result) => {
					if ( result ) {
						this.load();
					}
				}
			);
	}

	override import(): void {
		// todo check if necessary or implement
		console.log('import');
	}

	export(item: Contact<exist> | Contact<exist>[] | TableSelectionIndicator<Contact<exist>>): void {
		this.loading$.next(true);
		const params: RPCRequestParams = item === null
			? { Filters: this.readParams.Filters }
			: {};
		this.contactsService.export(item, params)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				switchMap((resp) => {
					return resp.Success ?
						this.filesService.export(resp.Data[0]?.id, resp.Data[0]?.name)
						: EMPTY;
				}),
				finalize(() => {
					this.loading$.next(false);
				})
			)
			.subscribe();
	}

	sendCampaign(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		alert(JSON.stringify(item));
		// todo add sending campaigns
	}

	delete(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		this.openSpecificDialog('Delete', item);
	}

	removeFromGroup(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		this.openSpecificDialog('RemoveFromGroup', item);
	}

	addToGroup(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		this.openSpecificDialog('AddToGroup', item);
	}

	moveToGroup(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		this.openSpecificDialog('MoveToGroup', item);
	}

	moveToStopList(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		this.openSpecificDialog('MoveToStopList', item);
	}

	removeFromStopList(item?: Contact | Contact[] | TableSelectionIndicator<Contact>): void {
		this.openSpecificDialog('RemoveFromStopList', item);
	}

	removeActions(): void {
		this.rowActions = [
			{
				icon: 'icon-password-show',
				label: 'actions.details',
				action: this.edit.bind(this)
			}
		];
		this.dropDownRowActions = [];
		this.mainActions = [];
		this.additionalActions = [];
		this.ctxActions = [];
	}

	protected openSpecificDialog(
		type: ContactsDialogType,
		item?: Contact | Contact[] | TableSelectionIndicator<Contact>,
		stopList = false
	): void {
		this.dialog.open(ContactActionsComponent, {
			data: {
				contacts: item,
				params: this.readParams,
				stopList,
				type
			},
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.selection = [];
					this.load();
				}
			});
	}
}
