import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { Subject, takeUntil } from 'rxjs';
import { AlarisFilesService, formatBytes } from '@campaign-portal/components-library';

@Component({
	selector: 'app-recipients',
	templateUrl: './recipients.component.html',
	styleUrls: ['./recipients.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecipientsComponent implements OnDestroy {

	@Input() campaign!: Campaign;
	protected readonly formatBytes = formatBytes;
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly fs: AlarisFilesService
	) {
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	export(fileId: string, fileName?: string): void {
		this.fs.export(fileId, fileName).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
	}
}
