import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Subject } from 'rxjs';

import { AlarisEditPanelService, AlarisLanguageService, InputError } from '@campaign-portal/components-library';

import { ApiToken } from '@campaign-portal/namespace/entities/api/specs';
import { creating } from '@campaign-portal/namespace/common/id';

import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { ApiKeysService } from '../../api.service';
import { CPCustomValidators } from '@helpers/validators/custom-validators';

const ONE_DAY_TO_EXPIRATION = 1;

@Component({
	selector: 'app-create-key',
	templateUrl: './create-key.component.html',
	styleUrl: './create-key.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateApiKeyComponent extends CanDeactivateComponent {

	readonly form = new FormGroup<{
		name: FormControl<string>;
		expirationDate: FormControl<number>,
		callbackURL: FormControl<string>
	}>({
		name: new FormControl<string>(
			'', {
				nonNullable: true,
				validators: [
					Validators.required,
					Validators.maxLength(40),
					CPCustomValidators.hasTemplateDuplicate(null, this.apiService.list)
				]
			}
		),
		expirationDate: new FormControl<number>(ONE_DAY_TO_EXPIRATION, { nonNullable: true }),
		callbackURL: new FormControl<string>(
			'',
			{
				nonNullable: true,
				validators: Validators.pattern(CPCustomValidators.URL_REGEXP)
			}
		)

	});
	readonly periods = new Map([ // Map -> to keep order in keyValue pipe
		[1, '1 day'],
		[7, '7 days'],
		[30, '30 days'],
		[90, '3 months'],
		[365, '1 year'],
		[0, 'never']
	]);
	readonly errors: InputError[] = [
		{ key: 'required', value: 'errors.required' },
		{ key: 'duplicate', value: 'errors.duplicate' },
		{ key: 'maxlength', value: 'errors.maxCharacters', params: { amount: '40' } },
		{ key: 'pattern', value: 'errors.pattern' }
	];

	readonly originalOrder = (): number => 0;
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	override readonly ngUnsubscribe = new Subject<void>();
	private readonly destroyedRef = inject(DestroyRef);

	constructor(
		public readonly apiService: ApiKeysService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly guard: CanDeactivateGuardService,
		private readonly lService: AlarisLanguageService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
	}

	daysToExpiration(numberOfDays: number): string {
		const now = new Date();
		const result = now.setDate(now.getDate() + numberOfDays);
		return new Date(result).toISOString();
	}

	displayPeriod(key: number): string {
		return this.lService.translate('api.periods.' + this.periods.get(key) ?? '');
	}

	save(): void {
		this.allowedDeactivation.next(false);
		const [
			name, expires, callbackURL
		] = [
			this.form.controls.name.value,
			this.form.controls.expirationDate.value
				? this.daysToExpiration(this.form.controls.expirationDate.value) : null,
			this.form.controls.callbackURL.value
		];
		const apiKey: ApiToken<creating> = {
			id: null,
			name,
			expires
		};
		callbackURL ? apiKey.callbackURL = callbackURL : null;

		this.apiService.create(apiKey)
			.pipe(takeUntilDestroyed(this.destroyedRef))
			.subscribe(
				(resp) => {
					this.allowedDeactivation.next(true);
					if ( resp.Success ) {
						this.close();
						this.apiService.openSpecificDialog(name ?? '', resp.Data[0], () => {
						});
					}
				}
			);
	}

	close(): void {
		this.editPanel.close();
	}
}
