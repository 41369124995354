import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ApproximateData } from '@campaign-portal/namespace/entities/campaigns/specs';
import { TrafficType } from '@campaign-portal/namespace/common/enums';

import { AlarisBalanceService, ChannelUtilsService } from '@campaign-portal/components-library';

import { CampaignWizardService } from '../campaign-wizard.service';
import { CampaignsService } from '../../services/campaigns.service';
import { CampaignChannelSettingsControls } from '../../shared/types/form-types';

enum CampaignSchedule {
	NOW,
	SCHEDULED
}

@Component({
	selector: 'app-campaign-completed',
	templateUrl: './campaign-completed.component.html',
	styleUrls: ['./campaign-completed.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignCompletedComponent implements OnInit, OnDestroy {

	// scheduler
	readonly CampaignSchedule = CampaignSchedule;
	readonly sendTypeControl = new FormControl<CampaignSchedule>(CampaignSchedule.NOW, { nonNullable: true });

	// notifications
	readonly notificationControl = new FormControl<boolean>(
		!!this.cw.settingsControls.emailReportAddresses.value || false,
		{ nonNullable: true }
	);

	// analysis
	approximateCampaignData!: ApproximateData;

	// Chart
	chartValue: number[] = [];
	chartLabels: string[] = [];
	activeItemIndex = NaN;

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly cw: CampaignWizardService,
		public readonly cu: ChannelUtilsService,
		public readonly balance: AlarisBalanceService,
		public readonly campaignsService: CampaignsService,
		private readonly translate: TranslateService,
		private readonly cd: ChangeDetectorRef
	) {
	}


	get amount(): number {
		return isNaN(this.activeItemIndex) ?
			this.approximateCampaignData?.recipients : this.chartValue[this.activeItemIndex];
	}

	get label(): string {
		return isNaN(this.activeItemIndex) ?
			this.translate.instant('gl.total') :
			this.chartLabels[this.activeItemIndex];
	}

	get channels(): FormGroup<CampaignChannelSettingsControls>[] {
		return this.cw.channelsArray.sort(ch => ch.value.trafficType !== TrafficType.SMS ? -1 : 1);
	}

	ngOnInit(): void {

		this.notificationControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				if ( value ) {
					this.cw.settingsControls.emailReportAddresses.enable();
					this.cw.settingsControls.failureAlertThreshold.enable();
				} else {
					this.cw.settingsControls.emailReportAddresses.disable();
					this.cw.settingsControls.failureAlertThreshold.disable();
				}
			});

	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	analyseCampaign(): void {

		this.campaignsService.approximate(this.cw.prepare())
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.approximateCampaignData = resp.Data;
				this.cw.totalRecipients = resp.Data.recipients;
				this.chartValue = this.approximateCampaignData.chart.map(item => item.value);
				this.chartLabels = this.approximateCampaignData.chart.map(item => item.name);
				this.cd.markForCheck();
			});
	}


	isItemActive(index: number): boolean {
		return this.activeItemIndex === index;
	}

	onHover(index: number, hovered: any): void {
		this.activeItemIndex = hovered ? index : 0;
	}

	getColor(index: number): string {
		return `var(--tui-chart-${index})`;
	}

	makeNaN(): void {
		this.activeItemIndex = NaN;
	}
}
