import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, switchMap } from 'rxjs';

import { CubeTypeENUM, FilterType, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ChartMessagesByStatus } from '@campaign-portal/namespace/entities/charts/specs';

import {
	AlarisBalanceService,
	AlarisConfigService,
	ChannelUtilsService,
	ChartUtilsService,
	Day,
	FIRST_DAY,
	RowActionSimple,
	SharedTemplatesService
} from '@campaign-portal/components-library';

import { calculateBarChartMax, generateAxisYLabels, sumElementsInArray } from '@helpers/utils/math';
import { TotalProgressChart } from '@helpers/types/app.classes-interfaces';
import { ChartsService } from '../charts.service';
import { TuiPoint } from '@taiga-ui/core';
import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-message-by-status-chart',
	templateUrl: './message-by-status-chart.component.html',
	styleUrls: ['./message-by-status-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageByStatusChartComponent implements OnInit, OnDestroy, AfterViewInit {
	readonly periodType = new FormControl<string>(this.cu.periodTypes[2].value, { nonNullable: true });
	readonly range = new FormControl();
	readParams: RPCRequestParams = {};
	// Chart
	value: TuiPoint[][] = [];
	labelsX: string[] = [];
	labelsY: string[] = [];
	hintX: string[] = [];
	legend: string[] = [];
	maxChartHeight = 0;
	totalMessages = 0;
	totalCost = 0;
	totals: TotalProgressChart[] = [];
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly FIRST_DAY = FIRST_DAY;

	chartControls = new FormGroup({});
	chartVisibilityConfig: { control: FormControl<boolean>; type: string; percent: string }[] = [];
	trafficTypeControl = new FormControl<null | TrafficType>(null);
	isTrafficFilterOpen = false;
	actions: RowActionSimple<string>[] = [
		{
			icon: '',
			label: 'dashboard.allTraffic',
			action: (): void => this.trafficTypeControl.setValue(null)
		},
		{
			icon: '',
			label: this.channelUtils.name(TrafficType.VIBER),
			action: (): void => this.trafficTypeControl.setValue(TrafficType.VIBER)
		},
		{
			icon: '',
			label: this.channelUtils.name(TrafficType.SMS),
			action: (): void => this.trafficTypeControl.setValue(TrafficType.SMS)
		}
	];
	protected readonly CubeTypeENUM = CubeTypeENUM;
	private readonly destroyedRef = inject(DestroyRef);

	constructor(
		public readonly cu: ChartUtilsService,
		public readonly templates: SharedTemplatesService,
		public readonly alarisConfig: AlarisConfigService,
		public readonly bs: AlarisBalanceService,
		public readonly channelUtils: ChannelUtilsService,
		private readonly chartsService: ChartsService,
		private readonly cd: ChangeDetectorRef
	) {
	}

	get lastWeek(): Day {
		return Day.currentLocal().append({ day: -6 });
	}

	ngOnInit(): void {
		this.range.valueChanges
			.pipe(
				switchMap(range => {
					this.loading$.next(false); // Avoid set more than one portal
					this.readParams = {
						...this.readParams,
						Grouping: {
							CubeType: this.cu.periodCubeTypeReducer(this.periodType.value),
							Groups: [], isDetailed: false, isTotal: false
						},
						Filters: [{ Field: 'groupingDate', Type: FilterType.BETWEEN, Value: range }]
					};
					if ( this.trafficTypeControl.value ) {
						this.readParams.Filters?.push({
							Field: 'trafficType', Type: FilterType.IN, Value: [this.trafficTypeControl.value]
						});
					}
					this.loading$.next(true);
					return this.chartsService.messagesByStatusChartRead(this.readParams);
				}),
				takeUntilDestroyed(this.destroyedRef)
			)
			.subscribe(({ Data }) => {
				this.updateChartData(Data);
				// rerender
				this.loading$.next(false);
				this.cd.markForCheck();
			});

		this.trafficTypeControl.valueChanges
			.pipe(takeUntilDestroyed(this.destroyedRef))
			.subscribe(() => {
				// to update chart data
				this.range.updateValueAndValidity();
			});
	}


	ngAfterViewInit(): void {
		// applying templates;
		this.cd.markForCheck();
	}

	updateChartData(data: ChartMessagesByStatus): void {
		// convert to LineChart format Point[][]
		this.value = data.values.map(line => line.map((v, i) => [i, v]));

		this.maxChartHeight = calculateBarChartMax(data.values);
		this.labelsX = this.cu.reduceLabelsX(this.periodType.value, data.labels);
		this.hintX = data.labels;
		this.labelsY = generateAxisYLabels(this.maxChartHeight, 4);
		this.legend = data.datasetName.map(label => 'enums.' + label.toUpperCase());
		// totals
		this.totalMessages = sumElementsInArray(data.values.flat());
		this.totals = this.legend.map((label, index) => {
			return this.cu.calcTotals(label, data.values[index] ?? []);
		});
		this.totalCost = data.totalCost;


		this.generateChartControls(data.datasetName);
		this.generateChartConfig(data.datasetName);
	}

	ngOnDestroy(): void {
		this.loading$.next(false);
		this.loading$.complete();
	}

	generateChartControls(dataset: string[]): void {
		dataset.forEach(s => {
			if ( !this.chartControls.get(s) ) {
				this.chartControls.addControl(s, new FormControl(true));
			}
		});
	}

	generateChartConfig(dataset: string[]): void {
		this.chartVisibilityConfig = [];

		dataset.forEach((s, i) => this.chartVisibilityConfig.push({
			control: this.chartControls.get(s) as FormControl<boolean>,
			type: s,
			percent: ((this.totals[i].total * 100) / this.totalMessages).toFixed(1)
		}));
	}


	getFormat(type: string | undefined = CubeTypeENUM.MONTH): string {
		switch (type) {
			case CubeTypeENUM.YEAR :
				return 'MMMM y';
			case CubeTypeENUM.MONTH :
				return 'MMMM d, y';
			case CubeTypeENUM.DAY :
				return 'MMMM d, y, h:ss';
			case 'WEEK' :
				return 'MMMM d, y, EEEE';
			default:
				return 'MMMM d, y';
		}
	}
}
